import { create } from 'zustand';

export interface GlobalState {
    activeSourceRecordTypeForDrawer: string;
    activeBusinessObjectForDrawer: string;
    libraryCollapsed: boolean;
    libraryBusinessObjectsCollapsed: boolean;
    libraryRecordTypesCollapsed: boolean;
    libraryDataSourcesCollapsed: boolean;
    libraryReportsCollapsed: boolean;
    sidebarExpanded: boolean;
    libraryEnabled: boolean;

    pageDirty: boolean;
    toggleLibrary: () => void;
    toggleLibraryBOs: () => void;
    toggleLibraryRecordTypes: () => void;
    toggleLibraryDatasources: () => void;
    toggleLibraryReports: () => void;
    setActiveSourceRecordType: (srtId: string) => void;
    setActiveBusinessObject: (businessObjectId: string) => void;
    toggleSidebar: () => void;
    setPageDirty: (dirty: boolean) => void;


    setLibraryEnabled: (enabled: boolean) => void;
}

const useGlobalState = create<GlobalState>(set => ({
    activeSourceRecordTypeForDrawer: '',
    activeBusinessObjectForDrawer: '',
    libraryCollapsed: false,
    libraryBusinessObjectsCollapsed: true,
    libraryRecordTypesCollapsed: true,
    libraryDataSourcesCollapsed: true,
    libraryReportsCollapsed: true,
    sidebarExpanded: true,
    libraryEnabled: true,
    pageDirty: false,

    setPageDirty: (dirty: boolean) => {
        set((state: any) => {
            return { pageDirty: dirty };
        });
    },

    setLibraryEnabled: (enabled: boolean) => {
        set((state: any) => {
            return { libraryEnabled: enabled }
        });
    },

    setActiveSourceRecordType: (srtId: string) => {
        set((state: any) => {
            return {activeSourceRecordTypeForDrawer: srtId};
        });
    },

    toggleSidebar: () => {
        set((state: any) => {
            return {
                sidebarExpanded: !state.sidebarExpanded
            }
        });
    },

    setActiveBusinessObject: (businessObjectId: string) => {
        set((state: any) => {
            return {activeBusinessObjectForDrawer: businessObjectId};
        });
    },

    toggleLibrary: () => {
        set((state: any) => {
            return {libraryCollapsed: !state.libraryCollapsed}
        });
    },

    toggleLibraryBOs: () => {
        set((state: any) => {
            return {libraryBusinessObjectsCollapsed: !state.libraryBusinessObjectsCollapsed}
        });
    },

    toggleLibraryRecordTypes: () => {
        set((state: any) => {
            return {libraryRecordTypesCollapsed: !state.libraryRecordTypesCollapsed}
        });
    },

    toggleLibraryDatasources: () => {
        set((state: any) => {
            return {libraryDataSourcesCollapsed: !state.libraryDataSourcesCollapsed}
        });
    },

    toggleLibraryReports: () => {
        set((state: any) => {
            return {libraryReportsCollapsed: !state.libraryReportsCollapsed}
        });
    }
}));

    

export default useGlobalState;