import React, { Component, ReactElement, ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

export default class Card extends Component<Props> {
    render() {
        return (<div className="card">
            <div className="card-body">
                {this.props.children}
            </div>
        </div>);
    }
}

interface CardHeaderProps {
    title: string;
    subtitle?: string;
    children?: ReactNode;
}

export class CardHeader extends Component<CardHeaderProps> {
    render() {
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                        <h5 className="header-title mb-0">{this.props.title}</h5>
                        <p className="text-muted font-13">{this.props.subtitle}</p>
                    </div>
                    <div className="col text-end">
                        {this.props.children}
                    </div>
                    
                </div>
                
            </div>
            
            
        );
    }
}