import RelationshipsList from "@components/businessObjects/RelationshipsList.component";
import SaveButton from "@components/button/SaveButton.component";
import { BusinessObject, BusinessObjectRelationship, BusinessObjectRelationshipORM } from "@models/businessObject";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { useBusinessObjects } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import Select from 'react-select';
import PageTitle from "@components/pageTitle/PageTitle.component";
import { useNavigateToDrawer } from "@components/drawers/DrawerManager.component";
import BusinessObjectSelector from "./BusinessObjectSelector.component";
import AsyncButton from "@components/button/AsyncButton.component";


interface RelationshipAsItPertainsToThisBusinessObject {
    relationshipId: string;
    relatedBusinessObjectId: string;
    relationshipType: 'HAS_MANY' | 'BELONGS_TO';
    foreignKeyName: string;
}

interface Props {
    businessObjectId: string;
}


const BusinessObjectRelationshipsList = ({ businessObjectId }: Props) => {
    const businessObjects = useBusinessObjects();
    const businessObject = useMemo(() => {
        if (businessObjects.data) {
            return businessObjects.data.find(bo => bo.id === businessObjectId);
        }
    }, [businessObjectId, businessObjects.dataUpdatedAt]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [relationships, setRelationships] = useState<BusinessObjectRelationship[]>([]);

    const [parsedRelationships, setParsedRelationships] = useImmer<RelationshipAsItPertainsToThisBusinessObject[]>([]);

    const [saving, setSaving] = useState(false);

    const loadRelationships = useCallback(async () => {
        setLoading(true);
        setError('');

        try {
            const results = await BusinessObjectRelationshipORM.find({
                '$or': [
                    {
                        'child_business_object_id': businessObjectId
                    }, {
                        'parent_business_object_id': businessObjectId
                    }
                ]
            });

            setRelationships(results.records);

            const relationshipsAsItPertainsToThisBusinessObject = results.records.map((rel): RelationshipAsItPertainsToThisBusinessObject => {
                let relatedObjectId: string;
                let relationshipType: 'HAS_MANY' | 'BELONGS_TO';
                if (rel.parent_business_object_id === businessObjectId) {
                    relatedObjectId = rel.child_business_object_id;
                    relationshipType = 'HAS_MANY';
                } else {
                    relatedObjectId = rel.parent_business_object_id;
                    relationshipType = 'BELONGS_TO';
                }
        
                return {
                    relationshipId: rel.id as string,
                    relatedBusinessObjectId: relatedObjectId,
                    relationshipType: relationshipType,
                    foreignKeyName: rel.child_foreign_key_name ? rel.child_foreign_key_name : 'related_record_uuid',
                };
            });

            setParsedRelationships(relationshipsAsItPertainsToThisBusinessObject);

            
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }, [businessObjectId]);

    

    useEffect(() => {
        if (businessObjectId) {
            loadRelationships();

        }
    }, [businessObjectId]);

    const navigateToDrawer = useNavigateToDrawer();
    const navigateToRelationship = useCallback((relId: string) => {
        navigateToDrawer('rel', relId);
    }, [navigateToDrawer]);

    const [newRelationshipBoId, setNewRelationshipBoId] = useState('');
    const [creatingRelationship, setCreatingRelationship] = useState(false);
    const createNewRelationship = useCallback(async () => {
        setCreatingRelationship(true);
        try {
            const newRel = await BusinessObjectRelationshipORM.save({
                id: null,
                child_business_object_id: businessObjectId,
                parent_business_object_id: newRelationshipBoId,
                allow_many_children: true,
            });
            loadRelationships();
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setCreatingRelationship(false);
        }
    }, [newRelationshipBoId, businessObjectId, loadRelationships])



    if (loading || !businessObjects.data) {
        return <div>
            <i className="mdi mdi-loading mdi-spin"></i> Loading relationships
        </div>
    } else if (error) {
        return <p className="alert alert-danger">{error}</p>
    }

    const relationshipTypeOptions = [
        {value: 'HAS_MANY', label: 'Has Many'},
        {value: 'BELONGS_TO', label: 'Belongs To'}
    ];



    return <div>
        {parsedRelationships.length == 0 && (
            <div className="mb-2">
                <strong>No relationships.</strong> To create relationships between business objects, you can connect them in the <Link to="/">mission control screen</Link> or use the form below.
            </div>
        )}
        {parsedRelationships.map(rel => {
            const relatedBo = businessObjects.data!.find(bo => bo.id === rel.relatedBusinessObjectId);
            return <div onClick={() => navigateToRelationship(rel.relationshipId)} className="shadow-box mb-2 p-2 action">
                <span>
                    {rel.relationshipType === 'BELONGS_TO' && <span>Belongs to</span>}
                    {rel.relationshipType === 'HAS_MANY' && <span>Has many</span>}
                </span>: <strong>{relatedBo!.name}</strong>
            </div>
        })}
        <hr />
        <Form.Group>
            <Form.Label>Create new relationship:</Form.Label>
            <BusinessObjectSelector
                selectedId={newRelationshipBoId}
                blacklist={parsedRelationships ? parsedRelationships.map(rel => rel.relatedBusinessObjectId).concat([businessObjectId]) : [businessObjectId]}
                onSelect={(bo: BusinessObject | undefined) => {
                    setNewRelationshipBoId(bo ? bo.id as string : '');
                }}
            />
            <AsyncButton
                className="mt-2"
                variant="pliable"
                disabled={!newRelationshipBoId}
                loading={creatingRelationship}
                text="Create Relationship"
                onClick={createNewRelationship}
            />

        </Form.Group>
        
    </div>
        
    
    
    
}

export default BusinessObjectRelationshipsList;
