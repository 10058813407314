import SourceRecordTypeName from "@components/sources/SourceRecordTypeName.component";
import { BusinessObjectField, BusinessObjectMapOption } from "@models/businessObject";
import { SourceRecordType, useColumnLabeler, useColumnLabels } from "@models/source";
import { useSourceRecordTypes } from "@stores/data.store";
import produce from "immer";
import { useCallback, useMemo, useState } from "react";
import { DragDropContext, Droppable, Draggable, DragUpdate, DropResult } from "react-beautiful-dnd";
import styled from 'styled-components';
import { Badge } from "react-bootstrap";
import { shortid } from "@services/id.service";


/**
 * DISCUSSION SUMMARY
 * 
 * If no interaction within a few seconds, create some random edges and animate them to show that you can connect
 * 
 * If they check the box to "use this field to merge records", then we have rules around combining attributes from different sources, and we can show an error
 */
const SingleMapOptionStyles  = styled.div`
    background: white;
    border-radius: 5px;
    padding: 12px 12px 12px 37px;
    display: block;
    user-select: none;



    &.combine-target {
        background-color: var(--ct-light-gray);
    }

`

const SingleMapOptionText = styled.div`
font-family: "Fira Code", monospace;
    span.source {
        font-weight: bold;
        color: black;
    }
    span.attribute {
        margin-left: .5rem;
    }
`


interface Props {
    mapOptions: BusinessObjectMapOption[];
    showPriority: boolean;
    onChangeMapOptions: (newOptions: BusinessObjectMapOption[]) => void
}

const mapOptionId = (mo: BusinessObjectMapOption) => {
    return `mo|srt|${mo.source_record_type_id}|attr|${mo.attribute_key}`;
}


interface MapOptionWrapperProps {
    mapOption: BusinessObjectMapOption;
    isCombineTarget: boolean;
    showPriority: boolean;

    onSeparate: (separatingFrom: string, separating: BusinessObjectMapOption) => void;
    onChangeSeparator: (parentMoId: string, separator: string) => void;
    index?: number;
    onDelete: () => void;
}

interface SingleMapOptionProps {
    mapOption: BusinessObjectMapOption;
    isCombineTarget: boolean;
}

const DndContainer = styled.div`

`



const MultiMapOptionStyles = styled.div`
    background: white;
    border-radius: 5px;
    padding: 12px 12px 12px 37px;
    user-select: none;

    div.header {
        font-family: "Poppins";
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 5px;
    }

    &.combine-target {
        background-color: var(--ct-light-gray);
    }

    div.option {
        &:hover {
            background-color: var(--ct-border-color);
        }

        display: flex;
        .text-container {
            flex: 1;
        }
        button.separate-button {
            background: none;
            border: none;
        }
    }

    
`




const SeparatorSelector = styled.select`
    border: solid 1px var(--ct-border-color);
    margin-left: .5rem;
    color: var(--ct-body-color);
`

interface MultiMapOptionProps {
    mapOptionId: string;
    mapOptions: BusinessObjectMapOption[];
    combinationRule: string;
    concatJoiner: string;
    onSeparate: (separatingFrom: string, mo: BusinessObjectMapOption) => void;
    isCombineTarget: boolean;
    onChangeSeparator: (separator: string) => void;
}
const MultiMapOption = (props: MultiMapOptionProps) => {
    const labelColumn = useColumnLabeler();
    return <MultiMapOptionStyles className={props.isCombineTarget ? 'combine-target': ''}>
        <div className="header">
            Combine With: 
            <SeparatorSelector value={props.concatJoiner} onChange={(e) => props.onChangeSeparator(e.target.value)}>
                <option value=" ">Space ( )</option>
                <option value="-">Hyphen (-)</option>
                <option value="_">Underscore (_)</option>
                <option value=",">Comma (,)</option>
            </SeparatorSelector>
        </div>   
        <Droppable 
            droppableId={props.mapOptionId}
            type="multimap"
        >
            {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                    {props.mapOptions.map((mo, idx) => {
                        return <Draggable
                            draggableId={mo.id}
                            key={mo.id}
                            index={idx}
                        >
                            {draggableProvided => (
                                <div className="option"
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                    ref={draggableProvided.innerRef}
                                    key={mo.id}
                                >
                                    <SingleMapOptionText className="text-container">
                                        <span className="source">
                                            <SourceRecordTypeName sourceRecordTypeId={mo.source_record_type_id}/>:
                                        </span>
                                        <span className="attribute">
                                            {labelColumn(mo.source_record_type_id!, mo.attribute_key!)}
                                        </span>
                                        
                                    </SingleMapOptionText>
                                    <button className="separate-button" onClick={() => props.onSeparate(props.mapOptionId, mo)}>
                                            <i className="mdi mdi-export"></i>
                                        </button>
                                </div>
                            )}
                            

                        </Draggable>
                        
                        
                    })}
                    {provided.placeholder}
                </div>
        )}
        </Droppable>
        
    </MultiMapOptionStyles>
}

const SingleMapOption = (props: SingleMapOptionProps) => {
    const labelColumn = useColumnLabeler();
    return <SingleMapOptionStyles className={props.isCombineTarget ? 'combine-target': ''}>
        <i className="mdi-mdi-drag"></i>
        <SingleMapOptionText>
            <span className="source">
                <SourceRecordTypeName sourceRecordTypeId={props.mapOption.source_record_type_id}/>:
            </span>
            <span className="attribute">
                {labelColumn(props.mapOption.source_record_type_id!, props.mapOption.attribute_key!)}
            </span>
        </SingleMapOptionText>
        
    </SingleMapOptionStyles>
}

const SingleMapOptionWrapper = styled.div`
margin-top: 6px;
position: relative;

`

const MapOptionContents = styled.div`
display: flex;
align-items: center;
`

const DragHandle = styled.div`
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 25px;
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: center;
    &:hover {
        color: black;
    }

    i {
        font-size: 24px;
        display: block;
        position: absolute;
        line-height: 24px;
        top: calc(50% - 12px);

    }
`


const ButtonContainer = styled.div`
align-items: center;
justify-content: center;
display: flex;
margin-right: 1rem;
user-select: none;
button {
    font-size: 18px;
    padding: 0px;
    margin: 0px;
    border-radius: 100%;
    border: none;
    background-color: transparent;
    color: var(--ct-body-color);
    line-height: 24px;
    text-align: center;
    margin-left: .5rem;

    &:hover {
        color: black;
    }
}
`
const MapOptionWrapper = (props: MapOptionWrapperProps) => {

    return <Draggable 
        draggableId={props.mapOption.id}
        key={props.mapOption.id}
        index={props.index!}
    >
        {provided => (
            <SingleMapOptionWrapper
                {...provided.draggableProps}
                className="shadow-box"
                
                ref={provided.innerRef}
                key={props.mapOption.id}
            >
                <DragHandle {...provided.dragHandleProps}>
                    <i className="mdi mdi-drag"></i>
                </DragHandle>
                <MapOptionContents>
                    <div style={{flex: 1}}>
                        {!!props.mapOption.sub_options && (
                            <MultiMapOption
                                concatJoiner={!!props.mapOption.concat_joiner ? props.mapOption.concat_joiner! : ' '}
                                onChangeSeparator={(newSeparator) => props.onChangeSeparator(props.mapOption.id, newSeparator)}
                                onSeparate={props.onSeparate}
                                mapOptionId={props.mapOption.id}
                                combinationRule={props.mapOption.combination_rule || 'CONCAT'}
                                mapOptions={props.mapOption.sub_options!}
                                isCombineTarget={props.isCombineTarget}
                            />
                        )}
                        {!props.mapOption.sub_options && (
                            <SingleMapOption isCombineTarget={props.isCombineTarget} mapOption={props.mapOption}/>
                        )}
                    </div>
                
                    
                    <ButtonContainer>
                        
                        {props.showPriority && <Badge pill bg="info">Priority {props.index! + 1}</Badge>}
                        <button onClick={() => props.onDelete()}>
                            <i className="mdi mdi-delete"></i>
                        </button>
                    </ButtonContainer>
                    {/* <PriorityIndicator>
                        Priority {props.index! + 1}
                    </PriorityIndicator> */}
                </MapOptionContents>
                
                
                
            </SingleMapOptionWrapper>
        )}
    </Draggable>
     
}


const BusinessObjectFieldMap = (props: Props) => {

    const [dragOverId, setDragOverId] = useState('');

    const onSeparate = useCallback((separatingFrom: string, separating: BusinessObjectMapOption) => {
        console.log('SEPARATING', separatingFrom, separating);

        const newOptions = produce(props.mapOptions, draft => {
            const separatingFromIndex = props.mapOptions.findIndex(mo => mo.id === separatingFrom);

            const separatingFromOption = props.mapOptions[separatingFromIndex];

            draft.push(separating);

            if (separatingFromOption.sub_options!.length === 2) {
                const notThisOne = separatingFromOption.sub_options!.find(so => so.id !== separating.id);

                if (!notThisOne) {
                    throw new Error('Could not find other member sub option');
                }
                // Flip it to a single option
                draft[separatingFromIndex] = notThisOne;
                
            } else {
                const subIndex = separatingFromOption.sub_options!.findIndex(so => so.id === separating.id);
                draft[separatingFromIndex].sub_options!.splice(subIndex, 1);
            }
        });

        props.onChangeMapOptions(newOptions);
    }, [props.mapOptions, props.onChangeMapOptions]);

    const handleMainDragEnd = useCallback((res: DropResult) => {
        if (
            res.destination && res.destination.droppableId === res.source.droppableId &&
            res.destination.index === res.source.index
        ) {
            // Didn't do anything
            return;
        };

        
        const newMapOptions = produce(props.mapOptions, draft => {
            const sourceSpl = res.draggableId.split('|');
            console.log('Dropped:', res.draggableId);
            const movingItem = draft[res.source.index];

            if (res.combine && !movingItem.sub_options) {
                const combineTargetIndex = props.mapOptions.findIndex(d => d.id === res.combine?.draggableId);

                console.log()

                if (combineTargetIndex < 0) {
                    
                    throw new Error('Could not find combine target');
                }

                const combineTarget = draft[combineTargetIndex];

                
                if (!combineTarget.sub_options) {
                    draft[combineTargetIndex] = {
                        id: shortid(),
                        concat_joiner: ' ',
                        sub_options: [
                            combineTarget,
                            movingItem,
                        ]
                    }
                } else {
                    draft[combineTargetIndex].sub_options?.push(movingItem);
                }

                // Finally, remove the item being moved so we don't mess with the indexes beforehand
                draft.splice(res.source.index, 1);
            } else if (res.destination) {

                draft.splice(res.source.index, 1);
                draft.splice(res.destination.index, 0, movingItem);

            }

        });

        console.log('New options:', newMapOptions);

        props.onChangeMapOptions(newMapOptions);
    }, [props.onChangeMapOptions, props.mapOptions]);

    const handleSubDragEnd = useCallback((res: DropResult) => {
        // Need to do a little dancing to find the main component in question...

        const draggingId = res.draggableId;
        if (!res.destination) {
            return;
        }
        const newOptions = produce(props.mapOptions, draft => {
            const mainOption = draft.find(d => {
                if (!d.sub_options) {
                    return false;
                }

                return d.sub_options.some(so => so.id === draggingId);
            });

            if (!mainOption) {
                throw new Error('Could not find parent option');
            }

            const movingItem = mainOption.sub_options![res.source.index];
            mainOption.sub_options!.splice(res.source.index, 1);
            mainOption.sub_options!.splice(res.destination!.index, 0, movingItem);

        });

        props.onChangeMapOptions(newOptions);
    }, [props.onChangeMapOptions, props.mapOptions]);
    const onDragEnd = useCallback((res: DropResult) => {
        if (res.type === 'multimap') {
            handleSubDragEnd(res);
        } else {
            handleMainDragEnd(res);
        }
        setDragOverId('');
        
    }, [props.onChangeMapOptions, props.mapOptions]);

    const getRootMapOptionById = useCallback((id: string) => {
        return props.mapOptions.find(mo => mo.id === id);
    }, [props.mapOptions])

    const onDragUpdate = useCallback((res: DragUpdate) => {
        const currentlyDragging = getRootMapOptionById(res.draggableId);
        if (res.combine && !currentlyDragging?.sub_options) {
            
            setDragOverId(res.combine.draggableId);
        } else {
            setDragOverId('');
        }
    }, []);

    const onChangeSeparator = useCallback((parentMoId: string, separator: string) => {
        const newOptions = produce(props.mapOptions, draft => {
            const mo = draft.find(d => d.id === parentMoId);
            if (mo) {
                mo.concat_joiner = separator;
            }
        });

        props.onChangeMapOptions(newOptions);
    }, [props.mapOptions, props.onChangeMapOptions]);

    const onDeleteMapOption = useCallback((moId: string) => {
        const newOptions = produce(props.mapOptions, draft => {
            const moIdx = draft.findIndex(d => d.id === moId);
            if (moIdx >= 0) {
                draft.splice(moIdx, 1);
            }
        });

        props.onChangeMapOptions(newOptions);
    }, [props.mapOptions, props.onChangeMapOptions]);
    return <>
        <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
            <Droppable droppableId="main" isCombineEnabled type="main">
                {provided => (
                    <DndContainer 
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {props.mapOptions.map((mo, idx) => {
                            return <MapOptionWrapper
                                showPriority={props.showPriority}
                                onChangeSeparator={onChangeSeparator}
                                onSeparate={onSeparate}
                                mapOption={mo}
                                index={idx}
                                isCombineTarget={dragOverId === mo.id}
                                onDelete={() => {
                                    onDeleteMapOption(mo.id);
                                }}
                            />
                            
                        })}
                        {provided.placeholder}
                    </DndContainer>
                )}
            </Droppable>
        </DragDropContext>
    </>
}

export default BusinessObjectFieldMap;