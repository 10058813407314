import { MissionControlNodeProps } from '@components/missionControl/dataflow/MissionControlNode.component';
import BuildOrchestrationORM, { BuildOrchestration } from '@models/buildOrchestration';
import BusinessObjectORM, { BusinessObject, BusinessObjectRelationship, BusinessObjectRelationshipORM } from '@models/businessObject';
import DataMartORM, { DataMart, ReportColumn } from '@models/dataMart';
import DataLoadORM from '@models/dataload';
import DraftVersionORM from '@models/draftVersion';
import FileORM from '@models/file';
import { BaseModel } from '@models/orm';
import PipelineORM from '@models/pipeline';
import { NodeFieldJoinPath, PipelineNode, PipelineNodeORM, PipelineNodeRelationship, PipelineNodeRelationshipORM } from '@models/pipelineNode';
import { PipelineOrchestration } from '@models/pipelineOrchestration';
import { ColumnStats, TableLoadResponse } from '@models/shared';
import SourceORM, { ColumnPreference, Source, SourceDataConnection, SourceDataConnectionORM, SourceDataRelationship, SourceDataRelationshipORM, SourceRecordType, SourceRecordTypeORM, SourceType } from '@models/source';
import { Template } from '@models/template';
import TemplateGroupORM, { TemplateGroup } from '@models/templateGroup';
import { closeWaiting, confirmation, showWaiting } from '@services/alert/alert.service';
import ApiService, { ListRecordsResponse, SingleRecordResponse } from '@services/api/api.service';
import ConfigService, { Config, UserConfig } from '@services/config/config.service';
import { parseDateString } from '@services/time.service';
import TrackingService, { Events } from '@services/tracking.service';
import { FilterConfig } from "@models/standardizationPipeline";
import { apiurl } from '@services/url.service';

import {
    useQuery,
    useQueryClient,
    useMutation,
    QueryClient,
} from 'react-query';
import { Viewport, XYPosition } from 'reactflow';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
        },
    },
});

export interface BusinessObjectLineageNode {
    id: string;
    record_type: string;
    record_uuid: string;
    record_source_record_type_id?: string;
    record_business_object_id?: string;
    record_data: {
        [key: string]: any;
    }
}

export interface BusinessObjectLineageEdge {
    from_id: string;
    to_id: string;
}

export interface RecordDetailsResponse {
    data: {
        [key: string]: any
    };
    lineage: {
        nodes: BusinessObjectLineageNode[];
        edges: BusinessObjectLineageEdge[];
    }
}

export interface RelationshipGraphResponse {
    nodes: BusinessObjectLineageNode[],
    edges: BusinessObjectLineageEdge[],
}


export const useProjectConfig = () => {
    return useQuery('project_config', async () => {
        await ConfigService.getInstance().loadConfig();
        return ConfigService.getInstance().config!;
    }, {
        // 30 mins
        staleTime: 60 * 30 * 1000
    });
}

export const useUserConfig = () => {
    return useQuery('user_config', async () => {
        await ConfigService.getInstance().loadUserConfig();
        return ConfigService.getInstance().userConfig;
    }, {
        // 30 mins
        staleTime: 60 * 30 * 1000,
    });
}

export const saveUserConfig = async (userConfig: UserConfig) => {
    await ApiService.getInstance().request('POST', '/user-config', userConfig);
    queryClient.invalidateQueries('user_config');
}

export const enterDraftMode = async (trigger: string, showModal: boolean = true) => {
    let showWaitInt: any;
    if (showModal) {
        showWaitInt = setTimeout(() => showWaiting({message: 'Loading Draft...'}), 500); // show waiting if it takes a while
    }
    // we only have a single draft slot - the the id is the same for all right now
    const draft_version_id = 'draft_mode';
    if (draft_version_id) {
        // persist to local storage for page refresh
        localStorage.setItem('draft_version_id', draft_version_id as string);
        queryClient.setQueryData(['draft_version_id'], draft_version_id);
        clearDraftData();
        if (showModal) {
            clearTimeout(showWaitInt);
            closeWaiting();
        }
        
        TrackingService.track_event(Events.TOGGLE_DRAFT_MODE, {
            trigger: trigger,
            enable: 'DRAFT'
        });
    }
}

export const exitDraftMode = async (trigger?: string) => {
    localStorage.removeItem('draft_version_id');
    queryClient.setQueryData(['draft_version_id'], null);
    await clearDraftData();
    if (typeof trigger !== 'undefined') {
        TrackingService.track_event(Events.TOGGLE_DRAFT_MODE, {
            trigger: trigger,
            enable: 'PROD'
        });
    }
}

export const clearDraftData = async () => {
    // invalidate everything except the draft version
    await invalidateEverything();
 }

export const useDraftVersionId = () => {
    // Will get cached draft version user was working on - if this returns a value they are in draft mode.
    return useQuery('draft_version_id', async () => {
        let version = queryClient.getQueryData(['draft_version_id']);
        if (!version) {
            version = localStorage.getItem('draft_version_id');
        }
        return version;
    }, {
        // 30 mins
        staleTime: 60 * 30 * 1000
    });
}

export interface SnowflakeTableOption {
    name: string;
    schema_name: string;
    database_name: string;
    rows: number;
}

export const useAvailableSnowflakeTables = () => {
    const projectConfig = useProjectConfig();
    return useQuery(['snowflake_tables', projectConfig.data ? projectConfig.data.id : 'no-id'], async () => {
        if (!projectConfig.data || projectConfig.data.database != 'SNOWFLAKE') {
            return [];
        }
        const results = await ApiService.getInstance().request('GET', `/tenant-config/${projectConfig.data.id}/get-snowflake-tables`) as ListRecordsResponse<SnowflakeTableOption>;
        return results.records;
    }, {
        // 30 mins since they can click to refresh,
        staleTime: 60 * 1000 * 30,
    })
}


export const useRecord = (businessObjectId: string, recordUuid: string, includeLineage: boolean = true) => {
    return useQuery(['business_object_record', businessObjectId, recordUuid, includeLineage], async () => {
        const result = await ApiService.getInstance().request('GET', `/businessobjects/${businessObjectId}/record/${recordUuid}`, {
            lineage: includeLineage ? 'y' : 'n'
        }) as RecordDetailsResponse;
        return result;
    }, {
        // 2 mins?
        staleTime: 60 * 1000 * 2
    })
}

export const useRelationshipGraph = (businessObjectId: string, recordUuid: string) => {
    return useQuery(['business_object_relationship_graph', businessObjectId, recordUuid], async () => {
        const result = await ApiService.getInstance().request('GET', `/businessobjects/${businessObjectId}/record/${recordUuid}/relationship-graph`) as RelationshipGraphResponse;
        return result; 
    }, {
        // 2 mins?
        staleTime: 60 * 1000 * 2
    })
}

export const useSourceRecord = (sourceId: string, sourceRecordTypeId: string, recordUuid: string) => {
    return useQuery(['source_record', sourceId, sourceRecordTypeId, recordUuid], async () => {
        const result = await ApiService.getInstance().request('GET', `/sources/${sourceId}/record-type/${sourceRecordTypeId}/record/${recordUuid}`) as SingleRecordResponse<any>;

        result.record._LOADED_AT = parseDateString(result.record._LOADED_AT);
        return result.record;
    }, {
        // 30 mins
        staleTime: 60 * 1000 * 30
    });
}


export const useSources = () => {
    return useQuery('sources', async () => {
        const { records } = await SourceORM.find();
        return records;
    }, {
        staleTime: 30 * 1000 // 30 seconds stale time
    });
}

export const useSource = (sourceId: string) => {
    return useQuery(['sources', sourceId], async () => {
        if (!sourceId) {
            return undefined;
        }
        const record = await SourceORM.findById(sourceId);
        return record;
    }, {
        staleTime: 30 * 1000 // 30 seconds stale time
    });
}

export const saveSource = async (source: Source) => {
    const result = await SourceORM.save(source);
    queryClient.invalidateQueries('sources');
    return result;
}

export const deleteSource = async (source: Source) => {
    const result = await SourceORM.delete(source);
    queryClient.invalidateQueries('sources');
    return result;
}

export const useBusinessObjects = () => {
    return useQuery('business_objects', async () => {
        const { records } = await BusinessObjectORM.find();
        return records;
    }, {
        staleTime: 30 * 1000 // 30 seconds stale time
    });
}

export const useBusinessObject = (businessObjectId: string, enabled: boolean = true) => {
    return useQuery(['business_object', businessObjectId], async () => {
        if (!businessObjectId) {
            return null;
        }
        return await BusinessObjectORM.findById(businessObjectId);
    }, {
        enabled,
        staleTime: 30 * 1000 // 30 seconds stale time
    });
}

export const deleteBusinessObject = async (bo: BusinessObject) => {
    const result = await BusinessObjectORM.delete(bo);
    queryClient.invalidateQueries(['business_object', bo.id]);
    queryClient.invalidateQueries('business_objects');
    invalidateMissionControlDataFlowData();
    return result;
}

export const useBusinessObjectRelationships = (businessObjectId?: string) => {
    return useQuery(['business_object_relationships', businessObjectId], async () => {
        if (!!businessObjectId) {
            const results = await BusinessObjectRelationshipORM.find({
                '$or': [
                    {
                        'child_business_object_id': businessObjectId
                    }, {
                        'parent_business_object_id': businessObjectId
                    }
                ]
            });
            return results.records;

        } else {
            const results = await BusinessObjectRelationshipORM.find();
            return results.records;
        }
        
    }, {
        staleTime: 30 * 1000
    });
}

export const useBusinessObjectRelationship = (relationshipId: string) => {
    return useQuery(['business_object_relationship', relationshipId], async () => {
        if (!relationshipId) {
            return null;
        }
        return await BusinessObjectRelationshipORM.findById(relationshipId);
    }, {
        staleTime: 30 * 1000 // 30 seconds stale time
    });
}

export const useIsInDraftMode = () => {
    const {data: draftVersionId} = useDraftVersionId();
    return !!draftVersionId;
}

export const useBusinessObjectRelationshipPeers = (relationshipId: string) => {
    return useQuery(['business_object_relationship', relationshipId, 'peers'], async () => {
        if (!relationshipId) {
            return [];
        }

        const response = await ApiService.getInstance().request('GET', `/businessobjectrelationships/${relationshipId}/peers`) as ListRecordsResponse<BusinessObjectRelationship>;
        return response.records;

    }, {
        staleTime: 30 * 1000
    })
}

export const saveBusinessObjectRelationship = async (relationship: BusinessObjectRelationship) => {
    const result = await BusinessObjectRelationshipORM.save(relationship);
    queryClient.invalidateQueries(['business_object_relationship', relationship.id as string]);
    invalidateMissionControlDataFlowData();
    return result;
}

export const saveBusinessObject = async (businessObject: BusinessObject) => {
    const result = await BusinessObjectORM.save(businessObject);
    queryClient.invalidateQueries('business_objects');
    queryClient.invalidateQueries(['business_object', businessObject.id]);
    queryClient.invalidateQueries(['report_columns', businessObject.id]);
    return result;
};

export const patchBusinessObject = async (id: string, data: any) => {
    const result = await BusinessObjectORM.patch(id, data);
    queryClient.invalidateQueries('business_objects');
    queryClient.invalidateQueries(['business_object', id]);
    queryClient.invalidateQueries(['report_columns', id]);
    return result;
}

export const invalidateBusinessObjects = () => {
    queryClient.invalidateQueries('business_objects');
    queryClient.invalidateQueries('business_object');
}

export const invalidateBusinessObjectRelationships = () => {
    queryClient.invalidateQueries('business_object_relationships');
    queryClient.invalidateQueries('business_object_relationship');
}

export const saveSourceRecordType = async (sourceRecordType: SourceRecordType) => {
    const result = await SourceRecordTypeORM.save(sourceRecordType);
    invalidateSourceRecordTypes();
    invalidateMissionControlDataFlowData();
    invalidateMissionControlDataSourcesData();
    return result;
};

export const deleteSourceRecordType = async (sourceRecordType: SourceRecordType) => {
    const result = await SourceRecordTypeORM.delete(sourceRecordType);
    invalidateSourceRecordTypes();
    invalidateMissionControlDataFlowData();
    invalidateMissionControlDataSourcesData();
}

export const useSourceDataRelationships = () => {
    return useQuery('source_data_relationships', async () => {
        const { records } = await SourceDataRelationshipORM.find();
        return records;
    }, {
        staleTime: 30 * 1000
    });
};

export const invalidateSourceRecordTypes = () => {
    queryClient.invalidateQueries('source_record_types');
    queryClient.invalidateQueries('source_record_type');
}

export const invalidateSources = () => {
    queryClient.invalidateQueries('sources');
}


export const saveSourceDataRelationship = async (rel: SourceDataRelationship) => {
    const result = await SourceDataRelationshipORM.save(rel);
    queryClient.invalidateQueries('source_data_relationships');
    return result;
}

export const deleteSourceDataRelationship = async (rel: SourceDataRelationship) => {
    const result = await SourceDataRelationshipORM.delete(rel);
    queryClient.invalidateQueries('source_data_relationships');
    return result;
}

export const useSourceRecordTypes = (sourceId: string | null = '', enabled: boolean = true) => {
    return useQuery(['source_record_types', sourceId], async () => {
        let filter = {}
        if (!!sourceId) {
            filter = {'source_id': sourceId}
        }
        const { records } = await SourceRecordTypeORM.find(filter);
        return records;
    }, {
        enabled: enabled,
        staleTime: 30 * 1000,  // 30 seconds stale time
    })
}

export const useSourceRecordType = (sourceRecordTypeId: string, enabled: boolean = true) => {
    return useQuery(['source_record_type', sourceRecordTypeId], async () => {
        if (!sourceRecordTypeId) {
            return null;
        }
        return  await SourceRecordTypeORM.findById(sourceRecordTypeId);
    }, {
        enabled,
        staleTime: 30 * 1000,  // 30 seconds stale time
    })
}

export const invalidateSourceRecordType = (sourceRecordTypeId: string) => {
    return queryClient.invalidateQueries(['source_record_type', sourceRecordTypeId]);
}

export const useSourceDataConnection = (connectionId: string) => {
    return useQuery(['source_data_connection', connectionId], async () => {
        if (connectionId) {
            return await SourceDataConnectionORM.findById(connectionId);

        }
        return null;
    }, {
        staleTime: 10 * 1000
    });
    
}

export const useSourceDataConnections = (sourceRecordTypeId: string = '', relationshipId: string = '') => {
    return useQuery(['source_data_connections', sourceRecordTypeId, relationshipId], async () => {
        if (!sourceRecordTypeId && !relationshipId) {
            return [];
        } else if (sourceRecordTypeId) {
            const { records } = await ApiService.getInstance().request('GET', `/sourcerecordtypes/${sourceRecordTypeId}/connections`) as ListRecordsResponse<SourceDataConnection>;
            return records;
        } else {
            const { records } = await ApiService.getInstance().request('GET', `/businessobjectrelationships/${relationshipId}/connections`) as ListRecordsResponse<SourceDataConnection>;
            return records;
        }
        
        
    }, {
        staleTime: 30 * 1000,  // 30 seconds stale time
    });
}

export const usePipelines = () => {
    return useQuery('pipelines', async () => {
        const { records } = await PipelineORM.find();
        return records;
    }, {
        staleTime: 30 * 1000 // 30 seconds stale time
    });
}


export const usePipelineOrchestration = (pipelineOrchestrationId: string | null, refetchInterval: (data: any | undefined, query: any) => any) =>
    useQuery<PipelineOrchestration>({ queryKey: ['pipeline-orchestration', pipelineOrchestrationId], refetchInterval, queryFn: () => PipelineORM.getPipelineOrchestration(pipelineOrchestrationId as string), enabled: !!pipelineOrchestrationId});

export const useBuildOrchestration = (buildOrchestrationId: string | null, refetchInterval: (data: any | undefined, query: any) => any) =>
    useQuery<BuildOrchestration>({ queryKey: ['build-orchestration', buildOrchestrationId], refetchInterval, queryFn: () => BuildOrchestrationORM.findById(buildOrchestrationId as string), enabled: !!buildOrchestrationId});

export const useBuildOrchestrationHistory = () => {
    return useQuery<BuildOrchestration[]>({ queryKey: ['build-orchestrations', 'history'], queryFn: () => BuildOrchestrationORM.getHistory()})
}

export const useSourceTypes = (enabled: boolean = true) =>
    useQuery<SourceType[]>({ queryKey: ['enabledSourceTypes'], queryFn: () => SourceORM.getEnabledSourceTypes(), enabled });


export const useFiles = (sourceId: string = '') => {
    return useQuery(['files', sourceId], async () => {
        let filter = {}
        if (!!sourceId) {
            filter = {'source_id': sourceId}
        }
        const { records } = await FileORM.find(filter);
        return records;
    }, {
        staleTime: 30 * 1000,  // 30 seconds stale time
    });
}

export const reloadFiles = () => {
    queryClient.invalidateQueries('files');
}

export const useDataMarts = () => {
    return useQuery('datamarts', async () => {
        const { records } = await DataMartORM.find();
        return records;
    }, {
        staleTime: 30 * 1000 // 30 seconds stale time
    });
}

export const invalidateDataMarts = () => {
    queryClient.invalidateQueries('datamarts');
}

export const useDataMart = (dataMartId: string) => {
    return useQuery(['datamarts', dataMartId], async () => {
        if (dataMartId) {
            return DataMartORM.findById(dataMartId);
        }
        return null;
    }, {
        staleTime: 30 * 1000,
    });
}

export const saveDataMart = async (dm: DataMart) => {
    const result = await DataMartORM.save(dm);
    queryClient.invalidateQueries('datamarts');
    return result;
}

export const deleteDataMart = async (id: string) => {
    const result = await DataMartORM.deleteById(id);
    queryClient.invalidateQueries('datamarts');
    return result;
}

export interface MissionControlDataSourceNode {
    id: string;
    source_id: string;
    source_record_type_id: string;
    label: string;
    icon_path: string;
    attributes: ColumnPreference[];
    side: string;
}



interface MissionControlDataSourceEdge {
    id: string;
    source: string;
    source_attribute: string;
    target: string;
    target_attribute: string;
}

interface MissionControlDataSourceResponse {
    nodes: MissionControlDataSourceNode[];
    edges: MissionControlDataSourceEdge[];
}


export const useMissionControlDataSourcesData = (relationshipId: string) => {
    return useQuery(['mission_control_data_sources', relationshipId], async () => {
        if (!relationshipId) {
            return {
                nodes: [],
                edges: [],
            }
        }
        const result = await ApiService.getInstance().request('GET', `/businessobjectrelationships/${relationshipId}/graph`) as MissionControlDataSourceResponse;
        return result;
    }, {});
}

export const invalidateMissionControlDataSourcesData = () => {
    queryClient.invalidateQueries('mission_control_data_sources');
}



export interface MissionControlDataFlowNode {
    id: string;
    data: MissionControlNodeProps,
}


export interface MissionControlDataFlowEdge {
    id: string;
    source: string;
    target: string;
    data: {
        type: 'NODE_RELATIONSHIP' | 'MAPPING' | 'REPORT';
        object_id: string;
        secondary_id?: string;
        status_indicator?: string;
    };
}
export interface MissionControlDataResponse {
    nodes: MissionControlDataFlowNode[];
    edges: MissionControlDataFlowEdge[];
    is_stale: boolean;
}

export const useMissionControlDataFlowData = () => {
    return useQuery('mission_control_data_flow', async () => {
        const result = await ApiService.getInstance().request('GET', '/mission_control/data-flow') as MissionControlDataResponse;
        return result;
    }, {
        staleTime: 1000,
    });
}

export const invalidateMissionControlDataFlowData = () => {
    queryClient.invalidateQueries('mission_control_data_flow');
}


export const useDataLoadsForSRT = (srtId: string) => {
    return useQuery(['dataloads', srtId], async () => {
        if (!srtId) {
            return [];
        }
        const result = await DataLoadORM.find({
            'source_record_type_id': srtId
        });

        return result.records;
    }, {
        staleTime: 30000
    });
}


export const invalidateDataLoadsForSRT = (srtId: string) => {
    queryClient.invalidateQueries(['dataloads', srtId]);
}

interface AmbiguousRecordsResponse {
    child_data: {
        [key: string]: any
    };
    parent_data: {
        [key: string]: any
    }[];
    child_plb_uuid: string;
    group_id: string;
}

export const useExampleAmbiguousRecordsForRelationship = (relationshipId: string) => {
    return useQuery(['relationshipAmbiguousRecords', relationshipId], async () => {
        const result = await ApiService.getInstance().request('GET', `/pipelinenoderelationships/${relationshipId}/example-ambiguous`) as ListRecordsResponse<AmbiguousRecordsResponse>
        return result.records
    }, {
        staleTime: 5 * 60 * 1000,
    })
}

export const useExampleOrphanRecordsForRelationship = (relationshipId: string) => {
    return useQuery(['relationshipOrphanRecords', relationshipId], async () => {
        const result = await ApiService.getInstance().request('GET', `/pipelinenoderelationships/${relationshipId}/example-orphans`) as ListRecordsResponse<any>
        return result.records
    }, {
        staleTime: 5 * 60 * 1000,
    })
}

export const invalidateRelationshipIssueData = () => {
    queryClient.invalidateQueries('relationshipOrphanRecords');
    queryClient.invalidateQueries('relationshipAmbiguousRecords');
}


export const useBusinessObjectData = (
    businessObjectId: string,
    sortColumn: string,
    page: number = 1,
    searchQuery: string = '',
    additionalWheres: string = '',
) => {
    return useQuery([
        'business_object_data', 
        businessObjectId,
        sortColumn,
        page,
        searchQuery,
        additionalWheres,
    ], async () => {
        const result = await ApiService.getInstance().request('GET', `/businessobjects/${businessObjectId}/data`, {
            limit: 100,
            page: page,
            sort: sortColumn,
            q: searchQuery,
            wheres: additionalWheres,
        }) as TableLoadResponse;
        return result;
    }, {
        staleTime: 60 * 1000 * 30
    })
}

export const invalidateAfterBuild = () => {
    queryClient.invalidateQueries('business_object_data');
    queryClient.invalidateQueries('relationshipAmbiguousRecords')
    queryClient.invalidateQueries('relationshipOrphanRecords')
    queryClient.invalidateQueries('report_columns')
    queryClient.invalidateQueries('business_object_relationship')
    queryClient.invalidateQueries('business_object_relationships');
    invalidateMissionControlDataFlowData();
}

export const useSourceData = (
    sourceRecordTypeId: string,
    sortColumn: string,
    page: number = 1,
    searchQuery: string = '',
    additionalWheres: string = '',
) => {
    return useQuery([
        'source_data', 
        sourceRecordTypeId,
        sortColumn,
        page,
        searchQuery,
        additionalWheres,
    ], async () => {
        const result = await ApiService.getInstance().request('GET', `/sourcerecordtypes/${sourceRecordTypeId}/data`, {
            limit: 100,
            page: page,
            sort: sortColumn,
            q: searchQuery,
            wheres: additionalWheres,
        }) as TableLoadResponse;
        return result;
    }, {
        staleTime: 60 * 1000 * 30
    })
}

export const useJoinPaths = (pipelineNodeId: string) => {
    return useQuery(['join_paths', pipelineNodeId], async () => {
        if (!pipelineNodeId) {
            return [];
        }

        const result = await ApiService.getInstance().request('GET', `/pipelinenodes/${pipelineNodeId}/join-paths`) as ListRecordsResponse<NodeFieldJoinPath[]>;
        return result.records;
    })
}


export const useReportColumns = (pipelineNodeId: string) => {
    return useQuery(['report_columns', pipelineNodeId], async () => {
        if (!pipelineNodeId) {
            return [];
        }
        const result = await ApiService.getInstance().request('GET', `/pipelinenodes/${pipelineNodeId}/reporting-columns`) as ListRecordsResponse<ReportColumn>;
        return result.records;
    }, {
        staleTime: 60000,
    });
}



export const useSourceTypeConfig = (sourceTypeId: string) => {
    return useQuery(['source_type_config', sourceTypeId], async () => {
        if (!sourceTypeId) {
            return null;
        }

        const result = await ApiService.getInstance().request('GET', `/sources/source-type/${sourceTypeId}/config`) as SingleRecordResponse<SourceType>;
        return result.record;
    }, {
        staleTime: 60000
    })
}

export const useSourceSetupScript = (sourceId: string) => {
    return useQuery(['source_setup_script', sourceId], async () => {
        if (!sourceId) {
            return null;
        }

        const result = await ApiService.getInstance().request('GET', `/sources/${sourceId}/setup-script`) as SingleRecordResponse<string>;
        return result.record;
    }, {
        staleTime: 60000
    })
}

export const useTemplates = () => {
    return useQuery('templates', async () => {
        const result = await ApiService.getInstance().request('GET', '/templates/all') as ListRecordsResponse<Template>;
        return result.records;
    }, {
        staleTime: 60000,
    })
}

export const useTemplateGroup = (templateGroupId: string) => {
    return useQuery(['template_group_id', templateGroupId], async () => {
        return await TemplateGroupORM.findById(templateGroupId);
    });
}

export const deleteTemplateGroup  = async (templateGroup: TemplateGroup) => {
    const result = await TemplateGroupORM.delete(templateGroup);
    queryClient.invalidateQueries(['template_group_id', templateGroup.id]);
    return result;
}

export const invalidateEverything = () => {
    return queryClient.invalidateQueries();
}

export interface ColumnValueDistribution {
    value: string;
    total: number;
}

export const useColumnValueDistribution = (pipelineNodeId: string, column: string) => {
    return useQuery(['column_value_distribution', pipelineNodeId, column],  async () => {
        if (!pipelineNodeId || !column) {
            return [];
        }
        const result = await ApiService.getInstance().request('GET', `/pipelinenodes/${pipelineNodeId}/column/values`, {
            column_name: column,
        }) as ListRecordsResponse<ColumnValueDistribution>;
        return result.records;
    }, {
        staleTime: 30000,
    })
}

export const useColumnShape = (pipelineNodeId: string, column: string) => {
    return useQuery(['column_shape', pipelineNodeId, column],  async () => {
        // If it's _plb_uuid, we know what it is and can hardcode it
        if (column === '_plb_uuid') {
            return {
                key: '_plb_uuid',
                distinct_values: 0,
                empty_values: 0,
                uniqueness: 1.0,
                density: 1.0,
                min: '',
                max: '',
            }
        }
        const result = await ApiService.getInstance().request('GET', `/pipelinenodes/${pipelineNodeId}/column/shape`, {
            column_name: column
        }) as SingleRecordResponse<ColumnStats>;
        
        return result.record;
    }, {
        staleTime: 30000,
    });
}

export const useDrilldown = (dataMartId: string, metricIdx: number, breakdownValues: string[]) => {
    return useQuery(['drilldown', dataMartId, metricIdx, breakdownValues], async () => {
        const result = await ApiService.getInstance().request('POST', `/datamarts/${dataMartId}/drilldown`, {
            drilldown_metric_idx: metricIdx,
            breakdown_values: breakdownValues,
        }) as ListRecordsResponse<any>;
        return result.records;
    }, {
        staleTime: 30000
    })
}

export const invalidateDrilldowns = (dataMartId: string) => {
    queryClient.invalidateQueries(['drilldown', dataMartId]);

}

export const usePipelineNodes = () => {
    return useQuery('pipeline_nodes', async () => {
        const { records } = await PipelineNodeORM.find();
        return records;
    }, {
        staleTime: 30 * 1000 // 30 seconds stale time
    });
}

export const usePipelineNode = (pipelineNodeId?: string) => {
    return useQuery(['pipeline_nodes', pipelineNodeId], async () => {
        if (!pipelineNodeId) {
            return null;
        }
        return await PipelineNodeORM.findById(pipelineNodeId);
    }, {
        retry: 0,
        staleTime: 30 * 1000 // 30 seconds stale time
    });
}

export const useOutputTableForNode = (pipelineNodeId?: string) => {
    return useQuery(['pipeline_nodes', pipelineNodeId, 'output_table'], async () => {
        if (!pipelineNodeId) {
            return '';
        }
        const resp = await ApiService.getInstance().request('GET', `/pipelinenodes/${pipelineNodeId}/output`) as SingleRecordResponse<any>;
        return resp.record.output_table;
    });
}

export const savePipelineNode = async (node: PipelineNode) => {
    const result = await PipelineNodeORM.save(node);
    invalidatePipelineNodes();
    invalidatePipelineNode(node.id as string);
    invalidateMissionControlDataFlowData();
    return result;
};

export const savePipelineNodeRelationship = async (rel: PipelineNodeRelationship) => {
    const result = await PipelineNodeRelationshipORM.save(rel);
    invalidatePipelineNodeRelationships();
    invalidateMissionControlDataFlowData();
    return result;
};


export const usePipelineNodeRelationshipPeers = (relationshipId: string) => {
    return useQuery(['pipeline_node_relationships', relationshipId, 'peers'], async () => {
        if (!relationshipId) {
            return [];
        }

        const response = await ApiService.getInstance().request('GET', `/pipelinenoderelationships/${relationshipId}/peers`) as ListRecordsResponse<BusinessObjectRelationship>;
        return response.records;

    }, {
        staleTime: 30 * 1000
    })
}

export const invalidatePipelineNode = (pipelineNodeId: string) => {
    return queryClient.invalidateQueries(['pipeline_nodes', pipelineNodeId]);
}

export const invalidatePipelineNodes = () => {
    return queryClient.invalidateQueries('pipeline_nodes');
}

export const invalidatePipelineNodeRelationships = () => {
    return queryClient.invalidateQueries('pipeline_node_relationships');
}

export const usePipelineNodeRelationship = (relationshipId: string) => {
    return useQuery(['pipeline_node_relationships', relationshipId], async () => {
        if (!relationshipId) {
            return undefined;
        }
        const result = await PipelineNodeRelationshipORM.findById(relationshipId);
        return result;
    }, {
        staleTime: 30000,
    });
}

export const usePipelineNodeRelationships = (pipelineNodeId?: string) => {
    return useQuery(['pipeline_node_relationships', pipelineNodeId], async () => {
        if (!!pipelineNodeId) {
            const results = await PipelineNodeRelationshipORM.find({
                '$or': [
                    {
                        'child_node_id': pipelineNodeId
                    }, {
                        'parent_node_id': pipelineNodeId
                    }
                ]
            });
            return results.records;

        } else {
            const results = await PipelineNodeRelationshipORM.find();
            return results.records;
        }
        
    }, {
        staleTime: 30 * 1000
    });
}


export const setViewportInfo = (viewport:Viewport) => {
    queryClient.setQueryData(['viewport'], viewport);
}

export const getViewportInfo = () : Viewport | undefined => {
    return queryClient.getQueryData(['viewport']);
}

export interface SnowflakeColumnOption {
    name: string;
    type: string;
    primary_key: string;
}

export const useSourceColumns = (pipelineNodeId: string) => {
    return useQuery(['pipeline_node_source_columns', pipelineNodeId], async () => {
        if (!pipelineNodeId) {
            return [];
        }
        const results = await ApiService.getInstance().request('GET', `/pipelinenodes/${pipelineNodeId}/source-columns`) as ListRecordsResponse<SnowflakeColumnOption>;
        return results.records;
    }, {
        // 5 mins
        staleTime: 60 * 1000 * 5
    });
}

export const useAvailableBranches = () => {
    return useQuery(['github_branches'], async () => {
        const results = await ApiService.getInstance().request('GET', '/github/branches') as ListRecordsResponse<any>;
        return results.records;
    }, {
        staleTime: 30 * 1000
    });
}

export const useTableData = (
    tablePath: string,
    page: number,
    limit: number,
    filters?: string,
    cacheBuster?: string,
    searchQuery?: string,
    sortBy?: string,
) => {
    return useQuery(['table_data', tablePath, searchQuery, sortBy, page, limit, filters, cacheBuster], async () => {
        const tableParams = {
            q: searchQuery,
            page: page,
            limit: limit,
            sort: sortBy,
            filters: filters,
            cacheBuster: cacheBuster,
        }


        const url = apiurl(tablePath, tableParams);

        const listRecordsResp = await ApiService.getInstance().request('GET', url) as TableLoadResponse;
        return listRecordsResp;
    }, {
        staleTime: 60 * 1000 * 5,
        retry: 0,
    });
}

export const invalidateTableData = () => {
    queryClient.invalidateQueries('table_data');
}

export const toggleThenaWidget = () => {
    const thenaIframeEle = window.document.getElementById(
        'thena-iframe-element'
    ) as HTMLIFrameElement | null;
    thenaIframeEle?.contentDocument?.dispatchEvent(
        new CustomEvent('TOGGLE_THENA_WIDGET')
    );
}


interface GraphDataResponse {
    data: {
        nodes: {
            id: string;
            title: string;
        }[];
        edges: {
            from_id: string;
            to_id: string;
            relationship_id: string;
            id: string;
        }[]
    }
}


export const useReportingTree = () => {
    return useQuery(['reporting-tree'], async () => {
        const response = await ApiService.getInstance().request('GET', '/pipelinenodes/reporting-tree') as GraphDataResponse;
        return response.data;
    })
}