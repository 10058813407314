import { JobEnqueueResponse, SingleRecordResponse } from "@services/api/api.service";
import ORM, { BaseModel } from "./orm";
import BackgroundService from "@services/bg.service";
import { SourceRecordType } from "./source";
import { PipelineNode, PipelineNodeRelationship } from "./pipelineNode";
import { Visualization } from "./visualization";
import { Dashboard } from "./dashboard";




export interface DraftVersion extends BaseModel {
    version_id: string;
    draft_started_at: Date;
    draft_started_by: string;
    is_published: boolean;
    published_at?: Date|null;
    published_by?: string;
}

interface ModelPublishChangeSet<T> 
{ 
    has_model_changes: boolean;
    created: T[];
    updated: T[];
    updated_diff: any[];
    deleted: T[];
}

interface ModelDiff {
    SourceRecordType: ModelPublishChangeSet<SourceRecordType>;
    PipelineNode: ModelPublishChangeSet<PipelineNode>;
    PipelineNodeRelationship: ModelPublishChangeSet<PipelineNodeRelationship>;
    Visualization: ModelPublishChangeSet<Visualization>;
    Dashboard: ModelPublishChangeSet<Dashboard>;
}


export interface PublishPlan {
    next_version_idx: string;
    has_any_changes: boolean;
    diff: ModelDiff;
    total_created: number;
    total_updated: number;
    total_deleted: number;
}


export class _DraftVersionORM extends ORM<DraftVersion>{
    public async enterDraftMode() {
        const enterResp = await this.apiService.request('POST', `/${this.endpoint}/enter_draft_mode`) as SingleRecordResponse<any>;
        return enterResp.record.id;
    }

    public async planPublish() {
        const publishResp = await this.apiService.request('POST', `/${this.endpoint}/plan`, {}) as SingleRecordResponse<PublishPlan>;
        return publishResp.record;
    }

    public async publishChanges(nextVersionIdx: string, publishMessage: string) {
        const publishResp = await this.apiService.request('POST', `/${this.endpoint}/publish`, {
            next_version_idx: nextVersionIdx,
            publish_message: publishMessage
        }) as JobEnqueueResponse;
        const jobResults = await BackgroundService.getInstance().waitForJob(publishResp.job_id);
        return jobResults;
    }
    

    public async undoDraftVersionId() {
        const publishResp = await this.apiService.request('POST', `/${this.endpoint}/undo`) as JobEnqueueResponse;
        const jobResults = await BackgroundService.getInstance().waitForJob(publishResp.job_id);
        return jobResults;
    }
};

const DraftVersionORM = new _DraftVersionORM('draftversions');
export default DraftVersionORM;