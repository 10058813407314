import { TableExplorer } from "@components/datatable/TableExplorer.component";
import ApiService, { ListRecordsResponse } from "@services/api/api.service";
import { useBusinessObject, useBusinessObjectData, useBusinessObjectRelationships, useBusinessObjects } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import styled from 'styled-components';
import { 
    useTable,
    useSortBy, 
    useBlockLayout, 
    useResizeColumns, 
    usePagination 
} from 'react-table';
import { useSticky } from "react-table-sticky";
import { Link, useNavigate } from "react-router-dom";
import { BusinessObject } from "@models/businessObject";
import { summarizeNumber } from "@services/formatting.service";
import { Pane, PaneContent, PaneFooter } from "@pages/PageStructure.component";
import { TableLoadResponse } from "@models/shared";

const Styles = styled.div`
display: flex;
flex-direction: column;
height: 100%;
.table-container {
    height: calc(100% - 40px);
    overflow: scroll;
    width: 100%;
    flex: 1;
}

.footer-container {
    height: 40px;
    line-height: 40px;
    border-top: solid 1px var(--ct-border-color);
    padding: 0px 1rem;
}


.table {
    border: none;
    border-color: var(--ct-border-color);
    margin-bottom: 0px;
    font-size: 13px;

    td, th {
        padding: .2rem .5rem;
        line-height: 20px;
    }

    th {
        border-top: none;
        font-family: "Poppins";
    }

    td:first-child, th:first-child {
        border-left: none;
    }

    td:last-child, th:last-child {
        border-right: none;
    }

    thead tr:first-child {
        border-top: none;
    }

    tbody tr:first-child td {
        border-top: none;
    }

    tbody tr:last-child td {
        border-bottom: none;
    }
    thead {
        position: sticky;
        z-index: 1;
        width: fit-content;
        top: 0;
        box-shadow: var(--ct-border-color) 0px 1px 3px 0px;
    }

    tfoot {
        position: sticky;
        z-index: 1;
        bottom: 0;
    }
}

.resizer {
    display: inline-block;
    background: transparent;
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    ${'' /* prevents from scrolling while dragging on touch devices */}
    touch-action:none;

    &.isResizing {
      background: transparent;
    }
  }

`
interface Props {
    businessObjectId: string;
    showLineageButton?: boolean;
    allowExpand?: boolean;
    searchQuery?: string;
    onRecordCountUpdated?: (newCount: number) => any;
}



const BusinessObjectDataTable = (props: Props) => {
    const businessObject = useBusinessObject(props.businessObjectId);
    const relationships = useBusinessObjectRelationships(props.businessObjectId);

    const businessObjects = useBusinessObjects();

    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('');


    const [recordCount, setRecordCount] = useState(-1);

    const onDataLoaded = useCallback((reportData: TableLoadResponse) => {
        setRecordCount(reportData.total_records);
    }, []);

    useEffect(() => {
        if (recordCount >= 0 && props.onRecordCountUpdated) {
            props.onRecordCountUpdated(recordCount);
        }
    }, [recordCount, props.onRecordCountUpdated]);

    const columns = useMemo(() => {
        if (!businessObject.data || !relationships.data || !businessObjects.data) {
            return [];
        }

        let columnList: any[] = [{
            header: 'Record ID',
            key: '_plb_uuid',
            display: (value: any) => {
                if (!value) {
                    return <span>-</span>
                }
                return <span>{value.toString()}</span>
            }
        }];

        if (props.showLineageButton) {
            columnList.push({
                header: ' ',
                key: '',
                display: (value: any, rowData: any) => {
                    return <Link to={`/business-object/${props.businessObjectId}/data/record/${rowData._plb_uuid.toString()}`}>
                        <i className="mdi mdi-eye"></i> View Lineage
                    </Link>
                }
            });
        }
        
        columnList = columnList.concat(relationships.data.filter(r => r.child_business_object_id === props.businessObjectId).map(r => {
            const relatedBo = businessObjects.data.find((bo: BusinessObject) => bo.id === r.parent_business_object_id);
            return {
                header: r.name ? r.name : 'Related ' + relatedBo!.name,
                key: r.child_foreign_key_name,
                display: (value: any) => {
                    if (!value) {
                        return <span></span>
                    } else if (value.toString() == '<AMBIGUOUS>') {
                        return <Badge bg="danger">Ambiguous</Badge>
                    }
                    return <Link to={`/business-object/${r.parent_business_object_id}/data/record/${value.toString()}`}>{value.toString()}</Link>
                }
            }
        }));

        columnList = columnList.concat(businessObject.data.fields.map(f => {
            return {
                header: f.label,
                key: f.name,
            }
        }));

        console.log('COLUMN LIST:', columnList);

        
        return columnList;
    }, [businessObject.data, relationships.data, businessObjects.data, props.showLineageButton]);
    
    const navigate = useNavigate();
    const onClickExpand = useMemo(() => {
        if (props.allowExpand) {
            return () => {
                navigate(`/data/business-object/${props.businessObjectId}`)
            }
        }
        return undefined;
    }, [props.allowExpand, navigate]);

    return <Pane>
        <PaneContent >
            <TableExplorer
                tablePath={`/businessobjects/${props.businessObjectId}/data`}
                columns={columns}
                searchQuery={props.searchQuery}
                onDataLoaded={onDataLoaded}
                onClickExpand={onClickExpand}
            />
        </PaneContent>
        <PaneFooter>
            <div className="ps-1" style={{lineHeight: '40px'}}>
                {recordCount >= 0 && (
                    <span>{summarizeNumber(recordCount)} total records</span>
                )}
                
            </div>
        </PaneFooter>
    </Pane>
        
     




}

export default BusinessObjectDataTable;