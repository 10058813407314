import { BusinessObjectField } from "@models/businessObject";
import { deleteBusinessObject, invalidateMissionControlDataFlowData, saveBusinessObject, useBusinessObject, useBusinessObjectRelationships, useSourceRecordTypes, useSources } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Offcanvas, Spinner, Tab, Tabs } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { useImmer } from "use-immer";
import AsyncButton from "@components/button/AsyncButton.component";
import DrawerHeader from "@components/drawers/DrawerHeader.component";
import { ColumnRef, TableLoadResponse } from "@models/shared";
import TrackingService, { Events } from "@services/tracking.service";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import BusinessObjectRelationshipsList from "@components/businessObjects/BusinessObjectRelationshipsList.component";
import { promptEmoji } from "@services/alert/alert.service";
import SaveButton from "@components/button/SaveButton.component";
import DeleteButton from "@components/button/DeleteButton.component";
import { useNavigateToDrawer } from "./DrawerManager.component";
import { getStatusIndicatorsForBusinessObject } from "@services/objectStatus/objectStatus.service";
import Warning from "@components/statusIndicators/Warning.component";
import { LibraryItem } from "@components/nav/DataLibrary.component";
import SourceIcon from "@components/sources/SourceIcon.component";
import DataSourceImporter from "@pages/Sources/DataSourceImporter.component";
import BuildOrchestrationORM from "@models/buildOrchestration";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import BusinessObjectFieldTypeSelector from "@components/businessObjects/BusinessObjectFieldTypeSelector.component";
import BusinessObjectDataTable from "@components/businessObjects/BusinessObjectDataTable.component";
import { shortid } from "@services/id.service";



const Header = styled.div`
display: flex;
padding: 8px;

.img-container {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: #ccc;
    overflow: none;
    margin-right: 20px;
}

.action-container {
    width: 100px;
    text-align: right;
}

.text-container {
    flex: 1;
    h2 {
        font-size: 14px;
        color: rgb(121, 121, 121);
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;
        padding-left: 6px;
        margin: 0px;
    }

    h1 {
        padding: 0px;
        margin: 0px;
        color: rgb(15, 14, 49);
        font-weight: 600;
        text-overflow: ellipsis;
        font-size: 24px !important;
        line-height:20px;

        input {
            font-size: 24px !important;
            font-weight: 600 !important;
            line-height: 20px;
            padding: 2px 5px !important;
            height: 30px;
            overflow: visible;
        }


    }

    textarea {
        width: 100%;
    }
}
`

const TableContainer = styled.div`
width: 100%;
border: solid 1px var(--ct-border-color);
height: calc(100% - 100px);
`

interface AttributeComponentProps {
    type: string;
    description: string;
    icon?: string;
    addField?: () => void;
}


interface Props {
    businessObjectId: string;
    activeTab: string;
}

export interface FieldWithIndex {
    field: BusinessObjectField;
    originalIndex: number;
}

const ATTRIBUTE_TYPES: AttributeComponentProps[] = [
    {
        type: 'STRING',
        description: 'Stores arbitrary text of variable length',
    }, {
        type: 'INT',
        description: 'Stores a numeric value without decimals', 
    }, {
        type: 'FLOAT', 
        description: 'Stores a numeric value with decimals', 
    }, {
        type: 'BOOLEAN', 
        description: 'Stores a true/false value', 
    }, {
        type: 'DATETIME', 
        description: 'Stores a date and time value', 
    }, {
        type: 'DATE', 
        description: 'Stores a date value', 
    }
];

const ATTRIBUTE_OPTIONS = ATTRIBUTE_TYPES.map(a => {
    return {
        label: a.type,
        value: a.type,
    }
});

const BusinessObjectDrawer = (props: Props) => {
    const bo = useBusinessObject(props.businessObjectId);
    const [fields, setFields] = useImmer<BusinessObjectField[]>([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [sortAscending, setSortAscending] = useState(true);

    const navigateToDrawer = useNavigateToDrawer();

    const [saving, setSaving] = useState(false);

    const [runningPipeline, setRunningPipeline] = useState(false);
    const relationships = useBusinessObjectRelationships(props.businessObjectId as string);

    const [tableCacheBust, setTableCacheBust] = useState((new Date()).toISOString());

    const navigate = useNavigate();
    const location = useLocation();
    const save = useCallback(async () => {
        if (!bo.data) {
            return;
        }
        await saveBusinessObject({
            ...bo.data,
            fields: [...fields],
            name: name,
            description: description,
            image_url: imageUrl
        });
        bo.refetch();
        if (bo.data!.name !== name) {
            console.log('Changed name!');
            invalidateMissionControlDataFlowData();
            
        } else {
            console.log('NO CHANGE');
        }

    }, [fields, bo.dataUpdatedAt, name, description, imageUrl]);

    const [sharedFields, setSharedFields] = useState<ColumnRef[]>([]);
    const [mergeUsingSharedFields, setMergeUsingSharedFields] = useState(false);
    
    const onDelete = useCallback(async () => {
        navigate(location.pathname);
        if (bo.data) {
            await deleteBusinessObject(bo.data);
        }

    }, [bo.dataUpdatedAt, location]);
    
    useEffect(() => {
        if (!!bo.data) {
            setFields(bo.data.fields.map(f => {
                return {...f}
            }));
            setName(bo.data.name);
            setDescription(bo.data.description);
            setImageUrl(bo.data.image_url as string);
        }
    }, [bo.dataUpdatedAt]);

    const sortedFields: FieldWithIndex[] = useMemo(() => {
        const fieldsWithIndex = fields.map((f, idx) => {
            return {
                field: f,
                originalIndex: idx,
            }
        });
        return fieldsWithIndex.sort((a, b) => {
            let modifier = 1;
            if (!sortAscending) {
                modifier = -1;
            }

            if (!a.field.name) {
                return -1;
            }
            if (!b.field.name) {
                return 1;
            }

            if (a.field.name < b.field.name) {
                return modifier * -1;
            }

            return modifier;
        })
    }, [fields, sortAscending]);

    const removeField = useCallback((idx: number) => {
        setFields(draft => {
            draft.splice(idx, 1);
        })
    }, [fields]);


    const fieldValueChanged = useCallback((idx: number, attribute: keyof BusinessObjectField, value: any) => {
        setFields(draft => {
            // @ts-ignore
            draft[idx][attribute] = value;
        });
    }, [fields]);


    const addField = useCallback(() => {
        setFields(draft => {
            draft.push({
                name: '',
                label: '',
                description: '',
                type: 'STRING',
                id: shortid(),
                part_of_composite_key: false,
            })
        });
    }, [fields]);

    const updateFieldSourceOfTruth = useCallback((idx: number, pipelineId: string) => {
        setFields(draft => {
            if (pipelineId) {
                draft[idx].source_pipeline_priority = [pipelineId];
            } else {
                draft[idx].source_pipeline_priority = [];
            }
        });
    }, [fields]);

    const sources = useSources();
    const sourceRecordTypes = useSourceRecordTypes();

    const dataSourceOptions = useMemo(() => {
        if (!bo.data || !sourceRecordTypes.data) {
            return [];
        }

        return bo.data.input_record_type_ids.map(srt_id => {
            const srt = sourceRecordTypes.data.find(s => s.id === srt_id);

            if (!srt) {
                return {
                    label: 'INVALID',
                    value: '',
                }
            }


            return {
                label: srt.name,
                value: srt.id,
            }
        })
    }, [bo.dataUpdatedAt, sourceRecordTypes.dataUpdatedAt]);

    const columnOptions = useMemo(() => {
        if (!fields) {
            return [];
        }
        return fields.map(f => {
            return {
                label: f.name,
                value: f.name,
            }
        })
    }, [fields]);

    const updateSharedFields = useCallback((values: string[]) => {
        setSharedFields(values.map(v => {
            return {
                column_path: v,
            }
        }));
    }, [sharedFields]);

    const changeIcon = useCallback(() => {
        promptEmoji({
            onConfirm: (emoji: any) => {
                setImageUrl(emoji.imageUrl as string);
            },
            onClose: () => {
                return;
            }
        });
    }, [fields]);


    const runOrchestratedPipeline = async () => {
        try {
            setRunningPipeline(true);
            const orchestration = await BuildOrchestrationORM.buildModel('BusinessObject', bo.data!.id as string);

            if (orchestration.status == 'COMPLETE') {
                toast('info', 'Everything up to date', 'No need to rebuild.');
              
            } else {
                navigate(`/?watchOrchestrationId=${orchestration.id}`);

            }

            TrackingService.track_event(Events.BUSINESS_OBJECT_PROCESS_CLK, {
                business_object_id: props.businessObjectId
            });

        }  catch (err) {
            const errMessage = getErrorMessage(err);
            toast('danger', 'Error', errMessage);
        } finally {
            setRunningPipeline(false);
        }

    }

    const columnOrder = useMemo(() => {
        if (!bo.data) {
            return [];
        }

        let arr = ['_plb_uuid'];

        if (relationships.data) {
            relationships.data.forEach(rel => {
                if (rel.child_business_object_id == bo.data!.id) {
                    arr.push(rel.child_foreign_key_name as string);
                }
            })
        }

        return arr.concat(bo.data!.fields.map(f => f.name));
    }, [bo.dataUpdatedAt, relationships.dataUpdatedAt]);

    const indicators = useMemo(() => {
        if (!bo.data) {
            return []
        }
        return getStatusIndicatorsForBusinessObject(bo.data);
    }, [bo.dataUpdatedAt]);

    const attributesIndicators = useMemo(() => {
        return indicators.filter(i => i.drawerTab === 'attributes');
    }, [indicators]);

    const sourcesIndicators = useMemo(() => {
        return indicators.filter(i => i.drawerTab === 'sources');
    }, [indicators]);

    const gotoMapping = useCallback((bo_id: string, sourceRecordTypeId: string) => {
        navigate(`/mapping/${bo_id}?sourceRecordTypeId=${sourceRecordTypeId}`);
    }, [])

    const onSelectKeyDown = useCallback((e: any) => {
        if (e.key === "Tab") {
            e.preventDefault();
            addField();
        }
    }, []);

    const [noDataError, setNoDataError] = useState(false);

    const onDataLoaded = useCallback((data: TableLoadResponse) => {
        if (data.records.length === 0) {
            setNoDataError(true);
        } else {
            setNoDataError(false);
        }
    }, []);

    const [sourcesLoading, setSourcesLoading] = useState(false);

    const onAddNewSource = useCallback(async (sourceRecordTypeId: string) => {
        if(!bo.data) {
            return;
        }
        // Create the stdization pipeline
        setSourcesLoading(true);

        try {
            await saveBusinessObject({
                ...bo.data,
                input_record_type_ids: bo.data.input_record_type_ids.concat([sourceRecordTypeId])
            });
            bo.refetch();
            navigate(`/mapping/${props.businessObjectId}?sourceRecordTypeId=${sourceRecordTypeId as string}&onSave=rebuild`);
        } catch (err) {
            console.error(err);
        } finally {
            setSourcesLoading(false);
        }

    }, [props.businessObjectId, bo.dataUpdatedAt, navigate]);



 

    // const [activeTab, setActiveTab] = useState('attributes');
    console.log('BO ACTIVE TAB', props.activeTab);
    return <>
            {bo.isLoading && <Spinner/>}
            {bo.data && <>
                <DrawerHeader
                    label="Business Object"
                    title={name}
                    description={description}
                    totalRecords={bo.data.total_records}
                    onChangeTitle={(v: string) => setName(v)}
                    onChangeDescription={(v: string) => setDescription(v)}
                    onIconClick={changeIcon}
                    imageUrl={imageUrl}
                >

                    <SaveButton className="me-1"
                        onClick={save}
                    />
                    <DeleteButton onConfirmDelete={() => onDelete()}/>


                    
                    
                </DrawerHeader>

                <Tabs
                    activeKey={props.activeTab}
                    onSelect={(k) => navigateToDrawer('bo', props.businessObjectId, k as string)}
                    className="mb-3 nav-bordered"
                >
                    <Tab eventKey="data" title="Data">
                        {bo.data && bo.data.input_record_type_ids.length > 0 && (
                            <AsyncButton 
                                className="btn btn-primary mb-3"
                                icon="mdi mdi-play"
                                loading={runningPipeline}
                                text="Rebuild"
                                disabled={runningPipeline}
                                onClick={runOrchestratedPipeline}
                            />
                        )}
                        

                        {noDataError && (
                            <Warning>
                                {bo.data && bo.data.input_record_type_ids.length > 0 && (
                                    <div>No data available! It looks like you need to <strong>build.</strong></div>
                                )}
                                {(!bo.data || bo.data.input_record_type_ids.length == 0) && (
                                    <div>No data available! Do you need to <a role="button" className="colorize" onClick={() => navigateToDrawer('bo', props.businessObjectId, 'sources')}>connect data sources</a>?</div>

                                )}
                            </Warning>
                        )}
                        
                        {!noDataError && (
                            <TableContainer>
                                <BusinessObjectDataTable
                                    allowExpand
                                    onRecordCountUpdated={(count: number) => {
                                        if (count === 0) {
                                            setNoDataError(true);
                                        }
                                    }}
                                    businessObjectId={bo.data.id as string}
                                />
                            </TableContainer>
                            
                            
                        )}
                        
                    </Tab>
                    <Tab eventKey="attributes" title="Fields">
                        <div className="scrollable-container">
                            {indicators.filter(i => i.drawerTab === 'attributes').map(i => {
                                return <Warning>
                                    {i.message}
                                </Warning>
                            })}
                            <table className="table table-centered table-fixed">
                                <thead className="table-light">
                                    <tr>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Description
                                        </th>
                                        <th>
                                            Data Type
                                        </th>
                                        <th style={{width: '60px'}}>
                                            <button onClick={() => addField()} className="btn btn-pliable">
                                                <i className="mdi mdi-plus"></i>
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {sortedFields.map((f) => {

                                        return (
                                            <tr key={f.originalIndex}>
                                                <td>
                                                    <Form.Control autoFocus value={f.field.label} className="inline-edit font-14 mt-1 fw-bold" placeholder="Field Name" onChange={(e) =>fieldValueChanged(f.originalIndex, 'label', e.target.value)}></Form.Control>
                                                

                                                </td>
                                                <td>
                                                    <Form.Control className="inline-edit font-12"value={f.field.description} onChange={(e) => fieldValueChanged(f.originalIndex, 'description', e.target.value)} placeholder="Describe your field"></Form.Control>

                                                </td>
                                                <td>
                                                    <BusinessObjectFieldTypeSelector
                                                        selected={f.field.type}
                                                        onSelect={(newVal: string) => {
                                                            fieldValueChanged(f.originalIndex, 'type', newVal);
                                                        }}
                                                    />
                                                    
                                                </td>
                                                {/* <td>
                                                    <Badge bg="success">100% Agreement Rate</Badge>
                                                </td> */}
                                                
                                                <td className="text-end">
                                                    <a className="action-icon" role="button" onClick={() => removeField(f.originalIndex)}>
                                                        <i className="mdi mdi-delete"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            
                            </table>
                        </div>
                    
                    </Tab>
                    <Tab eventKey="relationships" title="Relationships">
                        <div className="scrollable-container">
                            <BusinessObjectRelationshipsList businessObjectId={props.businessObjectId}/>

                        </div>
                    </Tab>
                    <Tab eventKey="sources" title="Sources">
                        <div className="scrollable-container">
                            {indicators.filter(i => i.drawerTab === 'sources').map(i => {
                                return <Warning>
                                    {i.message}
                                </Warning>
                            })}


                            
                            {bo.data && sourceRecordTypes.data && bo.data.input_record_type_ids.length >= 1 && 
                                <>
                                    <p>Here are all the sources mapped to this business object. Click on one to edit the mapped fields.</p>
                                    <ul style={{margin: '0px', padding: '0px'}}>
                                        {bo.data.input_record_type_ids.map(source_record_type_id => {

                                            const rt = sourceRecordTypes.data.find(srt => srt.id === source_record_type_id);

                                            // Fix to account for possible staleness in the UI, like if I deleted a source but the BO hasn't reloaded yet.
                                            if (!rt) {
                                                return <></>
                                            }
                                            return  <LibraryItem itemType='DATA SOURCE' name={rt?.name as string} onClick={() => gotoMapping(props.businessObjectId, source_record_type_id as string)}>
                                                <SourceIcon image_url={rt!.image_url} />
                                            </LibraryItem>
                                            
                                        })}
                                    </ul>
                                    <div>
                                        <Link to={`/mapping/${props.businessObjectId}`}>Edit All Mappings</Link>
                                    </div>
                                </>
                            }

                            <hr />
                            <h4>Add new source</h4>
                            <DataSourceImporter
                                onSourceAdded={onAddNewSource}
                                allowSelectExisting={true}
                            />
                        </div>
                        
                    </Tab>
                </Tabs>
            </>}
         </>
}

export default BusinessObjectDrawer;