import ORM, { BaseModel } from "./orm";
import { MergeConfig } from "./pipeline";
import { ColumnRef, TableShape } from "./shared";
import { FilterConfig } from "./standardizationPipeline";



export interface BusinessObjectMapOption {
    id: string;
    source_record_type_id?: string;
    attribute_key?: string;
    combination_rule?: string;
    sub_options?: BusinessObjectMapOption[];
    hardcoded_value?: string;
    concat_joiner?: string;
}


export interface BusinessObjectField {
    id?: string; // id set by backend
    name: string;
    label: string;
    type: string;
    description?: string;
    source_pipeline_priority?: string[];
    map_options?: BusinessObjectMapOption[];
    part_of_composite_key: boolean;
}


export interface ConsolidationPipelineConfig {
    merge_using_shared_identifiers: boolean;
    merge_using_shared_fields: boolean;
    shared_fields: ColumnRef[];
}

export interface BusinessObject extends BaseModel {
    name: string;
    description: string;
    fields: BusinessObjectField[];
    table_name?: string;
    shape?: TableShape;

    image_url?: string;
    input_record_type_ids: string[];
    total_records?: number;
    last_build_completed?: string;
    combine_logic_gate?: string;
    combine_required_match_count?: number;
}

const BusinessObjectORM = new ORM<BusinessObject>('businessobjects');
export default BusinessObjectORM;


export interface BusinessObjectRelationshipSourceDataConnection {
    child_srt_id: string;
    child_srt_column: string;
    parent_srt_id: string;
    parent_srt_column: string;
    id?: string;
}

export interface BusinessObjectRelationship extends BaseModel {
    name?: string;
    description?: string;
    parent_business_object_id: string;
    child_business_object_id: string;
    allow_many_children: boolean;
    child_foreign_key_name?: string;
    issue_ambiguous_records?: number;
    issue_orphan_records?: number;

    resolution_method?: string;
    parent_filter?: FilterConfig;
    source_data_connections?: BusinessObjectRelationshipSourceDataConnection[];
}

export const BusinessObjectRelationshipORM = new ORM<BusinessObjectRelationship>('businessobjectrelationships');
