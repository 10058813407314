import { Component } from 'react';
import { useRouteError } from 'react-router';

interface RouteError {
    statusText: string;
    message: string;
}

export default function ErrorPage() {
    const error = useRouteError() as RouteError;

    return (
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-lg-5">
                <div className="card mt-5">
                <div className="card-header pt-4 pb-4 text-center bg-primary">
                    <h1 className="text-white">Oops!</h1>
                </div>
                <div className="card-body p-4">
                    <div className="text-center">
                    <h1 className="text-error mt-4">{error.statusText}</h1>
                    <h4 className="text-uppercase text-danger mt-3">
                        Error code {error.message}
                    </h4>
                    <p className="text-muted mt-3">Sorry bro.</p>

                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
}
