import AsyncButton from "@components/button/AsyncButton.component";
import { loadSeed, regenerateSeed, saveSeed, useSeed } from "@models/seed";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent } from "@pages/PageStructure.component";
import { JobEnqueueResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Handsontable from 'handsontable/base';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import { HotTable } from '@handsontable/react';
import { CellChange, ChangeSource } from "handsontable/common";
import { usePipelineNode } from "@stores/data.store";
import toast from "@services/toast.service";
import { useImmer } from "use-immer";
import produce from "immer";
import { getErrorMessage } from "@services/errors.service";
import SaveButton from "@components/button/SaveButton.component";


registerAllModules();

interface Props {
    seedId: string;
}
const SeedEditor = ( { seedId }: Props) => {
    const seed = useSeed(seedId as string);

    const [generating, setGenerating] = useState(false);
    const regenerate = useCallback(async () => {
        setGenerating(true);
        const jobInfo = await regenerateSeed(seedId as string) as JobEnqueueResponse;
        try {
            const result = await BackgroundService.getInstance().waitForJob(jobInfo.job_id);
            await seed.refetch();
            toast('success', 'Success', 'Table updated with latest distinct values.');

        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setGenerating(false);

        }
        
    }, [seed.dataUpdatedAt]);

    const pipelineNode = usePipelineNode(seed.data && seed.data.generated_from_node_id ? seed.data.generated_from_node_id : '');

    const [updatedTableData, setUpdatedTableData] = useImmer<string[][]>([]);

    useEffect(() => {
        if (seed.data && seed.data.seed_data) {
            setUpdatedTableData(seed.data.seed_data);
        }
    }, [seed.dataUpdatedAt]);
    const tableData = useMemo(() => {
        if (!seed.data) {
            return [];
        }

        return seed.data.seed_data ? seed.data.seed_data : [];
    }, [seed.dataUpdatedAt]);

    const tableDataChanged = useCallback((changes: CellChange[] | null, source: ChangeSource) => {
        if (changes) {
            setUpdatedTableData(draft => {
                changes.forEach(c => {
                    const rowIdx = c[0] as number;
                    const colIdx = c[1] as number;
                    const newValue = c[3] as string;
                    draft[rowIdx][colIdx] = newValue || '';
                });
            });
        }
    }, []);

    const sourceNodeName = useMemo(() => {
        if (pipelineNode.data) {
            return pipelineNode.data.name;
        }
        return '';
    }, [pipelineNode.dataUpdatedAt]);

    const sourceNodeFieldName = useMemo(() => {
        if (!pipelineNode.data || !seed.data) {
            return '';
        }

        if (seed.data.generated_from_node_column) {
            const theField = pipelineNode.data.fields.find(f => f.id === seed.data.generated_from_node_column);
            return theField?.name;
        }
        return '';
    }, [seed.dataUpdatedAt, pipelineNode.dataUpdatedAt]);

    const [saving, setSaving] = useState(false);
    const save = useCallback(async () => {
        if (!seed.data) {
            return;
        }
        setSaving(true);
        try {
            await saveSeed(produce(seed.data, draft => {
                draft.seed_data = updatedTableData;
            }));
            toast('success', 'Success', 'Seed saved. It will be loaded into Snowflake when you rebuild this model.');
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [seedId, updatedTableData, seed.dataUpdatedAt]);

    return <div>
        {seed.data && <>
                        
            {seed.data.generated_from_node_id && <>
            
            
                <div>
                        <div className="mb-2" >
                            <div>This table contains all unique values from the <strong>{sourceNodeFieldName}</strong> column in your <strong><Link to={`/node/${seed.data.generated_from_node_id}`}>{sourceNodeName}</Link></strong> node. You can regenerate the table at any time by clicking on "Regenerate".</div>

                        </div>
                        
                        
                        <HotTable
                            data={tableData}
                            stretchH="all"
                            colHeaders={seed.data.seed_headers}
                            height={220}
                            afterChange={tableDataChanged}
                            licenseKey="non-commercial-and-evaluation"
                        />
                        <div className="buttons mt-2 border-top pt-2">
                            <SaveButton
                                
                                onClick={save}
                                className="me-1"
                            />
                            <AsyncButton
                                onClick={regenerate}
                                loading={generating}
                                text="Regenerate"
                            />
                        </div>
                        
                
                    
                </div>
            </>}
        </>
            
            
        }
    </div>
}

export default SeedEditor;

