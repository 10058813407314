import AsyncButton from "@components/button/AsyncButton.component";
import SaveButton from "@components/button/SaveButton.component";
import LoadingCard from "@components/card/LoadingCard.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import SeedEditor from "@components/seeds/SeedEditor.component";
import Danger from "@components/statusIndicators/Danger.component";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import { regenerateSeed, saveSeed, useTranslationSeed } from "@models/seed";
import { Pane, PaneContent } from "@pages/PageStructure.component";
import ApiService, { JobEnqueueResponse, ListRecordsResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";
import { getErrorMessage } from "@services/errors.service";
import { convertToSnakeCase } from "@services/formatting.service";
import toast from "@services/toast.service";
import { usePipelineNode } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";


interface Props {
    pipelineNodeId: string;
    fieldId: string;
}

interface Translation {
    _plb_column_value: string;
    _plb_translation: string;
}

const PipelineNodeFieldTranslation = (props: Props) => {
    const pipelineNode = usePipelineNode(props.pipelineNodeId);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    const [tableData, setTableData] = useState<Translation[]>([]);
    const [error, setError] = useState('');

    const fieldName = useMemo(() => {
        if (!pipelineNode.data) {
            return '';
        }

        const theField = pipelineNode.data.fields.find(f => f.id === props.fieldId);
        if (theField) {
            return theField.label;
        }
        return '';
    }, [pipelineNode.dataUpdatedAt, props.fieldId]);

    const translationSeed = useTranslationSeed(props.pipelineNodeId as string, props.fieldId);

    const [loading, setLoading] = useState(false);

    const [generating, setGenerating] = useState(false);
    const navigate = useNavigate();
    const generateSeed = useCallback(async () => {
        if (!pipelineNode.data) {
            return;
        }

        if (fieldName == '') {
           toast('danger', 'Error', 'Please build before generating a translation file')
           return;
        }
        
        setGenerating(true);

        const model_name = convertToSnakeCase('translate_' + pipelineNode.data.name + '_' + fieldName.toLowerCase());
        
        // Create a new seed
        const savedSeed = await saveSeed({
            id: null,
            name: model_name,
            description: '',
            table_name: '',
            upstream_node_ids: [],
            fields: [],
            node_type: 'SEED',
            seed_type: 'TRANSLATION',
            seed_headers: ['VALUE', 'TRANSLATION'],
            seed_data: [],
            dbt_model_path: `pliable/translation/${model_name}.csv`,
            generated_from_node_column: props.fieldId,
            generated_from_node_id: pipelineNode.data.id as string,
        });

        const jobInfo = await regenerateSeed(savedSeed.id as string) as JobEnqueueResponse;
        const result = await BackgroundService.getInstance().waitForJob(jobInfo.job_id);
        await translationSeed.refetch();
        setGenerating(false);



    }, [pipelineNode.dataUpdatedAt, fieldName, props.fieldId]);

    const innerContent = useMemo(() => {    
        if (error) {
            return <Danger>{error}</Danger>
        }

        if (translationSeed.isLoading) {
            return <PliableLoader/>
        }

        if (!translationSeed.data) {
            return <div>
                {generating && (
                    <>
                        <i className="mdi mdi-loading mdi-spin"></i> Populating distinct values...
                    </>
                )}
                {!generating && (
                    <div>
                        <p>Click on "Generate translation file" below to create a list of distinct values in this column.</p>
                        
                        <button className="btn btn-primary btn-sm" onClick={generateSeed}>Generate translation file</button>
                    </div>
                )}
            </div>
                
                
        } else if (generating) {
            return <>
                <i className="mdi mdi-loading mdi-spin"></i> Populating distinct values...
            </>
        }
    
        return <SeedEditor seedId={translationSeed.data.id as string}/>
    }, [tableData, error, loading, translationSeed.dataUpdatedAt, generating])
    
    return <>
        <Modal.Header closeButton>
            <Modal.Title>
                Edit Field Translations: {fieldName}
                <br/>
            </Modal.Title>
            
        </Modal.Header>
        <Modal.Body>
         {innerContent}
        </Modal.Body>
        
    </>
}

export default PipelineNodeFieldTranslation;