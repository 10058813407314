import { NodeFieldJoinPath, PipelineNode, PipelineNodeField } from "@models/pipelineNode";
import { ReactNode, useMemo } from "react";
import PipelineNodeName, { PipelineNodeRelationshipName } from "../PipelineNodeName.component";

interface Props {
    joinPath: NodeFieldJoinPath[];
    targetNode?: PipelineNode;
    targetField?: PipelineNodeField;
    linkOut?: boolean;
}

const PipelineNodeJoinPathLabel = (props: Props) => {
    const joinPathLabel = useMemo(() => {
        if (props.targetNode && props.targetField) {

        }
        if (props.joinPath) {
            if (props.joinPath.length == 1) {
                return <PipelineNodeName pipelineNodeId={props.joinPath[0].object_id}/>
            }


            const components: ReactNode[] = [];
            props.joinPath.forEach(jp => {
                if (jp.object_type == 'NODE_RELATIONSHIP') {
                    
                    components.push(<PipelineNodeRelationshipName linkToNewPage relationshipId={jp.object_id} link={props.linkOut}/>)
                } else {
                    components.push(<PipelineNodeName linkToNewPage link={props.linkOut} pipelineNodeId={jp.object_id}/>)
                }
            })
            return <>
                
                    {components.map((c, idx) => {
                        return <span key={idx}>
                            {idx > 0 && <i className="ms-1 me-1 mdi mdi-arrow-right-bold"/>}
                            {c}
                        </span>
                    })}
                
            </>
        }
    }, [props.joinPath]);
    return <>{joinPathLabel}</>
}


export default PipelineNodeJoinPathLabel;