import Dropdown from "@components/form/Dropdown.component";
import BusinessObjectORM, { BusinessObject } from "@models/businessObject";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { saveBusinessObject, useBusinessObjects } from "@stores/data.store";
import { useCallback, useMemo, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Select, { SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

interface Props {
    selectedId: string;
    inline?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    onSelect: (businessObject: BusinessObject|undefined) => void;
    blacklist?: string[];
}

const BusinessObjectSelector = (props: Props) => {
    const businessObjects = useBusinessObjects();
    const [backgroundLoading, setBackgroundLoading] = useState(false);

    const options = useMemo(() => {
        if (businessObjects.data) {
            return businessObjects.data.filter(bo => (!props.blacklist || !props.blacklist.includes(bo.id as string))).map(bo => {
                return {
                    label: bo.name,
                    value: bo.id as string
                }
            })
        }
        return [];
    }, [businessObjects.dataUpdatedAt, props.blacklist]);



    const onSelect = useCallback(async (opt: string) => {
        if (!opt) {
            props.onSelect(undefined);
        } else {
            props.onSelect(businessObjects.data?.find(b => b.id === opt));
        }
    }, [businessObjects.dataUpdatedAt]);

    let wrapperClass = '';

    const onCreate = useCallback(async (newName: string) => {
        const bo = await saveBusinessObject({
            id: null,
            name: newName,
            description: '',
            input_record_type_ids: [],
            fields: [],
        });

        props.onSelect(bo);

    }, [props.onSelect]);
    if (props.inline) {
        wrapperClass = 'inline-form-control';
    }

    return <div className={wrapperClass}>
        {businessObjects.isLoading && (
            <Spinner size="sm"/>
        )}
        {!businessObjects.isLoading && (
            <>
                <Dropdown
                    allowCreate={props.allowCreate}
                    onCreateOption={onCreate}
                    placeholder="Select business object"
                    options={options}
                    selected={props.selectedId}
                    onChange={onSelect}
                />
            </>

            
            
        )}

    </div>
}

export default BusinessObjectSelector;