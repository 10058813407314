import { useBusinessObjects, useDraftVersionId, useMissionControlDataFlowData, useProjectConfig } from '@stores/data.store';
import { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@services/url.service';
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent, PaneContentWithSubnav } from './PageStructure.component';
import DataLibrary from '@components/nav/DataLibrary.component';
import PliableLoader from '@components/loaders/PliableLoader.component';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import AsyncButton from '@components/button/AsyncButton.component';
import TenantConfigService from '@services/tenantConfig.service';
import { TableExplorer } from '@components/datatable/TableExplorer.component';
import LoadingCard from '@components/card/LoadingCard.component';
import { getErrorMessage } from '@services/errors.service';



const DataAssistantPage = () => {
    const { mode } = useParams();
    const missionControl = useMissionControlDataFlowData();
    // const setDataLibraryEnabled = useGlobalState((state: any) => state.setLibraryEnabled);
    // setDataLibraryEnabled(false);

    const query = useQuery();
    const navigate = useNavigate();

    const {dataUpdatedAt, data: tenantConfig} = useProjectConfig();

    const [activeEdgeId, setActiveEdgeId] = useState('');

    const [loading, setLoading] = useState(false);
    const [running, setRunning] = useState(false);

    const {data: draftVersionId} = useDraftVersionId();

    const [focusNodeId, setFocusNodeId] = useState('');
    const [command, setCommand] = useState('');
    const [resultSql, setResultSql] = useState('');
    const [cacheBust, setCacheBust] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const focusNodeId = query.get('focusNodeId');
        setFocusNodeId(focusNodeId ? focusNodeId: '');

    }, [query]);

    const toggleFocusOnNode = useCallback((nodeId: string) => {
        if (nodeId == focusNodeId) {
            navigate('/')
        } else {
            navigate('/?focusNodeId=' + nodeId)

        }
    }, [navigate, focusNodeId]);

    const runCommand = useCallback(async () => {
        setRunning(true);
        setError('');
        setResultSql('');
        setCacheBust(Date.now().toString())
        try{
            const result = await TenantConfigService.getInstance().commandAssistant(command);
            if(result.error){
                setResultSql('');
                setError(result.error);
            }else{
                setResultSql(result.sql);
                setError('');
            }
        }catch(ex){
            setResultSql('');
            setError(getErrorMessage(ex));
        }
        setRunning(false);

    }, [command, cacheBust]);



    return <PageStructure
        pageTitle="Data Assistant"
    >
        
        <PageSidebar>
            <Pane>
                <PaneContent>
                    <DataLibrary onToggleNodeActive={toggleFocusOnNode}/>
                </PaneContent>
            </Pane>
        </PageSidebar>
        <PageContent>
            <Pane>
                {/* <nav className="subnav">
                    <a role="button" className="nav-link" onClick={addSourceClick}>
                        <i className="mdi mdi-database"></i> New Source
                    </a>
                    <a role="button" className="nav-link" onClick={addNewStagingTableClick}>
                        <i className="mdi mdi-broom"></i> New Staging Table
                    </a>
                    <a role="button" className="nav-link" onClick={addNewBusinessObjectClick}>
                        <i className="mdi mdi-star"></i> New Business Object
                    </a>
                    <a role="button" className="nav-link" onClick={addNewReportClick}>
                        <i className="mdi mdi-table"></i> New Report
                    </a>
                </nav> */}

                <PaneContentWithSubnav>
         
                    <div className='m-2'>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="What are you looking to do?"
                                value={command}
                                onChange={(e) => setCommand(e.target.value)}
                            />

                            <AsyncButton
                                icon="mdi mdi-content-save"
                                variant="warning"
                                text={`Submit`}
                                loading={running}
                                onClick={runCommand}
                            />
                        </InputGroup>
                    </div>
                    {
                        running && <LoadingCard action="Thinking" />
                    }
                    {error && 
                        <div className='m-2' style={{padding: '0px 100px'}}>{error}</div>
                    }
                    {resultSql && 
                        <TableExplorer
                            tablePath={`/tenant-config/data-assistant/someidddd/data`}
                            cacheBuster={cacheBust}
                            />
                    }
                    
                    

                </PaneContentWithSubnav>
            </Pane>
            
            {loading && <PliableLoader />}


        </PageContent>
    </PageStructure>
}

export default DataAssistantPage;

