import AsyncButton from "@components/button/AsyncButton.component";
import S3Uploader from "@components/s3uploader/S3Uploader.component";
import { Source, SourceRecordTypeORM } from "@models/source";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { invalidateMissionControlDataFlowData, invalidateMissionControlDataSourcesData, invalidateSourceRecordTypes, invalidateSources, saveSource, useSourceRecordTypes } from "@stores/data.store";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import FileORM, { File, FilePart } from '@models/file';
import styled from 'styled-components';
import FileLoader from "@components/files/FileLoader.component";
import CreatableSelect from 'react-select/creatable';
import BackgroundService from "@services/bg.service";
import ApiService, { JobEnqueueResponse, ListRecordsResponse } from "@services/api/api.service";
import { SimpleDataTable, tableDataFromRows } from "@components/datatable/DataTable.component";
import { useNavigate } from "react-router-dom";
import DataSourceImporter from "./DataSourceImporter.component";
import Select from 'react-select';

const Styles = styled.div`
    .modal-content {
        color: #000000;
        font-family: Poppins;
        text-align: left;
    }

    .modal-header {
        padding: 12px 24px 0px 24px;

        border-bottom: none;

        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
    }

    .modal-body {
        padding: 12px 24px;
        .form-label {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            color: #000000;
        }

        .form-control {
            border-radius: 5px;
            border: 1px solid #878787;
        }
    }

    .modal-footer {
        border-top: none;
    }

    .file-upload-info {
        margin: 20px 40px;
        text-align: center;
    }

    .uppy-DragDrop-browse {
        color: #FF9F00;
    }

    .separator {
        color: #D9D9D9;
        display: flex;
        align-items: center;
        text-align: center;
      }
      
      .separator::before,
      .separator::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #D9D9D9;
        ;
      }
      
      .separator:not(:empty)::before {
        margin-right: .25em;
      }
      
      .separator:not(:empty)::after {
        margin-left: .25em;
      }
`

export interface DataSourceSelectModalProps {
    onSourceAdded: (sourceRecordTypeId: string) => void;
    onClose?: () => void;
    children?: string | JSX.Element | JSX.Element[];
    show?: boolean;
    allowSelectExisting?: boolean;
    selectExistingBlacklist?: string[];
    allowSystemConnectors?: boolean;
    existingPipelineNodeId?: string;
}

function DataSourceSelectModal(props: DataSourceSelectModalProps) {

    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        if (props.show !== undefined) {
            setShow(props.show);
        }
    }, [props.show])

    const close = useCallback(() => {
        setShow(false);

        if (props.onClose){
            props.onClose();
        }
    }, [props.onClose]);

    const onSourceAdded = useCallback((sourceRecordTypeId: string) => {
        close();
        if (props.onSourceAdded) {
            props.onSourceAdded(sourceRecordTypeId);
        }
    }, [props.onSourceAdded]);


    return (
        <>
            <Modal show={show} backdrop="static" onHide={close} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Import Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {show && (
                        <>
                            <DataSourceImporter
                                onSourceAdded={onSourceAdded}
                                allowSelectExisting={props.allowSelectExisting}
                                selectExistingBlacklist={props.selectExistingBlacklist}
                                allowSystemConnectors={props.allowSystemConnectors}
                                pipelineNodeId={props.existingPipelineNodeId}
                            />
                            
                        </>
                        
                    )}
                    
                </Modal.Body>
            </Modal>
            {props.show === undefined && (
                <div onClick={(e) => setShow(true)}>{props.children}</div>

            )}
        </>
    );
    
}

export default DataSourceSelectModal;