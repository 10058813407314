import styled from 'styled-components';
import { ReactComponent as TopSVG } from '../../assets/images/pliable/loader/Top.svg';
import { ReactComponent as MiddleSVG } from '../../assets/images/pliable/loader/Middle.svg';
import { ReactComponent as BottomSVG } from '../../assets/images/pliable/loader/Bottom.svg';


const LoaderContainer = styled.div`

position: relative;
height: 150px;
margin-bottom: 25px;


.top-svg {
  animation: animate-flashing 0.75s infinite linear alternate;
  animation-delay: 0.5s;
}

.middle-svg {
  position: absolute;
  z-index: 2;
  top: 64px;
  animation: animate-flashing 0.75s infinite linear alternate;
  animation-delay: 0.25s;
}

.bottom-svg {
  position: absolute;
  z-index: 3;
  top: 100px;
  animation: animate-flashing 0.75s infinite linear alternate;
  animation-delay: 0.0s;
}

  
  @keyframes animate-flashing {
    0% {
      opacity: 1.0;
    }
    50%, 100% {
      opacity: 0.3;
    }
  }

&.loader-med {
  height: 75px;

  .top-svg {
    transform: scale(0.5);
  }
  
  .middle-svg {
    transform: scale(0.5);
    top: 39px;
  }
  
  .bottom-svg {
    transform: scale(0.5);
    top: 57px;
  }
}

&.loader-sm {
  height: 50px;

  .top-svg {
    transform: scale(0.3);
  }
  
  .middle-svg {
    transform: scale(0.3);
    top: 30px;
  }
  
  .bottom-svg {
    transform: scale(0.3);
    top: 42px;
  }
}

`

interface Params {
  size?: 'lg'|'med'|'sm'
}

const PliableLoader = ({size = 'sm'}: Params) => {
    return <LoaderContainer className={`loader-${size}`}>
          <div className={`d-flex`}>
              <TopSVG className="top-svg" />
              <MiddleSVG className="middle-svg" />
              <BottomSVG  className="bottom-svg" />
          </div>
      </LoaderContainer>
}

export default PliableLoader;


