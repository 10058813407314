import { Source } from "@models/source";
import { useSource, useSourceTypes, useSources } from '@stores/data.store';
import { useEffect, useMemo, useState } from "react";
import styled from 'styled-components';

interface SourceIconProps {
    source?: Source;
    sourceId?: string;
    className?: string;
    image_url?: string;
}

const ImageContainer = styled.div`
width: 50px;

border-radius: 100%;
overflow: hidden;

img {
  max-width: 50px;
  max-height: 50px;
}
`

const SourceIcon = (props: SourceIconProps) => {
    
    const { isLoading: loadingSourceTypes, data: soureTypeOptions } = useSourceTypes();
    const sourceData = useSource(props.sourceId || '');

    const [theSource, setTheSource] = useState<Source|undefined>(undefined);

    useEffect(() => {
        if (props.source) {
            setTheSource(props.source);
        } else if (sourceData.dataUpdatedAt) {
            setTheSource(sourceData.data);
        }
    }, [props.sourceId, props.source, sourceData.dataUpdatedAt]);

    if(!!props.image_url) {
        return <ImageContainer>
            <img src={props.image_url}/>
        </ImageContainer>;
    }

    if (loadingSourceTypes || !theSource){
        return <></>;
    }
    

    const sourceType = soureTypeOptions?.find((st) => st.id == theSource?.type);

    if (!sourceType) {
        return <></>
    }

    return <ImageContainer>
        <img src={sourceType.icon_path}/>
    </ImageContainer>;

}

export default SourceIcon;