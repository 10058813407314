import { MissionControlDataFlowEdge, MissionControlDataFlowNode, invalidateDataMarts, invalidatePipelineNodes, saveDataMart, useMissionControlDataFlowData, usePipelineNodes } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import styled from 'styled-components';
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PipelineNode, PipelineNodeORM } from "@models/pipelineNode";
import { getPromptAnswer } from "@services/alert/alert.service";
import { shortid } from "@services/id.service";
import { getGroupValueForNodeType, getNodeTypeConfig } from "@services/modeling.service";
import PipelineNodeConnector from "@components/pipelineNodes/PipelineNodeConnector.component";
import { Option } from "@components/form/Dropdown.component";
import { DraftOnly } from "@components/project/DraftModeRequired.component";

export const NODE_RANK: any = {
    "REPORTING": 1,
    "DATA_MODELING": 2,
    "STAGING": 3,
    "SOURCE": 4,
}

const DOWNSTREAM_OBJ: any = {
    "BUSINESS_OBJECT": 'Report',
    "STAGING": 'Buiness Object',
    "SOURCE": 'Staging',
}

interface DagDataLibraryProps {
    activeNodeId: string;
}

const recursiveGetNodesAndEdges = (
    thisNode: MissionControlDataFlowNode, 
    allNodes: MissionControlDataFlowNode[], 
    allEdges: MissionControlDataFlowEdge[], 
    nodeIdsWeSaw: string[], 
    edgeIdsWeSaw: string[],
    limitDirection?: string,
): [MissionControlDataFlowNode[], MissionControlDataFlowEdge[]]=> {
    let nodes: MissionControlDataFlowNode[] = [];
    let edges: MissionControlDataFlowEdge[] = [];


    // Get edges pointing at this node
    allEdges.forEach(e => {
        if (e.source == thisNode.id && !nodeIdsWeSaw.includes(e.target) && (!limitDirection || limitDirection == 'source')) {
            const relatedNode = allNodes.find(n => n.id === e.target);
            if (!relatedNode) {
                return;
            }
            nodes.push(relatedNode);
            edges.push(e);
            edgeIdsWeSaw.push(e.id);
            nodeIdsWeSaw.push(relatedNode.id);
            const [moreNodes, moreEdges] = recursiveGetNodesAndEdges(relatedNode, allNodes, allEdges, nodeIdsWeSaw, edgeIdsWeSaw, 'source');
            moreNodes.forEach(n => nodeIdsWeSaw.push(n.id));
            moreEdges.forEach(e => edgeIdsWeSaw.push(e.id));

            nodes = nodes.concat(moreNodes);
            edges = edges.concat(moreEdges);
        } else if (e.target == thisNode.id && !nodeIdsWeSaw.includes(e.source) && (!limitDirection || limitDirection == 'target')) {
            const relatedNode = allNodes.find(n => n.id === e.source);
            if (!relatedNode) {
                return;
            }
            nodes.push(relatedNode);
            edges.push(e);
            edgeIdsWeSaw.push(e.id);
            nodeIdsWeSaw.push(relatedNode.id);
            const [moreNodes, moreEdges] = recursiveGetNodesAndEdges(relatedNode, allNodes, allEdges, nodeIdsWeSaw, edgeIdsWeSaw, 'target');
            moreNodes.forEach(n => nodeIdsWeSaw.push(n.id));
            moreEdges.forEach(e => edgeIdsWeSaw.push(e.id));

            nodes = nodes.concat(moreNodes);
            edges = edges.concat(moreEdges);
        } else if ((e.target == thisNode.id || e.source == thisNode.id) && !edgeIdsWeSaw.includes(e.id)) {
            edgeIdsWeSaw.push(e.id);
            edges.push(e);
        }
    });

    return [nodes, edges];
}

const Styles = styled.div`
.list-group-item {
    padding: .25rem 0px;
    border: none;
    font-family: "Poppins";
    font-size: 13px;
    img {
        max-height: 30px;
        max-width: 30px;
    }

    &.empty {
        color: #ccc;
    }

    &.new {
        color: #555;

        &:hover {
            color: black;
        }
    }

    display: flex;
    align-items: center;

    div.icon {
        width: 25px;
        height: 25px;
        min-width: 25px;
        line-height: 25px;
        border-radius: 100%;
        text-align: center;
        margin-right: .5rem;
    }
    
}
`

interface SidebarNodeListProps {
    nodes: PipelineNode[];
}

export const SidebarNodeList = (props: SidebarNodeListProps) => {
    const navigate = useNavigate();
    const navigateToNode = useCallback((nodeId: string) => {
        navigate(`/node/${nodeId}`)
    }, [navigate]);
    return <Styles>
        <ul className="list-group">
            {props.nodes.map(n => {
                const nodeConfig = getNodeTypeConfig(n);
                return <li key={n.id} onClick={() => navigateToNode(n.id as string)} className={`list-group-item list-group-item-action ${nodeConfig.group.color}`}>
                    <div className={`icon bg-${nodeConfig.group.color}`}>
                        <i className={nodeConfig.nodeType.icon}></i>
                    </div> {n.name}
                </li>
            })}
        </ul>
    </Styles>
}


interface NodeListItemProps {
    node: MissionControlDataFlowNode;
}

const NodeListItem = (props: NodeListItemProps) => {
    const navigate = useNavigate();
    const nodes = usePipelineNodes();

    const theNode = useMemo(() => {
        if (!nodes.data) {
            return;
        }

        return nodes.data.find(n => n.id === props.node.data.objectId);
    }, [props.node, nodes.dataUpdatedAt]);

    const activateNode = useCallback(() => {
        navigate(`/node/${props.node.data.objectId}`)
    }, [navigate, props.node]);

    const nodeConfig = useMemo(() => {
        if (!theNode) {
            return;
        }
        return getNodeTypeConfig(theNode);
    }, [theNode]);


    if (!nodeConfig || !theNode || !nodeConfig.group) {
        return <></>
    }
    return <li key={props.node.id} onClick={() => activateNode()} className={`list-group-item list-group-item-action ${nodeConfig.group.color}`}>
        <div className={`icon bg-${nodeConfig.group.color}`}>
            <i className={nodeConfig.nodeType.icon}></i>
        </div> {props.node.data.title}
    </li>
}

/**
 * Similar to data library but is context-aware of how the current node fits into the DAG
 * and can display upstream/downstream/everything else
 */
const DagDataLibrary = (props: DagDataLibraryProps) => {
    const data = useMissionControlDataFlowData();
    const [searchInput, setSearchInput] = useState('');
    const [activeNodeType, setActiveNodeType] = useState('');

    const [debouncedSearchInput] = useDebounce(searchInput, 500);

    const [upstreamNodes, setUpstreamNodes] = useState<MissionControlDataFlowNode[]>([]);
    const [downstreamNodes, setDownstreamNodes] = useState<MissionControlDataFlowNode[]>([]);
    const [otherNodes, setOtherNodes] = useState<MissionControlDataFlowNode[]>([]);

    const sortNodes = useCallback((a: MissionControlDataFlowNode, b: MissionControlDataFlowNode) => {
        const arank = NODE_RANK[getGroupValueForNodeType(a.data.nodeType)];
        const brank = NODE_RANK[getGroupValueForNodeType(b.data.nodeType)];

        if (arank > brank) {
            return 1;
        } else if (brank > arank) {
            return -1;
        }

        return a.data.title > b.data.title ? 1: -1;
    }, []);

    useEffect(() => {
        if (!data.data) {
            setUpstreamNodes([]);
            setDownstreamNodes([]);
            setOtherNodes([]);
            setActiveNodeType('')
        } else {
            const theNode = data.data.nodes.find(n => n.id === props.activeNodeId);
            if (theNode) {
                setActiveNodeType(theNode.data.nodeType);
                const preFilteredNodes = data.data.nodes.filter(n => n.data.title.toLowerCase().indexOf(debouncedSearchInput.toLowerCase()) >= 0);
                const [targetNodes] = recursiveGetNodesAndEdges(theNode, preFilteredNodes, data.data.edges, [props.activeNodeId], [], 'target');

                setUpstreamNodes(targetNodes.sort(sortNodes));

                const [sourceNodes] = recursiveGetNodesAndEdges(theNode, preFilteredNodes, data.data.edges, [props.activeNodeId], [], 'source');

                setDownstreamNodes(sourceNodes.sort(sortNodes));

                const nodeIds = sourceNodes.map(n => n.id).concat(targetNodes.map(n => n.id));

                setOtherNodes(preFilteredNodes.filter(n => !nodeIds.includes(n.id) && n.id != props.activeNodeId).sort(sortNodes));
            }
        }
    }, [data.dataUpdatedAt, debouncedSearchInput, props.activeNodeId]);

    const navigate = useNavigate();

    const [showConnectModal, setShowConnectModal] = useState(false);

    return <Styles>
        <PipelineNodeConnector
            connectingNodeId={props.activeNodeId.split(':')[1]}
            show={showConnectModal}
            onHide={() => setShowConnectModal(false)}
            actionFilter={(action: Option) => {
                return ['ADD_AS_SOURCE', 'MAP_TO_NEW_NODE'].includes(action.value);
            }}
            skipRedirectOnSave
        />
        
        <h2 className="mb-2">Quick Nav</h2>
        <Form.Group className="mb-3">
            <Form.Control className="round-input" onChange={(e) => setSearchInput(e.target.value)} placeholder='Search'>
        </Form.Control>
        </Form.Group>
        <h4 className="mb-0">Data Sources</h4>
        <p className="text-13 text-muted">This node gets its data from...</p>
        
        <ul className="list-group mb-3">
            {upstreamNodes.length == 0 && <li className="list-group-item empty">
                Nothing here
            </li>}
            {upstreamNodes.map(n => <NodeListItem key={n.id} node={n}/>)}
        </ul>
        <div className="d-flex center-vertically">
            <h4 className="flex-1 mb-0">Data Destinations</h4>
            <DraftOnly>
                <button className="icon-button" onClick={() => setShowConnectModal(true)}>
                    <i className="mdi mdi-plus-circle"></i> New
                </button>
            </DraftOnly>
            
            
        </div>
        <p className="text-13 text-muted">This node's data flows into...</p>
        <ul className="list-group mb-3">
            {downstreamNodes.length == 0 && <li className="list-group-item empty">
                Nothing here
            </li>}
            {downstreamNodes.map(n => <NodeListItem key={n.id} node={n}/>)}
            
        </ul>
        <hr />
        <h4>Other Nodes</h4>
        <ul className="list-group mb-3">
            {otherNodes.length == 0 && <li className="list-group-item empty">
                Nothing here
            </li>}
            {otherNodes.map(n => <NodeListItem key={n.id} node={n}/>)}
        </ul>
    </Styles>
}

export default DagDataLibrary;