import RecordInfo from "@components/card/RecordInfo.component";
import SectionHeader from "@components/card/SectionHeader.component";
import Warning from "@components/statusIndicators/Warning.component";
import { PipelineNodeField } from "@models/pipelineNode";
import { Pane, PaneContent } from "@pages/PageStructure.component";
import { formatPercentage, summarizeNumber } from "@services/formatting.service";
import { useColumnShape, useColumnValueDistribution, usePipelineNode } from "@stores/data.store";
import { useMemo } from "react";
import { Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";

interface ColumnRecordInfoProps {
    pipelineNodeId: string;
    column: PipelineNodeField;
}

export const ColumnRecordInfo = (props: ColumnRecordInfoProps) => {
    const shapeData = useColumnShape(props.pipelineNodeId, props.column.name);
    const theNode = usePipelineNode(props.pipelineNodeId);

    const title = useMemo(() => {
        if (!theNode.data) {
            return '';
        }

        return theNode.data.name + ': ' + props.column.label;
    }, [props.column.label, theNode.dataUpdatedAt]);
    if (shapeData.isLoading) {
        if (shapeData.isLoading) {
            return <>
                <i className="mdi mdi-loading mdi-spin"></i> Loading stats
            </>
        }
    }

    return <RecordInfo
        title={title}
        iconComponent={<></>}
        description={props.column.description}
        badges={<></>}
        stats={[{
            label: 'Density',
            icon: 'mdi mdi-water',
            value: shapeData.data ? formatPercentage(shapeData.data.density) + '%' : 'Unknown',
        }, {
            label: 'Uniqueness',
            icon: 'mdi mdi-hexagram',
            value: shapeData.data ? formatPercentage(shapeData.data.uniqueness) + '%' : 'Unknown',
        }]}


    />
    
}

interface ColumnDistroProps {
    pipelineNodeId: string;
    columnName: string;
    totalRecords: number;
}

export const ColumnDistro = (props: ColumnDistroProps) => {
    const data = useColumnValueDistribution(props.pipelineNodeId, props.columnName);

    if (data.isLoading) {
        return <>
            <i className="mdi mdi-loading mdi-spin"></i> Loading samples
        </>
    }

    return <table className="table">
        <thead className="bg-light">
            <tr>
                <th>Value</th>
                <th># of Occurrences</th>
                <th>% of Total</th>
            </tr>
        </thead>
        <tbody>
            {data.data?.map(d => {
                return <tr>
                    <th>{d.value}</th>
                    <td>{summarizeNumber(d.total)}</td>
                    <td>{formatPercentage(d.total / props.totalRecords)}%</td>
                </tr>
            })}
        </tbody>
    </table>
}

interface Props {
    pipelineNodeId: string;
    column?: PipelineNodeField;
    fieldId?: string;
    show: boolean;
    onHide: () => any;
}

const PipelineNodeColumnDrawer = (props: Props) => {
    const pn = usePipelineNode(props.pipelineNodeId);

    const column = useMemo(() => {
        if (!pn.data) {
            return undefined;
        }

        if (props.column) {
            return props.column;
        } else if (props.fieldId) {
            return pn.data.fields.find(f => f.id === props.fieldId);
        }
        return undefined;
    }, [props.column, props.fieldId, pn.dataUpdatedAt]);

    return <Offcanvas placement="end" show={props.show} onHide={props.onHide}>
    <Offcanvas.Header closeButton>
        <Offcanvas.Title>Column Details</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
        {pn.data && !pn.data.shape && (
            <Pane>
                <div className="p-3">
                    <Warning>
                        <div>
                            This node hasn't been built yet!<br />
                            <Link to={`/node/${props.pipelineNodeId}/config`}>Build</Link>
                        </div>
                        
                    </Warning>
                </div>
            </Pane>
        )}
        {pn.data && column && pn.data.shape && (
            <Pane>
                <div className="p-3">
                    <ColumnRecordInfo
                        pipelineNodeId={props.pipelineNodeId}
                        column={column}
                    />
                    
                </div>
                
                <SectionHeader>
                    Value Distribution
                
                </SectionHeader>
                <PaneContent>

                <div className="p-3">
                <ColumnDistro pipelineNodeId={props.pipelineNodeId} columnName={column.name} totalRecords={pn.data!.shape!.total_records}/>
                
                </div>
                    
                
                </PaneContent>
            </Pane>
                
               
        )}
        
    </Offcanvas.Body>
</Offcanvas>
}

export default PipelineNodeColumnDrawer;