/**
 * Helper functions for dealing with nodes of different types
 * 
 * @author Jason Raede
 */

import { PipelineNode } from "@models/pipelineNode";

interface PartialNode {
    id: string|null;
    node_type: string;
    upstream_node_ids: string[];
}

export interface NodeType {
    label: string;
    description: string;
    value: string;
    icon: string;
    upstreamMode: 'ALLOW_MULTIPLE' | 'ALLOW_ONE' | 'ALLOW_NONE';
    allowRelationships: boolean;
    disableSelection?: boolean;
    specialNodeType?: string;
}

export interface NodeTypeGroup {
    label: string;
    value: string;
    description: string;
    color: string;
    nodeTypes: NodeType[];
}

export const NODE_TYPE_GROUPS: NodeTypeGroup[] = [
    {
        label: 'Data Sources',
        value: 'SOURCE',
        color: 'pliable',
        description: 'Connect your raw data to Pliable',
        nodeTypes: [{
            label: 'Database Table',
            description: 'Pull in raw data from any Snowflake table',
            icon: 'mdi mdi-database',
            value: 'SOURCE',
            upstreamMode: 'ALLOW_NONE',
            allowRelationships: false,
        }, {
            label: 'Flat File',
            description: 'Upload one or more Excel-compatible files',
            icon: 'mdi mdi-file',
            value: 'SOURCE_FILE',
            upstreamMode: 'ALLOW_NONE',
            allowRelationships: false,
        }, {
            label: 'Seed',
            description: '',
            icon: 'mdi mdi-seed',
            value: 'SEED',
            upstreamMode: 'ALLOW_NONE',
            allowRelationships: false,
            disableSelection: true,
        }, {
            label: 'Seed Source',
            description: '',
            icon: 'mdi mdi-database',
            value: 'SEED_SOURCE',
            upstreamMode: 'ALLOW_NONE',
            allowRelationships: false,
            disableSelection: true,
        }]
    }, {
        label: 'Cleaning Step',
        value: 'STAGING',
        description: 'Clean, combine, deduplicate, and structure your data for analysis',
        color: 'dark',
        nodeTypes: [{
            label: 'Stack',
            description: 'Combine data from multiple upstream nodes without merging. Useful for unifying transactional data like events across multiple data sources.',
            icon: 'mdi mdi-view-list',
            value: 'STACK',
            upstreamMode: 'ALLOW_MULTIPLE',
            allowRelationships: true,
        }, {
            label: 'Merge',
            description: 'Merge data from multiple upstream nodes using a shared key. Useful if you have data for a particular object (like a customer) in multiple data sources.',
            icon: 'mdi mdi-merge',
            value: 'MERGE',
            upstreamMode: 'ALLOW_MULTIPLE',
            allowRelationships: true,
        }, {
            label: 'Split',
            description: 'Split rows from a single upstream node into multiple rows (e.g. arrays or delimited strings)',
            icon: 'mdi mdi-set-split',
            upstreamMode: 'ALLOW_ONE',
            value: 'SPLIT',
            allowRelationships: false,

        }, {
            label: 'Summarize',
            description: 'Summarize (aggregate) data from a single upstream node. Useful for changing the granularity of a data source to be the same as another source.',
            icon: 'mdi mdi-information',
            value: 'SUMMARIZE',
            upstreamMode: 'ALLOW_ONE',
            allowRelationships: true,
        }, {
            label: 'Identify',
            description: 'Groups records by identity without merging. Useful for identity resolution for customer touchpoints.',
            icon: 'mdi mdi-card-account-details',
            value: 'IDENTIFY',
            upstreamMode: 'ALLOW_ONE',
            allowRelationships: true,
        }, {
            label: 'View',
            description: 'Display a filtered subset of records from another node',
            icon: 'mdi mdi-database-search',
            upstreamMode: 'ALLOW_ONE',
            value: 'VIEW',
            allowRelationships: false,
        }, {
            label: 'Custom SQL',
            description: 'Write your own DBT-compatible SQL',
            icon: 'mdi mdi-code-braces',
            value: 'CUSTOM',
            upstreamMode: 'ALLOW_NONE',
            allowRelationships: false,
        }]
    }, {
        label: 'Data Modeling',
        value: 'DATA_MODELING',
        description: 'Structure your granular data so it is easy to use in analysis.',
        color: 'purple',
        nodeTypes: [{
            label: 'Fact',
            value: 'FACT',
            description: 'A piece of information with one or more numerical values, such as an order, a meeting, or a metric.',
            icon: 'mdi mdi-abacus',
            upstreamMode: 'ALLOW_MULTIPLE',
            allowRelationships: true,
            disableSelection: true,
        }, {
            label: 'Business Object',
            value: 'DIMENSION',
            description: 'An object that exists in your business, like a customer, product, or order. These are typically made up of multiple different sources.',
            icon: 'mdi mdi-briefcase',
            upstreamMode: 'ALLOW_MULTIPLE',
            allowRelationships: true,
        }, {
            label: 'Date Dimension',
            value: 'DATE_DIMENSION',
            description: 'A special dimension that manages dates',
            icon: 'mdi mdi-calendar-outline',
            upstreamMode: 'ALLOW_NONE',
            allowRelationships: true,
        }]
    }, {
        label: 'Reporting & Integrations',
        value: 'REPORTING',
        description: 'Easily build reports and export data using your business objects.',
        color: 'success',
        nodeTypes: [{
            label: 'Data Mart',
            description: 'Slice, dice, and summarize data from many related facts and dimensions',
            icon: 'mdi mdi-storefront',
            upstreamMode: 'ALLOW_ONE',
            value: 'DATAMART',
            allowRelationships: false,
            // disableSelection: true,
        }, {
            label: 'Tabular Report',
            description: 'Build a tabular report like you would in Excel',
            icon: 'mdi mdi-table',
            upstreamMode: 'ALLOW_MULTIPLE',
            value: 'REPORT',
            allowRelationships: false,
        }, {
            label: 'Destination',
            description: 'Pull data from another node and model it specifically to sync to somewhere outside of Pliable',
            icon: 'mdi mdi-sync-circle',
            upstreamMode: 'ALLOW_ONE',
            value: 'DESTINATION',
            allowRelationships: false,
        }]
    }
];

/**
 * Allows for more efficient lookups 
 */
const NODE_COLORS: {
    [key: string]: string
} = {};

const NODE_TYPES: {
    [key: string]: NodeType
} = {};

const NODE_GROUPS_BY_TYPE: {
    [key: string]: NodeTypeGroup;
} = {};

export const getGroupValueForNodeType = (nodeType: string): string => {
    if (NODE_GROUPS_BY_TYPE.hasOwnProperty(nodeType)) {
        return NODE_GROUPS_BY_TYPE[nodeType].value;
    } 
    return '';
}


NODE_TYPE_GROUPS.forEach(ntg => {
    ntg.nodeTypes.forEach(nt => {
        NODE_COLORS[nt.value] = ntg.color;
        NODE_TYPES[nt.value] = nt;
        NODE_GROUPS_BY_TYPE[nt.value] = ntg;
    });
});

export const getNodeTypeConfig = (node: PipelineNode) => {
    return getNodeTypeConfigFromNodeTypeValue(node.node_type);
}

export const getNodeTypeConfigFromNodeTypeValue = (nodeType: string) => {
    return {
        group: NODE_GROUPS_BY_TYPE[nodeType],
        nodeType: NODE_TYPES[nodeType]
    };
}


export const getNodeColor = (node: PipelineNode): string => {
    return NODE_COLORS[node.node_type];
}



/**
 * Returns a list of the node types that this node can be mapped to
 * as an upstream node.
 * 
 */
export const getValidNodeTypesForDownstreamConnection = (upstreamNode: PartialNode): string[] => {
    return Object.keys(NODE_TYPES).map(k => NODE_TYPES[k]).filter(nt => {
        return isValidUpstreamNode({
            id: null,
            node_type: nt.value,
            upstream_node_ids: [],
        }, upstreamNode);
    }).map(nt => nt.value);
}

export const getValidNodeTypeGroupsForDownstreamConnection = (upstreamNode: PartialNode): string[] => {
    const group = NODE_GROUPS_BY_TYPE[upstreamNode.node_type];
    switch (group.value) {
        case 'SOURCE':
        case 'STAGING':
            return ['STAGING', 'DATA_MODELING'];
        case 'DATA_MODELING':
            return ['REPORTING', 'VISUALIZATION'];
        case 'REPORTING':
            return ['VISUALIZATION'];
    }
    return [];
}



/**
 * Returns TRUE if the upstream node can be mapped to the downstream node
 */
export const isValidUpstreamNode = (downstreamNode: PartialNode, upstreamNode: PartialNode): boolean => {
    const validNodeTypeGroups = getValidNodeTypeGroupsForDownstreamConnection(upstreamNode);

    const downstreamGroup = NODE_GROUPS_BY_TYPE[downstreamNode.node_type];

    if (downstreamNode.node_type == 'CUSTOM' || downstreamNode.node_type == 'DATE_DIMENSION') {
        return false;
    }

    if (downstreamNode.upstream_node_ids && downstreamNode.upstream_node_ids.includes(upstreamNode.id as string)) {
        return false;
    }

    // Views can be connected to anything.
    if (downstreamNode.node_type == 'VIEW' || upstreamNode.node_type == 'VIEW') {
        return true;
    }

    // Destinations can be a downstream node for anything other than another destination
    if (downstreamNode.node_type == 'DESTINATION' && upstreamNode.node_type != 'DESTINATION') {
        return true;
    }
    if (!validNodeTypeGroups.includes(downstreamGroup.value)) {
        return false;
    }

    

    return true;
}

/**
 * Returns TRUE if the source node can be related to the target node
 */
export const isValidRelationshipTarget = (sourceNode: PipelineNode, targetNode: PipelineNode): boolean => {
    
    const sourceGroup = NODE_GROUPS_BY_TYPE[sourceNode.node_type];
    const targetGroup = NODE_GROUPS_BY_TYPE[targetNode.node_type];

    const sourceType = NODE_TYPES[sourceNode.node_type];
    const targetType = NODE_TYPES[targetNode.node_type];

    if (sourceGroup.value != targetGroup.value) {
        return false;
    }

    if (!sourceType.allowRelationships || !targetType.allowRelationships) {
        return false;
    }

    // Facts can only be related to dimensions
    if (sourceNode.node_type == 'FACT' && !['DIMENSION', 'DATE_DIMENSION'].includes(targetNode.node_type)) {
        return false;
    }
    if (targetNode.node_type == 'FACT' && !['DIMENSION', 'DATE_DIMENSION'].includes(targetNode.node_type)) {
        return false;
    }

    return true;
}

