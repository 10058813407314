import { MissionControlNodeProps } from '@components/missionControl/dataflow/MissionControlNode.component';
import dagre, { graphlib } from 'dagre';
import { Node, Edge, XYPosition } from "reactflow";
import Springy from '@services/springy';

export function autoLayout(nodes: Node[], edges: Edge[], algorithm: 'NETWORK' | 'LINEAR' = 'NETWORK', nodeWidth: number= 600, nodeHeight: number = 150, networkMultiplier: number = 100): {[key: string]: XYPosition} {
    const nodePositions: {[key: string]: XYPosition} = {};

    if (algorithm == 'LINEAR') {
        const g = new graphlib.Graph();
        g.setGraph({
            rankdir: 'LR'
        });
        g.setDefaultEdgeLabel(() => {
            return {};
        });


        nodes.forEach((node) => {
            g.setNode(node.id, { width: nodeWidth, height: nodeHeight });
        });

        edges.forEach((edge) => {
            g.setEdge(edge.source, edge.target);
        });

        dagre.layout(g);

        nodes.forEach((node) => {
            const nodeWithPosition = g.node(node.id);
            // node.targetPosition = isHorizontal ? Position.Left : Position.Top;
            // node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

            // We are shifting the dagre node position (anchor=center center) to the top left
            // so it matches the React Flow node anchor point (top left).
            nodePositions[node.id] = {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2
            };
        });

        return nodePositions;
    }
    

    const springyNodes: string[] = [];
    const springyEdges: string[][] = [];

    const graph = new Springy.Graph();
    nodes.map(n => {
        springyNodes.push(n.id);
    });
    edges.map(e => {
        if (springyNodes.includes(e.source) && springyNodes.includes(e.target)) {
            springyEdges.push([e.source, e.target]);

        }
    });

    // @ts-ignore
    graph.loadJSON({
        'nodes': springyNodes,
        'edges': springyEdges,
    });


    const layout = new Springy.Layout.ForceDirected(graph, nodeWidth, nodeHeight, 0.5, 0.0001);
    layout.start();
    layout.eachNode((n: any, point: any) => {
        nodePositions[n.id] = {
            'x': point.p.x * networkMultiplier,
            'y': point.p.y * networkMultiplier,
        }
        console.log(n, point);
    });
    return nodePositions;
   
}