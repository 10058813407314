import LoadingCard from "@components/card/LoadingCard.component";
import { PipelineNode } from "@models/pipelineNode";
import { summarizeNumber } from "@services/formatting.service";
import { timeAgo } from "@services/time.service";
import { usePipelineNodes } from "@stores/data.store";
import { useMemo } from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

const PipelineNodeList = () => {
    const nodes = usePipelineNodes();

    const nodesById: {[key: string]: PipelineNode} = useMemo(() => {
        if (!nodes.data) {
            return {}
        }

        const theMap: {[key: string]: PipelineNode} = {};
        nodes.data.forEach(n => {
            theMap[n.id as string] = n;
        });
        return theMap;
    }, [nodes.dataUpdatedAt])
    if (nodes.isLoading) {
        return <LoadingCard/>
    }

    return <div className="table-responsive">
        <table className="table table-centered table-nowrap table-bordered">
            <thead className="table-light">
                <tr>
                    <th>
                        Type
                    </th>
                    <th>
                        Name
                    </th>
                    <th>Sources</th>
                    <th>
                        Description
                    </th>
                    <th>Records</th>
                    <th>Last Build</th>
                </tr>
            </thead>
            <tbody>
                {nodes.data && nodes.data.map(n => {
                    return <tr>
                        <td>
                            {n.node_type == 'SOURCE' && <Badge bg="pliable">SOURCE</Badge>}
                            {n.node_type == 'STAGING' && <Badge bg="primary">STAGING</Badge>}
                            {n.node_type == 'BUSINESS_OBJECT' && <Badge bg="purple">BUSINESS OBJECT</Badge>}
                        </td>
                        <td>
                            <Link to={`/node/${n.id}`}>{n.name}</Link></td>
                        <td>
                            {n.upstream_node_ids.length > 0 && <ul className="mb-0">
                                {n.upstream_node_ids.map(u => {
                                    return <li>{nodesById[u].name}</li>
                                })}    
                            </ul>}
                        </td>
                        <td>{n.description}</td>
                        <td>{summarizeNumber(n.total_records ? n.total_records : 0)}</td>
                        <td>{n.last_build_completed ? timeAgo(n.last_build_completed) : 'Never'}</td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>
}

export default PipelineNodeList;