import PageStructure, { PageContent, Pane, PaneContent } from "@pages/PageStructure.component";
import { useIsInDraftMode } from "@stores/data.store"

const PipelineNodeNotFound = () => {
    const inDraftMode = useIsInDraftMode();

    return <PageStructure
            pageTitle="Not found"
        >
            <PageContent>
                <Pane>
                    <PaneContent>
                        <div className="p-3">
                            <h3>Oops! This node doesn't exist.</h3>
                            {inDraftMode && <>
                                It looks like you may have deleted it in develop mode. You can revert back to what's in production by clicking on "Revert Changes" above.
                            </>}
                            {!inDraftMode && <>
                                It looks like you may have deleted it in production mode, or you might need to publish your changes to production by switching back to develop mode and then clicking on "Publish to Production" above.
                            </>}
                        </div>
                    </PaneContent>
                </Pane>
            </PageContent>
        </PageStructure>

}

export default PipelineNodeNotFound;