import SourceIcon from "@components/sources/SourceIcon.component";
import { PipelineNode } from "@models/pipelineNode";
import { getNodeTypeConfig } from "@services/modeling.service";
import { ReactNode, useMemo } from "react";
import styled, { css } from 'styled-components';

const Container = styled.div<{bgColor?: string}>`
    width: 50px;
    margin-right: 18px;

    img, div.fake-img {
        max-width: 50px;
        max-height: 50px;
        border-radius: 100%;
    }

    div.fake-img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        ${props => css`
            background: var(--pliable-${props.bgColor});
        `}
        color: white;
        line-height: 50px;
        text-align: center;
        font-size: 30px;
    }
`

interface DashboardIconProps {
    icon: string;
    children?: ReactNode;
    bgColor: string;
}
export const DashboardIcon = (props: DashboardIconProps) => {
    return <Container bgColor={props.bgColor}>
        
        <div className="fake-img">
            <i className={props.icon}/>
        </div>
    
    

    </Container>
}

interface Props {
    node: PipelineNode;
}

const PipelineNodeIcon = (props: Props) => {
    const nodeTypeConfig = useMemo(() => {
        try {
            return getNodeTypeConfig(props.node);
        } catch (err) {
            return undefined;
        }
    }, [props.node]);
    

    const bgColor = useMemo(() => {
        if (nodeTypeConfig && nodeTypeConfig.group) {
            return nodeTypeConfig.group.color;
        }
        return 'success';
        
    }, [nodeTypeConfig]);

    const icon = useMemo(() => {
        if (nodeTypeConfig && nodeTypeConfig.nodeType) {
            return nodeTypeConfig.nodeType.icon;
        }
        return '';
    }, [nodeTypeConfig, props.node.source_id]);

    if (props.node.source_id) {
        return <Container>
            <SourceIcon sourceId={props.node.source_id}/>
        </Container>;
    }
    return <DashboardIcon bgColor={bgColor} icon={icon}/>
}


export default PipelineNodeIcon;