import AsyncButton from "@components/button/AsyncButton.component";
import S3Uploader from "@components/s3uploader/S3Uploader.component";
import SourceORM, { Source, SourceRecordTypeORM } from "@models/source";
import { getErrorMessage } from "@services/errors.service";
import { invalidateDataLoadsForSRT, invalidateMissionControlDataFlowData, invalidateMissionControlDataSourcesData, invalidatePipelineNodes, invalidateSourceRecordTypes, invalidateSources, invalidateTableData, saveSource, useAvailableSnowflakeTables, usePipelineNode, useProjectConfig, useSourceRecordTypes } from "@stores/data.store";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form} from "react-bootstrap";
import { File, FilePart } from '@models/file';
import styled from 'styled-components';
import BackgroundService from "@services/bg.service";
import ApiService, { JobEnqueueResponse, ListRecordsResponse } from "@services/api/api.service";
import { SimpleDataTable, tableDataFromRows } from "@components/datatable/DataTable.component";
import { useLocation, useNavigate } from "react-router-dom";
import Danger from "@components/statusIndicators/Danger.component";
import Dropdown, { MultiDropdown, Option } from "@components/form/Dropdown.component";
import { summarizeNumber, removeFileExtension } from "@services/formatting.service";
import BuildOrchestrationORM from "@models/buildOrchestration";
import Warning from "@components/statusIndicators/Warning.component";
import { PipelineNode, PipelineNodeORM } from "@models/pipelineNode";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";

const Styles = styled.div`
    .modal-content {
        color: #000000;
        font-family: Poppins;
        text-align: left;
    }

    .modal-header {
        padding: 12px 24px 0px 24px;

        border-bottom: none;

        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
    }

    .modal-body {
        padding: 12px 24px;
        .form-label {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            color: #000000;
        }

        .form-control {
            border-radius: 5px;
            border: 1px solid #878787;
        }
    }

    .modal-footer {
        border-top: none;
    }

    .file-upload-info {
        margin: 20px 40px;
        text-align: center;
    }

    .uppy-DragDrop-browse {
        color: #FF9F00;
    }

    .separator {
        color: #D9D9D9;
        display: flex;
        align-items: center;
        text-align: center;
      }
      
      .separator::before,
      .separator::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #D9D9D9;
        ;
      }
      
      .separator:not(:empty)::before {
        margin-right: .25em;
      }
      
      .separator:not(:empty)::after {
        margin-left: .25em;
      }
`



interface SnowflakeColumnOption {
    name: string;
    type: string;
    primary_key: string;
}

export interface DataSourceImporterProps {
    onSourceAdded: (sourcePipelineNodeId: string) => void;
    pipelineNodeId?: string;
    sourceRecordTypeId?: string;
    allowSelectExisting?: boolean;
    selectExistingBlacklist?: string[];
    allowSystemConnectors?: boolean;
}

function DataSourceImporter({onSourceAdded, pipelineNodeId, sourceRecordTypeId, allowSelectExisting, selectExistingBlacklist, allowSystemConnectors}: DataSourceImporterProps) {
    const [error, setError] = useState('');
    const [sourceName, setSourceName] = useState('');
    const [recordTypeName, setRecordTypeName] = useState('');
    const [recordTypeDescription, setRecordTypeDescription] = useState('');
    const [sourceDescription, setSourceDescription] = useState('');
    const [selectedSource, setSelectedSource] = useState<Source>();
    const [saving, setSaving] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [newFile, setNewFile] = useState<File>();


    const pipelineNode = usePipelineNode(pipelineNodeId);
    const location = useLocation();

    const [loading, setLoading] = useState<boolean>(false);
    const [importing, setImporting] = useState<boolean>(false);
    const [processing, setProcessing] = useState(false);
    const [fileParts, setFileParts] = useState<FilePart[]>([]);
    const [activeFilePart, setActiveFilePart] = useState<FilePart|undefined>(undefined);
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

    const tenantConfig = useProjectConfig();

    const database = useMemo(() => {
        if (tenantConfig.data) {
            return tenantConfig.data.database;
        }
        return '';
    }, [tenantConfig.dataUpdatedAt]);

    const tenantConfigId = useMemo(() => {
        if (tenantConfig.data) {
            return tenantConfig.data.id;
        }
        return '';
    }, [tenantConfig.dataUpdatedAt]);

    const navigate = useNavigate();

    const [step, setStep] = useState<'UPLOAD_DATA'|'CONFIRM_IMPORT'|'SELECT_CONNECTOR_TYPE'|'SELECT_TABLE'|'SET_COMPOSITE_KEY'|'SELECT_COLUMNS'>('UPLOAD_DATA');

    const { isLoading: loadingRecordTypes, data: recordTypes, refetch: refreshRecordTypes} = useSourceRecordTypes((!!selectedSource) ? selectedSource!.id : null, !!selectedSource);

    const sourceRecordTypes = useSourceRecordTypes();
    const [selectedTable, setSelectedTable] = useState('');
    const [compositeKeySelection, setCompositeKeySelection] = useState<string[]>([]);
    const [lastModifiedColumnSelection, setLastModifiedColumnSelection] = useState<string>('');
    

    // currently only supporting csv/excel
    const selectedSourceType = "flat_file";


    const fileUploadCompleted = useCallback(async (file: File) => {
        setNewFile(file);
        await processFile(file);

        setStep('CONFIRM_IMPORT');
    }, []);


    const close = useCallback((sourcePipelineNodeId: string) => {
        setShow(false);

        // Do this on a delay so it doesn't change as the modal is fading out.
        setTimeout(() => {
            setStep('UPLOAD_DATA');
        }, 1000);
        setSourceName('');
        setSourceDescription('');
        setFileParts([]);
        setRecordTypeDescription('');
        setRecordTypeName('');
        
        if (sourcePipelineNodeId) {
            onSourceAdded(sourcePipelineNodeId);

        }
    }, [onSourceAdded]);

    

    const processFile = useCallback(async (file: File) => {
        setProcessing(true);
        try {

            const results = await ApiService.getInstance().request(
                'POST',
                `/files/${file.id}/process`
            ) as JobEnqueueResponse;

            // Split it into file parts
            const partResults = await BackgroundService.getInstance().waitForJob(results.job_id);

            if (partResults === null) {
                setError(`There was an error processing your file`);
                return;
            }
            
            setFileParts(partResults);
            setActiveFilePart(partResults[0]);
            setRecordTypeName(removeFileExtension(file.name))

        } catch (err) {
            setError(getErrorMessage(err));

        } finally {
            setProcessing(false);
        }
    }, [newFile]);


    const runImport = useCallback(async () => {
        if (!activeFilePart) {
            return;
        }
        setImporting(true);
        setError('');
        try {
            
            let srtIdImportTarget: string;
            // Create the source record type
            if (!sourceRecordTypeId) {
                const srtResult = await SourceRecordTypeORM.save({
                    id: null,
                    // this lazy-creates the singleton flat file data source. See blueprints/source_record_types.py
                    source_id: 'FLAT_FILE',
                    name: recordTypeName,
                    description: recordTypeDescription,
                    resource_name: '',
                    column_preferences: [],
                    composite_key: compositeKeySelection,
                });
                srtIdImportTarget = srtResult.id as string;
            } else {
                srtIdImportTarget = sourceRecordTypeId;
            }


            
            const result = await ApiService.getInstance().request('POST', `/files/${activeFilePart!.file_id}/parts/${activeFilePart!.id}/load`, {
                'source_record_type_id': srtIdImportTarget,
                'columns': selectedColumns,
            }) as JobEnqueueResponse;

            const loadResults = await BackgroundService.getInstance().waitForJob(result.job_id);

            const pipelineNode = await PipelineNodeORM.find({
                'source_record_type_id': srtIdImportTarget,
            });

            invalidatePipelineNodes();
            invalidateDataLoadsForSRT(srtIdImportTarget);
            invalidateMissionControlDataSourcesData();
            invalidateMissionControlDataFlowData();
            invalidatePipelineNodes();
            invalidateTableData();
            close(pipelineNode.records[0]!.id as string);
            
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setImporting(false);
        }
    }, [activeFilePart, sourceRecordTypeId, recordTypeName, recordTypeDescription, selectedColumns, compositeKeySelection]);

    const [selectedConnectorType, setSelectedConnectorType] = useState('FLAT_FILE');

    const addButtonIsDisabled = useMemo(() => {
        if (selectedConnectorType == 'FLAT_FILE' && selectedColumns.length > 50) {
            return true;
        }
        if (sourceRecordTypeId) {
            return false;
        }
        if (recordTypeName) {
            return false;
        }
        

        return true;
    }, [sourceRecordTypeId, recordTypeName, selectedConnectorType, selectedColumns]);

    const recordTypeOptions = useMemo(() => {
        if (!sourceRecordTypes.data) {
            return [];
        }

        return sourceRecordTypes.data.filter(s => {
            if (!selectExistingBlacklist) {
                return true;
            }
            if (!selectExistingBlacklist.includes(s.id as string)) {
                return true;
            }
            return false;
        }).sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            return 1;
        }).map(f => {
            return {
                label: f.name,
                value: f.id,
            }
        })
    }, [sourceRecordTypes.dataUpdatedAt, selectExistingBlacklist]);

    const onSelectExisting = useCallback((val: PipelineNode|undefined) => {
        if (val) {
            onSourceAdded(val.id as string)
        }
    }, [onSourceAdded]);

    const chooseConnectorType = useCallback((value: string) => {
        setSelectedConnectorType(value);
        if (value == 'SNOWFLAKE_TABLE') {
            setStep('SELECT_TABLE');

        } else {
            setStep('UPLOAD_DATA');
        }
    }, []);

    const [connectorSourceLoading, setConnectorSourceLoading] = useState(false);

    const availableUpstreamNodeFilterFunc = useCallback((pn: PipelineNode) => {
        console.log('checking node', pn);
        if (!pipelineNode.data) {
            console.log('No pn data', pipelineNode.data);
            return true;
        }

        if (pn.node_type == 'BUSINESS_OBJECT') {
            return false;
        }
        return !pipelineNode.data.upstream_node_ids.includes(pn.id as string);
    }, [pipelineNode.dataUpdatedAt])
    

    const saveConnectorSource = useCallback(async () => {
        setConnectorSourceLoading(true);
        const newSource = {
            id: null,
            name: sourceName,
            type: selectedConnectorType,
        };

        const result = await SourceORM.save(newSource);
        navigate('/source/' + result.id as string);
        setConnectorSourceLoading(false);
    }, [sourceName, selectedConnectorType, navigate]);


    const availableSnowflakeTables = useAvailableSnowflakeTables();
    const availableTableOptions = useMemo(() => {
        if (!availableSnowflakeTables.data) {
            return [];
        }

        return availableSnowflakeTables.data.map(r => {
            return {
                value: `"${r.database_name}"."${r.schema_name}"."${r.name}"`,
                label: r.name,
                description: `Schema: ${r.database_name}.${r.schema_name}`,
                badgeText: r.rows == undefined ? 'VIEW' : (summarizeNumber(r.rows) + ' rows'),
            }
        });
    }, [availableSnowflakeTables.dataUpdatedAt]);


    const runTableImport = useCallback(async () => {
        setImporting(true);
        try {
            const srtResult = await SourceRecordTypeORM.save({
                id: null,
                // this lazy-creates the singleton flat file data source. See blueprints/source_record_types.py
                source_id: 'SNOWFLAKE_TABLE',
                name: recordTypeName,
                description: recordTypeDescription,
                resource_name: selectedTable,
                column_preferences: [],
                composite_key: compositeKeySelection,
                // columns_to_import: selectedColumns,
                last_modified_column: lastModifiedColumnSelection
            });

            const pipelineNode = await PipelineNodeORM.find({
                'source_record_type_id': srtResult.id,
            });

            const orchestration = await BuildOrchestrationORM.buildModel('PipelineNode', pipelineNode.records[0]!.id as string);
            await BackgroundService.getInstance().waitForJob(orchestration.job_id);

            invalidateSources();
            invalidateSourceRecordTypes();
            invalidateMissionControlDataSourcesData();
            invalidateMissionControlDataFlowData();
            close(pipelineNode.records[0]!.id as string);
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setImporting(false);
        }
        
    }, [selectedTable, compositeKeySelection, recordTypeName, recordTypeDescription, selectedColumns]);

    const [gettingTableStructure, setGettingTableStructure] = useState(false);
    const [compositeKeyOptions, setCompositeKeyOptions] = useState<Option[]>([]);
    const getTableStructure = useCallback(async () => {
        setGettingTableStructure(true);
        try {
            const tableColumns = await ApiService.getInstance().request('GET', `/tenant-config/${tenantConfigId}/get-snowflake-table-structure`, {
                'table': selectedTable,
            }) as ListRecordsResponse<SnowflakeColumnOption>;
            setCompositeKeyOptions(tableColumns.records.map(tc => {
                return {
                    value: tc.name,
                    label: tc.name,
                    description: tc.type,
                }
            }));
            setSelectedColumns(tableColumns.records.map(v => v.name));

            const selection = tableColumns.records.filter(tc => tc.primary_key === 'Y').map(tc => tc.name);
            setCompositeKeySelection(selection);
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setGettingTableStructure(false);
        }
        
    }, [selectedTable, tenantConfigId]);

    const loadSelectedTable = useCallback(async () => {
        await getTableStructure();
        setStep('CONFIRM_IMPORT');
    }, [getTableStructure]);


    useEffect(() => {
        if (activeFilePart) {
            setSelectedColumns(activeFilePart.headers);
            setCompositeKeyOptions(activeFilePart.headers.map(name => {
                return {
                    value: name,
                    label: name,
                    description: '',
                }
            }));

        } else {
            setSelectedColumns([]);
            setCompositeKeyOptions([]);
        }
    }, [activeFilePart]);

    const columnOptions = useMemo(() => {
        if (activeFilePart) {
            return activeFilePart.headers.map(h => {
                return {value: h, label: h}
            });
        }
        return [];
    }, [activeFilePart]);

    const loadingAvailableTables = useMemo(() => {
        return availableSnowflakeTables.isFetching || availableSnowflakeTables.isRefetching || availableSnowflakeTables.isLoading;
    }, [availableSnowflakeTables.isFetching, availableSnowflakeTables.isRefetching, availableSnowflakeTables.isLoading])
    
    const getContent = () => {
        if (step == 'SELECT_CONNECTOR_TYPE') {
            return (
                <>
                    <p>Where does your data live?</p>
                    
                    

                    <div className="row">
                        <div className="col-6 pe-1">
                            <button className="btn btn-pliable w-100" onClick={() => chooseConnectorType('FLAT_FILE')}>Flat File</button>

                        </div>
                        <div className="col-6 ps-1">
                            <button className="btn btn-primary w-100" onClick={() => chooseConnectorType('SNOWFLAKE_TABLE')}>Snowflake Table</button>
                            
                        </div>
                    </div>

                        
                </>
            )
        } else if (step == 'SELECT_TABLE') {
            return (
                <>
                    
                    <Form.Group>
                        <Form.Label>Select Database Table</Form.Label>
                    </Form.Group>


                    <>
                        <div className="d-flex center-vertically">
                            <Dropdown
                                className="mb-2 flex-1"
                                options={availableTableOptions}
                                selected={selectedTable}
                                onChange={setSelectedTable}
                            />
                            <button className="icon-button ms-1" disabled={loadingAvailableTables} onClick={() => availableSnowflakeTables.refetch()}>
                                {loadingAvailableTables && (
                                    <i className="mdi mdi-loading mdi-spin"></i>
                                )}
                                {!loadingAvailableTables && (
                                <i className="mdi mdi-refresh"></i>
                                )}
                            </button>
                        </div>
                        {!loadingAvailableTables && (
                            <div className="shadow-box p-2 mb-2">
                                <p><strong>Don't see the one you want?</strong> Make sure the Pliable role has access to the database, schema, and table you want to load:</p>
                                <p>
                                    <code>GRANT USAGE ON DATABASE DB_NAME TO ROLE PLIABLE_DATA_ROLE;</code>
                                </p>
                                <p>
                                    <code>GRANT USAGE ON SCHEMA DB_NAME.SCHEMA_NAME TO ROLE PLIABLE_DATA_ROLE;</code>
                                </p>
                                <p>
                                    <code>GRANT SELECT ON TABLE DB_NAME.SCHEMA_NAME.TABLE_NAME TO ROLE PLIABLE_DATA_ROLE;</code>
                                </p>
                                
                            </div>
                        )}
                        
                    </>
                   

                    <button className="btn btn-light btn-sm me-1" onClick={() => setStep('SELECT_CONNECTOR_TYPE')}>
                        <i className="mdi mdi-arrow-left"></i> Go back
                    </button>
                    <button className="btn btn-pliable-gradient" disabled={!selectedTable} onClick={() => loadSelectedTable()}>Continue</button>
                </>
            )
        // } else if (step == 'CONNECT_SYSTEM') {
        //     return (
        //         <>
        //              <Form.Group className="mb-3">
        //                 <Form.Label>Name</Form.Label>
        //                 <Form.Control autoFocus placeholder="" value={sourceName} onChange={(e: ChangeEvent) => setSourceName((e.target as HTMLInputElement).value)} type="text" size="lg"/>
        //             </Form.Group>
        //             <AsyncButton
        //                 variant="pliable"
        //                 onClick={saveConnectorSource}
        //                 text="Create Source"
        //                 loading={connectorSourceLoading}
        //             />
        //         </>
        //     )
        } else if (step == 'UPLOAD_DATA') {
            return (
                <>

                    <p>Upload any file readable by Excel.</p>

                    <div className="flex-1">
                        <div className="mb-2">
                            <S3Uploader
                                label="Drag and drop or %{browse} to upload" 
                                maxUploads={1} 
                                parallelUploads={1} 
                                onUploadComplete={(file: File) => fileUploadCompleted(file)}
                            />
                        </div>
                        {/* <div>
                            <p className="file-upload-info">Supported formats: XLSX, CSV, TSV, and most formats Excel supports</p>
                        </div> */}
                        {error && <Danger>{error}</Danger>}
                        
                    </div>
                </>
            );        
        } else if (step == 'CONFIRM_IMPORT') {

            return (
                <>

                    <div className="flex-1">
                        {selectedConnectorType == 'FLAT_FILE' && (
                            <div className="mb-2">
                                {fileParts.length > 1 && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>Select Tab</Form.Label>
                                        <Form.Select value={activeFilePart!.part_identifier} onChange={(e) => setActiveFilePart(fileParts.find(p => p.part_identifier === e.target.value))}>
                                            {fileParts.map(p => {
                                                return <option value={p.part_identifier}>{p.part_identifier}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                )}
                                
                                <h4>Sample Data</h4>
                                <div className="border mb-2">
                                    <SimpleDataTable data={tableDataFromRows([activeFilePart!.headers].concat(activeFilePart!.sample_rows))}/>
                                </div>
                                {activeFilePart!.headers.length > 50 && (
                                    <Warning>
                                        <div>
                                            <strong>This file has a ton of columns ({activeFilePart!.headers.length}).</strong><br />
                                            You should narrow it down to 50 to keep things usable.<br />
                                            <MultiDropdown
                                                options={columnOptions}
                                                selected={selectedColumns}
                                                onChange={setSelectedColumns}
                                            />
                                            <Form.Text>
                                                {selectedColumns.length > 50 && <span className="text-danger">{selectedColumns.length} / 50</span>}
                                                {selectedColumns.length <= 50 && <span className="text-success">{selectedColumns.length} / 50</span>}

                                                
                                            </Form.Text>
                                        </div>
                                    </Warning>
                                )}
                                {/* <button className="btn btn-pliable"><i className="mdi mdi-rotate-right"></i> Rotate</button> */}
                                {!location.pathname.endsWith('/files') && (
                                    <Form.Group className="mb-2">
                                        <Form.Label>Choose Primary Key</Form.Label>
                                        <MultiDropdown
                                            selected={compositeKeySelection}
                                            options={compositeKeyOptions}
                                            onChange={setCompositeKeySelection}
                                        />
                                        <Form.Text>We need to know this in order to track things like lineage. A primary key can consist of more than one column. Note: if you do not pick a primary key, we will automatically generate one by hashing the entire row.</Form.Text>
                                    </Form.Group>
                                )}
                            </div>
                        )}
                        {selectedConnectorType == 'SNOWFLAKE_TABLE' && (
                            <>
                                <div className="mb-2">
                                    <strong>Importing Snowflake Table</strong><br />
                                    <code>{selectedTable}</code>
                                    
                                </div>
                                <Form.Group className="mb-2">
                                    <Form.Label>Choose Primary Key</Form.Label>
                                    <MultiDropdown
                                        selected={compositeKeySelection}
                                        options={compositeKeyOptions}
                                        onChange={setCompositeKeySelection}
                                    />
                                    <Form.Text>We need to know this in order to track things like lineage. A primary key can consist of more than one column. Note: if you do not pick a primary key, we will automatically generate one by hashing the entire row.</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label className="small">Last Modified Column</Form.Label>
                                    <Dropdown
                                        selected={lastModifiedColumnSelection}
                                        options={compositeKeyOptions}
                                        onChange={setLastModifiedColumnSelection}
                                    />
                                    <Form.Text>We need to know this to track when data changes. Without it, we may need recalculate more often.</Form.Text>
                                </Form.Group>
                                {/* {compositeKeyOptions.length > 50 && (
                                    <Warning>
                                        <div>
                                            <strong>This table has a ton of columns.</strong><br />
                                            We recommend narrowing it down to keep things usable. Please select the columns you want to use for analysis:<br />
                                            <MultiDropdown
                                                options={compositeKeyOptions}
                                                selected={selectedColumns}
                                                onChange={setSelectedColumns}
                                            />
                                        </div>
                                    </Warning>
                                )} */}
                            </>
                        )}

                    </div>
                    {error && <Danger>{error}</Danger>}
                    {!sourceRecordTypeId && (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="recordTypeName">Name</Form.Label>
                                <Form.Control id="recordTypeName" autoFocus placeholder="" value={recordTypeName} onChange={(e: ChangeEvent) => setRecordTypeName((e.target as HTMLInputElement).value)} type="text" size="lg"/>
                                <Form.Text>For example, "Salesforce Contacts Export" or "Bank Transactions"</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="recordTypeDescription">Description</Form.Label>
                                <Form.Control id="recordTypeDescription" placeholder="" value={recordTypeDescription} onChange={(e: ChangeEvent) => setRecordTypeDescription((e.target as HTMLInputElement).value)} as="textarea" rows={3} />
                            </Form.Group>
                        </>
                        
                    )}
                        
                    <footer>
                        <Button onClick={() => close('')} variant="light" className="flex-grow-1 me-1">
                            Cancel
                        </Button>
                        {selectedConnectorType == 'FLAT_FILE' && (
                            <>
                                <AsyncButton loading={importing} onClick={() => runImport()}  variant="pliable" className="flex-grow-1" text="Add" disabled={addButtonIsDisabled}></AsyncButton>
                                {importing && (
                                    <div>
                                        <Form.Text>
                                            We're loading this file into Snowflake. This may take up to 30 seconds.
                                        </Form.Text>
                                    </div>
                                )}
                                
                            </>
                            
                        )}
                        {selectedConnectorType == 'SNOWFLAKE_TABLE' && (
                            <>
                                <AsyncButton loading={importing} onClick={() => runTableImport()}  variant="pliable" className="flex-grow-1" text="Add" disabled={addButtonIsDisabled}></AsyncButton>
                                {importing && (
                                    <div>
                                        <Form.Text>
                                            We're creating a copy of this table and applying some initial cleaning rules. This may take a few moments depending on the size of your warehouse and the number of rows in the table.
                                        </Form.Text>
                                    </div>
                                )}
                            </>
                            
                        )}
                        
                        
                    </footer>
                    
                </>
            );
        }
    }

    return (
        <Styles>
            {getContent()}
            {allowSelectExisting && (
                <>
                    <hr />
                    Or, choose an existing data source
                    <PipelineNodeSelector
                        selectedId=""
                        onSelect={onSelectExisting}
                        optionFilter={availableUpstreamNodeFilterFunc}
                    />
                </>
            )}
        </Styles>
    );
    
}

export default DataSourceImporter;