import styled from 'styled-components';
import { Badge } from "react-bootstrap";
import { PropsWithChildren, ReactNode, useCallback } from "react";
import { getPromptAnswer, prompt } from '@services/alert/alert.service';
import { useDraftVersionId } from '@stores/data.store';
import { requireDraftMode } from '@components/branch/help';
import { DraftModeRequired } from '@components/project/DraftModeRequired.component';


interface Props {
   
    title: string;
    description?: string;
    onChangeTitle?: (newTitle: string) => void;
    onChangeDescription?: (newDescription: string) => void;
    saving?: boolean;
}

const Styles = styled.div`

.img img {
    max-width: 40px;
    max-height: 40px;
    margin-right: 10px;
}


h2, .description {
    .edit-icon {
        opacity: 0;
    }

    &:hover {
        .edit-icon {
            opacity: 1; 
        }
    }
}
`

const RecordTitleAndDescription = (props: PropsWithChildren<Props>) => {
    const {data: draftVersionId} = useDraftVersionId();

    const onEditTitle = useCallback(async () => {
        if (!props.onChangeTitle) {
            return;
        }
        const newTitle = await getPromptAnswer('Enter new name', 'Update Name', false, props.title);

        if (newTitle) {
            props.onChangeTitle(newTitle);
        }
    }, [props.onChangeTitle, props.title]);

    const onEditTitleClick = useCallback(() => {
        requireDraftMode(
            draftVersionId as string,
            () => onEditTitle()
        )
    }, [draftVersionId]);

    const onEditDescription = useCallback(async () => {
        if (!props.onChangeDescription) {
            return;
        }
        const newDescription = await getPromptAnswer('Enter new description', 'Update Description', true, props.description);

        if (newDescription) {
            props.onChangeDescription(newDescription);
        }
    }, [props.onChangeDescription, props.description]);

    const onEditDescriptionClick = useCallback(() => {
        requireDraftMode(
            draftVersionId as string,
            () => onEditDescription()
        )
    }, [draftVersionId]);

    return <Styles>
        <div className="mb-1">
            {props.children}
        </div>
        <div className="d-flex mb-1 center-vertically">
           
            <h2 className="mb-0 flex-1">
                {props.title}
                <DraftModeRequired justHideChildren>
                    {props.onChangeTitle && <button className="icon-button edit-icon" onClick={onEditTitleClick}><i className="mdi mdi-pencil"></i></button>}

                </DraftModeRequired>
            </h2>
        </div>
        <p className="description font-poppins">
            {props.description ? props.description : 'No description'}
            <DraftModeRequired justHideChildren>
                {props.onChangeDescription && <button className="icon-button edit-icon" onClick={onEditDescriptionClick}><i className="mdi mdi-pencil"></i></button>}

            </DraftModeRequired>
        </p>
        
    </Styles>
}

export default RecordTitleAndDescription;