import Dropdown from '@components/form/Dropdown.component';
import Select from 'react-select';

interface Props {
    selected: string;
    onSelect: (newValue: string) => void;
    className?: string;
    disabled?: boolean;
}



const ATTRIBUTE_OPTIONS = [
    {
        label: 'Text',
        value: 'STRING',
    }, {
        label: 'Date (No Time)',
        value: 'DATE',
    }, {
        label: 'Date + Time',
        value: 'DATETIME',
    },{
        label: 'Date + Time (w/ Timezone)',
        value: 'DATETIME_TZ',
    }, {
        label: 'Number (No Decimals)',
        value: 'INT'
    }, {
        label: 'Number (w/ Decimals)',
        value: 'DECIMAL',
    }
];

const BusinessObjectFieldTypeSelector = (props: Props) => {
    return <>
        <Dropdown 
            disabled={!!props.disabled}
            className={props.className ? props.className: ''}
            options={ATTRIBUTE_OPTIONS} 
            selected={props.selected} 
            onChange={(e) => props.onSelect(e ? e : '')}
        />    
    </>
}

export default BusinessObjectFieldTypeSelector;
