import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LoadingCard from "@components/card/LoadingCard.component";
import AuthService from "@services/auth/auth.service";
import { useAuthActions } from "@frontegg/react";
import TrackingService, { Events } from "@services/tracking.service";
import { queryClient } from "@stores/data.store";



const TenantReturn = () => {
    const navigate = useNavigate();
    const { tenantUuid } = useParams();
    const { switchTenant } = useAuthActions();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const authState = AuthService.getInstance().authState;
        if (authState?.user?.tenantId != tenantUuid){
            switchTenant({ tenantId: tenantUuid as string });
            queryClient.removeQueries();
            TrackingService.track_event(Events.USER_TENANT_SWITCHED, {
                new_tenant_uuid: tenantUuid
            });

        }
        const path = searchParams.get('return_path') || '/';
        TrackingService.track_event(Events.USER_CTA_RETURN, {
            path: path,
            cta_name: searchParams.get('cta_name')
        });
        setTimeout(() => navigate(path, {replace: true}), 400);
    }, []);

    return <LoadingCard />;
}

export default TenantReturn;