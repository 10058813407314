import { useMemo } from "react";
import Select from 'react-select';
import Dropdown, { Option as DropdownOption } from "@components/form/Dropdown.component";
import { ColumnSort, PipelineNodeField } from "@models/pipelineNode";
import { useIsInDraftMode } from "@stores/data.store";

interface Props {
    fields: PipelineNodeField[];
    currentSort: ColumnSort[];
    onChange: (newSorts: ColumnSort[]) => void;
}

const DefaultSortForm: React.FC<Props> = ({ fields, currentSort, onChange }) => {
    const inDraftMode = useIsInDraftMode();

    const defaultSortOptions: any[] = useMemo(() => {
        if (!fields.length) {
            return [];
        }

        const options: any[] = [];

        const handleOption = (label: string, value: string, description?: string) => {
            options.push({
                label: `${label} DESC`,
                value: {
                    column_id: value,
                    direction: 'DESC'
                },
            });
            options.push({
                label: `${label} ASC`,
                value: {
                    column_id: value,
                    direction: 'ASC'
                }
            });
        };

        fields.forEach(field => {
            handleOption(field.label, field.name, field.description);
        });

        return options;
    }, [fields]);

    const availableDefaultSortOptions = useMemo(() => {
        if(!!currentSort) {
            return defaultSortOptions.filter((op) => {
                let found = currentSort.find((sort) => sort.column_id == op.value.column_id);
                return !found;
            });
        }
        return [];
    }, [defaultSortOptions, currentSort]);


    const defaultSortSelectValue = useMemo(() => {
        if(!!currentSort){
            return defaultSortOptions.filter((op) => {
                return !!currentSort.find((sort) => {
                    return sort.column_id == op.value.column_id && sort.direction == op.value.direction
                })
            })
        }

    }, [currentSort, defaultSortOptions])

    return (
        <div>
            <Select
                isDisabled={!inDraftMode}
                // placeholder="Select..."
                isMulti={true}
                options={availableDefaultSortOptions}
                value={defaultSortSelectValue}
                onChange={(e) => {
                    console.log(Array.from(e.values()));
                    //   @ts-ignore
                    onChange(Array.from(e.values()).map((op) => op.value));
                }}
                className="mb-2"
            />
        </div>
    );
};

export default DefaultSortForm;