import ApiService, { JobEnqueueResponse } from "@services/api/api.service";
import ORM, { BaseModel } from "./orm";
import { queryClient } from "@stores/data.store";
import { QueryClient, useQuery } from "react-query";
import BackgroundService from "@services/bg.service";
import { PipelineNode } from "./pipelineNode";

export interface Seed extends BaseModel {
    name: string;
    headers: string[];
    data?: string[][];
    seed_type: string;

    generated_from_node_id?: string;
    generated_from_node_column?: string;
    generated_at?: Date;
}

const SeedORM = new ORM<PipelineNode>('seeds', ['generated_at']);

export const useTranslationSeed = (nodeId: string, fieldId: string) => {
    return useQuery(['translation_seeds', nodeId, fieldId], async () => {
        if (!nodeId || !fieldId) {
            return null;
        }
        const { records } = await SeedORM.find({
            'generated_from_node_id': nodeId,
            'generated_from_node_column': fieldId,
        });


        if (records.length > 0) {
            return records[0];
        }
        return null;
    }, {
        staleTime: 30 * 1000
    });
}

export const regenerateSeed = async (seedId: string) => {
    const response = await ApiService.getInstance().request('POST', `/seeds/${seedId}/regenerate`, {});
    queryClient.invalidateQueries(['seeds', seedId]);
    return response;
} 

export const loadSeed = async (seedId: string) => {
    const jobInfo = await ApiService.getInstance().request('POST', `/seeds/${seedId}/load`, {}) as JobEnqueueResponse;
    const result = await BackgroundService.getInstance().waitForJob(jobInfo.job_id);
    return result;
}

export const useSeeds = () => {
    return useQuery(['seeds'], async () => {
        const { records } = await SeedORM.find();
        return records;
    }, {
        staleTime: 30 * 1000
    });
}

export const useSeed = (seedId: string) => {
    return useQuery(['seeds', seedId], async () => {
        const result = await SeedORM.findById(seedId);
        return result;
    }, {
        // Longer stale time since there's more data
        staleTime: 60 * 1000 * 5
    })
}

export const saveSeed = async (seed_node: PipelineNode) => {
    const result = await SeedORM.save(seed_node);
    queryClient.invalidateQueries('seeds');
    queryClient.invalidateQueries('translation_seeds');
    return result;
}