import Nav from "@components/nav/Nav.component";
import DraftBanner from "@components/pageTitle/DraftBanner.component";
import PageTitle, { Breadcrumb } from "@components/pageTitle/PageTitle.component";
import { useDraftVersionId } from "@stores/data.store";
import useGlobalState from "@stores/global.state";
import { ReactNode, useMemo } from "react";
import styled, { css } from 'styled-components';


export const Main = styled.main`
    
    height: 100%;
    width: 100%;
    button.toggle-library-button {
        position: fixed;
        z-index: 1001;
        top: 12px;
        left: 50px;
        width: 26px;
        height: 26px;
        border-radius: 13px;
        font-size: 18px;
        padding: 0px;
        line-height: 18px;
        border: solid 1px var(--ct-border-color);
        background-color: white;
        color: #878787;
        box-shadow: 0px 1.470588207244873px 2.2058823108673096px 0px #0000001A;
        -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        &:hover {
            border-color: #555;
            color: #555;
        }

    }

    &.data-library {
        .toggle-library-button {
            left: 380px;
        }
    }

    .content-wrapper {
        height: 100vh;
        width: 100vw;
        height: -webkit-fill-available;
        max-height: 100vh;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
                flex-direction: row;
        
        .page-content {
            width: calc(100vh - 60px);
            height: 100vh;
            flex: 1;

            .page-content-inner {
                height: calc(100vh);
                display: flex;
                flex-direction: row;

                &.page-content-inner-draft {
                    height: calc(100vh - 50px);
                }
            }
        }
    }
`

interface Props {
    children: ReactNode;
    pageTitle: string;
    breadcrumbs?: Breadcrumb[];
    pageTitleChildren?: ReactNode;
}

const PageStructure = (props: Props) => {
    const sidebarVisible = useGlobalState((state: any) => !state.libraryCollapsed);
    const toggleSidebar = useGlobalState((state: any) => state.toggleLibrary);
    const {data: draftVersionId} = useDraftVersionId();

    let className = 'd-flex flex-nowrap';

    if (sidebarVisible) {
        className = className + ' data-library';
    }

    return <Main className={className}>
        {/* <button className="toggle-library-button" onClick={() => toggleSidebar()}>
            {sidebarVisible && <i className="mdi mdi-chevron-left"></i>}
            {!sidebarVisible && <i className="mdi mdi-chevron-right"></i>}
        </button> */}

        <div className="content-wrapper">
            <Nav/>
            <div className="page-content">
                <DraftBanner />
                {/* <PageTitle
                    title={props.pageTitle}
                    breadcrumbs={props.breadcrumbs}
                >
                    {props.pageTitleChildren}
                </PageTitle> */}
                <div className={`page-content-inner page-content-inner-draft`}>
                    {props.children}
                </div>
            </div>
            
            
        </div>
    </Main>
}

export default PageStructure;

export const PageSidebar = styled.div<{ big?: boolean; right?: boolean}>`
    width: 330px;
    height: 100%;
    overflow-y: hidden;
    background-color: white;
    position: relative;
    border-right: solid 1px var(--ct-border-color);
    overflow-x: hidden;

    ${props =>
        props.big &&
        css`
            width: 430px;
        `
    };

    ${props => 
        props.right && 
        css`
            border-right: none;
            border-left: solid 1px var(--ct-border-color);
        `
    };
`

export const PageContent = styled.div<{ bigSidebar?: boolean; rightSidebar?: boolean; noSidebar?: boolean; bigRightSidebar?: boolean;}>`
width: calc(100vw - 60px - 330px);

${props =>
    props.bigSidebar &&
    css`
        width: calc(100vw - 60px - 430px);
    `
};

${props =>
    props.bigSidebar && props.rightSidebar && !props.bigRightSidebar &&
    css`
        width: calc(100vw - 60px - 430px - 330px);
    `
};

${props =>
    props.bigSidebar && props.rightSidebar && props.bigRightSidebar && 
    css`
        width: calc(100vw - 60px - 430px - 430px);
    `
};

${props =>
    props.rightSidebar &&  !props.bigSidebar &&
    css`
        width: calc(100vw - 60px - 330px - 330px);
    `
};

${props =>
    props.noSidebar && props.rightSidebar && props.bigRightSidebar &&
    css`
        width: calc(100vw - 60px - 430px);
    `
};

${props =>
    props.noSidebar && props.rightSidebar && !props.bigRightSidebar &&
    css`
        width: calc(100vw - 60px - 330px);
    `
};

${props =>
    props.noSidebar && !props.rightSidebar &&
    css`
        width: calc(100vw - 60px);
    `
};
`

export const PageContentNoSidebar = styled.div`
width: calc(100vw - 60px);
`


export const PaneContentWithSubnav = styled.div`
height: calc(100% - 50px);
`

export const Pane = styled.div`
height: 100%;
display: flex;
flex-direction: column;
`;

export const PaneContent = styled.div<{noScroll?: boolean, subnav?: boolean}>`
flex: 1 1 auto;
height: 0;

${props =>
    !props.noScroll && 
    css`
        overflow-y: auto;
    `
};

${props => props.subnav && css`
    height: calc(100% - 50px) !important;
`}


`;


export const PaneContentSidebar = styled.div`
    width: 330px;
    height: 100%;
    background: white;
    border-left: solid 1px var(--ct-border-color);
`

export const PaneContentInnerWithSidebar = styled.div`
    width: calc(100% - 330px);
`

export const PaneFooter = styled.div<{big?: boolean}>`
height: 40px;
border-top: solid 1px var(--ct-border-color);
background:white;

${props =>
    props.big && 
    css`
        height: 100px;
    `
};
`;

const SwipeablePaneStyles = styled.div<{big?: boolean}>`
display: flex;
flex-direction: row;
height: 100%;
position: relative;

&> div {
    width: 330px;
    position: absolute;
    top: 0px;
    left: 0px;
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    ${props =>
        props.big &&
        css`
            width: 430px;
        `
    };
}
`


interface SwipeablePaneProps {
    children: ReactNode[];
    childIndex: number;
    big?: boolean;
}
export const SwipeablePane = (props: SwipeablePaneProps) => {
    const width = useMemo(() => {
        if (props.big) {
            return 430;
        }
        return 330;
    }, [props.big]);
    const translate = (props.childIndex -1) * -1 * width;
    return <SwipeablePaneStyles big={props.big} style={{
    }}>
        {props.children.filter(c => !!c).map((c, idx) => {
            
            return <div style={{left: `${(idx - props.childIndex) * width}px`, height: '100%'}}
            >
                {c}
            </div>
        })}
    </SwipeablePaneStyles>
}