import { SimpleDataTable } from "@components/datatable/DataTable.component";
import { ColumnRefForm } from "@components/sql/ColumnRef.component";
import { ColumnRef } from "@models/shared";
import { Filter, FilterConfig } from "@models/standardizationPipeline";
import ApiService from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import { decimalFormatter, integerFormatter } from "@services/formatting.service";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Overlay, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import Select, { SingleValue } from 'react-select';
import TrackingService, {Events} from "@services/tracking.service";
import styled from 'styled-components';
import produce from "immer";
import Dropdown, { Option as DropdownOption } from "@components/form/Dropdown.component";
import { BusinessObjectField } from "@models/businessObject";
import { DOMContainer } from "@restart/ui/esm/useWaitForDOMRef";
import { useImmer } from "use-immer";
import { useDebouncedCallback } from "use-debounce";
import { PipelineNodeField } from "@models/pipelineNode";
import Editor from "@monaco-editor/react";
import { DraftOnly } from "@components/project/DraftModeRequired.component";
import { useIsInDraftMode } from "@stores/data.store";


export const CleaningTable = styled.table`
td, th {
    padding-top: .25rem;
    padding-bottom: .25rem;
}

td:not(:last-child), th:not(:last-child) {
    padding-right: .25rem;
}

td:not(:first-child), th:not(:first-child) {
    padding-left: .25rem;
}
`
export const CleaningRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: .5rem;
    margin-bottom: .5rem;
    align-items: center;

    .text-col {
        width: 80px;
    }

    .split-col {
        width: calc(50% - 40px);
        padding: 0px .25rem;
    }

    .full-col {
        flex: 1;
        padding: 0px .25rem;
    }
`

export const comparatorOptions = [
    {
        label: 'is equal to',
        value: 'EQUALS',
    }, 
    {
        label: 'is not equal to',
        value: 'NOT_EQUALS',
    },
    {
        label: 'contains',
        value: 'CONTAINS',
    },
    {
        label: 'does not contain',
        value: 'NOT_CONTAINS',
    },
    {
        label: 'is greater than',
        value: 'GT',
    }, {
        label: 'is greater than or equal to',
        value: 'GTE',
    }, {
        label: 'is less than',
        value: 'LT',
    }, {
        label: 'is less than or equal to',
        value: 'LTE',
    }, {
        label: 'is blank',
        value: 'EMPTY',
    }, {
        label: 'is not blank',
        value: 'NOT_EMPTY',
    },{
        label: 'starts with',
        value: 'STARTS_WITH',
    },{
        label: 'does not start with',
        value: 'NOT_STARTS_WITH',
    },{
        label: 'ends with',
        value: 'ENDS_WITH',
    },{
        label: 'does not end with',
        value: 'NOT_ENDS_WITH',
    }, {
        label: 'is not a valid date',
        value: 'INVALID_DATE',
    }, {
        label: 'is a valid date',
        value: 'VALID_DATE',
    }, {
        label: 'is a valid number',
        value: 'VALID_NUMBER',
    }, {
        label: 'is not a valid number',
        value: 'INVALID_NUMBER',
    }
];

const comparatorsWithValues = [
    'EQUALS', 'NOT_EQUALS', 'CONTAINS', 'NOT_CONTAINS', 'GT', 'GTE', 'LT', 'LTE', 'STARTS_WITH', 'NOT_STARTS_WITH', 'ENDS_WITH', 'NOT_ENDS_WITH'
];

export const FilterTable = styled.table`
border: none;
td {
    padding: .5rem;
    border: none;
}

td:first-child {
    padding-left: 0px;
}

td:last-child {
    padding-right: 0px;
}
tr {
    border: none;
}
`

const FilterBox = styled.div`
border-radius: 5px;
background: var(--ct-border-color);
padding: .5rem;
`

const FilterForm = styled.div`
width: 100%;
`

const SingleFilterStyles = styled.div`
    background-color: #eef2f7;
    color: #313a46;
    border-radius: 5px;
    padding: .5rem;
    margin-bottom: .5rem;
    display: flex;

    &:last-child {
        margin-bottom: 0px;
    }

    &:not(.highlight):hover {
        background-color: white;
        color: black;
        cursor: pointer;
    }

    &.highlight {
        outline: solid 2px var(--pliable-yellow);


    }
`

interface SingleFilterProps {
    filter: Filter;
    columnOptions: DropdownOption[];
   
    onClick: () => void;
    onRemove: () => void;
    highlight: boolean;
}

const SingleFilter = ( props : SingleFilterProps) => {
    const columnName = useMemo(() => {
        if (!props.filter.column_ref.column_path) {
            return '[none]';
        }
        if (props.columnOptions.length == 0) {
            
            return `${props.filter.column_ref.column_path as string}`;
        }

        
        let rv = `${props.columnOptions.find(co => co.value === props.filter.column_ref.column_path)?.label}`;


        return rv;
    }, [props.columnOptions, props.filter.column_ref.column_path]);

    const value = useMemo(() => {
        if (!comparatorsWithValues.includes(props.filter.comparator)) {
            return '';
        }
        
        return `"${props.filter.value}"`;
    }, [props.filter.comparator, props.filter.column_value?.column_path, props.filter.value]);

    const comparator = useMemo(() => {
        return comparatorOptions.find(c => c.value === props.filter.comparator)?.label;

    }, [props.filter.comparator]);
    const className = useMemo(() => {
        if (props.highlight) {
            return 'highlight';
        }
        return ''
    }, [props.highlight])
    return <SingleFilterStyles className={className} onClick={() => props.onClick()}>
        <div className="flex-1">
            <strong>{columnName}</strong> <span>{comparator}</span> <strong>{value}</strong>
        </div>
        
        <DraftOnly>
            <span>
                <button onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    props.onRemove()
            }} className="icon-button"><i className="mdi mdi-close-thick"></i></button>
            </span>
        </DraftOnly>
       

    </SingleFilterStyles>
}

interface Props {
    config: FilterConfig;
    columnOptions: string[]|PipelineNodeField[];
    onChange: (config: FilterConfig) => void;
    changeOnApply?: boolean;
}



const logicGateOptions = [
    {
        label: 'Match ALL',
        description: 'Find records that match all of the filters below',
        value: 'AND',
    }, {
        label: 'Match ANY',
        description: 'Find records that match at least one of the filters below',
        value: 'OR',
    }, {
        label: 'Custom SQL',
        description: 'Write custom SQL for more advanced filtering options',
        value: 'CUSTOM',
    }
]

const EditorStyles = styled.div`
.monaco-editor .suggest-widget { 
    width: 280px !important; 
    left: 0px !important;
}
`

interface FilterResponse {
    filtered_out: any[];
    filtered_in: any[];
    count: number;
}

const FilterConfigForm = (props: Props) => {
    const [filterResponse, setFilterResponse] = useState<FilterResponse|undefined>(undefined);

    const [formData, setFormData] = useImmer<FilterConfig>({
        filters: [],
        logic_gate: 'AND',
    });

    const incrementFilterIdx = () => {
        setEditingFilterIdx(editingFilterIdx + 1)
    }

    const decrementFilterIdx = () => {
        setEditingFilterIdx(editingFilterIdx + -1)
    }

    const changedColumnRef = useCallback((idx: number, ref: ColumnRef) => {
        const newData = produce(props.config, draft => {
            draft.filters[idx].column_ref = ref;
        });

        props.onChange(newData);
        
    }, [props.config, props.onChange]);

    const changedColumnValue = useCallback((idx: number, ref: ColumnRef|undefined) => {
        const newData = produce(props.config, draft => {
            draft.filters[idx].column_value = ref;
        });
        
        props.onChange(newData);
    }, [props.config, props.onChange]);

    const changeComparator = useCallback((idx: number, comparator: string) => {
        const newData = produce(props.config, draft => {
            draft.filters[idx].comparator = comparator;
        })

        props.onChange(newData);
       
    }, [props.config, props.onChange]);

    

    const changeValue = useCallback((idx: number, value: string) => {
        const newData = produce(props.config, draft => {
            draft.filters[idx].value = value;
        })

        props.onChange(newData);
    }, [props.config, props.onChange]);

    const addFilter = useCallback(() => {
        const newData = produce(props.config, draft => {
            draft.filters.push({
                column_ref: {},
                comparator: 'EQUALS',
                value: '',
            });
        });

        props.onChange(newData);
        incrementFilterIdx();

    }, [props.config, props.onChange]);

    const [editingFilterIdx, setEditingFilterIdx] = useState(-1);

    

    const removeFilter = useCallback((idx: number) => {
        
        const newData = produce(props.config, draft => {
            draft.filters.splice(idx, 1);
        });

        props.onChange(newData);
        if (editingFilterIdx == idx) {
            setEditingFilterIdx(-1);
        } else if (editingFilterIdx > idx) {
            decrementFilterIdx();
        }

    }, [props.config, props.onChange, editingFilterIdx]);

    const filterIsValid = useCallback((f: Filter) => {
        if (!f.column_ref || !f.column_ref.column_path) {
            return false;
        } if (!f.comparator || (comparatorsWithValues.includes(f.comparator) && !f.value)) {
            return false;
        }
        return true;
    }, []);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const setLogicGate = useCallback((gate: string) => {
        props.onChange(produce(props.config, draft => {
            draft.logic_gate = gate;
        }));
        
    }, [props.config, props.onChange]);

    const setCustomSQL = useCallback((sql?: string) => {
        props.onChange(produce(props.config, draft => {
            draft.custom_sql = sql;
        }));
    }, [props.config, props.onChange]);


    const columnOptions: DropdownOption[] = useMemo(() => {
        if (!props.columnOptions.length) {
            return [];
        }

        if (typeof(props.columnOptions[0]) == 'object') {
            return props.columnOptions.map(co => {
                const field = co as PipelineNodeField;
                return {
                    label: field.label,
                    value: field.name,
                    description: field.description,
                };
            });
        } else {
            return props.columnOptions.map(co => {
                return {
                    label: co as string,
                    value: co as string,
                }
            })
        }
        return [];
    }, [props.columnOptions]);

    const [filterEditorTarget, setFilterEditorTarget] = useState<DOMContainer<HTMLElement>|undefined>(undefined);
    const addNewFilter = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // @ts-ignore
        setFilterEditorTarget(e.target);
        props.onChange(produce(props.config, draft => {
            draft.filters.push({
                column_ref: {},
                comparator: 'EQUALS',
                value: '',
            });
        }));
        setEditingFilterIdx(props.config.filters.length);
    }, [props.onChange, formData, props.config, editingFilterIdx]);

    const editorRef = useRef<any>();
    const [completionDisposable, setCompletionDisposable] = useState<any>();

    useEffect(() => {
        return () => {
            if (
                completionDisposable?.dispose &&
                typeof completionDisposable.dispose === "function"
            ) {
                completionDisposable.dispose();
            }
        };
    }, [completionDisposable]);
    
    const editorDidMount = useCallback((editor: any, monacoParam: any) => {
        editorRef.current = editor;

        setCompletionDisposable(
            monacoParam.languages.registerCompletionItemProvider('*', {
                provideCompletionItems: function(model: any, position: any) {
                    const suggestions = columnOptions.map(co => {
                        return {
                            label: co.label,
                            kind: monacoParam.languages.CompletionItemKind.Variable,
                            insertText: `"${co.value}"`
                        }
                    });     

                    return {
                        suggestions: suggestions
                    };
                }
            })
        );
    }, []);

    const inDraftMode = useIsInDraftMode();

    return <div>
            <Dropdown
                disabled={!inDraftMode}
                options={logicGateOptions}
                selected={props.config.logic_gate}
                onChange={setLogicGate}
                className="mb-2"
            />

            {props.config.logic_gate === 'CUSTOM' && (
                <EditorStyles>
                    <Editor
                        onChange={setCustomSQL}
                        height="150px"
                        defaultLanguage="sql"
                        options={{
                            readOnly: !inDraftMode,
                            minimap: { enabled: false },
                            lineNumbers: 'off',
                            glyphMargin: false,
                            folding: false,
                            // Undocumented see https://github.com/Microsoft/vscode/issues/30795#issuecomment-410998882
                            lineDecorationsWidth: 0,
                            lineNumbersMinChars: 0,
                            scrollbar: {
                                vertical: 'hidden'
                            },
                        }}
                        defaultValue={props.config.custom_sql}
                        
                        onMount={(editor, monaco) => editorDidMount(editor, monaco)}
                    />
                </EditorStyles>
                
            )}
            {props.config.logic_gate !== 'CUSTOM' && (
                <>
                    <FilterBox>
                        {props.config.filters.length === 0 && (
                            <div>No filters (matching all records)</div>
                        )}
                        {props.config.filters.map((f, idx) =>
                            <SingleFilter 
                                highlight={idx === editingFilterIdx} 
                                filter={f} 
                                columnOptions={columnOptions}  
                                onClick={() => setEditingFilterIdx(idx)} 
                                onRemove={() => removeFilter(idx)}
                            />
                        )}
                
                        
                        
                    </FilterBox>
                    {editingFilterIdx >= 0 && props.config.filters[editingFilterIdx] && (
                        <FilterForm className="shadow-box mt-2 p-2">
                            <Form.Group className="mb-2">
                                <Form.Label className="small">Select Column</Form.Label>
                                {columnOptions.length > 0 && (
                                    <Dropdown
                                        disabled={!inDraftMode}
                                        allowEmpty
                                        options={columnOptions}
                                        selected={props.config.filters[editingFilterIdx].column_ref.column_path}
                                        onChange={(newValue) => {
                                            changedColumnRef(editingFilterIdx, {
                                                column_path: newValue
                                            });
                                        }}
                                    />
                                )}
                                
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label className="small">Comparator</Form.Label>
                                <Dropdown
                                    disabled={!inDraftMode}
                                    options={comparatorOptions}
                                    
                                    selected={props.config.filters[editingFilterIdx].comparator}
                                    onChange={(val) => changeComparator(editingFilterIdx, !!val ? val : '')}
                                />
                            </Form.Group>
                            
                            {comparatorsWithValues.includes(props.config.filters[editingFilterIdx].comparator) && (
                                <Form.Group className="mb-2">
                                    <Form.Label className="small">Value</Form.Label>
                                    <Form.Control disabled={!inDraftMode} placeholder="enter value" type="text" className="match-with-dropdown" value={props.config.filters[editingFilterIdx].value} onChange={(e) => changeValue(editingFilterIdx, e.target.value)}/>
                                </Form.Group>
                            )}
                            
                            <button className="btn btn-sm btn-light" onClick={() => setEditingFilterIdx(-1)}>Done Editing</button>
                            
                        </FilterForm>
                    )}
                    <DraftOnly>
                        <button onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            addNewFilter(e);
                        }}  className="icon-button my-2">
                            <i className="mdi mdi-plus-circle"></i> Add Filter
                        </button>
                    </DraftOnly>
                    
                </>
            )}
            
            
            
        </div>
}

export default FilterConfigForm;

interface FilterFormDropdownProps extends Props {
    onClickApply: () => any;
    enableApplyOnProd?: boolean;
}
export const FilterFormDropdown = (props: FilterFormDropdownProps) => {

    const [showPopover, setShowPopover] = useState(false);
    const onApply = useCallback(() => {
        document.body.click();
        props.onClickApply();
    }, [props.onClickApply]);
    const inDraftMode = useIsInDraftMode();
    const popover = useMemo(() => {
        return <Popover id="popover-basic" show style={{width: '500px'}}>
            <Popover.Header>
                <div className="d-flex center-vertically">
                    <h3 className="flex-1 mb-0">Filter Data</h3>
                    {(inDraftMode || props.enableApplyOnProd) &&
                        <button className="btn btn-outline-success btn-sm" onClick={onApply}>
                            <i className="mdi mdi-check"></i> Apply Filters
                        </button>
                    }
                </div>
            </Popover.Header>
            <Popover.Body>
                <FilterConfigForm {...props}/>
            </Popover.Body>
        </Popover>
    }, [showPopover, props.onClickApply, props.config, props.columnOptions, props.onChange]);
    return <>
        <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
            <Button className="btn-sm" variant="outline-secondary"><i className="mdi mdi-filter-menu"></i> {inDraftMode ? 'Edit' : 'View'} Filters ({props.config.filters.length})</Button>
        </OverlayTrigger>
    </>
}