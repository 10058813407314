import { ReactNode } from "react";
import styled from 'styled-components';
import WarningStatusIndicator from "./WarningStatusIndicator.component";
import DangerStatusIndicator from "./DangerStatusIndicator.component";

const Styles = styled.div`
background-color: var(--ct-danger-bg);
color: var(--ct-danger);
border-radius: 5px;
padding: 1rem;
margin-bottom: 0.5rem;
font-family: "Poppins";

display: flex;

.icon-container {
    display: inline-block;
    margin-right: 1rem;
    width: 40px;
}

`
interface Props {
    children: ReactNode;
}

const Danger = (props: Props) => {
    return <Styles>
        <div className="icon-container">
            <DangerStatusIndicator/>

        </div>
        {props.children}
    </Styles>
}

export default Danger