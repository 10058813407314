import PliableLoader from "@components/loaders/PliableLoader.component";
import BuildOrchestrationORM from "@models/buildOrchestration";
import { TemplateGroup, TemplateGroupStep } from "@models/templateGroup";
import ApiService from "@services/api/api.service";
import TrackingService, { Events } from "@services/tracking.service";
import { useSources, useTemplateGroup } from "@stores/data.store";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Params {
    template_group: TemplateGroup;
    step: TemplateGroupStep;
    onComplete: () => void;
}
const MS_PER_MINUTE = 60000;

const WaitStep = (props: Params) => {
    const navigate = useNavigate();
    const {refetch: refetchTemplateGroup} = useTemplateGroup(props.template_group.id as string);

    const [lastActivity, setLastActivity] = useState<number|null>(null);

    // page load
    useEffect(() => {
        setLastActivity(new Date().getTime());
        TrackingService.track_event(Events.TEMPLATE_WAITING_FOR_SYNC, {
            step_name: props.step.name,
            step_id: props.template_group.active_step_idx,
            template_id: props.template_group.template_id,
        });
    }, []);

    useEffect(() => {
        if (props.template_group.status != 'done') {
            if (lastActivity && lastActivity > Date.now() - 60 * MS_PER_MINUTE) {
                // check for updates for 60 minutes from last actvity
                const interval = setInterval(async () => {
                    refetchTemplateGroup();
                }, 1000 * 15);
        
                //Clearing the interval
                return () => clearInterval(interval);
            }
            
        }
    }, [lastActivity, props.template_group.status]);
    
    return <>
                
                <div className="d-flex justify-content-center">
                    <PliableLoader size="lg" />
                </div> 
                <h1>{props.step.action_title}</h1>
                <p>{props.step.action_description}</p>
            
        </>
}

export default WaitStep;