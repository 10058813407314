import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AuthService from '@services/auth/auth.service';
import GlobalDndContext from './dnd-context';
import { FronteggProvider, FronteggThemeOptions } from '@frontegg/react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import createRoutes from './routes';


const contextOptions = {
  baseUrl: AuthService.getInstance().authUrl,
  clientId: AuthService.getInstance().feClientId,
  appId: AuthService.getInstance().feAppId,
};

const authOptions = {
  keepSessionAlive: true,
  routes: {
    loginUrl: '/login',
    logoutUrl: '/logout',
    authenticatedUrl: '/afterlogin',
    signUpUrl: '' // disable frontegg signup page
  }
};

const themeOptions: FronteggThemeOptions = {
  loginBox: {
    login: {
      signupMessage:
        '<div style="text-align:center;">Dont have an account? <a href="/signup">Sign up</a></div>',
    },
  },
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/*"
      element={
        <GlobalDndContext>
            <FronteggProvider authOptions={authOptions} contextOptions={contextOptions} themeOptions={themeOptions} hostedLoginBox={false} entitlementsOptions={{ enabled: true }} >
              <App />
            </FronteggProvider>
        </GlobalDndContext>
      }
    >
      {createRoutes()}
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<RouterProvider router={router} />);


