import { TableExplorer } from "@components/datatable/TableExplorer.component";
import ApiService, { ListRecordsResponse } from "@services/api/api.service";
import { useBusinessObject, useBusinessObjectData, useBusinessObjectRelationships, useBusinessObjects, useSourceData, useSourceRecordType } from "@stores/data.store";
import { useCallback, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import styled from 'styled-components';
import { 
    useTable,
    useSortBy, 
    useBlockLayout, 
    useResizeColumns, 
    usePagination 
} from 'react-table';
import { useSticky } from "react-table-sticky";
import { Link } from "react-router-dom";
import { BusinessObject } from "@models/businessObject";
import { parseDateString, timeAgo } from "@services/time.service";
import { summarizeNumber } from "@services/formatting.service";
import { FilterConfig } from "@models/standardizationPipeline";
import { ColumnPreference } from "@models/source";
import { TableLoadResponse } from "@models/shared";

const Styles = styled.div`
display: flex;
flex-direction: column;
height: 100%;
.table-container {
    height: calc(100% - 40px);
    overflow: scroll;
    width: 100%;
    flex: 1;
}

.footer-container {
    height: 40px;
    line-height: 40px;
    border-top: solid 1px var(--ct-border-color);
    padding: 0px 1rem;
    background-color: var(--bs-table-bg);
}


.table {
    border: none;
    border-color: var(--ct-border-color);
    margin-bottom: 0px;

    font-size: 13px;

    td, th {
        padding: .2rem .5rem;
        line-height: 20px;
    }

    th {
        border-top: none;
        font-family: "Poppins";
    }

    td:first-child, th:first-child {
        border-left: none;
    }

    td:last-child, th:last-child {
        border-right: none;
    }

    thead tr:first-child {
        border-top: none;
    }

    tbody tr:first-child td {
        border-top: none;
    }

    tbody tr:last-child td {
        border-bottom: none;
    }
    thead {
        position: sticky;
        z-index: 1;
        width: fit-content;
        top: 0;
        box-shadow: var(--ct-border-color) 0px 1px 3px 0px;
    }

    tfoot {
        position: sticky;
        z-index: 1;
        bottom: 0;
    }
}

.resizer {
    display: inline-block;
    background: transparent;
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    ${'' /* prevents from scrolling while dragging on touch devices */}
    touch-action:none;

    &.isResizing {
      background: transparent;
    }
  }

`
interface Props {
    sourceRecordTypeId: string;
    searchQuery?: string;
    filters?: FilterConfig;
    columnPreferences?: ColumnPreference[];
    onRecordCountChange?: (newRecordCount: number) => any;
    cacheBuster?: string;
}



const SourceRecordTypeDataTable = (props: Props) => {
    const sourceRecordType = useSourceRecordType(props.sourceRecordTypeId);


    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('');
   

    const columns = useMemo(() => {
        if (!sourceRecordType.data) {
            return [];
        }

        let columnList: any[] = [{
            header: 'Record ID',
            key: '_plb_uuid',
            
        }];

        if (props.columnPreferences) {
            columnList = columnList.concat(props.columnPreferences.filter(p => p.visible).map(p => {
                return {
                    header: p.rename || p.key,
                    key: p.key,
                }
            }))
        } else {
            columnList = columnList.concat((sourceRecordType.data.columns!).map(c => {
            
                return {
                    header: c.label,
                    key: c.key,
                    
                }
            }));
        }
        


        return columnList;
    }, [sourceRecordType.dataUpdatedAt, props.columnPreferences]);

    console.log('SRT TABLE FILTERS:', props.filters);


    const [recordCount, setRecordCount] = useState(0);

    const onDataLoaded = useCallback((reportData: TableLoadResponse) => {
        setRecordCount(reportData.total_records);
        if (props.onRecordCountChange) {
            props.onRecordCountChange(reportData.total_records);
        }
    }, []);

    



    

    return <Styles>
    <TableExplorer
        tablePath={`/sourcerecordtypes/${props.sourceRecordTypeId}/data`}
        columns={columns}
        searchQuery={props.searchQuery}
        filters={props.filters}
        onDataLoaded={onDataLoaded}
        cacheBuster={props.cacheBuster}
    />
    <div className="footer-container" style={{display: 'flex'}}>
        <div style={{'flex': 1}}>
            {summarizeNumber(recordCount)} total records
            
        
            
        </div>
        <div className="text-end">
            
        </div>
    </div>
    
</Styles>




}

export default SourceRecordTypeDataTable;