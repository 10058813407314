import { TableExplorer } from "@components/datatable/TableExplorer.component";
import { usePipelineNode, usePipelineNodeRelationships, usePipelineNodes } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Modal } from "react-bootstrap";
import styled from 'styled-components';
import { Link, useNavigate } from "react-router-dom";
import { summarizeNumber } from "@services/formatting.service";
import { Pane, PaneContent, PaneFooter } from "@pages/PageStructure.component";
import { ColumnSort,PipelineNodeCellAction, PipelineNodeField } from "@models/pipelineNode";
import { FilterConfig } from "@models/standardizationPipeline";
import { TableLoadResponse } from "@models/shared";
import PipelineNodeColumnDrawer from "./PipelineNodeColumnDrawer.component";
import { Column, SimpleDataTable } from "@components/datatable/DataTable.component";
import PipelineNodeColumnLineage from "./PipelineNodeColumnLineage.component";

const Styles = styled.div`
display: flex;
flex-direction: column;
height: 100%;
.table-container {
    height: calc(100% - 40px);
    overflow: scroll;
    width: 100%;
    flex: 1;
}

.footer-container {
    height: 40px;
    line-height: 40px;
    border-top: solid 1px var(--ct-border-color);
    padding: 0px 1rem;
}


.table {
    border: none;
    border-color: var(--ct-border-color);
    margin-bottom: 0px;
    font-size: 13px;

    td, th {
        padding: .2rem .5rem;
        line-height: 20px;
    }

    th {
        border-top: none;
        font-family: "Poppins";
    }

    td:first-child, th:first-child {
        border-left: none;
    }

    td:last-child, th:last-child {
        border-right: none;
    }

    thead tr:first-child {
        border-top: none;
    }

    tbody tr:first-child td {
        border-top: none;
    }

    tbody tr:last-child td {
        border-bottom: none;
    }
    thead {
        position: sticky;
        z-index: 1;
        width: fit-content;
        top: 0;
        box-shadow: var(--ct-border-color) 0px 1px 3px 0px;
    }

    tfoot {
        position: sticky;
        z-index: 1;
        bottom: 0;
    }
}

.resizer {
    display: inline-block;
    background: transparent;
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    ${'' /* prevents from scrolling while dragging on touch devices */}
    touch-action:none;

    &.isResizing {
      background: transparent;
    }
  }

`


interface Props {
    pipelineNodeId: string;
    showLineageButton?: boolean;
    filters?: FilterConfig;
    defaultSorts?: ColumnSort[];
    allowExpand?: boolean;
    searchQuery?: string;
    onRecordCountUpdated?: (newCount: number) => any;
    compact?: boolean;
    columnWhitelist?: string[];
    excludeRelationships?: boolean;
    dataOverride?: Record<string, any>[];
}

const PipelineNodeDataTable = (props: Props) => {
    const pipelineNode = usePipelineNode(props.pipelineNodeId);
    const relationships = usePipelineNodeRelationships(props.pipelineNodeId);

    const pipelineNodes = usePipelineNodes();

    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('');


    const [recordCount, setRecordCount] = useState(-1);

    const onDataLoaded = useCallback((reportData: TableLoadResponse) => {
        setRecordCount(reportData.total_records);
    }, []);

    useEffect(() => {
        if (recordCount >= 0 && props.onRecordCountUpdated) {
            props.onRecordCountUpdated(recordCount);
        }
    }, [recordCount, props.onRecordCountUpdated]);

    const columns: Column[] = useMemo(() => {
        if (!pipelineNode.data || !relationships.data || !pipelineNodes.data) {
            return [];
        }

        let columnList: any[] = [];

        // Keep track of current columns to make sure we don't pass duplicate column names to the data table. This currently can only
        // happen (I think) if someone creates multiple unnamed relationships between the same objects, bc then the foreign key
        // would be the same.
        const columnHeaders: string[] = [];

        // Hiding lineage until we bring it back
        if (props.showLineageButton && false) {
            columnList.push({
                header: ' ',
                key: '',
                display: (value: any, rowData: any) => {
                    return <Link to={`/business-object/${props.pipelineNodeId}/data/record/${rowData._plb_uuid.toString()}`}>
                        <i className="mdi mdi-eye"></i> View Lineage
                    </Link>
                }
            });
        }
        columnList.push({
            header: ' ',
            key: '_plb_uuid',
            display: (value: any, rowData: any) => {
                if (value) {
                    return <Link to={`/node/${props.pipelineNodeId}/data/${value}`}>
                    <i className="mdi mdi-eye"></i> View Record
                    </Link>
                } else {
                    return <span></span>
                }
                
            },
            enableSorting: false,
        });
        
        if (!props.excludeRelationships) {
            
            const relationshipColumns = relationships.data.filter(r => r.child_node_id === props.pipelineNodeId).map(r => {
                const parent = pipelineNodes.data.find(pn => pn.id === r.parent_node_id);
                const parentName = parent ? parent.singular : '<not found>';
                return {
                    header: parentName + (r.name ? (' (' + r.name + ')') : ''),
                    // header: r.child_foreign_key_name,
                    key: r.child_foreign_key_name,
                    display: (value: any) => {
                        if (!value) {
                            return <span></span>
                        } else if (value.toString() == '<AMBIGUOUS>') {
                            return <Badge bg="danger">Ambiguous</Badge>
                        } else {
                            return <Link to={`/node/${r.parent_node_id}/data/${value}`}>View Record</Link>
                        }
                    },
                    enableSorting: false,
                    
                };
            });
    
            relationshipColumns.forEach(c => {
                if (!columnHeaders.includes(c.header)) {
                    columnList.push(c);
                    columnHeaders.push(c.header);
                }
            })
        }
        

        const relationshipFields = relationships.data.filter(r => r.child_node_id === props.pipelineNodeId).map(r => r.child_foreign_key_name);

        // Choose composite key fields first

        columnList = columnList.concat(
        [...pipelineNode.data.fields]
            .filter(f => f.show_column)
            .sort((a, b) => a.part_of_composite_key ? -1 : (b.part_of_composite_key ? 1 : 0))
            .filter(f => !props.columnWhitelist || props.columnWhitelist.includes(f.id))
            .filter(f => !relationshipFields.includes(f.name))
            .map(f => {
                return {
                    header: f.label,
                    key: f.name,
                    formatter: f.formatter,
                }
            }));
            return columnList;
    }, [pipelineNode.data, props.excludeRelationships, relationships.data, pipelineNodes.data, props.showLineageButton, props.columnWhitelist]);
        
    

    const cellActions: { [columnId: string]: PipelineNodeCellAction[] } = useMemo(() => {
        if (!pipelineNode.data) {
            return {};
        }

        const actions: { [columnId: string]: PipelineNodeCellAction[] } = {};

        pipelineNode.data.fields.forEach((field) => {
            if (!!field.cell_actions && field.cell_actions.length > 0) {
                actions[field.name] = field.cell_actions;
            }
        });

        return actions;

    }, [pipelineNode.data]);
    

    const navigate = useNavigate();
    const onClickExpand = useMemo(() => {
        if (props.allowExpand) {
            return () => {
                navigate(`/data/business-object/${props.pipelineNodeId}`)
            }
        }
        return undefined;
    }, [props.allowExpand, navigate]);

    const [activeColumn, setActiveColumn] = useState<PipelineNodeField|undefined>(undefined);

    const [activeLineageColumn, setActiveLineageColumn] = useState<PipelineNodeField|undefined>(undefined);
    const onShowColumnLineage = useCallback((columnName: string) => {
        if (!pipelineNode.data) {
            return;
        }

        // Find the column they're referring to

        const theField = pipelineNode.data.fields.find(f => f.label === columnName);
        if (theField) {
            setActiveLineageColumn(theField);
        }
    }, [columns]);

    const onShowColumnStats = useCallback((columnName: string) => {
        if (!pipelineNode.data) {
            return;
        }

        // Find the column they're referring to

        const theField = pipelineNode.data.fields.find(f => f.label === columnName);
        if (theField) {
            setActiveColumn(theField);
        } else {
            // It might be a related field or Record UUID
            const theColumn = columns.find(c => c.header == columnName);
            if (theColumn) {
                setActiveColumn({
                    id: theColumn.key,
                    label: theColumn.header,
                    name: theColumn.key,
                    taxonomic_id: '',
                    part_of_composite_key: false,
                    description: '',
                    map_options: [],
                    type: 'STRING',
                    cell_actions: [],
                    formatter: '',
                })
            }
        }

        

    }, [columns]); 


    const mappedInitialSort = useMemo(() => {
        const initialSort = (pipelineNode.data?.default_sort ?? []) as ColumnSort[];
        return initialSort.map(sort => ({
            id: sort.column_id,
            desc: sort.direction === 'DESC',
        }));
    }, [pipelineNode.dataUpdatedAt]);

    const onCellDoubleClick = useCallback((col: Column, row: Record<string, string>, value: any) => {

    }, []);

    const innerContent = <>
        <PipelineNodeColumnDrawer
            pipelineNodeId={props.pipelineNodeId}
            column={activeColumn}
            show={!!activeColumn}
            onHide={() => setActiveColumn(undefined)}
        />
        <Modal size="xl" show={!!activeLineageColumn} onHide={() => setActiveLineageColumn(undefined)}>
            <Modal.Header closeButton>
                <Modal.Title>Column Lineage: {activeLineageColumn?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{height: 'calc(80vh)'}} className="bordered">
                    {activeLineageColumn && <PipelineNodeColumnLineage
                        fieldId={activeLineageColumn.id}
                        pipelineNodeId={props.pipelineNodeId}
                    />}
                </div>
                
            </Modal.Body>
        </Modal>
        
        {props.dataOverride && <>
            
            <SimpleDataTable
                onShowColumnLineage={onShowColumnLineage}
                onShowColumnStats={onShowColumnStats}
                initialSort={mappedInitialSort}
                columnActions={cellActions}
                enableColumnContextMenu
                data={props.dataOverride}
                columns={columns}
                onCellDoubleClick={onCellDoubleClick}
            />
        </>}
        {!props.dataOverride && <>
            
            <TableExplorer
                tablePath={`/pipelinenodes/${props.pipelineNodeId}/data`}
                columns={columns}
                searchQuery={props.searchQuery}
                columnActions={cellActions}
                onDataLoaded={onDataLoaded}
                onClickExpand={onClickExpand}
                filters={props.filters}
                initialSort={mappedInitialSort}
                onShowColumnStats={onShowColumnStats}
                onShowColumnLineage={onShowColumnLineage}
                enableColumnContextMenu
                onCellDoubleClick={onCellDoubleClick}
            />;
        </>}
        
        

    </>;

    if (props.compact) {
        return <Pane>
            <PaneContent>
                {innerContent}
            </PaneContent>
        </Pane>
    }


    return <Pane>
        
        
        <PaneContent >
            {innerContent}
        </PaneContent>
        <PaneFooter>
            <div className="ps-1" style={{lineHeight: '40px'}}>
                {recordCount >= 0 && (
                    <span>{summarizeNumber(recordCount)} total records</span>
                )}
                
            </div>
        </PaneFooter>
    </Pane>
        
     




}

export default PipelineNodeDataTable;