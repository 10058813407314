import ApiService from "@services/api/api.service";
import { useTemplates } from "@stores/data.store";
import { useCallback, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";


export interface TemplatePickerProps {
    show?: boolean;
    onClose?: () => void;
    onTemplateSelected: (template_id: string) => void;
}



const TemplatePickerModal = (props: TemplatePickerProps) => {
    const close = useCallback(() => {
        if (props.onClose){
            props.onClose();
        }

    }, [props.onClose]);

    const [showTemplateSelector, setShowTemplateSelector] = useState(false);

    const templates = useTemplates();

    const [applyingTemplate, setApplyingTemplate] = useState(false);
    
    return (

        <Modal size="lg" show={!!props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Available Templates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Choose a template to automatically create business objects and reports!</p>
                <ul className="list-group">
                    {templates.data && templates.data.map(t => {
                        return <li className="list-group-item">
                            <h5>{t.name}</h5>
                            <p>{t.description}</p>
                            <button className="btn btn-pliable-gradient" disabled={applyingTemplate} onClick={() => {
                                props.onTemplateSelected(t.id);
                                close();
                            }}>
                                {applyingTemplate && (
                                    <>
                                        <i className="mdi mdi-loader mdi-spin"></i>
                                    </>
                                )}
                                Use Template
                            </button>
                        </li>
                    })}
                </ul>
            </Modal.Body>
        </Modal>
    )
}

export default TemplatePickerModal;