import { Connection, Edge, Node } from "reactflow";

export const isValidConnection = (connection: Connection, nodes: Node[], edges: Edge[]) => {
    // Nodes must be 2 business object, or a business objet and srt
    try {
        if (connection.source == connection.target) {
            return false;
        }
    
        if (connection.source?.startsWith('SourceRecordType:')) {
            if (!connection.target?.startsWith('BusinessObject:')) {
                return false;
            }
        }

        if (connection.source?.startsWith('DataMart:') || connection.target?.startsWith('DataMart:')) {
            return false;
        }
    
        const existingEdge = edges.find(e => {
            return (e.target == connection.target && e.source == connection.source) || (e.target == connection.source && e.source == connection.target); 
        });
    
        if (existingEdge) {
            return false;
        }
    
        return true;
    } catch (err) {
        console.error(err);
    }

    return false;
    
}