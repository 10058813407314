import { SourceRecordType } from "@models/source";
import { useSourceRecordTypes, useSources } from "@stores/data.store";
import { useEffect, useMemo, useState } from "react";

interface SourceRecordTypeNameProps {
    sourceRecordType?: SourceRecordType;
    sourceRecordTypeId?: string;
}

const SourceRecordTypeName = (props: SourceRecordTypeNameProps) => {

    const sourceRecordTypes = useSourceRecordTypes();

    const [sourceRecordType, setSourceRecordType] = useState<SourceRecordType|undefined>(undefined);

    useEffect(() => {
        if (props.sourceRecordType) {
            setSourceRecordType(props.sourceRecordType);
        } else if (props.sourceRecordTypeId && sourceRecordTypes.data) {
            setSourceRecordType(sourceRecordTypes.data.find(srt => srt.id === props.sourceRecordTypeId));
        }
    }, [props.sourceRecordType, props.sourceRecordTypeId, sourceRecordTypes.dataUpdatedAt])

   

    return <>
        {sourceRecordType?.name}
    </>
}

export default SourceRecordTypeName;