import ConfigService from "@services/config/config.service";
import { ReactNode, useMemo, useState } from "react";

interface FeatureFlagWrapperProps {
    requiredFlags: string[];
    children: ReactNode;
}

const useFeatureFlags = (): string[] => {
    const config = ConfigService.getInstance().config;
    if (config?.flags) {
        const rv = Object.keys(config.flags).filter(k => !!config?.flags[k]);
        return rv;
    }
    return [];
}

const FeatureFlagWrapper = (props: FeatureFlagWrapperProps) => {
    const flags = useFeatureFlags();

    const shouldShow = useMemo(() => {
        if (!flags) {
            return false;
        }
        return props.requiredFlags.every(f => flags.includes(f));
    }, [props.requiredFlags, flags]);

    if (shouldShow) {
        return <>{props.children}</>
    }

    return <></>;
    
}

export default FeatureFlagWrapper;