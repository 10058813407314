import SourceORM from "@models/source"
import toast from "@services/toast.service";
import { useCallback, useEffect, useState } from "react";
import AsyncButton from '@components/button/AsyncButton.component';
import LoadingCard from "@components/card/LoadingCard.component";
import { useSource } from "@stores/sources.store";
import { TemplateGroup, TemplateGroupStep } from "@models/templateGroup";
import PliableLoader from "@components/loaders/PliableLoader.component";
import TrackingService, { Events } from "@services/tracking.service";

interface Params {
    source_id: string;
    template_group: TemplateGroup;
    step: TemplateGroupStep;
    onComplete: () => void;
}


const FivetranSourceStep = (props: Params) => {
    const {isLoading: sourceLoading,  data: source} = useSource(props.source_id as string);
    const [finalizing, setFinalizing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingConnectorCard, setLoadingConnectorCard] = useState(false);
    const [currentState, setCurrentState] = useState<'SETUP_CONNECTOR'|'FINALIZING_CONNECTION'>('SETUP_CONNECTOR');
  

    // page load
    useEffect(() => {
        TrackingService.track_event(Events.TEMPLATE_STEP_VIEWED, {
            step_name: props.step.name,
            step_id: props.template_group.active_step_idx,
            template_id: props.template_group.template_id,
        });
    }, []);


    useEffect(() => {  
        if(sourceLoading) {
            return;
        }

        if (!!source && (!source.config || !source.config.fivetran_connector_id ||  source.fivetran?.connector.status.setup_state != "connected" )) {
            // if we dont have a connector that is connected
            setCurrentState('SETUP_CONNECTOR');
        } else {
            setCurrentState('FINALIZING_CONNECTION');
            if(!finalizing){
                runFinalizing();
            }
        }
    }, [source, finalizing]);



    const loadConnectorConfigCard = useCallback(async () => {
        if(!loadingConnectorCard) {
            try {
                setLoadingConnectorCard(true);
                const return_path = `/template-group/${props.template_group.id}/source/${props.source_id}/finish-fivetran`
                const resp = (await SourceORM.getConnectorCardUrl(props.source_id as string, return_path));
                window.location.href = resp.connect_card_url as string;
            }catch(ex) {
                toast('danger', 'Error Loading Connector Card', `${ex}`);
                setLoadingConnectorCard(false);
            }
        }
    }, [props.template_group.id, props.source_id]);


    const runFinalizing = useCallback(async () => {   
        if (!source || finalizing) {
            return;
        }

        setFinalizing(true);

        try{
            // update the resources we want to sync from fivetran (they are created by the template)
            await SourceORM.init(props.source_id as string, {use_existing: 'true'});
            
            // enable the fivetran connector
            await SourceORM.toggleSync(props.source_id as string, false);

            TrackingService.track_event(Events.TEMPLATE_STEP_COMPLETED, {
                step_name: props.step.name,
                step_id: props.template_group.active_step_idx,
                template_id: props.template_group.template_id,
            });

            props.onComplete();
            setFinalizing(false);
            
        }catch(ex){
            setFinalizing(false);
            toast('danger', 'Error Saving', `${ex}`)
        }
    }, [source, finalizing]);


    
    if (loading || sourceLoading) {
        return <LoadingCard />;
    } else if (currentState === 'SETUP_CONNECTOR') {
        return <>
            <h1>{props.step.action_title}</h1>
                <p>{props.step.action_description}</p>
            <div className="d-flex justify-content-center">
                <AsyncButton
                    icon="mdi mdi-table-edit"
                    variant="pliable"
                    text={`Connect ${source!.name}`}
                    loading={loadingConnectorCard}
                    onClick={loadConnectorConfigCard}
                />
            </div>
        </>
    } else {
        return (<>
            <div className="d-flex justify-content-center">
                <PliableLoader size="lg" />
            </div> 
            <h1>{props.step.finalizing_message}</h1>
            <p>This should just take a moment</p>
            
        </>);
    }
}

export default FivetranSourceStep;