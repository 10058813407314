import { useCallback, useEffect, useState } from "react";
import 'reactflow/dist/style.css';
import { Link, useNavigate } from "react-router-dom";
import { Form, Row } from "react-bootstrap";
import SaveButton from "@components/button/SaveButton.component";
import { useRouteBlocker } from "@services/routing.service";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent, PaneContentWithSubnav } from "@pages/PageStructure.component";
import TenantConfigSubnav from "@components/tenantConfig/TenantConfigSubnav.component";
import AuthService from "@services/auth/auth.service";
import TimezoneSelect, { ITimezoneOption, type ITimezone } from 'react-timezone-select'
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css'
import { useBuildOrchestrationHistory, useProjectConfig } from "@stores/data.store";
import TenantConfigService from "@services/tenantConfig.service";
import TrackingService, { Events } from "@services/tracking.service";
import BuildOrchestrationORM from "@models/buildOrchestration";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import PliableLoader from "@components/loaders/PliableLoader.component";
import { timeAgo } from "@services/time.service";



const TenantBuildSchedulerPage = () => {
    const navigate = useNavigate();

    const {dataUpdatedAt, data: tenantConfig} = useProjectConfig();
    const [scheduleEnabled, setScheduleEnabled] = useState(false);
    const [cronExpression, setCronExpression] = useState('')
    const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    const tenant_name = AuthService.getInstance().getTenantName();

    const builds = useBuildOrchestrationHistory();


    useEffect(() => {
        if(!tenantConfig){
            return;
        }

        setCronExpression(tenantConfig.build_schedule.cron_expression);
        setScheduleEnabled(tenantConfig.build_schedule.enabled);
        if (!!tenantConfig.build_schedule.timezone){
            setSelectedTimezone(tenantConfig.build_schedule.timezone)
        }

    }, [dataUpdatedAt]);

    const onBuildAll = useCallback(async (e: any) => {
        try{
            const orchestration = await BuildOrchestrationORM.buildAll(true);
            navigate(`/dag?watchOrchestrationId=${orchestration.id}`);
        }catch(ex) {
            toast('danger', 'Error', `${getErrorMessage(ex)}`);
        }
        
    }, [navigate]);

    const onSave = useCallback(async () => {
        const updates = {
            cron_expression: cronExpression,
            timezone:  (selectedTimezone.hasOwnProperty('label'))  ? (selectedTimezone as ITimezoneOption).value : selectedTimezone,
            enabled: scheduleEnabled
        }
        await TenantConfigService.getInstance().saveBuildSchedule(tenantConfig!.id as string, updates);
        TrackingService.track_event(Events.BUILD_SCHEDULE_UPDATED, updates);
        setPageDirty(false);
    }, [dataUpdatedAt, cronExpression, selectedTimezone, scheduleEnabled]);

    const onCronChange = useCallback((value: string) => {
        if (!tenantConfig) {
            return;
        }

        setCronExpression(value);
        if(tenantConfig.build_schedule.cron_expression != value) {
            setTimeout(() => {
                setPageDirty(true);
            }, 0);
        }
        
    }, [tenantConfig]);

    const onScheduleEnabledChange = useCallback(() => {
        if (!tenantConfig) {
            return;
        }

        setScheduleEnabled(!scheduleEnabled);
        setPageDirty(true);
        
    }, [tenantConfig, scheduleEnabled]);

    const onTimezoneChange = useCallback((value: ITimezone) => {
        setSelectedTimezone(value);
        setPageDirty(true);
    }, []);


    const {pageDirty, setPageDirty } = useRouteBlocker(onSave);

    return <PageStructure
        pageTitle="Build Schedule"
        breadcrumbs={[
            {
                title: 'Account',
            }
            
        ]}
    >
        
        <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3">
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Account Name
                            </Form.Label>
                            <Form.Control value={tenant_name as string} disabled={true} />
                            <Form.Text></Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>
                                Account ID
                            </Form.Label>
                            <Form.Control value={tenantConfig?.tenant_slug as string} disabled={true} />
                            <Form.Text>
                                <strong>cannot be changed</strong> after creation
                            </Form.Text>
                        </Form.Group>
                        <hr />
                        <Link to="/account/authorize-sources">Link Sources</Link>
                    </div>
                </PaneContent>
            </Pane>
        </PageSidebar>
        <PageContent>

            <Pane>
                <TenantConfigSubnav>
                    <SaveButton
                        onClick={onSave}
                        disabled={!pageDirty}
                    />

                </TenantConfigSubnav>
                <PaneContentWithSubnav>
                    <Pane>
                        <PaneContent>

                        
                <Row style={{padding: '25px'}}>
                <div className="col-9">
                        <Form.Group className="mb-2">
                            <Form.Label>
                            <i className="mdi mdi-wrench"></i> Build Models 
                            </Form.Label>
                            <Cron 
                                value={cronExpression} 
                                setValue={onCronChange} 
                                clockFormat="12-hour-clock"
                                clearButton={false}
                                disabled={!scheduleEnabled}
                            />
                            
                        </Form.Group>
                </div>  
                <div className="col-3">
                    <Form.Group>
                            <Form.Check 
                                id="schedule_enabled_check"
                                type="switch"
                                label={`Schedule ${(scheduleEnabled) ? 'Enabled' : 'Disabled'}`}
                                checked={scheduleEnabled}
                                onChange={onScheduleEnabledChange}
                            />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                </div>
                <div className="col-3">
                        <Form.Group className="mb-2">
                            <Form.Label>
                                <i className="mdi mdi-map-clock"></i> Timezone 
                            </Form.Label>
                            <TimezoneSelect
                                value={selectedTimezone}
                                onChange={onTimezoneChange}
                                isDisabled={!scheduleEnabled}
                            />
                            
                        </Form.Group>
                </div>
           </Row>
           <Row>
                <div className="col-12">
                    <div className="p-3">
                        <button onClick={onBuildAll} className="btn btn-pliable">Force Rebuild</button>

                    </div>
                </div>
           </Row>
           <div className="p-3">
                <h2>Build History</h2>
            {builds.isLoading && <PliableLoader/>}
            {!builds.isLoading && (
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Started At</th>
                            <th>Started By</th>
                            <th>Selector</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {builds.data && builds.data.map(b => {
                            return <tr>
                                <td>{b.queued_at ? timeAgo(b.queued_at) : 'Never'}</td>
                                <td>{b.executed_by}</td>
                                <td>{b.node_selector}</td>
                                <td>{b.status}</td>
                                <td>
                                    <Link to={`/?watchOrchestrationId=${b.id}&keepBuildOpen=true`} className="btn btn-primary btn-sm">Details</Link>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            )}
            </div>
            </PaneContent>
                    </Pane>
                </PaneContentWithSubnav>
            </Pane>
        
            
           
        </PageContent>
               
    </PageStructure>
    
}


export default TenantBuildSchedulerPage;