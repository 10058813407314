import { useBusinessObjects } from "@stores/data.store";
import { useMemo } from "react";

interface Props {
    businessObjectId: string;
}

const BusinessObjectName = (props: Props) => {
    const businessObjects = useBusinessObjects();

    const name = useMemo(() => {
        if (businessObjects.data && props.businessObjectId) {
            const bo = businessObjects.data.find(bo => bo.id === props.businessObjectId);
            if (bo) {
                return bo.name;
            }
        }
        return '';
    }, [businessObjects.dataUpdatedAt, props.businessObjectId]);

    return <span>{name}</span>
}

export default BusinessObjectName;