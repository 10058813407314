import { ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import Badge from 'react-bootstrap/Badge';
import { summarizeNumber } from "@services/formatting.service";
import { Emoji } from 'emoji-picker-react';


const Header = styled.div`
display: flex;
padding: 8px;

.img-container {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: white;
    overflow: none;
    margin-right: 20px;

    .badge {
        margin-top: 10px;
    }
}

.action-container {
    width: 150px;
    text-align: right;
}

.text-container {
    flex: 1;
    h2 {
        font-size: 14px;
        color: rgb(121, 121, 121);
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;
        padding-left: 6px;
        margin: 0px;
    }

    h1 {
        padding: 0px;
        margin: 0px;
        margin-bottom: 4px;
        color: rgb(15, 14, 49);
        font-weight: 600;
        text-overflow: ellipsis;
        font-size: 24px !important;
        line-height:20px;

        input {
            font-size: 24px !important;
            font-weight: 600 !important;
            line-height: 20px;
            padding: 2px 5px !important;
            height: 30px;
            overflow: visible;
        }
        
        span  {
            padding-left: 6px;
        }


    }
    p.description {
        padding-left: 6px;
    }

    textarea {
        width: 100%;
        padding: 2px 5px !important;
        font-size: 13px;
        font-weight: 300;
    }
}
`


interface Props {
    label: string;
    title: string;
    children?: ReactNode;
    description: string;
    imageUrl?: string;
    totalRecords?: number;
    onIconClick?: () => void
    onChangeTitle?: (newValue: string) => void;
    onChangeDescription?: (newValue: string) => void;
}

const DrawerHeader = (props: Props) => {
    return <Header>
        {props.imageUrl && (
            <div className="img-container text-center" role="button" onClick={props.onIconClick} >
                {props.imageUrl && <img src={props.imageUrl} width={50} /> }
                {!!props.totalRecords && <Badge bg="secondary">{summarizeNumber(props.totalRecords)}</Badge>}
            </div>
        )}
        
        
        <div className="text-container">
            <h2>{props.label}</h2>
            <h1>
                {!!props.onChangeTitle && (
                    <Form.Control type="text" onChange={(e) => props.onChangeTitle!(e.target.value)} className="inline-edit" value={props.title}/>
                )}
                {!props.onChangeTitle && (
                    <span>{props.title}</span>
                )}
                
            </h1>
            {!!props.onChangeDescription && (
                <Form.Control as="textarea" onChange={(e) => props.onChangeDescription!(e.target.value)} className="inline-edit" placeholder="Description" value={props.description}/>
            )}
            {!props.onChangeDescription && (
                <p className="text-muted description">{props.description}</p>
            )}
            
        </div>
        <div className="action-container">
            {props.children}
        </div>
    </Header>
}

export default DrawerHeader;