import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import LoadingCard from "@components/card/LoadingCard.component";
import AuthService from "@services/auth/auth.service";
import TrackingService, {Events} from "@services/tracking.service";
import { YoutubeVideos } from "@services/alert/VideoModal.component";



const AfterLogin = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const authState = AuthService.getInstance().authState;
        if (authState) {
            // just logged in lets identify them
            TrackingService.identify(authState.user!.id, {
                name: authState.user?.name,
                email: authState.user?.email
            });

            TrackingService.track_event(Events.USER_LOGIN)
        }

        const seen_overview_v1 = localStorage.getItem('seen_overview_v1');
        const app_url = (seen_overview_v1 == 'true') ? '/' : `/?showVideoId=${YoutubeVideos.OverviewVideo}`

        navigate(app_url, {replace: true});

    }, []);

    return <LoadingCard />;
}

export default AfterLogin;