import PliableLoader from '@components/loaders/PliableLoader.component';
import React, { Component, ReactElement, ReactNode } from 'react';
import { Spinner, Col } from "react-bootstrap";
import styled from 'styled-components';



const Container = styled.div`

h2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
}
`


interface Props {
    action?: string;
    subTitle?: string;
    minHeight?: string;
}

export default class LoadingCard extends Component<Props> {
    render() {
        const minHeight = (this.props.minHeight) ? this.props.minHeight : '40vh';
        return (
            <Container className="m-2">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: minHeight}}>
                        <div><PliableLoader size='sm'/></div>
                        { this.props.action && <div><h2 className="mt-2">{this.props.action}...</h2></div>}
                        { this.props.subTitle && <div><p>{this.props.subTitle}...</p></div>}
                </div>
            </Container>);
    }
}

