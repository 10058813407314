import { Agent } from "http";
import { parseDateString } from '../services/time.service';
import ApiService, {  ListRecordsResponse, SingleRecordResponse } from "../services/api/api.service";
export interface BaseModel {
    id: string|null;
    last_updated?: Date|null;
}

export default class ORM<T extends BaseModel> {
    protected apiService: ApiService;
    protected endpoint: string;
    private dateFields: string[];

    constructor(endpoint: string, dateFields: string[] = []) {
        this.endpoint = endpoint;
        this.dateFields = dateFields;
        this.apiService = ApiService.getInstance();
    }

    public convertDates(record: T) {
        ['last_updated'].concat(this.dateFields).forEach(f => {
            const val = record[f as keyof T] as string | undefined;
            if (val) {
                // @ts-ignore
                record[f] = parseDateString(val);
            }

        });
        return record;
    }

    public async find(filter: object|null = null, limit: number = 200, page: number = 1, sort: string|null = null): Promise<ListRecordsResponse<T>> {
        //console.log('Finding with filter:', filter, this.endpoint);
        const result = await ApiService.getInstance().request('GET', `/${this.endpoint}`, {
            filter: filter ? JSON.stringify(filter) : '',
            limit: limit,
            page: page,
            sort: sort,
        }) as ListRecordsResponse<T>;

        result.records = result.records.map(r => this.convertDates(r));
        return result;
    }

    public async findById(id: string): Promise<T> {
        const result = await ApiService.getInstance().request('GET', `/${this.endpoint}/${id}`) as SingleRecordResponse<T>;
        return this.convertDates(result.record);

    }

    public async save(item: T): Promise<T> {

        let result: SingleRecordResponse<T>;
        if (item.id === null) {
            result = await ApiService.getInstance().request('POST', `/${this.endpoint}`, item) as SingleRecordResponse<T>;
        } else {
            result = await ApiService.getInstance().request('PATCH', `/${this.endpoint}/${item.id}`, item) as SingleRecordResponse<T>;
            
        }
        return this.convertDates(result.record);
    }

    public async patch(id: string, data: any): Promise<T> {
        const result = await ApiService.getInstance().request('PATCH', `/${this.endpoint}/${id}`, data) as SingleRecordResponse<T>;
        return this.convertDates(result.record);
    }

    public async delete(item: T): Promise<boolean> {
        return this.deleteById(item.id as string);
    }

    public async deleteById(id: string): Promise<any> {
        const result = await ApiService.getInstance().request('DELETE', `/${this.endpoint}/${id}`);

        return result;
    }

}