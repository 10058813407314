import PageTitle from "@components/pageTitle/PageTitle.component";
import SourceRecordTypeDataTable from "@components/sources/SourceRecordTypeDataTable.component";
import { ColumnPreference, SourceRecordType, SourceRecordTypeCleaningStep, SourceRecordTypeORM } from "@models/source";
import { FilterConfig } from "@models/standardizationPipeline";
import { invalidateDataLoadsForSRT, invalidateSourceRecordType, saveSourceRecordType, useColumnShape, useColumnValueDistribution, useDataLoadsForSRT, useProjectConfig, useSourceRecordType } from "@stores/data.store";
import produce from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Form, Modal, Offcanvas, Spinner, Tab, Tabs } from "react-bootstrap";
import { Outlet, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useImmer } from "use-immer";
import FilterConfigForm from "./FilterConfigForm.component";
import styled from 'styled-components';
import SourceRecordTypeColumnSelector from "@components/sources/SourceRecordTypeColumnSelector.component";
import { formatPercentage, summarizeNumber } from "@services/formatting.service";
import CleaningStepsList from "@components/sources/CleaningStepsList.component";
import Dropdown, { MultiDropdown } from "@components/form/Dropdown.component";
import BuildOrchestrationORM from "@models/buildOrchestration";
import BackgroundService from "@services/bg.service";
import { getErrorMessage } from "@services/errors.service";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent, PaneFooter, SwipeablePane } from "@pages/PageStructure.component";
import SaveButton from "@components/button/SaveButton.component";
import toast from "@services/toast.service";
import FileORM from "@models/file";
import DataLoadORM from "@models/dataload";
import { JobEnqueueResponse } from "@services/api/api.service";
import DataSourceImporter from "@pages/Sources/DataSourceImporter.component";
import { timeAgo } from "@services/time.service";
import LoadingCard from "@components/card/LoadingCard.component";

import { promptEmoji } from "@services/alert/alert.service";
import RecordInfo from "@components/card/RecordInfo.component";
import SectionHeader from "@components/card/SectionHeader.component";
import { useRouteBlocker } from "@services/routing.service";


const FilterDropdown = styled.div`
box-shadow: 0px 1px 6px 2px #ccc;
position: absolute;
background: white;
top: 55px;
right: 20px;
text-align: left;
z-index: 500;
width: 400px;
`

const ACTION_OPTIONS = [
    {
        value: 'KEEP_THESE',
        label: 'Keep only matching records',
    },
    {
        value: 'REMOVE_THESE',
        label: 'Remove all matching records',
    },
    {
        value: 'UPDATE_COLUMN_VALUE',
        label: 'Change the value of a column'
    },
    {
        value: 'TRANSFORM_COLUMN',
        label: 'Transform the value of a column'
    },
    {
        value: 'REMOVE_DUPLICATES',
        label: 'Remove duplicates',
    },
];

interface Transformer {
    label: string;
    description: string;
    value: string;
    takesArguments?: boolean;
    snowflakeOnly?: boolean;
}

const TransformerOptions: Transformer[] = [{
    label: 'Clean Zip Code',
    description: 'Cleans up zip codes by normalizing to 5 digits and ensuring there are leading zeroes',
    value: 'CLEAN_ZIP_CODE',
}, {
    label: 'Clean Phone Numbers',
    description: 'Normalizes phone numbers to (XXX) XXX-XXXX format',
    value: 'CLEAN_PHONE',
}, {
    label: 'Convert to Lowercase',
    description: 'Converts text values to all lowercase',
    value: 'LOWERCASE',
}, {
    label: 'Convert to Uppercase',
    description: 'Converts text values to all uppercase',
    value: 'UPPERCASE',
}, {
    label: 'Remove Non-Numeric Characters',
    description: 'Strips all characters that are not digits',
    value: 'REMOVE_NON_NUMERIC',
}, {
    label: 'Normalize Dates',
    description: 'Parses and cleans dates by trying a variety of common formats',
    value: 'PARSE_DATE',
}, {
    label: 'Convert to "Slug" Format',
    description: 'Converts text to a web-safe "slug" format, like "New York" -> "new-york"',
    value: 'SLUGIFY',
}, {
    label: 'Get URL Path',
    description: 'Extracts the path component from a URL (https://pliable.co/landing-page will return "/landing-page")',
    value: 'URL_PATH',
}, {
    label: 'Get URL Domain',
    description: 'Extracts the domain component from a URL (https://pliable.co/landing-page will return "pliable.co")',
    value: 'URL_DOMAIN',
}, {
    label: 'Hoist Nested Object',
    description: 'Hoists a nested JSON object to become columns on the main table',
    value: 'HOIST',
    snowflakeOnly: true,
}]

interface ColumnDistroProps {
    srtId: string;
    column: string;
    totalRecords: number;
}

const ColumnDistro = (props: ColumnDistroProps) => {
    const data = useColumnValueDistribution(props.srtId, props.column);

    if (data.isLoading) {
        return <>
            <i className="mdi mdi-loading mdi-spin"></i> Loading samples
        </>
    }

    return <table className="table">
        <tbody>
            {data.data?.map(d => {
                return <tr>
                    <th>{d.value}</th>
                    <td>{summarizeNumber(d.total)}</td>
                    <td>{formatPercentage(d.total / props.totalRecords)}%</td>
                </tr>
            })}
        </tbody>
    </table>
}

interface ColumnRecordInfoProps {
    srtId: string;
    column: ColumnPreference;
}

const ColumnRecordInfo = (props: ColumnRecordInfoProps) => {
    const shapeData = useColumnShape(props.srtId, props.column.key);
    if (shapeData.isLoading) {
        if (shapeData.isLoading) {
            return <>
                <i className="mdi mdi-loading mdi-spin"></i> Loading stats
            </>
        }
    }

    return <RecordInfo
        title={props.column.rename || props.column.key}
        iconComponent={<></>}
        description={props.column.description}
        badges={<>
            {props.column.is_static && <Badge bg="info">Static Column</Badge>}
        </>}
        stats={[{
            label: 'Density',
            icon: 'mdi mdi-water',
            value: shapeData.data ? formatPercentage(shapeData.data.density) + '%' : 'Unknown',
        }, {
            label: 'Uniqueness',
            icon: 'mdi mdi-hexagram',
            value: shapeData.data ? formatPercentage(shapeData.data.uniqueness) + '%' : 'Unknown',
        }]}


    />
    
}


const SourceRecordTypeWrapperPage = () => {
    const { sourceRecordTypeId } = useParams();
    const sourceRecordType = useSourceRecordType(sourceRecordTypeId as string);
    const [formData, setFormData] = useImmer<SourceRecordType>({
        id: null,
        name: '',
        description: '',
        source_id: '',
        resource_name: '',
        column_preferences: [],
        cleaning_steps: [],
    });

    

    useEffect(() => {
        if (sourceRecordType.data) {
            setFormData(produce(sourceRecordType.data, draft => {
                return
            }));
        }
    }, [sourceRecordType.dataUpdatedAt]);

    const onNameChange = useCallback((newName: string) => {
        setFormData(draft => {
            draft.name = newName;
        })
    }, []);

    const onDescriptionChange = useCallback((newDescription: string) => {
        setFormData(draft => {
            draft.description = newDescription;
        })
    }, []);

    const onColumnPreferencesChange = useCallback((newPrefs: ColumnPreference[]) => {
        setFormData(draft => {
            draft.column_preferences = newPrefs;
        });
        setPageDirty(true);
    }, []);

    const [searchInput, setSearchInput] = useState('');

    const [filters, setFilters] = useImmer<FilterConfig>({
        filters: [],
        logic_gate: 'AND',
    });

    const [ debouncedSearchInput ] = useDebounce(searchInput, 500);

    const [showFilterModal, setShowFilterModal] = useState(false);

    const [recordCount, setRecordCount] = useState(0);

    const [cleaningStep, setCleaningStep] = useImmer<SourceRecordTypeCleaningStep>({
        action: '',
        filters: {
            filters: [],
            logic_gate: 'AND',
        }
    });

    const config = useProjectConfig();

    const availableTransformers = useMemo(() => {
        if (!config.data) {
            return [];
        }

        if (config.data.database == 'SNOWFLAKE') {
            return TransformerOptions;
        }
        return TransformerOptions.filter(o => !o.snowflakeOnly);
    }, [config.dataUpdatedAt])

    const refreshTableData = useCallback(() => {
        setTableCacheBuster((new Date()).toISOString());
    }, []);

    const onSave = useCallback(async (srt: SourceRecordType) => {
        console.log('Saving', srt);
        setBuilding(true);
        setBuildError('');
        try {
            await saveSourceRecordType(srt);

            // Now build so they see the result
            const orchestration = await BuildOrchestrationORM.buildModel('SourceRecordType', sourceRecordTypeId as string);
            await BackgroundService.getInstance().waitForJob(orchestration.job_id);



            refreshTableData();
            sourceRecordType.refetch();
        } catch (err) {
            toast('danger', 'Build Error', getErrorMessage(err));
        } finally {
            setBuilding(false);
        }
    }, [sourceRecordTypeId]);
    

    

    const dataLoads = useDataLoadsForSRT(sourceRecordTypeId as string);


    

    const onSaveClick = useCallback(() => {
        onSave(formData);
        setPageDirty(false);
    }, [formData, onSave]);

    const { pageDirty, setPageDirty } = useRouteBlocker(onSaveClick);

    const removeCleaningStep = useCallback((idx: number) => {
        const updateData = produce(formData, draft => {
            if (draft.cleaning_steps) {
                draft.cleaning_steps.splice(idx, 1);
            }
        });
        setFormData(updateData);
        onSave(updateData);
    }, [onSave, formData]);

    const applyCleaningRule = useCallback(() => {
        const filteredCleaningStep = produce(cleaningStep, draft => {
            draft.filters = filters;
        });

        const updateData = produce(formData, draft => {
            if (draft.cleaning_steps) {
                draft.cleaning_steps.push(filteredCleaningStep);
            } else {
                draft.cleaning_steps = [filteredCleaningStep];
            }
        });

        setFormData(updateData);
        onSave(updateData);

        setFilters({
            filters: [],
            logic_gate: 'AND',
        });
        setCleaningStep({
            action: '',
            filters: {
                filters: [],
                logic_gate: 'AND',
            }
        });
    }, [cleaningStep, onSave, filters]);

    const onChangeFilters = useCallback((newFilters: FilterConfig) => {
        setFilters(newFilters);
        setShowFilterModal(false);
    }, []);

    const [buildError, setBuildError] = useState('');
    const [building, setBuilding] = useState(false);
    const [tableCacheBuster, setTableCacheBuster] = useState('');
    
    const [activeTab, setActiveTab] = useState('config');

    const [deletingFile, setDeletingFile] = useState(false);

    const onDeleteFile = useCallback(async (dataLoadId: string) => {
        setDeletingFile(true);
        const { job_id } = await DataLoadORM.deleteById(dataLoadId) as JobEnqueueResponse;
        await BackgroundService.getInstance().waitForJob(job_id);
        invalidateDataLoadsForSRT(sourceRecordTypeId as string);
        invalidateSourceRecordType(sourceRecordTypeId as string);
        setDeletingFile(false);
        refreshTableData()
    }, [sourceRecordTypeId]);

    const downloadOriginalFile = useCallback(async (fileId: string) => {
        await FileORM.downloadOriginal(fileId);
    }, []);
    

    const columnOptions = useMemo(() => {

        return formData.column_preferences.map(c => {
            return {
                label: c.rename || c.key,
                value: c.key,
            }
        });
    }, [formData.column_preferences]);


    const [sidebarIdx, setSidebarIdx] = useState(0);

    const [sidebarPage, setSidebarPage] = useState('CLEANING');

    const changeIcon = useCallback(() => {
        promptEmoji({
            onConfirm: (emoji: any) => {
                setFormData(draft => {
                    draft.image_url = emoji.imageUrl as string;
                });
            },
            onClose: () => {
                return;
            }
        });
    }, []);

    const [activeColumn, setActiveColumn] = useState<ColumnPreference|undefined>(undefined);


    const activeColumnShape = useMemo(() => {
        if (!sourceRecordType.data) {
            return undefined;
        }
        if (activeColumn && sourceRecordType.data.shape) {
            return sourceRecordType.data.shape!.columns.find(c => c.key === activeColumn.key);
        }
        return undefined;

    }, [activeColumn, sourceRecordType.dataUpdatedAt]);

    const saveActiveColumn = useCallback((column: ColumnPreference) => {
        if (!sourceRecordType.data) {
            return;
        }

        setFormData(draft => {
            const idx = draft.column_preferences.findIndex(k => k.key == column.key);
            if (idx >= 0) {
                draft.column_preferences[idx] = column;
            }
        });
        setPageDirty(true);
    }, [formData]);

    const onChangeActiveColumnName = useCallback((newName: string) => {
        if (!activeColumn) {
            return;
        }

        const newCol = produce(activeColumn, draft => {
            draft.rename = newName;
        });

        setActiveColumn(newCol);
        saveActiveColumn(newCol);
    }, [activeColumn, saveActiveColumn]);

    const onChangeActiveColumnDefaultValue = useCallback((newValue: string) => {
        if (!activeColumn) {
            return;
        }
        const newCol = produce(activeColumn, draft => {
            draft.default_value = newValue;
        });

        setActiveColumn(newCol);
        saveActiveColumn(newCol);
    }, [activeColumn, saveActiveColumn]);

    const onChangeActiveColumnDescription = useCallback((newValue: string) => {
        if (!activeColumn) {
            return;
        }
        const newCol = produce(activeColumn, draft => {
            draft.description = newValue;
        });

        setActiveColumn(newCol);
        saveActiveColumn(newCol);
    }, [activeColumn, saveActiveColumn]);

    const onChangeActiveColumnStaticValue = useCallback((newValue: string) => {
        if (!activeColumn) {
            return;
        }
        const newCol = produce(activeColumn, draft => {
            draft.static_value = newValue;
        });

        setActiveColumn(newCol);
        saveActiveColumn(newCol);
    }, [activeColumn, saveActiveColumn]);

    const onToggleActiveColumnVisible = useCallback(() => {
        if (!activeColumn) {
            return;
        }
        const newCol = produce(activeColumn, draft => {
            draft.visible = !draft.visible;
        });

        setActiveColumn(newCol);
        saveActiveColumn(newCol);
    }, [activeColumn, saveActiveColumn]);

    return <PageStructure
        pageTitle={sourceRecordType.data ? sourceRecordType.data.name : ''}
        breadcrumbs={[
            {
                title: 'Data Source',
            }
            
        ]}
        pageTitleChildren={
            <>
                
                <button className="icon-button filters-button" onClick={() => setShowFilterModal(true)} style={{
                        marginLeft: '-50px'
                    }}>
                        <i className="mdi mdi-filter"></i> Filters ({filters.filters.length})
                 </button>

                    {showFilterModal && (
                        <FilterDropdown>
                            <div className="p-3">
                                <div className="d-flex center-vertically mb-3">
                                    <h3 className="flex-1 mb-0">
                                        Edit Filters
                                    </h3>
                                    <button className="btn-close" onClick={() => setShowFilterModal(false)}>
                                    </button>
                                </div>
                                <FilterConfigForm
                                    // onApply={applyFilters}
                                    config={filters} 
                                    columnOptions={sourceRecordType.data!.columns!.map(c => c.key)}
                                    onChange={onChangeFilters}
                                    changeOnApply
                                />
                            </div>
                            
                        </FilterDropdown>
                    )}
            </>
        }
    >
       
        <PageSidebar big>
            <Pane>
                <div className="flex-1">
                    <SwipeablePane childIndex={sidebarIdx} big>
                        <Pane>
                            <PaneContent>
                                <div className="p-3">
                                    <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Name
                                                </Form.Label>
                                                <Form.Control value={formData.name} onChange={(e) => onNameChange(e.target.value)}/>
                                                

                                            </Form.Group>
                                            <Form.Group className="mb-2">
                                                <Form.Label>
                                                    Description
                                                </Form.Label>
                                                <Form.Control as="textarea" value={formData.description} onChange={(e) => onDescriptionChange(e.target.value)}/>
                                            </Form.Group>
                                    
                                    <hr />
                                    <ul className="list-group" style={{
                                        fontFamily: 'Poppins'
                                    }}>
                                        <li onClick={() => {
                                            setSidebarPage('CLEANING');
                                            setSidebarIdx(1);
                                        }} role="button" className="list-group-item list-group-item-action d-flex">
                                            <div className="flex-1">
                                                <i className="mdi mdi-broom"></i> Cleaning Rules ({formData.cleaning_steps ? formData.cleaning_steps.length : '0'})
                                            </div>
                                            <div>
                                                <i className="mdi mdi-play"></i>
                                                
                                            </div>
                                            
                                        </li>
                                        <li onClick={() => {
                                            setSidebarPage('COLUMNS');
                                            setSidebarIdx(1);
                                        }} role="button" className="list-group-item list-group-item-action d-flex">
                                            <div className="flex-1">
                                                <i className="mdi mdi-view-column"></i> Columns 
                                            </div>
                                            <div>
                                                <i className="mdi mdi-play"></i>
                                                
                                            </div>
                                            
                                        </li>
                                        {config.data && config.data.database == 'PLIABLE' && (
                                            <li onClick={() => {
                                                setSidebarPage('FILES');
                                                setSidebarIdx(1);
                                            }} role="button" className="list-group-item list-group-item-action d-flex">
                                                <div className="flex-1">
                                                    <i className="mdi mdi-file"></i> Manage Files
                                                </div>
                                                <div>
                                                    <i className="mdi mdi-play"></i>
                                                    
                                                </div>
                                                
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </PaneContent>
                        </Pane>
                        {sidebarPage == 'COLUMNS' && (
                            <Pane>
                                <div style={{height: '70px'}} className="border-bottom">
                                    <div className="p-3">
                                        <div className="mb-3 d-flex center-vertically">
                                            <h2 className="mb-0 flex-1">Columns</h2>
                                            <a  role="button" onClick={() => setSidebarIdx(0)}>
                                                <i className="mdi mdi-arrow-left"></i> Back
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <PaneContent>
                                    <div className="p-3">
                                    <p className="mb-3">Rename, reorder, and hide columns in your data source.</p>
                                        {sourceRecordType.data && (
                                            <div style={{position: 'relative'}}>
                                                <SourceRecordTypeColumnSelector
                                                columnPreferences={formData.column_preferences}
                                                onClickColumn={setActiveColumn}
                                                shape={sourceRecordType.data!.shape!}
                                                onChangeColumnPreferences={onColumnPreferencesChange}
                                            />
                                            </div>
                                            
                                        )}
                                    </div>
                                    
                                        
                                </PaneContent>
                            </Pane>
                        )}
                        {sidebarPage == 'FILES' && (
                            <Pane>
                                <div style={{height: '70px'}} className="border-bottom">
                                    <div className="p-3">
                                        <div className="mb-3 d-flex center-vertically">
                                            <h2 className="mb-0 flex-1">Manage Files</h2>
                                            <a  role="button" onClick={() => setSidebarIdx(0)}>
                                                <i className="mdi mdi-arrow-left"></i> Back
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <PaneContent>

                                    <div className="p-3">
                                        {deletingFile && (
                                            <div className="alert alert-info">
                                                <i className="mdi mdi-loading mdi-spin"></i> Removing file data and rebuilding data source...
                                            </div>
                                        )}
                                        {!deletingFile && (
                                            <DataSourceImporter
                                                onSourceAdded={() => {
                                                    refreshTableData();
                                                }}
                                                sourceRecordTypeId={sourceRecordTypeId as string}
                                            />
                                        )}
                                        
                                        {!deletingFile && dataLoads.data && (
                                            <table className="table table-centered table-nowrap">
                                                <tbody>
                                                    {dataLoads.data.map(dl => <tr>
                                                        <td>
                                                            <span className="text-muted font-123">
                                                                Filename
                                                            </span>
                                                            <a role="button" onClick={() => downloadOriginalFile(dl.file_id)}>
                                                                
                                                                <h5 className="font-14 mt-1">
                                                                    <span className="me-1"><i className="mdi mdi-download"></i></span>
                                                                    {dl.file_name}
                                                                    
                                                                </h5>
                                                            </a>
                                                            
                                                        </td>
                                                        <td>
                                                            <span className="text-muted font-13">Uploaded</span>
                                                            <h5 className="font-14 mt-1">{timeAgo(dl.completed_at)}</h5>
                                                        </td>
                                                        <td>
                                                            <span className="text-muted font-13">
                                                                Records
                                                            </span>
                                                            <h5 className="font-14 mt-1">{summarizeNumber(dl.total_records)}</h5>
                                                        </td>
                                                        <td className="table-action text-end">
                                                            
                                                            <button className="action-icon me-1" onClick={() => onDeleteFile(dl.id as string)} title="Delete file">
                                                                <i className="mdi mdi-delete"></i>
                                                            </button>
                                                            {/* <button className="action-icon me-1" onClick={() => downloadOriginalFile(dl.file_id)} title="Download file">
                                                                <i className="mdi mdi-download"></i>
                                                            </button> */}
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </PaneContent>
                            </Pane>
                        )}
                        {sidebarPage == 'CLEANING' && sourceRecordType.data &&  (
                            <Pane>
                                <PaneContent>
                                    <div className="p-3">
                                        <div className="mb-3 d-flex center-vertically">
                                            <h2 className="mb-0 flex-1">Cleaning Rules</h2>
                                            <a  role="button" onClick={() => setSidebarIdx(0)}>
                                                <i className="mdi mdi-arrow-left"></i> Back
                                            </a>
                                        </div>
                                        <p className="mb-3">
                                            Automatically clean up your data source data every time it is updated. 
                                        </p>
                                        
                                        <h4 className="mb-0">Find the records you want to modify</h4>
                                        {/* <button className="btn btn-primary btn-sm" onClick={() => setShowFilterModal(true)}>Edit Filters  ({filters.filters.length})</button> */}
                                        <FilterConfigForm
                                            // onApply={applyFilters}
                                            config={filters} 
                                            columnOptions={sourceRecordType.data!.columns!.map(c => c.key)}
                                            onChange={onChangeFilters}
                                            changeOnApply
                                        />
                                        <p>{summarizeNumber(recordCount)} records match your filters.</p>
                                        <hr />
                                        
                                        <Form.Group className="mt-2">
                                            <Form.Label className="small">
                                                What do you want to do to these records?
                                            </Form.Label>
                                            <Dropdown
                                                options={ACTION_OPTIONS}
                                                className="mb-2"
                                                onChange={(e: string) => setCleaningStep(draft => {
                                                    draft.action = e
                                                })}
                                                selected={cleaningStep.action}
                                            />
                                        </Form.Group>
                                        

                                        {cleaningStep.action == 'UPDATE_COLUMN_VALUE' && (
                                                    <div className="mb-2">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="small">Select Column</Form.Label>
                                                                <Dropdown
                                                                    placeholder="Select column"
                                                                    onChange={(e) => setCleaningStep(draft => {
                                                                        draft.columns = (e ? [e] : [])
                                                                    })}
                                                                    selected={(!!cleaningStep.columns && cleaningStep.columns.length > 0) ? cleaningStep.columns[0] : ''}
                                                                    options={columnOptions}
                                                                />
                                                            </Form.Group>
                                                            
                                                        </div>
                                                        <div>
                                                            <Form.Control placeholder="Enter new value" value={cleaningStep.column_value} onChange={(e) => setCleaningStep(draft => {
                                                                draft.column_value = e.target.value
                                                            })}/>
                                                        </div>
                                                        
                                                        
                                                    
                                                    </div>
                                                    
                                                    
                                                )}
                                                {cleaningStep.action == 'TRANSFORM_COLUMN' && (
                                                    <div className="mb-2">
                                                        <div className="mb-2">
                                                            <Dropdown
                                                                placeholder="Select column"
                                                                onChange={(e) => setCleaningStep(draft => {
                                                                    draft.columns = (e ? [e] : [])
                                                                })}
                                                                selected={(!!cleaningStep.columns && cleaningStep.columns.length > 0) ? cleaningStep.columns[0] : ''}
                                                                options={columnOptions}
                                                            />
                                                        </div>
                                                        <div className="mb-2">
                                                            <Dropdown
                                                                options={availableTransformers}
                                                                selected={cleaningStep.transformer ? cleaningStep.transformer: ''}
                                                                onChange={(e) => setCleaningStep(draft => {
                                                                    draft.transformer = e
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                )}
                                                {cleaningStep.action == 'REMOVE_DUPLICATES' && (
                                                    <>
                                                        <Form.Group className="mb-2">
                                                            <Form.Label className="small">
                                                                Select column(s)
                                                            </Form.Label>
                                                            <MultiDropdown
                                                                onChange={(e) => setCleaningStep(draft => {
                                                                    draft.columns = (e ? e : [])
                                                                })}
                                                                selected={(!!cleaningStep.columns && cleaningStep.columns.length > 0) ? cleaningStep.columns : []}
                                                                options={columnOptions}
                                                            />
                                                            
                                                            <Form.Text>
                                                                We will look for records that have the same value in all of these columns.
                                                            </Form.Text>
                                                        </Form.Group>
                                                        
                                                    </>
                                                
                                                )}
                                        <button className="btn btn-pliable-gradient w-100" onClick={applyCleaningRule}>Apply Rule</button>
                                        <hr />
                                        <h5>Current Cleaning Steps</h5>
                                        <CleaningStepsList
                                            cleaningSteps={formData.cleaning_steps ? formData.cleaning_steps : []}
                                            onRemove={removeCleaningStep}
                                        />

                                    </div>
                                </PaneContent>
                            </Pane>
                        )}
                        
                    </SwipeablePane>
                </div>
                {(sidebarPage == 'COLUMNS' || sidebarIdx == 0) && (
                    <PaneFooter big className="border-top">
                    <div className="p-3 d-flex center-vertically" style={{height: '100%'}}>
                        <SaveButton className="w-100" onClick={onSaveClick}/>

                    </div>
                </PaneFooter>
                )}
                
            </Pane>
            
           
            {/* <Tabs
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k as string)}
                className="mb-2 nav-bordered"
            >
                <Tab eventKey="config" title="Details">
                    <div className="content-inner has-subnav">
                        
                    </div>
                    
                </Tab>
                <Tab eventKey="columns" title="Columns">
                    <div className="content-inner has-subnav p-2">
                        {sourceRecordType.data && (
                            <SourceRecordTypeColumnSelector
                                columnPreferences={formData.column_preferences}
                                shape={sourceRecordType.data!.shape!}
                                onChangeColumnPreferences={onColumnPreferencesChange}
                            />
                        )}
                        
                    </div>
                    
                </Tab>
                <Tab eventKey="cleaning" title="Cleaning">
                    <div className="content-inner has-subnav ps-2 pe-2">
                        <h4 className="mb-0">Modify {summarizeNumber(recordCount)} matching records</h4>
                        <small><a role="button" onClick={() => setShowFilterModal(true)}>Edit Filters</a></small>
                        <Form.Group className="mt-2">
                            <Form.Label className="small">
                                What do you want to do to these records?
                            </Form.Label>
                            <Dropdown
                                options={ACTION_OPTIONS}
                                className="mb-2"
                                onChange={(e: string) => setCleaningStep(draft => {
                                    draft.action = e
                                })}
                                selected={cleaningStep.action}
                            />
                        </Form.Group>
                        

                        {cleaningStep.action == 'UPDATE_COLUMN_VALUE' && (
                                    <div className="mb-2">
                                        <div className="mb-2">
                                            <Form.Group>
                                                <Form.Label className="small">Select Column</Form.Label>
                                                <Dropdown
                                                    placeholder="Select column"
                                                    onChange={(e) => setCleaningStep(draft => {
                                                        draft.columns = (e ? [e] : [])
                                                    })}
                                                    selected={(!!cleaningStep.columns && cleaningStep.columns.length > 0) ? cleaningStep.columns[0] : ''}
                                                    options={columnOptions}
                                                />
                                            </Form.Group>
                                            
                                        </div>
                                        <div>
                                            <Form.Control placeholder="Enter new value" value={cleaningStep.column_value} onChange={(e) => setCleaningStep(draft => {
                                                draft.column_value = e.target.value
                                            })}/>
                                        </div>
                                        
                                        
                                    
                                    </div>
                                    
                                    
                                )}
                                {cleaningStep.action == 'TRANSFORM_COLUMN' && (
                                    <div className="mb-2">
                                        <div className="mb-2">
                                            <Dropdown
                                                placeholder="Select column"
                                                onChange={(e) => setCleaningStep(draft => {
                                                    draft.columns = (e ? [e] : [])
                                                })}
                                                selected={(!!cleaningStep.columns && cleaningStep.columns.length > 0) ? cleaningStep.columns[0] : ''}
                                                options={columnOptions}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <Dropdown
                                                options={TransformerOptions}
                                                selected={cleaningStep.transformer ? cleaningStep.transformer: ''}
                                                onChange={(e) => setCleaningStep(draft => {
                                                    draft.transformer = e
                                                })}
                                            />
                                        </div>
                                    </div>
                                    
                                    
                                )}
                                {cleaningStep.action == 'REMOVE_DUPLICATES' && (
                                    <>
                                        <Form.Group className="mb-2">
                                            <Form.Label className="small">
                                                Select column(s)
                                            </Form.Label>
                                            <MultiDropdown
                                                onChange={(e) => setCleaningStep(draft => {
                                                    draft.columns = (e ? e : [])
                                                })}
                                                selected={(!!cleaningStep.columns && cleaningStep.columns.length > 0) ? cleaningStep.columns : []}
                                                options={columnOptions}
                                            />
                                            
                                            <Form.Text>
                                                We will look for records that have the same value in all of these columns.
                                            </Form.Text>
                                        </Form.Group>
                                        
                                    </>
                                
                                )}
                        <button className="btn btn-pliable-gradient w-100" onClick={applyCleaningRule}>Apply Rule</button>
                        <hr />
                        <h5>Current Cleaning Steps</h5>
                        <CleaningStepsList
                            cleaningSteps={formData.cleaning_steps ? formData.cleaning_steps : []}
                            onRemove={removeCleaningStep}
                        />
                        
                    </div>
                    
                </Tab>
            </Tabs> */}
        </PageSidebar>
        <PageContent bigSidebar>
            {building && <LoadingCard action="Rebuilding" />}
            {!building && (
                <SourceRecordTypeDataTable
                    sourceRecordTypeId={sourceRecordTypeId as string}
                    cacheBuster={tableCacheBuster}
                    searchQuery={debouncedSearchInput}
                    filters={filters}
                    columnPreferences={formData.column_preferences}
                    onRecordCountChange={setRecordCount}
                />
            )}
        </PageContent>
    </PageStructure>
}

export default SourceRecordTypeWrapperPage;