import LoadingCard from "@components/card/LoadingCard.component";
import Dropdown, { Option } from "@components/form/Dropdown.component";
import { PipelineNode, PipelineNodeRelationship } from "@models/pipelineNode";
import { savePipelineNode, savePipelineNodeRelationship, usePipelineNodes } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import PipelineNodeSelector from "./PipelineNodeSelector.component";
import { NODE_TYPE_GROUPS, getNodeTypeConfig, getValidNodeTypeGroupsForDownstreamConnection, getValidNodeTypesForDownstreamConnection, isValidRelationshipTarget, isValidUpstreamNode } from "@services/modeling.service";
import Warning from "@components/statusIndicators/Warning.component";
import SaveButton from "@components/button/SaveButton.component";
import { useNavigate } from "react-router-dom";
import { useEntitlements, useFeatureFlags } from "@frontegg/react";

interface Props {
    connectingNodeId: string;
    show: boolean;
    onHide: () => any;
    actionFilter?: (action: Option) => boolean;
    skipRedirectOnSave?: boolean;
}

const PipelineNodeConnector = (props: Props) => {
    const nodes = usePipelineNodes();
    const { isEntitled: pivotTablesEnabled } = useEntitlements({
        featureKey: 'pivot_tables'
    })

    const connectingNode = useMemo(() => {
        if (!nodes.data) {
            return undefined;
        }

        return nodes.data.find(n => n.id === props.connectingNodeId);
    }, [nodes.dataUpdatedAt, props.connectingNodeId]);

    const [action, setAction] = useState('');


    const [otherNode, setOtherNode] = useState<PipelineNode|undefined>(undefined);
    const [newNodeName, setNewNodeName] = useState('');

    // When the action changes, reset the other node
    useEffect(() => {
        setOtherNode(undefined);
    }, [action]);
    const [newNodeType, setNewNodeType] = useState('');

    const thisNodeTypeConfig = useMemo(() => {
        if (!connectingNode) {
            return undefined;

        }
        return getNodeTypeConfig(connectingNode);
    }, [connectingNode]);

    const availableActions = useMemo(() => {
        if (!connectingNode) {
            return [];
        }

        const actions = [];
        const typeConfig = getNodeTypeConfig(connectingNode);

        if (['SOURCE', 'STAGING', 'DATA_MODELING'].includes(typeConfig.group.value)) {
            actions.push({
                value: 'ADD_AS_SOURCE',
                label: 'Connect as source',
                description: 'Push data from this node to an existing node',
            });
            actions.push({
                value: 'MAP_TO_NEW_NODE',
                label: 'Map to new node',
                description: 'Push data from this node to a new node',
            });
        }
        
        // This now works with VIEW nodes.
        if (typeConfig.group.value === 'REPORTING') {
            actions.push({
                value: 'MAP_TO_NEW_NODE',
                label: 'Map to new node',
                description: 'Push data from this node to a new node',
            });
        }

        if (['STAGING', 'DATA_MODELING', 'REPORTING'].includes(typeConfig.group.value) && connectingNode.node_type != 'CUSTOM') {
            actions.push({
                value: 'ADD_AS_TARGET',
                label: 'Connect as destination',
                description: 'Push data from an existing node to this node',
            });
        }

        if (typeConfig.nodeType.allowRelationships) {
            actions.push({
                value: 'ADD_RELATIONSHIP',
                label: 'Create a relationship',
                description: 'Create a relationship between this node and another node',
            });
        }
        if (props.actionFilter) {
            return actions.filter(props.actionFilter);
        }

        return actions;
    }, [connectingNode, props.actionFilter])

    const otherNodeFilter = useCallback((node: PipelineNode) => {
        if (!connectingNode) {
            return false;
        }

        if (action == 'ADD_AS_SOURCE') {
            return isValidUpstreamNode(node, connectingNode);
        } else if (action == 'ADD_AS_TARGET') {
            return isValidUpstreamNode(connectingNode, node);
        } else if (action == 'ADD_RELATIONSHIP') {
            return isValidRelationshipTarget(connectingNode, node);
        }
        return false;
    }, [action, connectingNode]);

    const newNodeTypeOptions = useMemo(() => {
        if (!connectingNode) {
            return [];
        }

        const validNodeTypes = getValidNodeTypesForDownstreamConnection(connectingNode);

        const options: Option[] = [];
        NODE_TYPE_GROUPS.forEach(ntg => {
            ntg.nodeTypes.filter(nt => validNodeTypes.includes(nt.value) && nt.disableSelection !== true).forEach(nt => {
                if (nt.value == 'PIVOT_TABLE' && !pivotTablesEnabled) {
                    return;
                }
                options.push({
                    value: nt.value,
                    label: nt.label,
                    description: nt.description,
                    badgeText: ntg.label,
                    badgeVariant: ntg.color,
                });
            });
        });
        return options;
        
        
    }, [connectingNode, pivotTablesEnabled]);

    const otherNodeTypeConfig = useMemo(() => {
        if (!otherNode) {
            return undefined;
        }
        return getNodeTypeConfig(otherNode);
    }, [otherNode]);

    const otherNodeFirstSource = useMemo(() => {
        if (!otherNode || !nodes.data) {
            return undefined;
        }

        if (!otherNode.upstream_node_ids || otherNode.upstream_node_ids.length == 0) {
            return undefined;
        }

        return nodes.data.find(n => n.id === otherNode.upstream_node_ids[0]);
    }, [otherNode, nodes.dataUpdatedAt]);

    const thisNodeFirstSource = useMemo(() => {
        if (!connectingNode || !nodes.data) {
            return undefined;
        }

        if (!connectingNode.upstream_node_ids || connectingNode.upstream_node_ids.length == 0) {
            return undefined;
        }

        return nodes.data.find(n => n.id === connectingNode.upstream_node_ids[0]);
    }, [connectingNode, nodes.dataUpdatedAt]);

    const innerContent = useMemo(() => {
        if (!connectingNode) {
            return <LoadingCard/>;
        }
        return <>
            {['DATAMART'].includes(connectingNode.node_type) && <div>
                This node can be added as a destination for a single <strong className="text-purple">data modeling</strong> node. You will be able to pull in columns from the selected node and all of its related nodes.    
            </div>}
            {['SUMMARIZE', 'STACK', 'MERGE', 'IDENTIFY'].includes(connectingNode.node_type) && <div>
                This node can be added as a source to any <strong className="text-dark">staging</strong> or <strong className="text-purple">data modeling</strong> node, and a destination for any <strong className="text-pliable">source</strong> or <strong className="text-dark">staging</strong> node. It can also be related to other <strong className="text-dark">staging</strong> nodes.    
            </div>}
            {connectingNode.node_type == 'STACK' && <div>
                This node can be added as a source to any <strong className="text-dark">staging</strong> or <strong className="text-purple">data modeling</strong> node.    
            </div>}
            {connectingNode.node_type == 'DIMENSION' && <div>
                This node can be added as a source for any <strong className="text-success">reporting</strong> node, and a destination for any <strong className="text-dark">staging</strong> or <strong className="text-pliable">source</strong> node. It can also be related to <strong className="text-purple">facts</strong> or other <strong className="text-purple">dimensions</strong>.    
            </div>}
            {connectingNode.node_type == 'FACT' && <div>
                This node can be added as a source for any <strong className="text-success">reporting</strong> node, and a destination for any <strong className="text-dark">staging</strong> or <strong className="text-pliable">source</strong> node. It can also be related to <strong className="text-purple">dimensions</strong>.    
            </div>}
            {connectingNode.node_type == 'CUSTOM' && <div>
                This node can be added as a source for any <strong className="text-dark">staging</strong> or <strong className="text-purple">data modeling</strong> node, as long as it has a <code>_plb_uuid</code> and <code>_plb_loaded_at</code> field.    
            </div>}
            {['SOURCE', 'SOURCE_TABLE'].includes(connectingNode.node_type) && <div>
                This node can be added as a source to any <strong className="text-dark">staging</strong> or <strong className="text-purple">data modeling</strong> node.    
            </div>}
                <>
                    <hr />
                    <Form.Group>
                        <Form.Label className="small">What do you want to do?</Form.Label>
                        <Dropdown
                            options={availableActions}
                            onChange={setAction}
                            selected={action}
                        />
                    </Form.Group>
                </>
            
            
            {['ADD_AS_SOURCE', 'ADD_AS_TARGET', 'ADD_RELATIONSHIP'].includes(action) && (
                <>
                    <Form.Group className="mt-2">
                        <Form.Label className="small">Other Node</Form.Label>
                        <PipelineNodeSelector
                            selectedId={otherNode ? otherNode.id as string : ''}
                            onSelect={setOtherNode}
                            optionFilter={otherNodeFilter}
                        />

                    </Form.Group>
                    {action == 'ADD_AS_SOURCE' && otherNodeTypeConfig && otherNode && otherNodeTypeConfig.nodeType.upstreamMode == 'ALLOW_ONE' && otherNodeFirstSource && (
                        <div className="mt-2">
                            <Warning>
                                <div>Warning: the <strong>{otherNode.name}</strong> node only allows a single source node. If you continue, you will be replacing <strong>{otherNodeFirstSource.name}</strong> as the source for that node.
                                </div>
                            </Warning>
                        </div>
                        
                    )}
                    {action == 'ADD_AS_TARGET' && thisNodeTypeConfig && otherNode && thisNodeTypeConfig.nodeType.upstreamMode == 'ALLOW_ONE' && thisNodeFirstSource && (
                        <div className="mt-2">
                            <Warning>
                                <div>Warning: the <strong>{connectingNode.name}</strong> node only allows a single source node. If you continue, you will be replacing <strong>{thisNodeFirstSource.name}</strong> as the source for that node.
                                </div>
                            </Warning>
                        </div>
                        
                    )}


                </>

                
            )}
            {action == 'MAP_TO_NEW_NODE' && (
                <>
                    <Form.Group className="mt-2">
                        <Form.Label className="small">What type of node?</Form.Label>
                        <Dropdown
                            options={newNodeTypeOptions}
                            selected={newNodeType}
                            onChange={setNewNodeType}
                        />
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label className="small">Name your new node</Form.Label>
                        <Form.Control type="text" value={newNodeName} onChange={(e) => setNewNodeName(e.target.value)}/>
                    </Form.Group>
                </>
                
            )}
        </>
    }, [connectingNode, availableActions, setAction, newNodeTypeOptions, newNodeType, setNewNodeType, otherNodeTypeConfig, otherNodeFirstSource, otherNode, otherNodeFilter, newNodeName]);

    const disabled = useMemo(() => {
        if (['ADD_AS_SOURCE', 'ADD_AS_TARGET', 'ADD_RELATIONSHIP'].includes(action) && !otherNode) {
            return true;
        }
        if (action == 'MAP_TO_NEW_NODE' && (!newNodeType || !newNodeName)) {
            return true;
        }
        return false;
    }, [action, otherNode, newNodeType, newNodeName]);

    const navigate = useNavigate();

    const save = useCallback(async () => {
        if (!connectingNode || !thisNodeTypeConfig) {
            return;
        }
        if (action == 'ADD_AS_SOURCE') {
            if (!otherNode) {
                return;
            }
            const otherNodeConfig = getNodeTypeConfig(otherNode);
            if (otherNodeConfig.nodeType.upstreamMode == 'ALLOW_ONE') {
                otherNode.upstream_node_ids = [connectingNode.id as string];
            } else {
                if (!otherNode.upstream_node_ids) {
                    otherNode.upstream_node_ids = [];
                }

                otherNode.upstream_node_ids.push(connectingNode.id as string);
            }
            await savePipelineNode(otherNode);
            if (!props.skipRedirectOnSave) {
                navigate(`/node/${otherNode.id}/config`);

            }   
            props.onHide();
        } else if (action == 'ADD_AS_TARGET') {
            if (!otherNode) {
                return;
            }
            
            if (thisNodeTypeConfig.nodeType.upstreamMode == 'ALLOW_ONE') {
                connectingNode.upstream_node_ids = [otherNode.id as string];
            } else {
                if (!connectingNode.upstream_node_ids) {
                    connectingNode.upstream_node_ids = [];
                }

                connectingNode.upstream_node_ids.push(otherNode.id as string);
            }
            await savePipelineNode(connectingNode);
            if (!props.skipRedirectOnSave) {
                navigate(`/node/${connectingNode}/config`); 

            }
            props.onHide();
        } else if (action == 'ADD_RELATIONSHIP') {
            if (!otherNode) {
                return;
            }
            // Just guess about parent child and they can swap from there
            const newRel: PipelineNodeRelationship = {
                id: null,
                child_node_id: connectingNode.id as string,
                parent_node_id: otherNode.id as string,
                parent_lookup_logic_gate: 'AND',
                parent_lookups: [],
                child_foreign_key_name: '',
                name: '',
                description: '',
            }

            const savedRel = await savePipelineNodeRelationship(newRel);
            if (!props.skipRedirectOnSave) {
                navigate(`/node/${connectingNode.id}/relationships/${savedRel.id}`);

            }
            props.onHide();
        } else if (action == 'MAP_TO_NEW_NODE') {
            if (!newNodeType) {
                return;
            }
            let combineBehavior: string = 'MERGE';
            if (['MERGE', 'STACK'].includes(newNodeType)) {
                combineBehavior = 'MERGE';
            } else if (['SUMMARIZE', 'DATAMART'].includes(newNodeType)) {
                combineBehavior = 'AGGREGATE';
            }

            let specialNodeArgs: string[] = [];
            if (newNodeType == 'SPLIT') {

            }

            const newNode = await savePipelineNode({
                id: null,
                name: newNodeName,
                upstream_node_ids: [connectingNode.id as string],
                node_type: newNodeType,
                fields: [],
                description: '',
                table_name: '',
                combine_behavior: combineBehavior,
            });
            if (!props.skipRedirectOnSave) {
                navigate(`/node/${newNode.id}/config`);

            }
            props.onHide();
        }
        setOtherNode(undefined);
        setNewNodeType('');
        setAction('');
        setNewNodeName('');

    }, [connectingNode, otherNode, newNodeType, action, thisNodeTypeConfig, newNodeName, props.skipRedirectOnSave, props.onHide])
    

    return <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>
                Connecting Node: {connectingNode ? connectingNode.name : ''}
            </Modal.Title>
            
        </Modal.Header>
        <Modal.Body>
            {innerContent}
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-light me-1" onClick={props.onHide}>Cancel</button>
            <SaveButton
                disabled={disabled}
                onClick={save}
            />

        </Modal.Footer>

    </Modal>
}

export default PipelineNodeConnector;