import { ColumnPreference, SourceRecordType } from "@models/source";
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useCallback, useMemo, useState } from "react";
import { Badge, Form, Offcanvas } from "react-bootstrap";
import produce from "immer";
import TrackingService, {Events} from "@services/tracking.service";
import { getPromptAnswer } from "@services/alert/alert.service";
import { ColumnStats, TableShape } from "@models/shared";


interface Props {
    columnPreferences: ColumnPreference[];
    shape: TableShape;
    onClickColumn: (column: ColumnPreference) => void;
    onChangeColumnPreferences: (newPreferences: ColumnPreference[]) => void;
}

const Styles = styled.div`
.header {
    display: flex;
    line-height: 21px;
    font-family: "Poppins";
    .column-container {
        width: 50%;
    }

    .include-container {
        text-align: right;
        width: 50%;
        font-size: 13px;
    }


}
`

interface DraggableColumnProps {
    columnPreference: ColumnPreference;
    index: number;
    onClick: () => any;
    onToggleVisibility: (visible: boolean) => void;
    onRename: (newName: string) => void;
    onSetDefault: (newDefault: string) => void;
    onSetStaticValue: (newStatic: string) => void;
    onRemoveColumn: () => void;
    columnStats: ColumnStats;
}

const DraggableColumnStyles = styled.div`
width: 100%;



user-select: none;
margin-bottom: .5rem;

&:hover {
    cursor: pointer;
    background-color: var(--ct-light-gray);


}

.icon-container {
    padding: 8px 5px;
    border-right: solid 1px var(--ct-border-color);
    &:hover {
        background-color: var(--ct-light-gray);
    cursor: move;
    }
}




.name-container {
    padding: 8px 0px;
    font-family: "Poppins";
    flex: 1;
    text-overflow: ellipsis;
    padding-left: .5rem;
    padding-right: .5rem;
    overflow: hidden;
    white-space: nowrap;

    input {
        width: 100%;
    }

    
}




.checkbox-container {
    padding: 8px .5rem;
    text-align: right;
    width: 20%;
    display: flex;
    justify-content: right;
    align-items: top;

}

.stats-container {
    padding: 0px .5rem 0px 1.4rem;
}
`
const DraggableColumnContainer = styled.div``

const DraggableColumn = (props: DraggableColumnProps) => {
    const onToggle = useCallback((isChecked: boolean) => {
        props.onToggleVisibility(isChecked);

    }, [props.onToggleVisibility]);

    const nameValue = useMemo(() => {
        if (props.columnPreference.rename) {
            return props.columnPreference.rename;
        }
        return props.columnPreference.key;
    }, [props.columnPreference.key, props.columnPreference.rename]);
    return (
        <Draggable draggableId={props.columnPreference.key} key={props.columnPreference.key} index={props.index}>
            {provided => (
                <DraggableColumnStyles
                    {...provided.draggableProps}
                    
                    ref={provided.innerRef}
                    key={props.columnPreference.key}
                    className='shadow-box'
                    onClick={props.onClick}
                >
                    <div className="d-flex">
                        <div className="icon-container" {...provided.dragHandleProps}>
                            <i className="mdi mdi-drag"></i>
                        </div>
                        <div className="name-container">
                            {nameValue}
                           
                            
                            
                            

                        </div>

                        <div className="checkbox-container">

                                {props.columnPreference.is_static && (
                                    <div>

                                    <button className="icon-button" onClick={() => props.onRemoveColumn()}>
                                        <i className="mdi mdi-delete"></i>
                                    </button>
                                    </div>
                                )}
                            <div>
                                <Form.Check checked={props.columnPreference.visible} onChange={(e) => onToggle(e.target.checked)}/>
                                </div>
                        
                        </div>
                    </div>
                    
                    
                </DraggableColumnStyles>
            )}
        </Draggable>
    )
}

const SourceRecordTypeColumnSelector = (props: Props) => {
    /**
     * {
     *   "draggableId": "ACTIVITY_CT",
     *   "type": "DEFAULT",
     *   "source": {
     *       "index": 0,
     *       "droppableId": "main"
     *   },
     *   "reason": "DROP",
     *   "mode": "FLUID",
     *   "destination": {
     *       "droppableId": "main",
     *       "index": 1
     *   },
     *   "combine": null
     *  }
     */
    const onReorderColumns = useCallback((res: any) => {
        if (!res.destination) {
            return;
        }

        if (
            res.destination.droppableId === res.source.droppableId &&
            res.destination.index === res.source.index
        ) {
            // Didn't do anything
            return;
        };

        const newOrder = [...props.columnPreferences];
        const movingItem = newOrder[res.source.index];
        newOrder.splice(res.source.index, 1);
        newOrder.splice(res.destination.index, 0, movingItem);
        
        props.onChangeColumnPreferences(newOrder);
        TrackingService.track_event(Events.SRT_COLUMN_PREFS_REORDERED);

    }, [props.onChangeColumnPreferences, props.columnPreferences]);

    const onChangeVisibility = useCallback((idx: number, visible: boolean) => {
        const newColumns = produce(props.columnPreferences, draft => {
            draft[idx].visible = visible;
        });


        props.onChangeColumnPreferences([...newColumns]);
        TrackingService.track_event(Events.SRT_COLUMN_VISIBILITY_TOGGLED, {
            visible: visible
        });
    }, [props.onChangeColumnPreferences, props.columnPreferences]);

    const onRename = useCallback((idx: number, newName: string) => {
        const newColumns = produce(props.columnPreferences, draft => {
            draft[idx].rename = newName;
        });


        props.onChangeColumnPreferences([...newColumns]);
    }, [props.onChangeColumnPreferences, props.columnPreferences]);

    const onSetDefault = useCallback((idx: number, newDefault: string) => {
        const newColumns = produce(props.columnPreferences, draft => {
            draft[idx].default_value = newDefault;
        });


        props.onChangeColumnPreferences([...newColumns]);
    }, [props.onChangeColumnPreferences, props.columnPreferences]);

    const onSetStaticValue = useCallback((idx: number, newStatic: string) => {
        const newColumns = produce(props.columnPreferences, draft => {
            draft[idx].static_value = newStatic;
        });


        props.onChangeColumnPreferences([...newColumns]);
    }, [props.onChangeColumnPreferences, props.columnPreferences]);

    const onRemoveColumn = useCallback((idx: number) => {
        const newColumns = produce(props.columnPreferences, draft => {
            draft.splice(idx, 1);
        });

        props.onChangeColumnPreferences(newColumns);
    }, [props.onChangeColumnPreferences, props.columnPreferences]);

    const addSyntheticColumn = useCallback(async () => {
        const columnName = await getPromptAnswer('Enter column name', 'Add Static Column');
        if (columnName) {
            const newPreferences = produce(props.columnPreferences, draft => {
                draft.push({
                    key: columnName,
                    visible: true,
                    rename: columnName,
                    default_value: '',
                    static_value: '',
                    is_static: true,
                });
            });
            props.onChangeColumnPreferences(newPreferences);
        }
    }, [props.onChangeColumnPreferences, props.columnPreferences]);

    const [activeColumn, setActiveColumn] = useState<ColumnPreference|undefined>(undefined);

    const activeColumnShape = useMemo(() => {
        if (activeColumn) {
            return props.shape.columns.find(c => c.key === activeColumn.key);
        }

    }, [activeColumn, props.shape]);
    return <Styles>
        
        
        <div className="header">
            <div className="column-container">
                Columns
            </div>
            <div className="include-container">
                Visible
            </div>
        </div>
        <DragDropContext
            onDragEnd={onReorderColumns}
        >
            <Droppable droppableId="main">
                {provided => (
                    <DraggableColumnContainer
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {props.columnPreferences.map((cp: ColumnPreference, idx: number) => {
                            let stats: ColumnStats | undefined = props.shape?.columns.find(c => c.key === cp.key);

                            if (!stats) {
                                stats = {
                                    key: cp.key,
                                    distinct_values: 0,
                                    empty_values: 0,
                                    uniqueness: 0,
                                    density: 0,
                                    samples: [],
                                    min: '0',
                                    max: '0'
                                }
                            }
                            return <DraggableColumn
                                columnStats={stats}
                                columnPreference={cp}
                                onToggleVisibility={(visible: boolean) => onChangeVisibility(idx, visible)}
                                onSetDefault={(newDefault: string) => onSetDefault(idx, newDefault)}
                                onSetStaticValue={(newStatic: string) => onSetStaticValue(idx, newStatic)}
                                onRename={(newName: string) => onRename(idx, newName)}
                                onRemoveColumn={() => onRemoveColumn(idx)}
                                index={idx}
                                onClick={() => props.onClickColumn(cp)}
                            />
                        })}
                        {provided.placeholder}
                    </DraggableColumnContainer>
                )}
            </Droppable>

        </DragDropContext>
        <button onClick={() => addSyntheticColumn()} className="btn btn-primary">Add Static Column</button>

   
    </Styles>
}

export default SourceRecordTypeColumnSelector;