import { ReactNode } from "react";
import styled from 'styled-components';
import InfoStatusIndicator from "./InfoStatusIndicator.component";

const Styles = styled.div`
background-color: var(--pliable-blue-bg);
color: var(--pliable-blue);
border-radius: 5px;
padding: 1rem;
margin-bottom: 0.5rem;
font-family: "Poppins";

display: flex;
line-height: 26px;

.icon-container {
    display: inline-block;
    margin-right: 1rem;
    width: 40px;
}

`
interface Props {
    children: ReactNode;
}

const InfoAlert = (props: Props) => {
    return <Styles>
        <div className="icon-container">
            <InfoStatusIndicator/>

        </div>
        {props.children}
    </Styles>
}

export default InfoAlert