
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, {
  ConnectionMode,
  Connection,
  Node,
  XYPosition,
  NodeChange,
  Edge,
  MiniMap,
  ReactFlowInstance,
  Controls, 
  applyNodeChanges,
  NodeProps,
  Position,
  Handle,
  ControlButton,
  useOnViewportChange,
  Viewport
} from 'reactflow';
import 'reactflow/dist/style.css';

import handleSvg from '@assets/images/handle.svg';


import MissionControlNode, { MissionControlNodeProps } from './MissionControlNode.component';
import styled, { css } from 'styled-components';import ConfettiExplosion from 'react-confetti-explosion';
import { invalidateAfterBuild, saveBusinessObject, useBuildOrchestration, saveDataMart, useBusinessObjects, useMissionControlDataFlowData, usePipelineOrchestration, useSourceRecordTypes, useSourceTypes, useSources, MissionControlDataFlowNode, MissionControlDataFlowEdge, useTemplates, invalidateBusinessObjects, setViewportInfo, getViewportInfo, useDraftVersionId } from '@stores/data.store';
import { useQuery } from 'react-query';
import ApiService, { JobEnqueueResponse, ListRecordsResponse } from '@services/api/api.service';
import { autoLayout } from '@services/diagram.service';
import { BusinessObjectRelationshipORM } from '@models/businessObject';
import StandardizationPipelineORM from '@models/standardizationPipeline';
import { Badge, Modal, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import MissionControlConnectionLine from '../MissionControlConnectionLine';
import { MissionControlCardinalEdge, MissionControlEdgeProps } from '../edges/MissionControlEdge.component';
import { isValidConnection } from './utils';
import useGlobalState from '@stores/global.state';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BackgroundService from '@services/bg.service';
import { StatusIndicator, useStatusIndicatorsForNode } from '@services/objectStatus/objectStatus.service';
import { useNavigateToDrawer } from '@components/drawers/DrawerManager.component';
import DataSourceSelectModal from '@pages/Sources/DataSourceSelectModal.component';
import toast from '@services/toast.service';
import AuthService from '@services/auth/auth.service';
import { getPromptAnswer, prompt, requireConfirmation, pickTemplate, showVideo } from '@services/alert/alert.service';
import { useImmer } from 'use-immer';
import BuildOrchestrationORM, { BuildException, BuildExecution, BuildOrchestration, ModelStatus } from '@models/buildOrchestration';
import { getErrorMessage } from '@services/errors.service';
import debounce from 'lodash.debounce';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import { YoutubeVideos } from '@services/alert/VideoModal.component';
import produce from 'immer';
import MissionControlTodoList from './MissionControlTodoList.component';
import Danger from '@components/statusIndicators/Danger.component';
import { summarizeNumber } from '@services/formatting.service';
import { timeAgo } from '@services/time.service';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { DraftModeRequired } from '@components/project/DraftModeRequired.component';
import { requireDraftMode } from '@components/branch/help';
import { getNodeTypeConfigFromNodeTypeValue } from '@services/modeling.service';



const Loader = styled.div`
position: fixed;
top: 50%;
left: 50%;
z-index: 2;
width: 100px;
height: 85px;
background: rgba(0, 0, 0, 0.5);
color: white;
border-radius: 10px;
line-height: 100px;
text-align: center;
`


const NodeCardStyles = styled.div<{nodeTypeColor: string}>`
transition: opacity 0.15s ease-in-out;
-webkit-transition: opacity 0.15s ease-in-out;
position: relative;
width: 400px;
opacity: 1.0;
padding: 16px;

&.faded {
    opacity: 0.3;
}
div.build-info {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    text-align: left;
  
    .separator {
      display: inline-block;
      line-height: 15px;
      margin-left: .25rem;
      margin-right: .25rem;
    }
  }

.node-img {

    img, div.fake-img {
        max-width: 50px;
        max-height: 50px;
        border-radius: 100%;
    }

    div.fake-img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: #1BD77D;
        color: white;
        line-height: 50px;
        text-align: center;
        font-size: 30px;
      }
}

.node-contents {
    width: calc(100% - 50px - 3rem);
}

.node-box {
    position: relative;
    border-radius: 5px;

    border-top: solid 8px var(--pliable-yellow);
    transition: border-color 0.15s ease-in-out;
    -webkit-transition: border-color 0.15s ease-in-out;

    h4 {
        margin-bottom: 0px;
    }

    &.active {
        box-shadow: 0px 0px 10px 5px var(--ct-primary);
    }

    ${props => css`
        border-top-color: var(--pliable-${props.nodeTypeColor});
        div.fake-img {
            background: var(--pliable-${props.nodeTypeColor});
        }
    `}
}



.buttons {
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 25px;
    right: 25px;
    display: flex;
    flex-direction: column;
    transition: opacity 0.15s ease-in-out;
    -webkit-transition: opacity 0.15s ease-in-out;
}

&:hover .buttons {
    opacity: 1.0;
}

.building-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -25px;
    width: 50px;
    height: 50px;
    color: #ccc;
    font-size: 50px;
    line-height: 50px;
    z-index: 600;
}

.badges {
    margin-top: .25rem;
    margin-bottom: .5rem;
    .badge {
        cursor: pointer;
        font-size: 13px;
    }
    .badge:not(:last-child) {
        margin-right: .25rem;
    }
}


.actions {
    height: 35px;
    border-top: solid 1px var(--ct-border-color);
    display: flex;
    flex-direction: row;

    button {
        font-family: "Poppins";

        color: #888;
        height: 34px;
        border: none;
        border-right: solid 1px var(--ct-border-color);
        background-color: white;
        border-radius: 0px;

        &:disabled {
            color: #ccc;
        }

        &:hover:not(:disabled) {
            cursor: pointer;
            color: black;
        }

        &:first-child {
            border-bottom-left-radius: 5px;
        }

        &:last-child {
            border-bottom-right-radius: 5px;
            border-left: none;
        }

        &.active {
            color: white;
            background-color: var(--pliable-yellow);
        }
    }
}
`

export interface BuildErrorProps {
    buildException?: BuildException;
    objectType: string;
    objectId: string;
}


export const BuildError = (props: BuildErrorProps) => {

    if (props.buildException && props.buildException.error_type == 'MissingMappingException') {
        return <Danger>
            <div>
                <strong>Mapping Error</strong><br />
                <p>{props.buildException.message}</p>
                <Link className="btn btn-light" to={`/business-object/${props.objectId}/fields`}>Fix</Link>
            </div>
        </Danger>
    } else if (props.buildException && props.buildException.error_type == 'MissingRelationshipLookupParametersException') {
        return <Danger>
            <div>
                <strong>Relationship Error</strong><br />
                <p>{props.buildException.message}</p>
                <Link className="btn btn-light" to={`/relationship/${props.buildException.relationship_ref}`}>Fix</Link>
            </div>
        </Danger>
    } else if (props.buildException && ['ModelBuildException'].includes(props.buildException.error_type)) {
        return <Danger>
            <div>
                <strong>Build Error</strong><br />
                {props.buildException.message}
            </div>
        </Danger>
    } else if (props.buildException && ['BadSQLException'].includes(props.buildException.error_type)) {
        return <Danger>
            <div>
                <strong>Build Error</strong><br />
                {props.buildException.message}
                <p>Might you have a SQL error in a custom formula?</p>
            </div>
        </Danger>
    }

    return <Danger>
        <div>
            <strong>{props.buildException ? props.buildException.error_type : 'Unknown Error'}</strong><br />
            <p>{props.buildException && props.buildException.message}</p>
        </div>
    </Danger>
}

interface RelationshipError {
    relationshipId: string;
    parentObjectName: string;
    error: 'AMBIGUOUS' | 'ORPHAN';
}

interface NodeCardProps {
    nodeData: MissionControlDataFlowNode;
    onMouseEnter: () => any;
    onMouseLeave: () => any;
    faded?: boolean;
    // buildExecution?: BuildExecution;
    modelStatus?: ModelStatus;
    onBuild: () => any;
    onDelete: () => any;
    onConnect: () => any;
    relationshipErrors?: RelationshipError[];
    isShowingLineage?: boolean;
    onToggleShowLineage: () => any;

}


export const CustomHandle = styled(Handle)`
visibility: hidden;
width: 16px;
height: 16px;
`
const NodeCard = (props: NodeProps<NodeCardProps>) => {
    const navigateToDrawer = useNavigateToDrawer();
    const navigate = useNavigate();
    const {data: draftVersionId} = useDraftVersionId();
    const statusIndicators = useStatusIndicatorsForNode(props.data.nodeData.data);

    const onViewDetails = useCallback((e: any) => {
        e.preventDefault();
        e.stopPropagation();
        console.log(e.target)
        const objectId = props.id.split(':')[1];
        if (props.id.startsWith('BusinessObject')) {
            navigate(`/business-object/${objectId}`);
        } else if (props.id.startsWith('SourceRecordType:')) {
            navigate(`/data-source/${objectId}`);
        } else if (props.data.nodeData.data.nodeType == 'REPORT') {
            navigate(`/node/${objectId}`);
        } else if (props.id.startsWith('PipelineNode:')) {
            console.log(e.target)
            navigate(`/node/${objectId}`);
        }
    }, [props.id, navigate]);


    const onRunBuild = useCallback(() => {
        props.data.onBuild();
    }, [props.data.onBuild]);

    const onClickStatusIndicator = useCallback(() => {
        console.log('Clicked indicators', statusIndicators);
        navigate(`/business-object/${props.data.nodeData.data.objectId}/fields`)
    }, [statusIndicators, props.data.nodeData.data.objectId]);


    const onConnectClick = useCallback(() => {
        requireDraftMode(
            draftVersionId as string,
            props.data.onConnect
        )
    }, [draftVersionId]);

    const [connectDropdownOpen, setConnectDropdownOpen] = useState(false);

    const nodeTypeConfig = useMemo(() => {
        try {
            return getNodeTypeConfigFromNodeTypeValue(props.data.nodeData.data.nodeType);
        } catch (err) {
            return undefined;
        }
    }, [props.data.nodeData.data.nodeType]);
    

    const bgColor = useMemo(() => {
        if (nodeTypeConfig && nodeTypeConfig.group) {
            return nodeTypeConfig.group.color;
        }
        return 'success';
        
    }, [nodeTypeConfig]);

    const icon = useMemo(() => {
        if (nodeTypeConfig && nodeTypeConfig.nodeType) {
            return nodeTypeConfig.nodeType.icon;
        }
        return '';
    }, [nodeTypeConfig]);

    const wrapperClass = useMemo(() => {
        return props.data.nodeData.data.nodeType.toLowerCase();
    }, [props.data.nodeData.data.nodeType]);

    const buildError = useMemo(() => {
        if (props.data.modelStatus && props.data.modelStatus.status == 'error') {
            return props.data.modelStatus.error;
        } 
        return props.data.nodeData.data.buildError?.message;
    }, [props.data.modelStatus, props.data.nodeData.data.buildError]);

    const buildStatus = useMemo(() => {
        if (props.data.modelStatus) {
            return props.data.modelStatus.status;
        }
        return props.data.nodeData.data.buildStatus;
    }, [props.data.modelStatus, props.data.nodeData.data.buildStatus]);
    return <NodeCardStyles nodeTypeColor={bgColor} id={props.data.nodeData.id} className={`mb-3 ${props.data.faded ? 'faded': ''}`}>
        {/* <div style={{position: 'absolute', top: '92px', right: '-8px'}}>
            <NodeHandle/>
        </div> */}
        {buildStatus == 'started' && (
            <div className="building-icon">
                <i className="mdi mdi-refresh mdi-spin" title="Running"></i>
            </div>
        )}
        {buildStatus == 'queued' && (
            <div className="building-icon">
                <i className="mdi mdi-clock" title="Queued"></i>
            </div>
        )}
        {buildStatus == 'success' && (
            <div className="building-icon">
                <i className="mdi mdi-check-circle text-success" title="Complete"></i>
            </div>
        )}
        {buildStatus == 'error' && (
            <div className="building-icon">
                <i className="mdi mdi-alert-circle text-danger" title="Error"></i>
            </div>
        )}
        <div className={`node-box shadow-box ${props.data.isShowingLineage ? 'active' : ''}`}>
            <div className="d-flex">
                <div className="node-img p-3">
                    <div className="fake-img">
                        <i className={icon}/>
                    </div>
                    
                    

                </div>
                <div className="node-contents pt-3 pb-3 pe-3">
                    <h4 title={props.data.nodeData.data.title} className="overflow-ellipsis">{props.data.nodeData.data.title} {props.data.nodeData.data.totalUpstream > 1 &&  <Badge pill bg="secondary" title={`${props.data.nodeData.data.title} pulls from ${props.data.nodeData.data.totalUpstream} sources`}>{props.data.nodeData.data.totalUpstream}</Badge> }</h4>

                    <div className="badges">
                        {props.data.nodeData.data.needsBuilding && <Badge bg="warning">Needs build</Badge>}
                        {!props.data.nodeData.data.needsBuilding && <Badge bg="light">Up to date</Badge>}
                        {statusIndicators.map(si => {
                            return <Badge bg="warning" onClick={onClickStatusIndicator}>
                                {si.message == 'NO_FIELDS' && <span>No fields</span>}
                                {si.message == 'MISSING_MAPPINGS' && <span>Missing mappings</span>}
                                {si.message == 'NO_SOURCES' && <span>No sources</span>}
                            </Badge>
                        })}
                        {props.data.relationshipErrors && props.data.relationshipErrors.map(re => {
                            return <Badge bg={re.error == 'AMBIGUOUS' ? 'danger' : 'warning'} title={`Relationship to: ${re.parentObjectName}`} onClick={() => {
                                navigate(`/relationship/${re.relationshipId}`)
                            }}>
                                {re.error == 'AMBIGUOUS' && <span>Ambiguous Parents</span>}
                                {re.error == 'ORPHAN' && <span>Orphan Children</span>}
                            </Badge>
                        })}
                        
                        {!!buildError && (
                            <> 
                                <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={
                                    <Popover>
                                        <Popover.Header>Error Details</Popover.Header>
                                        <Popover.Body>
                                            {buildError}
                                            {/* <BuildError
                                                buildException={buildError}
                                                objectType={props.data.nodeData.data.nodeType}
                                                objectId={props.data.nodeData.data.objectId}
                                            /> */}
                                        </Popover.Body>
                                    </Popover>
                                }>
                                    <Badge bg="danger">Build error</Badge>
                                </OverlayTrigger>
                                
                                
                            </>
                            
                        )}
                        
                    </div>
                    

                    <div>
                        <i className="mdi mdi-file-multiple"></i> Total Records: {props.data.nodeData.data.totalRecords ? summarizeNumber(props.data.nodeData.data.totalRecords) : '0'}
                    </div>
                    
                    <div>
                        <i className="mdi mdi-clock"></i> Last Build: {props.data.nodeData.data.lastBuildCompleted ? timeAgo(new Date(props.data.nodeData.data.lastBuildCompleted as string), 'mini-minute-now') : 'never'}
                    </div>

                    
                
                </div>
            </div>
                <div className="actions">
                    <button onClick={onViewDetails} className="flex-1">
                        Details
                    </button>
                    <button onClick={onRunBuild} disabled={!props.data.nodeData.data.needsBuilding}  className="flex-1">
                        Build
                    </button>
                    <DraftModeRequired justHideChildren>
                        {props.data.nodeData.data.nodeType != 'REPORT' && (
                            <button onClick={onConnectClick}  className="flex-1">
                                Connect
                            </button>
                        )}
                    </DraftModeRequired>
                    
                    
                    
                    
                </div>
            
        </div>
        <>
          <CustomHandle
            type="source"
            position={Position.Top}
            id="a"
          >
          </CustomHandle>
        
          <CustomHandle  type="source" position={Position.Right} id="b">
          </CustomHandle>
          <CustomHandle  type="source" position={Position.Bottom} id="c">
          </CustomHandle>
          <CustomHandle  type="source" position={Position.Left} id="d">
          </CustomHandle>
        </>
        
    </NodeCardStyles>
}

const nodeTypes = {
  custom: NodeCard,
};

const edgeTypes = {
  cardinal: MissionControlCardinalEdge,
};

const connectionLineStyle = {
    strokeWidth: 2,
    stroke: '#FF9F00',
    strokeDashArray: 5,
};

const fitViewOptions = { padding: 1, duration: 500 };

function randomNumberBetween(a: number, b: number): number {
    return Math.floor(Math.random() * (b - a + 1) + b);
}

const xAxisDefaults = {
    'BUSINESS_OBJECT': 600,
    'RECORD_TYPE': 0,
    'REPORT': 1200,
}

function ViewportChangeLogger() {
    useOnViewportChange({
        onChange: (viewport: Viewport) => setViewportInfo(viewport),
    });
   
    return null;
}


interface DiagramManagerProps {
    focusNodeId?: string;
    onToggleNodeLineage: (nodeId: string) => any;
    nodeData: MissionControlDataFlowNode[];
    edgeData: MissionControlDataFlowEdge[];
    activeOrchestration?: BuildOrchestration;
    autoLayout?: boolean;
    onDeleteEdge: (edgeId: string) => void;
    onEditEdge: (edgeId: string) => void;
    onClickNode: (nodeId: string, drawerTab?: string) => void;
    onClickEdgeStatusIndicator: (edgeId: string) => void;
    onDeleteNode: (nodeId: string) => void;
    onConnectNode: (nodeId: string) => void;
    onBuildNode: (nodeId: string) => void;
};

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

export const MissionControlDataFlowDiagramManager = (props: DiagramManagerProps) => {
    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance|null>(null);

    const [nodes, setNodes] = useState<Node<NodeCardProps>[]>([]);
    const [edges, setEdges] = useState<Edge<MissionControlEdgeProps>[]>([]);


    const [nodePositions, setNodePositions] = useImmer<{[key: string]: XYPosition}>({});

    const [previousOrchestrationStatus, setPreviousOrchestrationStatus] = useState('');
    const container = useRef<HTMLDivElement>(null);

    const [focusedEdge, setFocusedEdge] = useState('');

    const previousViewport = getViewportInfo();

    const tenantId = AuthService.getInstance().getTenantId();

    const onReactFlowInit = useCallback((instance: ReactFlowInstance) => {
        setReactFlowInstance(instance);
    }, []);

    const persistViewport = useCallback(() => {
        if (reactFlowInstance) {
          const flow = reactFlowInstance.toObject();
          setViewportInfo(flow.viewport);
        }
      }, [reactFlowInstance]);

    const isValidConnectionCallback = useCallback((connection: Connection) => {
        return isValidConnection(connection, nodes, edges);
    }, [nodes, edges]);

    // When they click on the 
    const onPaneClick = useCallback(() => {
        setFocusedEdge('');
    }, []);
    const positionsCacheKey = useMemo(() => {
        return `mc_node_positions_3_${tenantId}`
    }, [tenantId]);

    const zoomToFit = useCallback(() => {
        if (!reactFlowInstance) {
            return;
        }
        reactFlowInstance.fitView({
            padding: 50,
            nodes: nodes.map(n => {
                return {id: n.id}
            }),
            duration: 5,
        });
    }, [reactFlowInstance, nodes]);

    const zoomToFitNode = useCallback((nodeId: string) => {
        if (!reactFlowInstance) {
            return;
        }
        console.log('Calling fit view');
        reactFlowInstance.fitView({
            padding: 50,
            minZoom: 1,
            maxZoom: 5,
            nodes: [{id: nodeId}],
            duration: 500,
        });
    }, [reactFlowInstance]);

    useEffect(() => {
        if (props.focusNodeId) {
            console.log('Zooming to fit node:', props.focusNodeId);
            setTimeout(() => {
                zoomToFitNode(props.focusNodeId as string);
            }, 100)
            
        }
    }, [props.focusNodeId, zoomToFitNode]);


    const [ranInitialAutoLayout, setRanInitialAutoLayout] = useState(false);


    const onToggleShowNodeLineage = useCallback((nodeId: string) => {
        props.onToggleNodeLineage(nodeId);
    }, [props.onToggleNodeLineage]);

    useEffect(() => {
        if (props.nodeData.length == 0) {
            return;
        }
        // Now set the nodes and edges
        let newNodes: Node<NodeCardProps>[] = [];

        // Store the current build status for each node in a lookup table for easy access


        newNodes = props.nodeData.map(n => {
            let position: XYPosition = {
                x: 0, y: 0
            };

            
            return {
                id: n.id,
                position: position,
                type: 'custom',
                data: {
                    modelStatus: props.activeOrchestration?.model_statuses[n.data.objectId],
                    nodeData: n,
                    isShowingLineage: props.focusNodeId == n.id,
                    onToggleShowLineage: () => {
                        onToggleShowNodeLineage(n.id);
                    },
                    onMouseEnter: () => {

                    },
                    onMouseLeave: () => {

                    },
                    faded: false,
                    onBuild: () => {
                        props.onBuildNode(n.id)
                    },
                    onDelete: () => {
                        props.onDeleteNode(n.id);
                    },
                    onConnect: () => {
                        props.onConnectNode(n.id);
                    }
                },
            }
        });

        const newNodeIds = newNodes.map(n => n.id);


        let newEdges: Edge[] = [];
        newEdges = props.edgeData.filter(e => {
            return newNodeIds.includes(e.source) && newNodeIds.includes(e.target);
            
        }).map(e => {

            let executionStatus = null;
            if (!!props.activeOrchestration && !['COMPLETE', 'ERROR'].includes(props.activeOrchestration.status)) {
                // If the target is being built, then set the execution status of the edge to ACTIVE
                const targetId = e.target.split(':')[1];
                if (props.activeOrchestration.model_statuses.hasOwnProperty(targetId) && !!props.activeOrchestration.model_statuses[targetId]) {
                    executionStatus = 'ACTIVE';

                // If not, set it to EXCLUDED so we can gray it out
                } else {
                    executionStatus = 'EXCLUDED';
                }
            }

            
            const isReport = e.source.startsWith('DataMart:') || e.target.startsWith('DataMart:');
            return {
                'id': e.id,
                'source': e.source,
                'target': e.target,
                'sourceHandle': 'c',
                'targetHandle': 'a',
                'interactionWidth': 50,
                'type': 'cardinal',

                'data': Object.assign({}, e.data, {
                    'executionStatus': executionStatus,
                    'type': e.data.type,

                    // No actions on report connections
                    'showActions': !isReport && (focusedEdge == e.id),
                    'onClick': () => {
                        if(focusedEdge != e.id) {
                            setFocusedEdge(e.id);
                        } else{
                            setFocusedEdge('');
                        }
                    },
                    'statusIndicator': {
                        type: e.data.status_indicator,
                    },
                    'onClickStatusIndicator': () => {
                        props.onClickEdgeStatusIndicator(e.id)
                    },
                    'onEdit': () => {
                        props.onEditEdge(e.id);
                    },
                    'onDelete': () => {
                        props.onDeleteEdge(e.id);
                    }
                }),
            }
        });

        const positions = autoLayout(newNodes, newEdges, 'LINEAR', 450, 300);
        newNodes = newNodes.map( n => {
            if (positions.hasOwnProperty(n.id)) {
                n.position = positions[n.id];
                console.log('Node has position');

            } else {
                console.log('No node position');
            }
            return n;
        });
        setRanInitialAutoLayout(true);
        
        // Unclear why we need to do this but this prevents DH-1071 where edges would remain even though they weren't in newEdges
        setNodes([]);
        setEdges([]);
        setTimeout(() => {
            setNodes(newNodes);
            setEdges(newEdges);
        })

        // setTimeout(() => {
        //     zoomToFit();
        // }, 100);
    }, [props.nodeData, props.edgeData, props.activeOrchestration, focusedEdge, ranInitialAutoLayout]);

    // useEffect(() => {
    //     setTimeout(() => {
    //         zoomToFit();
    //     }, 100);
    // }, [nodes])

    // const onNodesChange = useCallback((changes: NodeChange[]) => {
    //     if (changes.length === 0 || changes[0].type !== 'position') {
    //         return;
    //     }
    //     const updatedPositions = Object.assign({}, nodePositions);
    //     changes.forEach(c => {
    //         if (c.type === 'position' && c.position) {
    //             updatedPositions[c.id] = c.position;
    //         }
    //     });
    //     setNodePositions(updatedPositions);
    // }, [nodePositions]);


    if (nodes.length) {
        return <div style={{width: '100%', height: '100%'}} ref={container}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                defaultViewport={previousViewport}
                onPaneClick={onPaneClick}
                isValidConnection={isValidConnectionCallback}
                onInit={onReactFlowInit}
                nodesConnectable={false}
                edgeTypes={edgeTypes}
                draggable={false}
                minZoom={.001}
                nodeTypes={nodeTypes}
                fitView={!previousViewport}
                fitViewOptions={fitViewOptions}
                connectionMode={ConnectionMode.Loose}
                connectionLineComponent={MissionControlConnectionLine}
                connectionLineStyle={connectionLineStyle}
                style={{background: "#F9F7F5"}}
                proOptions={{hideAttribution: true}}
            >
                <MiniMap position="bottom-right" pannable/>
                <Controls
                        showZoom
                        showFitView
                        showInteractive={false}
                >
                </Controls>
                <ViewportChangeLogger/>
            </ReactFlow>
        </div>
    }
    return <></>;
}




