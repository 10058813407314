import Dropdown, { MultiDropdown } from "@components/form/Dropdown.component";
import { PipelineNode, PipelineNodeORM } from "@models/pipelineNode";
import { getErrorMessage } from "@services/errors.service";
import { getNodeColor, getNodeTypeConfig } from "@services/modeling.service";
import toast from "@services/toast.service";
import { usePipelineNodes } from "@stores/data.store";
import { useCallback, useMemo, useState } from "react";
import { Form, Spinner } from "react-bootstrap";

interface Props {
    selectedIds: string[];
    disabled?: boolean;
    onSelect: (ids: string[]) => void;

    blacklist?: string[];
    optionFilter?: (pn: PipelineNode) => boolean;
}

const PipelineNodeMultiSelector = (props: Props) => {
    const pipelineNodes = usePipelineNodes();
    const [backgroundLoading, setBackgroundLoading] = useState(false);

    const filterfunc = useCallback((pn: PipelineNode) => {
        if (props.blacklist && props.blacklist.includes(pn.id as string)) {
            return false;
        }
        if (props.optionFilter && !props.optionFilter(pn)) {
            return false;
        }
        return true;
    }, [props.optionFilter, props.blacklist]);

    const options = useMemo(() => {
        if (pipelineNodes.data) {
            return pipelineNodes.data.filter(filterfunc).map(node => {
                const ntg = getNodeTypeConfig(node);
                return {
                    label: node.name,
                    description: node.description,
                    value: node.id as string,
                    badgeText: ntg.nodeType.label,
                    badgeVariant: getNodeColor(node),
                }
            })
        }
        return [];
    }, [pipelineNodes.dataUpdatedAt, props.blacklist, filterfunc]);



    const onSelect = useCallback(async (opts: string[]) => {
        if (!opts) {
            props.onSelect([]);
        } else {
            props.onSelect(opts);
        }
    }, [props.onSelect]);

    let wrapperClass = '';

    return <div className={wrapperClass}>
        {pipelineNodes.isLoading && (
            <Spinner size="sm"/>
        )}
        {!pipelineNodes.isLoading && (
            <>
                <MultiDropdown
                    placeholder="Select node(s)"
                    options={options}
                    selected={props.selectedIds}
                    onChange={onSelect}
                />
            </>

            
            
        )}

    </div>
}

export default PipelineNodeMultiSelector;