import { CardHeader } from '../components/card/Card.component';


function ApiConnectionErrorPage() {
        return (
            <div>
                <div className="d-flex justify-content-center mt-4">
                    <div className="col-5 col-offset-4">
                        <div className="card">
                            <div className="card-body">
                                <CardHeader title="Connection Error" />
                                <p>
                                    We are having trouble connecting to the server. Please wait a moment and try again.
                                </p>
                                <p>
                                <a href="/" className="btn btn-xs btn-outline-dark">
                                        <i className="mdi mdi-restart"></i> Try Again
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        );
}


export default ApiConnectionErrorPage;