import { Component, ReactNode } from "react";
import Spinner from "react-bootstrap/Spinner";
import styled from 'styled-components';

const Styles = styled.div`
    h2 {
        font-size: 36px;
    }
`

interface BigMetricCardProps {
    icon: string;
    title: string;
    loading: boolean;
    metric?: string;
    active?: boolean;
    backgroundColor?: string;
    children?: ReactNode;

}
export default class BigMetricCard extends Component<BigMetricCardProps> {
    render() {
        let content;
        if (this.props.loading) {
            content = <Spinner/>
        } else if (this.props.metric) {
            content = (
                <h2 className="my-2">{this.props.metric.toString()}</h2>
            )
        }

        let cardClass = '';

        if (this.props.backgroundColor) {
            cardClass = 'bg-' + this.props.backgroundColor + ' text-white';
        }
    
        return <Styles>
            <div className={'card shadow-box tilebox-one ' + (cardClass)}>
                <div className="card-body">
                    <i className={'floating-icon ' + this.props.icon}/>
                    <h6 className="text-uppercase mt-0">{this.props.title}</h6>
                    {content}
                    <>
                        {this.props.children}
                    </>
                </div>
            </div>
        </Styles>;
    }
}