import { Source } from "@models/source"
import { useEffect, useMemo, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useSources } from "@stores/data.store";
import Dropdown from "@components/form/Dropdown.component";


interface SourceSelectorProps {
    selectedId: string;
    onSelect: (sourceId: string) => any;
    disabled?: boolean;
}
const SourceSelector = (props: SourceSelectorProps) => {

    const sources = useSources();

    const sourceOptions = useMemo(() => {
        if (!sources.data) {
            return [];
        }

        return sources.data.map(s => {
            return {
                value: s.id as string,
                label: s.name,
            }
        });
    }, [sources.dataUpdatedAt]);

    if (!sources.data) {
        return <></>
    }

    return <Dropdown
        options={sourceOptions}
        selected={props.selectedId}
        onChange={props.onSelect}
        disabled={props.disabled}
    />
}

export default SourceSelector;