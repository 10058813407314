import { useQuery } from "react-query";
import ORM, { BaseModel } from "./orm";
import { queryClient } from "@stores/data.store";
import { parseDateString } from "@services/time.service";

export interface Notification extends BaseModel {
   title: string;
   description: string;
   object_id?: string;
   object_type?: string;
   type: 'BUILD_ERROR';
   timestamp: Date; 
}


export class _NotificationORM extends ORM<Notification>{
    public convertDates(record: Notification) {
        ['last_updated', 'timestamp'].forEach(f => {
            const val = record[f as keyof Notification] as string | undefined;
            if (val) {
                
                console.log('Parsing', val);
                // @ts-ignore
                record[f] = parseDateString(val);
            }

        });
        return record;
    }
}


const NotificationORM = new _NotificationORM('notifications', ['timestamp']);
export default NotificationORM;

export const useNotifications = () => {
    return useQuery(['notifications'], async () => {
        return (await NotificationORM.find(null, 50, 1, '-timestamp')).records;
    });
}

export const clearNotification = async (notificationId: string) => {
    await NotificationORM.deleteById(notificationId);
    queryClient.invalidateQueries('notifications'); 
}

export const invalidateNotifications = () => {
    queryClient.invalidateQueries('notifications');
}