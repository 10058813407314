import { useBuildOrchestrationHistory } from "@stores/data.store";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent } from "./PageStructure.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import { timeAgo } from "@services/time.service";
import { Link } from "react-router-dom";

const BuildHistoryPage = () => {

    const builds = useBuildOrchestrationHistory();
    return <PageStructure
        pageTitle="Build History"
    >
        <PageSidebar>
            Sidebar
        </PageSidebar>
        <PageContent>
            <Pane>
                <PaneContent>
                    <div className="p-3">
                        {builds.isLoading && <PliableLoader/>}
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Started At</th>
                                    <th>Started By</th>
                                    <th>Selector</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {builds.data && builds.data.map(b => {
                                    return <tr>
                                        <td>{b.queued_at ? timeAgo(b.queued_at) : 'Never'}</td>
                                        <td>{b.executed_by}</td>
                                        <td>{b.node_selector}</td>
                                        <td>{b.status}</td>
                                        <td>
                                            <Link to={`/?watchOrchestrationId=${b.id}&keepBuildOpen=true`} className="btn btn-primary btn-sm">Details</Link>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </PaneContent>
            </Pane>
        </PageContent>
    </PageStructure>
}

export default BuildHistoryPage;