import { queryClient } from "@stores/data.store";
import AuthService from "../auth/auth.service";

export interface ListRecordsResponse<T> {
    total_records: number;
    total_pages: number;
    records: T[];
}

export interface DictRecordsResponse {
    records: object;
}

export interface AnyRecordsResponse {
    records: any;
}


export interface SingleRecordResponse<T> {
    record: T;
}

export interface JobEnqueueResponse {
    job_id: string;
}

export interface ActionLog {
    user: string;
    subject_type: string;
    subject_id: string;
    execution_time: Date;
    action: string;
    meta: object|null;
}

interface ErrorPayload {
    exception: string;
    msg: string;
}

const serialize = function(obj: any) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

export class ApiConnectionError extends Error {
    constructor(msg: string) {
        super(msg);

        // Set the prototype explicitly (from typescript docs)
        Object.setPrototypeOf(this, ApiConnectionError.prototype);
    }
}

export const setSourceTruncate = (truncate: number) => {
    localStorage.setItem('PLB_SOURCE_TRUNCATE', truncate.toString());
}
  
export const getSourceTruncate = () => {
    const item = localStorage.getItem('PLB_SOURCE_TRUNCATE');

    if (item) {
        try {
        return parseInt(item, 10);
        } catch (err) {
        console.error(err);
        }
    }
    return 0;
}

export class ApiError extends Error {
    code: number;
    payload?: ErrorPayload;
    constructor(msg: string, code: number, payload?: ErrorPayload) {
        super(msg);

        this.code = code;
        this.payload = payload;

        // Set the prototype explicitly (from typescript docs)
        Object.setPrototypeOf(this, ApiError.prototype);
    }
}

export const buildUrl = (base: string, queryString?: {
    [key: string]: any
}) => {
    let url = base;

    if (queryString) {
        url = url + '?' + serialize(queryString);
    }
    return url;
}



export default class ApiService {
    private static instance: ApiService;

    hostname: string;
    basePath: string;
    sourceTruncate?: number;

    public static getInstance(): ApiService {
        if (!ApiService.instance) {
            ApiService.instance = new ApiService();
        }

        return ApiService.instance;
    }

    constructor() {
        const hostname = window.location.hostname;

        if (hostname === 'local.whetstonedata.com') {

            this.hostname = 'https://local.whetstonedata.com:5501';
            //this.hostname = 'https://pliablesully.serveo.net';
            this.basePath = '/api/v1';
        } else {
            this.hostname = `https://${hostname}`;
            this.basePath = '/api/v1';
        }

        this.sourceTruncate = getSourceTruncate();
    }

    public setSourceTruncate(truncate: number) {
        this.sourceTruncate = truncate;
        setSourceTruncate(truncate);
    }

    public apiUrl() {
        return this.hostname + this.basePath;
    }

    public async request(method: 'GET'|'POST'|'PATCH'|'PUT'|'DELETE', path: string, payload?: object, nested: boolean = false): Promise<object> {
        let body = null;
        let query = '';

        if (method === 'POST' || method === 'PATCH' || method === 'PUT') {
            body = JSON.stringify(payload);
        }

        if (method === 'GET' && !!payload) {
            query = '?' + serialize(payload);
        }

        const branch = (!!queryClient.getQueryData(['draft_version_id'])) ? 'DRAFT' : 'PROD';

        try {
            const response = await fetch(this.apiUrl() + path + query, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + AuthService.getInstance().getAccessToken(),
                    'Plb-Branch': branch,
                    // Only truncate in draft
                    'Plb-Source-Truncate': branch == 'DRAFT' ? (this.sourceTruncate?.toString() || '0') : '0',
                },
                body: body,
                mode: 'cors',
                credentials: 'include',
            });
    
            if (response.status < 200 || response.status >= 400) {
                throw new ApiError('API Error', response.status, await response.json());
                // not sure if this is needed anymore
                // if (response.status === 401 && !nested) {
                //     //await this.refreshToken();
                //     return this.request(method, path, payload, true);

                // } else {
                //     throw new ApiError('API Error', response.status, await response.json());
                // }  
            } else if (response.status === 204) {
                return {};
            }
            return await response.json();
        } 
        catch (err) {
            if (err instanceof ApiError) {
                throw err;
            } else if (err instanceof Error && err.message == "Failed to fetch") {
                console.error(err);
                throw new ApiConnectionError(err.message);
            } else if (err instanceof Error) {
                console.error(err);
                throw new ApiError(err.message, -1, undefined);
            } else {
                throw err;
            }
        }
        
    }

    public async logAction(action: string, subjectType: string, subjectId: string, meta: object|null = null) {
        await this.request('POST', '/action-logs', {
            action: action,
            subject_type: subjectType,
            subject_id: subjectId,
            meta: meta,
        });
    }

    public async getActionLogs(): Promise<ActionLog[]> {
        const response = await this.request('GET', '/action-logs')as ListRecordsResponse<any>;

        return response.records as ActionLog[];
    }

}