import BusinessObjectFieldTypeSelector from "@components/businessObjects/BusinessObjectFieldTypeSelector.component";
import Dropdown from "@components/form/Dropdown.component";
import { PipelineNodeField } from "@models/pipelineNode";
import { Editor } from "@monaco-editor/react";
import { useIsInDraftMode, useProjectConfig } from "@stores/data.store";
import { useCallback, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { useDebouncedCallback } from "use-debounce";
import PipelineNodeFieldMap from "./PipelineNodeFieldMap.component";
import PipelineNodeFieldCellActions from "./PipelineNodeFieldCellActions.component";
import FormatForm from "@pages/SourceRecordType/FormatForm.component";

const EditorStyles = styled.div`
.monaco-editor .suggest-widget { 
    width: 280px !important; 
    left: 0px !important;
}
`

interface Transformer {
    label: string;
    description: string;
    value: string;
    takesArguments?: boolean;
    snowflakeOnly?: boolean;
}

const TransformerOptions: Transformer[] = [
{
    label: 'Translate',
    description: 'Manually translate values using a Pliable-managed mapping table',
    value: 'TRANSLATE',
},
{
    label: 'Clean Zip Code',
    description: 'Cleans up zip codes by normalizing to 5 digits and ensuring there are leading zeroes',
    value: 'CLEAN_ZIP_CODE',
}, {
    label: 'Clean Phone Numbers',
    description: 'Normalizes phone numbers to (XXX) XXX-XXXX format',
    value: 'CLEAN_PHONE',
}, {
    label: 'Convert to Lowercase',
    description: 'Converts text values to all lowercase',
    value: 'LOWERCASE',
}, {
    label: 'Convert to Uppercase',
    description: 'Converts text values to all uppercase',
    value: 'UPPERCASE',
}, {
    label: 'Remove Non-Numeric Characters',
    description: 'Strips all characters that are not digits',
    value: 'REMOVE_NON_NUMERIC',
}, {
    label: 'Normalize Dates',
    description: 'Parses and cleans dates by trying a variety of common formats',
    value: 'PARSE_DATE',
}, {
    label: 'Convert to "Slug" Format',
    description: 'Converts text to a web-safe "slug" format, like "New York" -> "new-york"',
    value: 'SLUGIFY',
}, {
    label: 'Get URL Path',
    description: 'Extracts the path component from a URL (https://pliable.co/landing-page will return "/landing-page")',
    value: 'URL_PATH',
}, {
    label: 'Get URL Domain',
    description: 'Extracts the domain component from a URL (https://pliable.co/landing-page will return "pliable.co")',
    value: 'URL_DOMAIN',
}]


interface Props {
    field: PipelineNodeField;
    onChange: (attr: keyof PipelineNodeField, value: any) => void;
    isMerging?: boolean;
    combineBehavior?: string;
    allFields: PipelineNodeField[];
    onEditTranslations: (fieldId: string) => void;
    otherFieldsForAutocomplete: string[];
    disableMultiple?: boolean;
    displayJoinPath?: boolean;
}
const PipelineNodeFieldEditor = (props: Props) => {
    const config = useProjectConfig();
    
    const inDraftMode = useIsInDraftMode();

    const editorRef = useRef<any>();
    const [completionDisposable, setCompletionDisposable] = useState<any>();
    
    const editorDidMount = useCallback((editor: any, monacoParam: any) => {
        editorRef.current = editor;

        setCompletionDisposable(
            monacoParam.languages.registerCompletionItemProvider('*', {
                provideCompletionItems: function(model: any, position: any) {

                    // add BO objects
                    const suggestions: any[] = props.otherFieldsForAutocomplete.map(f => {
                        return {
                            label: f,
                            kind: monacoParam.languages.CompletionItemKind.Variable,
                            insertText: `"${f}"`

                        }
                    })
    

                    return {
                        suggestions: suggestions
                    };
                }
            })
        );
    }, [props.otherFieldsForAutocomplete]);


    const availableTransformers = useMemo(() => {
        if (!config.data) {
            return [];
        }

        if (config.data.database == 'SNOWFLAKE') {
            return TransformerOptions.map(o => {
                return {
                    value: o.value,
                    label: o.label,
                }
            });
        }
        return TransformerOptions.filter(o => !o.snowflakeOnly).map(o => {
            return {
                value: o.value,
                label: o.label,
            }
        });
    }, [config.dataUpdatedAt]);

    const changeTitle = useDebouncedCallback((value) => {
        props.onChange('label', value);
    }, 500);

    const changeDescription = useDebouncedCallback((value) => {
        props.onChange('description', value);
    }, 500);

    if (props.field.type == 'FOREIGN_KEY') {
        return <>
            <div className="p-2">
                <h3>{props.field.label}</h3>
                <p>This is a <strong>foreign key</strong> field managed by a <Link to={`/relationship/${props.field.relationship_id}`}>relationship</Link>.</p>
                {/* TODO - allow them to edit join params here */}

            </div>
            <div>
                <div className="mx-2">
                    <hr />
                    {/* <AsyncButton loading={false} variant="secondary" text="Cancel" onClick={() => props.handleClose(true)}/> */}
                </div>
            </div>
        </>
    } else if (props.field.type == 'DENORMALIZED') {
        return <>
            <div className="p-2">
                <h3>{props.field.label}</h3>
                <p>This is a <strong>lookup</strong> field managed by a <Link to={`/relationship/${props.field.relationship_id}`}>relationship</Link>.</p>
                {/* TODO - allow them to edit the lookup field info here */}
            </div>
            <div>
                <div className="mx-2">
                    <hr />
                    {/* <AsyncButton loading={false} variant="secondary" text="Cancel" onClick={() => props.handleClose(true)}/> */}
                </div>
            </div>
        </>

    } else if (props.field.type == 'IDENTITY') {
        return <>
            <div className="p-2">
                <h3>{props.field.label}</h3>
                <p>This is an <strong>identity</strong> field automatically added by Pliable</p>
            </div>
            <div>
                <div className="mx-2">
                    <hr />
                    {/* <AsyncButton loading={false} variant="secondary" text="Cancel" onClick={() => props.handleClose(true)}/> */}
                </div>
            </div>
        </>
    }
    return <>
        <div className="p-2">
            <Form.Group className="mb-2">
                <Form.Label className="small">Label</Form.Label>
                <h3>
                    <input type="text" disabled={!inDraftMode} defaultValue={props.field.label} onChange={(e) => changeTitle( e.target.value)} className="inline-edit w-100"/>

                </h3>

            </Form.Group>
            <Form.Group>
                <Form.Label className="small">Description</Form.Label>
                <Form.Control disabled={!inDraftMode}  onChange={(e) => changeDescription(e.target.value)} defaultValue={props.field.description} as="textarea"/>
            </Form.Group>

        </div>
        <h2 className="sidebar-section-header">Options</h2>
        <div className="p-2">
            <Form.Group className="mb-2">
                <Form.Label className="small">Datatype</Form.Label>
                <BusinessObjectFieldTypeSelector
                    disabled={!inDraftMode} 
                    className="input-sm"
                    selected={props.field.type}
                    onSelect={newVal => props.onChange('type', newVal)}
                />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Check
                    disabled={!inDraftMode}
                    type="switch"
                    label="Custom formula"
                    checked={props.field.advanced_mode}
                    onChange={(e) => props.onChange('advanced_mode', !props.field.advanced_mode)}

                />

            </Form.Group>
                    
            {!props.field.advanced_mode && (
                <>
                    {props.isMerging && props.combineBehavior == 'AGGREGATE' && !props.field.part_of_composite_key && props.field.map_options && props.field.map_options.length >= 1 && (
                        <Form.Group className="mb-2">
                            <Form.Label className="small">Aggregation Behavior</Form.Label>
                            <Dropdown
                                disabled={!inDraftMode}
                                selected={props.field.merge_behavior}
                                options={[
                                    {
                                        value: 'PRIORITIZE',
                                        label: 'Pick One',
                                        description: 'Pliable will choose the first non-null value it can find',
                                    }, {
                                        value: 'COUNT_DISTINCT',
                                        label: 'Count Distinct',
                                        description: 'Count the total distinct values',
                                    }, {
                                        value: 'CONCAT',
                                        label: 'Concatenate',
                                        description: 'String values will be combined into a single comma-separated string',
                                    }, {
                                        value: 'SUM',
                                        label: 'Sum',
                                        description: 'Numeric values will be added together for all merged records',
                                    }, {
                                        value: 'MIN',
                                        label: 'Minimum',
                                        description: 'Pliable will pick the minimum value',
                                    }, {
                                        value: 'MAX',
                                        label: 'Maximum',
                                        description: 'Pliable will pick the maximum value',
                                    },
                                    {
                                        value: 'AVG',
                                        label: 'Average',
                                        description: 'Pliable will average the values',
                                    }
                                ]}
                                onChange={(selected: string) => {
                                    props.onChange('merge_behavior', selected);
                                }}
                            />
                                
                        </Form.Group>
                    )}
                    
                    
                    <Form.Group>
                        <Form.Label className="small">Transform (Optional)</Form.Label>
                        <Dropdown
                            disabled={!inDraftMode}
                            placeholder="None"
                            className="input-sm"
                            selected={props.field.transformer}
                            onChange={(selected: string) => props.onChange('transformer', selected)}
                            options={availableTransformers}
                        />
                        {props.field.transformer == 'TRANSLATE' && inDraftMode && (
                            <button className="mt-1 btn btn-sm btn-light" onClick={() => {
                                props.onEditTranslations(props.field.id)
                            }}>Configure Translations</button>
                        )}
                    </Form.Group>
                </>
            )}
            
            
        </div>
        
        <div>
            {props.field.advanced_mode && (
                <>
                    <h2 className="sidebar-section-header">Custom Formula</h2>
                    <div className="p-2">
                        <EditorStyles>
                            <Editor
                                
                                onChange={(value) => props.onChange('custom_sql', value ? value as string : '')}
                                height="150px"
                                defaultLanguage="sql"
                                options={{
                                    readOnly: !inDraftMode,
                                    minimap: { enabled: false },
                                }}
                                defaultValue={props.field.custom_sql}
                                onMount={(editor, monaco) => editorDidMount(editor, monaco)}
                            />
                        </EditorStyles>
                        
                    </div>
                    
                </>
                
            )}
            {!props.field.advanced_mode && (
                <>
                    <h2 className="sidebar-section-header">
                        {!props.disableMultiple && <>Mapped Source Columns</>}
                        {props.disableMultiple && <>Mapped Source Column</>}
                    </h2>
                    <div className="p-2">
                        {!props.field.part_of_composite_key && !props.disableMultiple && (
                            <div className="small">Reorder mapped columns to adjust priority. Pliable will use the first non-null value.</div>
                        )}
                        
                        <PipelineNodeFieldMap
                            showPriority={false}
                            mapOptions={props.field.map_options!} 
                            onChangeMapOptions={(newOptions) => {
                                props.onChange('map_options', newOptions);
                            }}
                            displayJoinPath={props.displayJoinPath}
                        />
                    </div>

                    
                </>
            )}
        </div>
    
        <div>
            <h2 className="sidebar-section-header">Format</h2>
            <div className="p-2">
                <Form.Group className="mb-2">
                    <FormatForm
                        selectedFormat={props.field.formatter || ''}
                        onChange={(newFormat) => {
                            props.onChange('formatter', newFormat);
                        }}
                    />
                </Form.Group>
            </div>
            <h2 className="sidebar-section-header">Custom Actions</h2>
            <div className="p-2">
                <PipelineNodeFieldCellActions 
                    cellActions={props.field.cell_actions} 
                    allFields={props.allFields}
                    onChangeCellActions={(newActions) => {
                        props.onChange('cell_actions', newActions);
                }} />
            </div>
        </div>
    </>
    
}

export default PipelineNodeFieldEditor;
