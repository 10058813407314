import { useCallback, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";


export enum YoutubeVideos {
    OverviewVideo = '_F3CFZ3OZmE'
  }


export interface VideoModalProps {
    onClose: () => void;
    embedId: string;
    title: string;
    show?: boolean;
}


const VideoModal = (props: VideoModalProps) => {
    const close = useCallback(() => {
        props.onClose();

    }, [props.onClose]);

    
    return (
        <Modal show={props.show} onHide={props.onClose} size="lg"
        dialogClassName="modal-video"
        centered>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="video-responsive">
                <iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${props.embedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                />
            </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => close()}>Close</button>
                <button className="btn btn-pliable" onClick={() => confirm()}>Go</button>
            </Modal.Footer> */}
        </Modal>

    )
}

export default VideoModal;