import { useCallback, useEffect, useState } from "react";
import 'reactflow/dist/style.css';
import { Link, useNavigate } from "react-router-dom";
import { Form, Row } from "react-bootstrap";
import SaveButton from "@components/button/SaveButton.component";
import { useRouteBlocker } from "@services/routing.service";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent, PaneContentWithSubnav } from "@pages/PageStructure.component";
import TenantConfigSubnav from "@components/tenantConfig/TenantConfigSubnav.component";
import AuthService from "@services/auth/auth.service";
import TimezoneSelect, { ITimezoneOption, type ITimezone } from 'react-timezone-select'
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css'
import { useBuildOrchestrationHistory, useProjectConfig, useSources } from "@stores/data.store";
import TenantConfigService from "@services/tenantConfig.service";
import TrackingService, { Events } from "@services/tracking.service";
import BuildOrchestrationORM from "@models/buildOrchestration";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import PliableLoader from "@components/loaders/PliableLoader.component";
import { timeAgo } from "@services/time.service";
import SourcesList from "@components/sources/SourcesList.component";



const TenantListSourcesPage = () => {
    const navigate = useNavigate();

    const {dataUpdatedAt, data: tenantConfig} = useProjectConfig();
    const {data: sources} = useSources();

    const tenant_name = AuthService.getInstance().getTenantName();


    return <PageStructure
        pageTitle="Build Schedule"
        breadcrumbs={[
            {
                title: 'Account',
            }
            
        ]}
    >
        
        <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3">
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Account Name
                            </Form.Label>
                            <Form.Control value={tenant_name as string} disabled={true} />
                            <Form.Text></Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>
                                Account ID
                            </Form.Label>
                            <Form.Control value={tenantConfig?.tenant_slug as string} disabled={true} />
                            <Form.Text>
                                <strong>cannot be changed</strong> after creation
                            </Form.Text>
                        </Form.Group>
                    </div>
                </PaneContent>
            </Pane>
        </PageSidebar>
        <PageContent>

            <Pane>
                <TenantConfigSubnav>
                </TenantConfigSubnav>
                <PaneContentWithSubnav>
                    <Pane>
                        <PaneContent>

                            <SourcesList />
                        </PaneContent>
                    </Pane>
                </PaneContentWithSubnav>
            </Pane>
        
            
           
        </PageContent>
               
    </PageStructure>
    
}


export default TenantListSourcesPage;