import PipelineNodeList from "@components/pipelineNodes/PipelineNodeList.component"
import PageStructure, { PageContentNoSidebar, Pane, PaneContent } from "@pages/PageStructure.component"

const PipelineNodesListPage = () => {
    return <PageStructure
        pageTitle="Pipeline Nodes"
    >
        <PageContentNoSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3">
                        <PipelineNodeList/>
                    </div>
                </PaneContent>
            </Pane>
        </PageContentNoSidebar>
    </PageStructure>
}

export default PipelineNodesListPage;