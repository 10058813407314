import { SourceRecordTypeCleaningStep } from "@models/source";
import { useCallback } from "react";

interface Props {
    cleaningSteps: SourceRecordTypeCleaningStep[];
    onRemove?: (idx: number) => void;
}

const CleaningStepsList = (props: Props) => {
    const removeCleaningStep = useCallback((idx: number) => {
        if (props.onRemove) {
            props.onRemove(idx);
        }
    }, [props.onRemove]);
    return <div>
        {props.cleaningSteps.map((cs, idx) => {
            return <div className="shadow-box p-2 mb-2">
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <p>
                        <span>
                            {cs.action == 'KEEP_THESE' && 'Keep'}
                            {cs.action == 'REMOVE_THESE' && 'Remove'}
                            {cs.action == 'UPDATE_COLUMN_VALUE' && <span>
                                Set <strong>{cs.columns![0]}</strong> to "<strong>{cs.column_value}</strong>" for
                            </span>}
                            {cs.action == 'REMOVE_DUPLICATES' && <span>
                                Remove duplicates (same value in <strong>{cs.columns?.join(', ')}</strong>) for 
                            </span>}
                            {cs.action == 'TRANSFORM_COLUMN' && <span>
                                Transform the <strong>{cs.columns ? cs.columns![0] : ''}</strong> column ({cs.transformer}) for
                            </span>}
                        </span>
                        {cs.filters.filters.length > 0 && (
                            <span> all records matching the following filters:</span>
                        )}
                        {cs.filters.filters.length == 0 && (
                            <span> all records</span>
                        )}
                        
                    
                    </p>
                    {props.onRemove && (
                        <div className="action-buttons text-end flex-1">
                            <button className="icon-button" onClick={() => removeCleaningStep(idx)}>
                                <i className="mdi mdi-delete"></i>
                            </button>
                        </div>
                    )}
                    
                </div>
                
                <ul>
                    {cs.filters.filters.map(f => {
                        return <li><strong>{f.column_ref.column_path}</strong> {f.comparator} <strong>"{f.value}"</strong></li>
                    })}
                </ul>
                
                
            </div>
        })}

    </div>
}

export default CleaningStepsList;