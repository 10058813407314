import BusinessObjectLineage from "@components/businessObjects/BusinessObjectLineage.component";
import BusinessObjectSubnav from "@components/businessObjects/BusinessObjectSubnav.component";
import SnowflakeLoader from "@components/loaders/SnowflakeLoader.component";
import PageTitle from "@components/pageTitle/PageTitle.component";
import SourceRecordTypeName from "@components/sources/SourceRecordTypeName.component";
import Danger from "@components/statusIndicators/Danger.component";
import { BusinessObject, BusinessObjectRelationship } from "@models/businessObject";
import { Pane, PaneContentWithSubnav } from "@pages/PageStructure.component";
import ApiService, { SingleRecordResponse } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import { timeAgo } from "@services/time.service";
import { useBusinessObjectData, useBusinessObjectRelationships, useBusinessObjects, useRecord, useRelationshipGraph, useSourceRecord, useSourceRecordTypes } from "@stores/data.store";
import { useEffect, useMemo, useState } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import styled from 'styled-components';

const LineageContainer = styled.div`
width: 100%;
height: 100%;
`





const BusinessObjectRecordLineage = () => {
    const businessObjects = useBusinessObjects();
    const { businessObjectId, recordId } = useParams();

    const record = useRecord(businessObjectId as string, recordId as string);
    // const relationshipGraph = useRelationshipGraph(businessObjectId as string, recordId as string);
    
    const businessObject = useMemo(() => {
        if (businessObjects.data) {
            return businessObjects.data.find(bo => bo.id === businessObjectId);
        }
    }, [businessObjectId, businessObjects.dataUpdatedAt]);


    return <Pane>
         <BusinessObjectSubnav
              businessObjectId={businessObjectId as string}
          />
          <PaneContentWithSubnav>
            {record.isLoading && <>
                <i className="mdi mdi-loading mdi-spin"></i> Calculating record lineage...
              </>}
              {!!record.error && <>
                <Danger>
                  Error loading lineage: {getErrorMessage(record.error as string)}
                </Danger>
              </>}
              {!record.isLoading && record.data && <>
                  <LineageContainer>
                      <BusinessObjectLineage
                          autoLayoutMethod="LINEAR"
                          businessObjectId={businessObjectId as string}
                          nodes={record.data.lineage.nodes}
                          edges={record.data.lineage.edges}
                      />
                  </LineageContainer>
              </>}
          </PaneContentWithSubnav>
    </Pane>
    
   
}

export default BusinessObjectRecordLineage;