import AsyncButton from "@components/button/AsyncButton.component";
import Dropdown, { MultiDropdown, Option } from "@components/form/Dropdown.component";

import DagDataLibrary from "@components/nav/DagDataLibrary.component";
import PipelineNodeDataTable from "@components/pipelineNodes/PIpelineNodeDataTable";
import PipelineNodeInfo from "@components/pipelineNodes/PipelineNodeInfo.component";
import PipelineNodeOutputNameEditor from "@components/pipelineNodes/PipelineNodeOutputNameEditor.component";
import PipelineNodeSubnav from "@components/pipelineNodes/PipelineNodeSubnav.component";
import { DraftModeRequired, DraftOnly } from "@components/project/DraftModeRequired.component";
import BuildOrchestrationORM, { BuildExecution, BuildOrchestration } from "@models/buildOrchestration";
import { PipelineNode, PipelineNodeField, PipelineNodeORM } from "@models/pipelineNode";
import { FilterConfig } from "@models/standardizationPipeline";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent, PaneContentInnerWithSidebar, PaneContentSidebar, PaneContentWithSubnav, PaneFooter } from "@pages/PageStructure.component";
import FilterConfigForm from "@pages/SourceRecordType/FilterConfigForm.component";
import { alert, requireConfirmation, showWaiting, closeWaiting} from "@services/alert/alert.service";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { invalidateEverything, invalidateMissionControlDataFlowData, invalidatePipelineNodes, useIsInDraftMode, usePipelineNode, useProjectConfig, useSourceColumns } from "@stores/data.store";
import useGlobalState from "@stores/global.state";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form, Modal, Dropdown as BSDropdown, ButtonGroup, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import Editor from "@monaco-editor/react";
import { Allotment, AllotmentHandle } from "allotment";
import PipelineOrchestration from "@components/orchestration/PipelineOrchestration.component";
import styled from 'styled-components';
import { Updater, useImmer } from "use-immer";
import { useRouteBlocker } from "@services/routing.service";
import ApiService, { ApiError, ListRecordsResponse } from "@services/api/api.service";
import PliableLoader from "@components/loaders/PliableLoader.component";
import produce from "immer";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import PipelineNodeColumnSelector, { PipelineNodeMultiColumnSelector } from "@components/pipelineNodes/PipelineNodeColumnSelector.component";
import PipelineNodeFieldsMapping from "@components/pipelineNodes/mapping/PipelineNodeFieldsMapping.component";
import Warning from "@components/statusIndicators/Warning.component";
import SaveButton from "@components/button/SaveButton.component";
import PipelineNodeNotFound from "@components/pipelineNodes/PipelineNodeNotFound.component";
import { isValidUpstreamNode } from "@services/modeling.service";
import PipelineNodeReportEditor from "@components/pipelineNodes/PipelineNodeReportEditor";

const DataPaneHeader = styled.div`
background: #2E2E2E;
color: white;
height: 30px;
line-height: 30px;
padding: 0px 1rem;
display: flex;
flex-direction: row;
cursor: pointer;

h2 {
    margin: 0px;
    padding: 0px;
    color: white !important;
    line-height: 30px;
    font-weight: 200;
    flex: 1;
}

button {
    border: none;
    background: none;
    padding: 0px;
    margin: 0px;
    color: white;
    font-size: 24px;
    font-weight: bold;
    color: #ccc;

    &:hover {
        color: white;
        cursor: pointer;
    }
}
`

interface NodeConfigProps {
    node: PipelineNode;
    onChange: Updater<PipelineNode>;
}

const ViewConfig = (props: NodeConfigProps) => {
    const inDraftMode = useIsInDraftMode();
    const upstreamNode = usePipelineNode(props.node.upstream_node_ids[0]);
    return <div className="p-3">
        <Form.Group className="mb-2">
            <Form.Label>Upstream Node</Form.Label>
            <PipelineNodeSelector
                selectedId={props.node.upstream_node_ids.length > 0 ? props.node.upstream_node_ids[0] : ''}
                onSelect={(selected: PipelineNode | undefined) => {
                    props.onChange(draft => {
                        if (selected) {
                            draft.upstream_node_ids = [selected.id as string]
                        } else {
                            draft.upstream_node_ids = [];
                        }
                        draft.fields = [];
                    });
                }}
                
            />

        </Form.Group>
        {upstreamNode.isLoading && <i className="mdi mdi-loading mdi-spin"></i>}
        {upstreamNode.data && <>
            <Form.Group className="mb-2">
                <Form.Label>Select Column(s)</Form.Label>
                <PipelineNodeMultiColumnSelector
                    pipelineNodeId={upstreamNode.data.id as string}
                    onSelect={(fieldIds: string[]) => {
                        props.onChange(draft => {
                            draft.upstream_column_ids = fieldIds;
                        })
                    }}
                    selectedIds={props.node.upstream_column_ids || []}
                    disabled={!inDraftMode}
                />

            </Form.Group>
            {upstreamNode.data.include_in_snapshots && <Form.Group className="mb-2">
                <Form.Check
                    checked={props.node.include_historical_data}
                    label="Include historical snapshot data"
                    onChange={() => {
                        props.onChange(draft => {
                            draft.include_historical_data = !draft.include_historical_data;
                        })
                    }}
                />
                {inDraftMode && <Form.Text>
                    Note that snapshots do not run on a schedule in develop mode, so you may have gaps in your data. In production mode a snapshot is taken after every scheduled build.
                </Form.Text>}
            </Form.Group>}
            <div className="card">
                <div className="card-body">
                    <Form.Group>
                        <Form.Label>Filters</Form.Label>
                        <FilterConfigForm
                            config={props.node.filters || {
                                filters: [],
                                logic_gate: 'AND',
                            }}
                            columnOptions={upstreamNode.data.fields}
                            onChange={(newFilters) => {
                                props.onChange(draft => {
                                    draft.filters = newFilters;
                                })
                            }}
                        />
                    </Form.Group>
                </div>
            </div>
            
        </>}
    </div>
}

const DateDimConfig = (props: NodeConfigProps) => {
    return <div className="p-3">
        {/* <Form.Group className="mb-3">
            <Form.Label>Fiscal Year Start</Form.Label>
            <div className="row">
                <div className="col-6 pe-1">
                    <Form.Group>
                        <Form.Label className="small">Month</Form.Label>
                        <select className="form-control" value={props.node.special_node_args?.fiscal_year_month} onChange={(e) => {
                            props.onChange(draft => {
                                if (!draft.special_node_args) {
                                    draft.special_node_args = {};
                                }
                                draft.special_node_args.fiscal_year_month = e.target.value;
                            })
                        }}>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                    </Form.Group>
                </div>
                <div className="col-6 ps-1">
                    <Form.Group>
                        <Form.Label className="small">Day</Form.Label>
                        <input type="number" className="form-control" min="1" max="31" value={props.node.special_node_args?.fiscal_year_day} onChange={(e) => {
                            props.onChange(draft => {
                                if (!draft.special_node_args) {
                                    draft.special_node_args = {};
                                }
                                draft.special_node_args.fiscal_year_day = e.target.value;
                            })
                        }}/>
                        
                    </Form.Group>
                </div>
            </div>
            
        </Form.Group> */}

        <Form.Group className="mb-3">
            <Form.Label>Date Range</Form.Label>
            <div className="row">
                <div className="col-6 pe-1">
                    <Form.Group>
                        <Form.Label className="small">Start</Form.Label>
                        <Form.Control type="date" value={props.node.special_node_args?.start_date} onChange={(e) => {
                            props.onChange(draft => {
                                if (!draft.special_node_args) {
                                    draft.special_node_args = {};
                                }
                                draft.special_node_args.start_date = e.target.value;
                            })
                        }} />
                    </Form.Group>
                    
                </div>
                <div className="col-6 ps-1">
                    <Form.Group>
                        <Form.Label className="small">End</Form.Label>
                        <Form.Control type="date" value={props.node.special_node_args?.end_date} onChange={(e) => {
                            props.onChange(draft => {
                                if (!draft.special_node_args) {
                                    draft.special_node_args = {};
                                }
                                draft.special_node_args.end_date = e.target.value;
                            })
                        }} />
                    </Form.Group>
                </div>
            </div>
            <Form.Text>Defaults to <code>1/1/2020</code> - <code>TODAY</code> if empty</Form.Text>

            
        </Form.Group>
    </div>
}

const SplitConfig = (props: NodeConfigProps) => {
    const inDraftMode = useIsInDraftMode();
    return <>
        <div className="p-3">
            <Form.Group className="mb-3">
                <Form.Label>Select source model</Form.Label>
                <PipelineNodeSelector
                    disabled={!inDraftMode}
                    selectedId={props.node.upstream_node_ids.length > 0 ? props.node.upstream_node_ids[0] : ''}
                    onSelect={(selected: PipelineNode | undefined) => {
                        if (selected) {
                            props.onChange(draft => {
                                draft.upstream_node_ids = [selected.id as string];
                            })
                        } else {
                            props.onChange(draft => {
                                draft.upstream_node_ids = [];
                            })
                        }
                    }}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Select source model column to split on</Form.Label>
                <PipelineNodeColumnSelector
                    disabled={!inDraftMode}
                    pipelineNodeId={props.node.upstream_node_ids.length > 0 ? props.node.upstream_node_ids[0] : ''}
                    selectedId={props.node.special_node_args?.column_id}
                    onSelect={(selected: string) => {
                        props.onChange(draft => {
                            if (!draft.special_node_args) {
                                draft.special_node_args = {};
                            }
                            draft.special_node_args.column_id = selected;
                        });
                    }}
                />

            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>Column Format</Form.Label>
                <Dropdown
                    disabled={!inDraftMode}
                    selected={props.node.special_node_args?.column_format}
                    onChange={(newVal: string) => {
                        props.onChange(draft => {
                            if (!draft.special_node_args) {
                                draft.special_node_args = {};
                            }
                            draft.special_node_args.column_format = newVal;
                        });
                    }}
                    options={[
                        {
                            label: 'Delimited String',
                            value: 'DELIMITED_STRING',
                        }, {
                            label: 'JSON Array',
                            value: 'ARRAY',
                        }
                    ]}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Delimiter</Form.Label>
                <Form.Control disabled={!inDraftMode || (props.node.special_node_args?.column_format != 'DELIMITED_STRING')} value={props.node.special_node_args?.delimiter} onChange={(e) => {
                    props.onChange(draft => {
                        draft.special_node_args!.delimiter = e.target.value;
                    })
                }}
                />
            </Form.Group>
        </div>
    </>
}

const SourceTableConfig = (props: NodeConfigProps) => {
    const [compositeKeyOptions, setCompositeKeyOptions] = useState<Option[]>([]);

    const sourceColumns = useSourceColumns(props.node.id as string);

    const inDraftMode = useIsInDraftMode();

    useEffect(() => {
        if (sourceColumns.data) {
            setCompositeKeyOptions(sourceColumns.data.map(c => {
                return {
                    value: c.name,
                    description: c.type,
                    label: c.name,
                }
            }))

            const selection = sourceColumns.data.filter(tc => tc.primary_key === 'Y').map(tc => tc.name);


            if (!props.node.composite_key) {
                props.onChange(draft => {
                    draft.composite_key = selection;
                });
            }

            const fivetranSynced = sourceColumns.data.find(c => c.name == '_FIVETRAN_SYNCED');

            if (!props.node.last_modified_column && !!fivetranSynced) {
                props.onChange(draft => {
                    draft.last_modified_column = '_FIVETRAN_SYNCED';
                })
            }
        }
    }, [sourceColumns.dataUpdatedAt, props.node.composite_key, props.onChange]);

    if (sourceColumns.isLoading) {
        return <PliableLoader/>
    }
    return <>
        <div className="p-3">
            <h2>Configure Node: Database Table</h2>
            <p>Pulls in data from a table or view in your Snowflake data warehouse and adds a guaranteed unique ID column.</p>
            <div className="shadow-box p-3">
                <Form.Group className="mb-3">
                    <Form.Label>Primary Key</Form.Label>
                    <MultiDropdown
                        disabled={!inDraftMode}
                        selected={props.node.composite_key ? props.node.composite_key : []}
                        options={compositeKeyOptions}
                        onChange={(newVal: string[]) => props.onChange(draft => {
                            draft.composite_key = newVal;
                        })}
                    />
                    <Form.Text>Pliable uses this to automatically assign a unique ID to each record. If multiple records have the same primary key, all but the most recently updated record will be removed.</Form.Text>
                </Form.Group>

                <Form.Group className="">
                    <Form.Label>Last Modified Column</Form.Label>
                    <Dropdown
                        disabled={!inDraftMode}
                        selected={props.node.last_modified_column}
                        options={compositeKeyOptions}
                        onChange={(newVal: string) => props.onChange(draft => {
                            draft.last_modified_column = newVal;
                        })}
                    />
                    <Form.Text>Provide a <code>DATE</code> or <code>DATETIME</code> column here so Pliable can determine which records have changed. Without this, Pliable will reprocess the entire table whenever you run a build.</Form.Text>
                </Form.Group>
            </div>
            
        </div>
        
    </>

}

const CustomSQLConfig = (props: NodeConfigProps) => {
    const editorRef = useRef<any>();
    const inDraftMode = useIsInDraftMode();
    const [showPliableColumnModal, setShowPliableColumnModel] = useState(false);
    const [completionDisposable, setCompletionDisposable] = useState<any>();

    useEffect(() => {
        if (props.node.id && !props.node.ignore_pliable_columns_in_custom_sql && !!props.node.needs_pliable_columns_in_custom_sql && inDraftMode) {
           
            setShowPliableColumnModel(true);
        }
    }, [props.node.ignore_pliable_columns_in_custom_sql, props.node.needs_pliable_columns_in_custom_sql, inDraftMode])
    
    const editorDidMount = useCallback((editor: any, monacoParam: any) => {
        editorRef.current = editor;

        // setCompletionDisposable(
        //     monacoParam.languages.registerCompletionItemProvider('*', {
        //         provideCompletionItems: function(model: any, position: any) {

        //             // add BO objects
        //             const suggestions: any[] = props.otherFieldsForAutocomplete.map(f => {
        //                 return {
        //                     label: f,
        //                     kind: monacoParam.languages.CompletionItemKind.Variable,
        //                     insertText: `"${f}"`

        //                 }
        //             })
    

        //             return {
        //                 suggestions: suggestions
        //             };
        //         }
        //     })
        // );
    }, []);

    const addColumns = useCallback(() => {
        props.onChange(draft => {

            draft.custom_sql = 'with original_model as (\n' + draft.custom_sql + `\n)\n\nselect *, ('plb:::m:${draft.name}::r:' || sha1(hash(*)::string)) as _plb_uuid, current_timestamp() as _plb_loaded_at from original_model`;
        });
        setShowPliableColumnModel(false);
    }, [props.onChange]);

    const ignore = useCallback(() => {
        props.onChange(draft => {
            draft.ignore_pliable_columns_in_custom_sql = true;
        });
        setShowPliableColumnModel(false);
    }, [props.onChange]);
    return <>
        <Modal show={showPliableColumnModal} onHide={() => setShowPliableColumnModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                This node needs a unique ID column called <code>_plb_uuid</code> and a timestamp column called <code>_plb_loaded_at</code> in order to be used in other Pliable models. Do you want to automatically add those columns?
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-light me-1" onClick={ignore}>Ignore</button>
                <button className="btn btn-pliable" onClick={addColumns}>Add Columns</button>
            </Modal.Footer>
        </Modal>
        <Editor
            onChange={(value) => props.onChange(draft => {
                draft.custom_sql = value ? value as string : '';
            })}
            height="100%"
            defaultLanguage="sql"
            options={{
                readOnly: !inDraftMode,
                minimap: { enabled: false },
            }}
            value={props.node.custom_sql}
            onMount={(editor, monaco) => editorDidMount(editor, monaco)}
        />
    </>
}

const PipelineNodeConfigurationPage = () => {
    const { pipelineNodeId } = useParams();
    const pipelineNode = usePipelineNode(pipelineNodeId as string);

    const allotmentRef = useRef<AllotmentHandle>(null);
    const [dataViewerIsOpen, setDataViewerIsOpen] = useState(false);
    const isInDraft = useIsInDraftMode();

    const [fields, setFields] = useState<PipelineNodeField[]>([]);
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    const [showSql, setShowSql] = useState(false);


    const [editingNode, setEditingNode] = useImmer<PipelineNode>({
        id: null,
        name: '',
        description: '',
        table_name: '',
        upstream_node_ids: [],
        fields: [],
        node_type: '',
        flat_file: false
    });
    
    const onEditNodeChange = useCallback((n: any) => {
        setEditingNode(n);

        // Weird change, haven't figured out why this was happening,
        // but there was an infinite loop in dev that this somehow fixed.
        setTimeout(() => {
            setPageDirty(true);
        })
        
    }, [pipelineNode.dataUpdatedAt]);


    useEffect(() => {
        if (pipelineNode.data) {
            setEditingNode(pipelineNode.data);
            setPageDirty(false);
        }
    }, [pipelineNode.dataUpdatedAt]);

    const allotmentResized = useDebouncedCallback((newSizes: number[]) => {
        if (newSizes[1] === 30) {
            setDataViewerIsOpen(false);
        } else {
            setDataViewerIsOpen(true);
        }
    }, 250);

    const openDataViewer = useCallback(() => {
        if (!allotmentRef.current) {
            return;
        }
        allotmentRef.current.resize([1000, 1000]);
    }, [allotmentRef]);

    const toggleDataViewer = useCallback(() => {
        if (!allotmentRef.current) {
            return;
        }

        if (dataViewerIsOpen) {
            // There's a resize issue with the editor so we need to switch back to table mode first
            setShowSql(false);
            window.requestAnimationFrame(() => {
                allotmentRef.current!.reset();

            })
        } else {
            allotmentRef.current!.resize([1000, 1000]);

        }
        
    }, [allotmentRef, dataViewerIsOpen]);

    const [building, setBuilding] = useState(false);
    const [activeOrchestration, setActiveOrchestration] = useState<BuildOrchestration|undefined>(undefined);


    const checkActiveOrchestration = useCallback(async () => {
        if (!activeOrchestration) {
            return;
        }

        const newStatuses: {
            [key: string]: BuildExecution
        } = {};
    
        Object.keys(activeOrchestration.build_executions).map(k => {
            const be = activeOrchestration.build_executions[k];
            newStatuses[`${be.object_type}:${be.object_id}`] = be;
        });

        
        if (!['ERROR', 'COMPLETE', 'IN_REVIEW'].includes(activeOrchestration.status)) {
            
            setTimeout(async () => {
                // Keep polling, which should then re-run this effect, and so on.
                const updatedOrch = await BuildOrchestrationORM.findById(activeOrchestration.id as string);
                setActiveOrchestration(updatedOrch);
            }, 1000);
        } else if (activeOrchestration.status == 'COMPLETE') {
            setActiveOrchestration(undefined);
            toast('success', 'Success', 'Models have been successfully updated.');
            invalidateEverything();
            closeWaiting()
            setBuilding(false);
        } else if (activeOrchestration.status == 'ERROR') {
            toast('danger', 'Error', getErrorMessage(activeOrchestration.error));
            closeWaiting()
            setBuilding(false);
        }

    }, [activeOrchestration]);

    useEffect(() => {
        if (activeOrchestration) {
            checkActiveOrchestration();
        }
    }, [activeOrchestration]);

    const [saving, setSaving] = useState(false);
    const save = useCallback(async () => {
        setSaving(true);
        try {
            await PipelineNodeORM.save(editingNode);

            invalidatePipelineNodes();
            invalidateMissionControlDataFlowData();
            
            setPageDirty(false);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
        
    }, [pipelineNode.dataUpdatedAt, editingNode]);

    const { pageDirty, setPageDirty } = useRouteBlocker(save);

    const onChange = useCallback((attr: keyof PipelineNode, newVal: any) => {
        console.log('CHANGE', attr, newVal, 'current fields', editingNode.fields);
        setEditingNode(draft => {
            // @ts-ignore
            draft[attr] = newVal;
        })
        // setEditingNode(draft => {
        //     // @ts-ignore
        //     draft[attr] = newVal;
        // });
    }, [editingNode, setEditingNode, setPageDirty]);

    const runBuild = useCallback(async (selector: 'THIS' | 'UPSTREAM' | 'DOWNSTREAM' | 'ALL') => {
        if (!pipelineNode.data) {
            return;
        }
        try {
            setBuilding(true);
            showWaiting({message: 'Building...'})
            openDataViewer();
            let selectorValue: string = '';
            switch (selector) {
                case 'THIS':
                    selectorValue = pipelineNode.data.name;
                    break;
                case 'UPSTREAM':
                    selectorValue = '+' + pipelineNode.data.name;
                    break;
                case 'DOWNSTREAM':
                    selectorValue = pipelineNode.data.name + '+';
                    break;
                case 'ALL':
                    selectorValue = '+' + pipelineNode.data.name + '+'
                    break;
            }
            const orchestration = await BuildOrchestrationORM.buildWithSelector(selectorValue);

            if (orchestration.status == 'COMPLETE') {
                toast('info', 'Everything up to date', 'No need to rebuild.');
                setBuilding(false)
            }
            setActiveOrchestration(orchestration);


        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
    }, [pipelineNode.dataUpdatedAt, openDataViewer]);

    const saveAndBuild = useCallback(async (selector: 'THIS' | 'UPSTREAM' | 'DOWNSTREAM' | 'ALL') => {
        setShowSql(false);
        if (isInDraft) {
            await save();
        }
        await runBuild(selector);
    }, [runBuild, save, isInDraft]);



    useEffect(() => {
        // Switch back to table view if they change the node ID
        setShowSql(false);
        setActiveOrchestration(undefined);
    }, [pipelineNodeId]);



    if (pipelineNode.status == 'error' && (pipelineNode.error as ApiError).code == 404) {
        return <PipelineNodeNotFound/>
    }
    return <PageStructure
        pageTitle={pipelineNode.data ? pipelineNode.data.name : ''}
        breadcrumbs={[
            {
                title: 'Pipeline Node',
            }
            
        ]}
    >
        <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3">
                        <PipelineNodeInfo pipelineNodeId={pipelineNodeId as string}/>
                    </div>
                    <div className="p-3 border-top">
                        <DagDataLibrary activeNodeId={`PipelineNode:${pipelineNodeId}`}/>
                    </div>
                    
                    
                </PaneContent>
            </Pane>
            
        </PageSidebar>
        <PageContent>
            
                    <Pane>
                        <PipelineNodeSubnav
                            pipelineNodeId={pipelineNodeId as string}
                        >
                            <ButtonGroup>
                                <Button disabled={building} title="Build this node + upstream nodes" variant="pliable" onClick={() => saveAndBuild('UPSTREAM')}>Build</Button>
                                <BSDropdown>
                                
                                
                                <BSDropdown.Toggle variant="pliable" disabled={building} style={{padding: '0em 1em'}}>
                                </BSDropdown.Toggle>

                                <BSDropdown.Menu className="font-13 text-end">
                                    <BSDropdown.Item onClick={() => saveAndBuild('THIS')}>Just this node</BSDropdown.Item>
                                    <BSDropdown.Item onClick={() => saveAndBuild('UPSTREAM')}>This node + upstream nodes</BSDropdown.Item>
                                    <BSDropdown.Item onClick={() => saveAndBuild('DOWNSTREAM')}>This node + downstream nodes</BSDropdown.Item>
                                    <BSDropdown.Item onClick={() => saveAndBuild('ALL')}>This node + upstream & downstream nodes</BSDropdown.Item>

                                </BSDropdown.Menu>
                                </BSDropdown>
                            </ButtonGroup>
    
                            
                                {/* <AsyncButton
                                    onClick={saveAndBuild}
                                    loading={building}
                                    text="Save & Build"
                                    variant="pliable"
                                /> */}
                                <DraftOnly>
                                    <SaveButton
                                        className="me-1"
                                        onClick={save}
                                    />
                                </DraftOnly>
                                
                            
                        </PipelineNodeSubnav>
                        <PaneContentWithSubnav>
                            <Allotment vertical ref={allotmentRef} onChange={allotmentResized}>
                                <Allotment.Pane minSize={200}>
                                    {pipelineNode.data && pipelineNode.data.node_type == 'CUSTOM' && (
                                        <>
                                            <CustomSQLConfig node={editingNode} onChange={onEditNodeChange}/>
                                        </>
                                    )}

                                    {pipelineNode.data && pipelineNode.data.node_type == 'SOURCE' && (
                                        <>
                                            <SourceTableConfig node={editingNode} onChange={onEditNodeChange}/>
                                        </>
                                    )}

                                    {pipelineNode.data && pipelineNode.data.node_type == 'STACK' && (
                                        <>
                                            <PipelineNodeFieldsMapping
                                                node={editingNode}
                                                onChange={onEditNodeChange}
                                            />
                                        </>   
                                    )}
                                    {pipelineNode.data && pipelineNode.data.node_type == 'SEED' && (
                                        <>
                                            <div className="p-3">
                                                This is a read-only seed file.
                                            </div>
                                        </>
                                    )}

                                    {pipelineNode.data && ['STAGING_TABLE', 'BUSINESS_OBJECT'].includes(pipelineNode.data.node_type) && (
                                        <>
                                            {/* Support for legacy without migration since otherwise we'd have to interpret what each one meant. */}
                                            <PipelineNodeFieldsMapping
                                                node={editingNode}
                                                onChange={onEditNodeChange}
                                                enableMerge
                                            />
                                        </>   
                                    )}

                                    {pipelineNode.data && pipelineNode.data.node_type == 'MERGE' && (
                                        <>
                                            <PipelineNodeFieldsMapping
                                                node={editingNode}
                                                onChange={onEditNodeChange}
                                                mergeTextMode="MERGE"
                                                enableMerge
                                            />
                                        </>   
                                    )}
                                    {pipelineNode.data && pipelineNode.data.node_type == 'SUMMARIZE' && (
                                        <>
                                            <PipelineNodeFieldsMapping
                                                node={editingNode}
                                                onChange={onEditNodeChange}
                                                enableMerge
                                                limitToSingleSource
                                                mergeTextMode='GROUP_BY'
                                            />
                                        </>   
                                    )}
                                    {pipelineNode.data && pipelineNode.data.node_type == 'IDENTIFY' && (
                                        <>
                                            <PipelineNodeFieldsMapping
                                                node={editingNode}
                                                onChange={onEditNodeChange}
                                                enableMerge
                                                limitToSingleSource
                                                mergeTextMode="IDENTIFY"
                                                requireMerge
                                            />
                                        </>   
                                    )}
                                    {pipelineNode.data && pipelineNode.data.node_type == 'DIMENSION' && (
                                        <>
                                            <PipelineNodeFieldsMapping
                                                node={editingNode}
                                                onChange={onEditNodeChange}
                                                enableMerge
                                            />
                                        </>   
                                    )}
                                    {pipelineNode.data && pipelineNode.data.node_type == 'FACT' && (
                                        <>
                                            <PipelineNodeFieldsMapping
                                                node={editingNode}
                                                onChange={onEditNodeChange}
                                                enableMerge
                                            />
                                        </>   
                                    )}
                                    {pipelineNode.data && pipelineNode.data.node_type == 'SPLIT' && (
                                        <>
                                            <SplitConfig
                                                node={editingNode}
                                                onChange={onEditNodeChange}
                                            />
                                        </>   
                                    )}
                                    {pipelineNode.data && pipelineNode.data.node_type == 'DATE_DIMENSION' && (
                                        <>
                                            <DateDimConfig
                                                node={editingNode}
                                                onChange={onEditNodeChange}
                                            />
                                        </>   
                                    )}
                                    {pipelineNode.data && pipelineNode.data.node_type == 'VIEW' && (
                                        <>
                                            <ViewConfig
                                                node={editingNode}
                                                onChange={onEditNodeChange}
                                            />
                                        </>   
                                    )}
                                    {pipelineNode.data && pipelineNode.data.node_type == 'DESTINATION' && (
                                        <>
                                            <PipelineNodeFieldsMapping
                                                node={editingNode}
                                                onChange={onEditNodeChange}
                                            />
                                        </>   
                                    )}
                                    {pipelineNode.data && pipelineNode.data.node_type == 'DATAMART' && (
                                        <>
                                            <PipelineNodeFieldsMapping
                                                node={editingNode}
                                                onChange={onEditNodeChange}
                                                includeRelationships
                                                enableMerge
                                                requireMerge
                                                mergeTextMode='GROUP_BY'
                                                limitToSingleFieldMap
                                                
                                            />
                                            
                                        </>   
                                    )}
                                    {pipelineNode.data && pipelineNode.data.node_type == 'REPORT' && (
                                        <>
                                            <PipelineNodeReportEditor
                                                node={editingNode}
                                                onChange={onEditNodeChange}
                                            />
                                            
                                        </>   
                                    )}
                                    
                                </Allotment.Pane>
                                <Allotment.Pane snap={false} minSize={30} preferredSize={30}>
                                    <DataPaneHeader onClick={() => toggleDataViewer()}>
                                            <h2>Data Viewer</h2>
                                            {dataViewerIsOpen && <>
                                                <button onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setShowSql(!showSql)
                                                }} className="me-3">
                                                    {showSql && <i className="mdi mdi-table"/>}
                                                    {!showSql && <i className="mdi mdi-code-braces"/>}
                                                </button>
                                            </>}
                                            
                                            <button >
                                                {dataViewerIsOpen && <i className="mdi mdi-chevron-down"></i>}
                                                {!dataViewerIsOpen && <i className="mdi mdi-chevron-up"></i>}
                                            </button>
                                        </DataPaneHeader>
                                        <Pane>
                                        <PaneContent>
                                            {showSql && <>
                                                <Editor
                                                    height="100%"
                                                    defaultLanguage="sql"
                                                    options={{
                                                        readOnly: true,
                                                        minimap: { enabled: false },
                                                    }}
                                                    value={editingNode.cached_generated_sql}
                                                />
                                            </>}
                                            {!showSql && <>
                                                {activeOrchestration && <div className="p-3">
                                                
                                                    <PipelineOrchestration orchestration={activeOrchestration}/>
                                                </div>}
                                                {building && !activeOrchestration && <div>
                                                    Rebuilding...    
                                                </div>}
                                                {!building && !activeOrchestration && (
                                                    <PipelineNodeDataTable
                                                        pipelineNodeId={pipelineNodeId as string}
                                                    />
                                                )}
                                            </>}
                                            
                                        </PaneContent>
                                        
                                        
                                            
                                        </Pane>
                                        
                                    </Allotment.Pane>
                            </Allotment>
                            
                            
                            
                            
                        </PaneContentWithSubnav>
                
                    </Pane>
                
            
        </PageContent>
    </PageStructure>
}

export default PipelineNodeConfigurationPage;