import { useCallback } from 'react';
import styled from 'styled-components';

interface Props {
    onClick?: () => void;
}

const Styles = styled.div`

background: linear-gradient(0deg, var(--ct-danger), var(--ct-danger)),
linear-gradient(180deg, var(--ct-danger) 0%, #EDD500 100%);

border: 1px solid var(--ct-danger);

box-shadow: 0px 2px 3px 0px #0000001A;
width: 26px;
height: 26px;
border-radius: 13px;
color: white;
text-align: center;
transition: scale .15s ease-in-out, opacity .15s ease-in-out;
line-height: 26px;
text-align: center;
font-weight: bold;
&:hover {
    cursor: pointer;
    scale: 1.5;
}
`

const DangerStatusIndicator = (props: Props) => {

    const onClick = useCallback(() => {
        if (props.onClick) {
            props.onClick();
        }
    }, [props.onClick]);
    return <Styles onClick={() => onClick()}>
        !
    </Styles>
}

export default DangerStatusIndicator