import { useNotifications } from '@models/notification';
import { Pane, PaneContent } from '@pages/PageStructure.component';
import { timeAgo } from '@services/time.service';
import styled from 'styled-components';
import PliableLoader from './loaders/PliableLoader.component';
import PipelineNodeName from './pipelineNodes/PipelineNodeName.component';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SuccessAlert from './statusIndicators/SuccessAlert.component';
import { useNodesThatNeedBuilding } from '@models/pipelineNode';

interface MessageProps {
    title: string;
    type: 'BUILD_ERROR' | 'BUILD_NEEDED';
    time: Date;
    description: string;
    objectType?: string;
    objectId?: string;
}

const MessageStyles = styled.div`
font-family: "Poppins";
color: black;
padding: 1rem;
overflow: hidden;

&:hover {
    cursor: pointer;
    background-color: #FF9F001A;

}
.header {
    display: flex;
    align-items: center;

    .icon {
        width: 40px;
    }

    .title {
        flex: 1;
        font-weight: bold;
    }

    .time {
        text-align: right;
    }

    margin-bottom: 10px;
}

.description {
    color: var(--ct-body-color);
}

&:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

&:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

&.warning {
    div.icon {
        color: rgb(238, 205, 76);
    }
}

&.BUILD_ERROR {
    div.icon {
        color: var(--ct-danger);
    }
}

&.BUILD_NEEDED {
    div.icon {
        color: var(--pliable-yellow);
    }
}

&.info {
    div.icon {
        color: var(--ct-info);
    }
}

`

/**
 * Message types:
 * 
 * - Generic "X nodes need building"
 * - Build error - this particular node failed for this reason
 * - Generic "this node has no sources"
 * - Generic "this relationship has no lookup parameters"
 * 
 */

const Message = (props: MessageProps) => {
    const navigate = useNavigate();
    const onClick = useCallback(() => {
        if (props.type === 'BUILD_ERROR') {
            navigate(`/node/${props.objectId}/config`);
        }
    }, [props.objectId, props.objectType, props.type]);
    return <MessageStyles className={props.type} onClick={onClick}>
        <div className="header">
            <div className="icon">
                {props.type == "BUILD_ERROR" && <i className="mdi mdi-close-circle"></i>}
                {props.type == "BUILD_NEEDED" && <i className="mdi mdi-hammer-screwdriver"></i>}
            </div>
            <div className="title">
                {props.title}
            </div>
            <div className="time">
                {timeAgo(props.time)}
            </div>

        </div>
        <div className="description">
            {props.type == 'BUILD_ERROR' && <>
                Node <strong><PipelineNodeName pipelineNodeId={props.objectId}/></strong> failed to build.
            </>}
            {props.type == 'BUILD_NEEDED' && <>
                Hit "build all" at the top-right of your screen to run a build.
            </>}
        </div>
    </MessageStyles>
}

interface Props {

}

const Inbox = (props: Props) => {
    const notifications = useNotifications();
    const nodesThatNeedBuilding = useNodesThatNeedBuilding();
    return <div className="shadow-box">
                {notifications.isLoading && <PliableLoader/>}
                {!notifications.isLoading && !nodesThatNeedBuilding.isLoading && !!notifications.data && !! nodesThatNeedBuilding.data && notifications.data.length == 0 && nodesThatNeedBuilding.data.length == 0 && <>
                    <div className="m-2">
                        <SuccessAlert>
                            Looking good
                        </SuccessAlert>
                    </div>
                    
                </>}

                {nodesThatNeedBuilding.data && nodesThatNeedBuilding.data.length > 0 && <>
                    <Message
                        title={`${nodesThatNeedBuilding.data.length} nodes need to be built`}
                        description=""
                        time={new Date()}
                        type="BUILD_NEEDED"
                    />
                </>}
                {notifications.data && <>
                    {notifications.data.map(n => {
                        return <Message
                            title={n.title}
                            description={n.description}
                            time={n.timestamp}
                            type={n.type}
                            objectId={n.object_id}
                            objectType={n.object_type}
                        />
                    })}
                </>}
               
        
    </div>
}

export default Inbox;