import { useQuery } from "@services/url.service";
import { useCallback } from "react";
import { Offcanvas } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import BusinessObjectDrawer from "./BusinessObjectDrawer";
import SourceRecordTypeDrawer from "./SourceRecordTypeDrawer.component";
import styled from 'styled-components';

const DrawerStyles = styled.div`
.offcanvas-body {
    overflow-y: hidden;

    .tab-content {
        overflow-y: hidden;
        height: calc(100vh - 50px - 112px - 40px)
    }
}
`

function getDrawerUrl(currentPath: string, drawer: string, objectId: string, tab: string = '') {
    if (!drawer) {
        return currentPath;
    }
    return currentPath + `?drawer=${drawer}&drawer_id=${objectId}&drawer_tab=${tab}`;
}

export function useNavigateToDrawer() {
    const navigate = useNavigate();
    const location = useLocation();

    return useCallback((drawer: string, objectId: string, tab: string = '') => {
        const url = getDrawerUrl(location.pathname, drawer, objectId, tab);
        navigate(url);
    }, [navigate, location]);
}

export function useClearDrawer() {
    const navigateToDrawer = useNavigateToDrawer();
    return useCallback(() => {
        navigateToDrawer('', '', '');
    }, [navigateToDrawer]);
}


const DrawerManager = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();

    const drawer = query.get('drawer');
    const drawerId = query.get('drawer_id');
    const drawerTab = query.get('drawer_tab');

    const navigateAwayFromDrawer = useCallback(() => {
        navigate(location.pathname);
    }, [location]);

    const showInMissionControl = useCallback(() => {
        let nodeId: string = '';
        if (drawer == 'bo') {
            nodeId = 'BusinessObject:' + drawerId;
        } else if (drawer == 'src') {
            nodeId = 'SourceRecordType:' + drawerId;
        }

        if (nodeId) {
            navigate('/?focusNodeId=' + nodeId);
        }
    }, [navigate, drawer, drawerId]);


    return <Offcanvas className="drawer" placement="end" show={!!drawer} onHide={navigateAwayFromDrawer}>
            <Offcanvas.Header closeButton>
                
                <div className="text-end flex-1 me-3">
                    {drawer && ['bo', 'src'].includes(drawer as string) && (
                        <button className="btn btn-sm btn-default" onClick={() => showInMissionControl()}>
                            <i className="mdi mdi-eye"></i> Show in Mission Control
                            
                        </button>
                    )}
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {drawer == 'bo' && !!drawerId && (
                    <BusinessObjectDrawer
                        activeTab={drawerTab || 'data'}
                        businessObjectId={drawerId as string}
                    />
                )}
                {drawer == 'src' && !!drawerId && (
                    <SourceRecordTypeDrawer
                        sourceRecordTypeId={drawerId as string}
                    />
                )}

            </Offcanvas.Body>
        </Offcanvas>
}

export default DrawerManager;