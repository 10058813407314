import { Form, Spinner } from "react-bootstrap";
import { useCallback, useState, useEffect } from "react";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import AuthService from "@services/auth/auth.service";
import ApiService, { JobEnqueueResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";

const CreateNewAccount = () => {
    const [saving, setSaving] = useState(false);
    const [superUser, setSuperUser] = useState(false);

    const [accountName, setAccountName] = useState('');
    const [adminFullName, setAdminFullName] = useState('');
    const [adminEmail, setAdminEmail] = useState('');

    const [creationResult, setCreationResult] = useState(null);

    useEffect(() => {
        setSuperUser(AuthService.getInstance().authState?.user?.superUser === true);
    }, []);

    const createAccount = useCallback(async () => {
        setSaving(true);
        try {
            const job = await ApiService.getInstance().request('POST', `/tenant/create`, {
                name: accountName,
                admin_fullname: adminFullName,
                admin_email: adminEmail
            }) as JobEnqueueResponse;
            const jobResult = await BackgroundService.getInstance().waitForJob(job.job_id);
            setCreationResult(jobResult);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [accountName, adminFullName, adminEmail]);

    if (superUser === false) {
        return <div>403- Unauthorized</div>
    }

    if(creationResult) {
        return (
            <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-6 mt-3">
                    Account acitvation sent to {creationResult['admin_email']}
                </div>
            </div>
            </div>
        )
    }

    return (

        <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-6 mt-3">
                    <Form.Group className="mb-2">
                        <Form.Label>
                            Account Name
                        </Form.Label>
                        <Form.Control value={accountName} onChange={(e) => setAccountName(e.target.value)}>

                        </Form.Control>
                        <Form.Text>
                            (ex. Acme Co.)
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>
                            Admin Full Name
                        </Form.Label>
                        <Form.Control value={adminFullName} onChange={(e) => setAdminFullName(e.target.value)}>

                        </Form.Control>
                        <Form.Text>
                            (ex. John Smith)
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>
                            Admin Email
                        </Form.Label>
                        <Form.Control value={adminEmail} onChange={(e) => setAdminEmail(e.target.value)}>

                        </Form.Control>
                        <Form.Text>
                            (ex. j.smith@test.com)
                        </Form.Text>
                    </Form.Group>

                    <button onClick={() => createAccount()} disabled={!accountName || !adminEmail} className="btn btn-pliable">
                        Create Pliable Account
                    </button>
                    {saving && <Spinner size="sm"/>}
                </div>
            </div>
        </div>
    );
    
}

export default CreateNewAccount;