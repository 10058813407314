/**
 * CHANGES:
 * 
 * Add filter to search for columns across all data sources (under the header)
 * Show active data source and collapse all otehrs when you click on a mission control edge
 * Hide delete buttons until hover
 * Add placeholder drop
 * Change "fields" header to "{bo.name} Fields"
 * Add "One row per _____" under the Fields header
 * Allow dragging data sources from data library
 * Click to edit field name
 * 
 * Add "special plb fields" to the data source (use SourceRecordType.column_preferences for special static fields)
 *  - Loaded At
 *  - Source Name
 *  - Add your own static field
 * 
 */


import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Background, ConnectionMode, Edge, Handle, Node, NodeProps, Panel, ReactFlow, ReactFlowState, useEdgesState, useStore, addEdge, Connection, Controls, MiniMap } from "reactflow";
import 'reactflow/dist/style.css';
import { OrchestratedExecution } from "@models/pipelineOrchestration";
import { ReactFlowEdge } from "@components/lineage/LineageGraph.component";
import MissionControlTableNode, { TableNodeProps, getStartingYPositionOfNextNodeInColumn } from "@components/missionControl/tablediagram/MissionControlTableNode.component";
import { useNavigate, useParams, useSearchParams, unstable_useBlocker } from "react-router-dom";
import { useImmer } from 'use-immer';
import { useSourceRecordTypes, useBusinessObject, saveBusinessObject, useSources} from '@stores/data.store';
import { BusinessObject, BusinessObjectField, BusinessObjectMapOption } from "@models/businessObject";
import styled from 'styled-components';
import { Collapse, Form, Modal, Offcanvas, Spinner } from "react-bootstrap";
import BusinessObjectFieldMap from "@components/businessObjects/BusinessObjectFieldMap.component";
import PageTitle from "@components/pageTitle/PageTitle.component";
import produce from "immer";
import SaveButton from "@components/button/SaveButton.component";
import { confirmation } from "@services/alert/alert.service";
import DataSourceSelectModal from "@pages/Sources/DataSourceSelectModal.component";
import BuildOrchestrationORM from "@models/buildOrchestration";
import ApiService, { SingleRecordResponse } from "@services/api/api.service";
import { PipelineExecution } from "@models/pipelineExecution";
import { DndProvider, DragSourceMonitor, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { SourceRecordTypeColumn } from "@models/source";
import { ArrowToggleIcon } from "@components/icons/Shapes.components";
import { useDebounce, useThrottledCallback } from "use-debounce";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import BusinessObjectFieldTypeSelector from "@components/businessObjects/BusinessObjectFieldTypeSelector.component";
import { useRouteBlocker } from "@services/routing.service";
import Dropdown from "@components/form/Dropdown.component";
import BusinessObjectSubnav from "@components/businessObjects/BusinessObjectSubnav.component";
import Danger from "@components/statusIndicators/Danger.component";
import { Pane, PaneContent, PaneContentWithSubnav } from "@pages/PageStructure.component";
import { shortid } from "@services/id.service";

const HeaderContainer = styled.div`
display: flex;
h4 {
    font-family: "Poppins";
    color: black;
    font-size: 24px;
    font-weight: 600;
    flex: 1;
}

button {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    text-align: center;
    padding: 0px;
    margin: 0px;
    border: none;
    background-color: #E9E9E9;
    color: black;

    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

    &:hover {
        background-color: #00A1E0;
        color: white;
    }
}
`

const SubHeaderContainer
 = styled.div`
height: 50px;
`

const MergeInfo = styled.div`
display: flex;
flex-direction: row;

.icon {
    width: 40px;
    font-size: 24px;
    line-height: 24px;
    margin-top: 3px;

    &.highlight {
        color: var(--pliable-yellow);
    }
}

.text {
    flex: 1;

    h5 {
        font-size: 18px;
        margin-bottom: 0px;
    }
}
`

const FieldListSubHeader = styled.h5`
margin-top: 2rem;
color: #aaa;
font-weight: 400;

button {
    font-size: 13px;
    background: none;
    padding: none;
    border: none;
    color: var(--ct-body-color);


    &:hover {
        color: black;
        cursor: pointer;
    }

    &.highlight {
        color: var(--pliable-yellow);
    }
}
`

const FieldTitle = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-bottom: .5rem;

&:hover {
    cursor: pointer;

    button {
        color: black;
    }
    
}

img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: solid 1px var(--ct-border-color);
    margin-right: 1rem;
}

h4 {
    flex: 1;
    color: black;
    margin-bottom: 0px;
}

button {
    background: none;
    padding: none;
    border: none;
    color: var(--ct-body-color);


    &:hover {
        color: black;
        cursor: pointer;
    }

    &.highlight {
        color: var(--pliable-yellow);
    }
}
`


const DndContainer = styled.div`

`

const DraggableFieldStyles = styled.div`
padding: 4px 8px;

margin-bottom: .5rem;
display: flex;
flex-direction: row;
line-height: 24px;

.column-label {
    flex: 1;
    font-size: 14px;
}

.icons {
    font-size: 18px;
}

&:hover {
    background-color: #eee;
    cursor: move;
}


&.disabled {
    &:hover {
        background-color: white !important;
    }
}
user-select: none;
`



const Loader = styled.div`
position: fixed;
top: 50%;
left: 50%;
z-index: 2;
width: 100px;
height: 85px;
background: rgba(0, 0, 0, 0.5);
color: white;
border-radius: 10px;
line-height: 100px;
text-align: center;
`


const SelectionCard = styled.div`
    box-shadow: 0px 2px 5px 0px #0000004D;
    width: 200px;
    height: 50px;
    padding: 12px;
    border-radius: 5px;
    background: #0F0E31;
    color: #ffffff;
    text-align: center;

`


const FieldContainer = styled.div`
padding: 16px 20px;
margin-bottom: 1rem;

&.is-over {
    background-color: #eee;
}

&.can-drop {
    outline: solid 1px var(--pliable-blue);
}

`

const ScrollCatcher = styled.div`
position: fixed;
right: 0px;
width: 100%;
height: 50px;
background: none;
z-index: 1000;

&.top {
    top: 100px;
}

&.bottom {
    bottom: 0px;
}
`



interface SelectionCardProps {
    onCancel: () => void;
}



// interface BusinessObjectMappingsFlowProps {
//     businessObjectFields: BusinessObjectField[];
//     setFields: (fields: BusinessObjectField[]) => void;
//     recordTypeMappings: StandardizationPipeline[];
//     setRecordTypeMappings: (mappings: StandardizationPipeline[]) => void;
// }


interface DropTargetProps {
    field: BusinessObjectField;
    children: ReactNode;
}

const DropTarget = (props: DropTargetProps) => {
    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
          accept: 'FIELD',
          drop: () => ({
            fieldId: props.field.id,
          }),
          collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
          }),
        }),
        [props.field.id],
    );

    const classes = ['shadow-box'];

    if (canDrop) {
        classes.push('can-drop');
    }

    if (isOver) {
        classes.push('is-over');
    }
    return <FieldContainer ref={drop} className={classes.join(' ')}>
        {props.children}
    </FieldContainer>
}

interface DraggableFieldProps {
    column: SourceRecordTypeColumn;
    srtId: string;
    showCheckMark?: boolean;
    onDrop: (fieldId: string) => void;
    setIsDragging: (isDragging: boolean) => void;
    disableActions?: boolean;

}

const DraggableField = (props: DraggableFieldProps) => {
    const [{ isDragging, opacity }, drag] = useDrag(() => ({
        type: 'FIELD',
        item: {

        },
        end(item, monitor) {
            const dropResult = monitor.getDropResult() as any;
            if (item && dropResult) {
                let alertMessage = ''
                const isDropAllowed = true;
            }

            if (dropResult) {
                props.onDrop(dropResult.fieldId);

            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
            isDragging: monitor.isDragging(),
        }),
    }));

    useEffect(() => {
        props.setIsDragging(isDragging);
    }, [isDragging])

    return <DraggableFieldStyles ref={drag} className={`shadow-box ${props.disableActions ? 'disabled' : ''}`}>
        <div className="column-label">{props.column.label}</div>
        <div className="icons">
            {props.showCheckMark && <span>
                <i className="mdi mdi-check-bold text-success"></i>
            </span>}
            <span>
                <i className="mdi mdi-drag"></i>
            </span>

        </div>
    </DraggableFieldStyles>


}

interface FieldComponentProps {
    field: BusinessObjectField;
    onChangeFieldName: (fieldId: string, newName: string) => any;
    onChangeFieldType: (fieldId: string, newType: string) => any;
    onDeleteField: (field: BusinessObjectField) => any;
    toggleFieldCompositeKey: (fieldId: string) => any;
    onChangeMapOptions: (fieldId: string, newOptions: BusinessObjectMapOption[]) => any;
    mergeText: string;
}
const FieldComponent = ({
    field, onChangeFieldName, onChangeFieldType, onDeleteField, toggleFieldCompositeKey, onChangeMapOptions, mergeText
}: FieldComponentProps) => {
    return <div>
        <DropTarget
            field={field}
        >
            <FieldTitle>
                <h4>
                    <input className="inline-edit" type="text" value={field.label} onChange={(e) => onChangeFieldName(field.id as string, e.target.value)}/>
                </h4>
                <div style={{width: '250px'}} className="me-3">
                    <BusinessObjectFieldTypeSelector
                        className="input-sm"
                        selected={field.type}
                        onSelect={newVal => onChangeFieldType(field.id!, newVal)}
                    />
                </div>
                <button onClick={() => onDeleteField(field)}>
                    <i className="mdi mdi-delete"></i>
                </button>
                <button onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleFieldCompositeKey(field!.id as string);
                }} className={'ms-1 ' + (field!.part_of_composite_key ? 'highlight' : '')}>
                    <i className="mdi key"></i>
                </button>
            </FieldTitle>
            
            <BusinessObjectFieldMap
                showPriority={mergeText && !field.part_of_composite_key && field.map_options && field.map_options.length > 1 ? true : false}
                mapOptions={field.map_options!} 
                onChangeMapOptions={(newOptions) => {
                    onChangeMapOptions(field.id!, newOptions);
                }}
            />
        
        </DropTarget>
    </div>
}

interface GhostFieldComponentProps {
    sourceRecordTypeColumn: SourceRecordTypeColumn;
}
const GhostFieldComponent = (props: GhostFieldComponentProps) => {
    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
          accept: 'FIELD',
          drop: () => ({
            fieldId: 'NEW',
          }),
          collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
          }),
        }),
        [],
    );

    const classes = ['shadow-box'];

    if (canDrop) {
        classes.push('can-drop');
    }

    if (isOver) {
        classes.push('is-over');
    }
    return <FieldContainer ref={drop} className={classes.join(' ')}>
        [Create new field]
    </FieldContainer>;
}

const BusinessObjectFieldsPage = () => {
    const { businessObjectId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const businessObject = useBusinessObject(businessObjectId!);
    
    const sources = useSources();
    const sourceRecordTypes = useSourceRecordTypes();
    const reactFlowWrapper = useRef<any>(null);
    const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const [fields, setFields] = useImmer<BusinessObjectField[]>([]);
    const [combineLogicGate, setCombineLogicGate] = useState('');
    const [combineRequiredMatches, setCombineRequiredMatches] = useState(1);
    const [inputRecordTypeIds, setInputRecordTypeIds] = useImmer<string[]>([]);

    const [showAddSourceModal, setShowAddSourceModal] = useState(false);

    const [newColConnectingHandleId, setNewColConnectingHandleId] = useState('');

    const selectedSourceRecordTypeId = useMemo(() => {
        return searchParams.get('sourceRecordTypeId');
    }, [searchParams]);

    const onSaveAction = useMemo(() => {
        return searchParams.get('onSave');
    }, [searchParams]);

    useEffect(() => {
        if (selectedSourceRecordTypeId) {
            setVisibleSources([selectedSourceRecordTypeId]);
        }
    }, [selectedSourceRecordTypeId]);

    const onSave = useCallback(async () => {
        if (!businessObject.data) {
            return;
        }

        const updatedBO = produce(businessObject.data, draft => {
            draft.fields = fields;
            draft.input_record_type_ids = inputRecordTypeIds;
            draft.combine_logic_gate = combineLogicGate;
        });

        await saveBusinessObject(updatedBO);
        
        setPageDirty(false);

        if (onSaveAction === 'rebuild') {
            const orchestration = await BuildOrchestrationORM.buildModel('BusinessObject', businessObject.data.id as string);
            navigate(`/?watchOrchestrationId=${orchestration.id}`);
        }
       
    }, [fields, businessObject.data, inputRecordTypeIds, onSaveAction, combineLogicGate]);

    const { pageDirty, setPageDirty } = useRouteBlocker(onSave);


    const addNewField = useCallback(() => {
        setFields(draft => {
            draft.unshift({
                id: shortid(),
                name: '',
                description: '',
                part_of_composite_key: false,
                label: 'New Field', 
                type: 'STRING',
                map_options: [],
            });
        });
        setPageDirty(true);
    }, []);

    const [setDataFirstTime, setSetDataFirstTime] = useState(false);

    useEffect(() => {
        if (businessObject.data && !setDataFirstTime) {
            setFields(businessObject.data.fields);
            setCombineLogicGate(businessObject.data.combine_logic_gate ? businessObject.data.combine_logic_gate : 'AND');
            setCombineRequiredMatches(businessObject.data.combine_required_match_count ? businessObject.data.combine_required_match_count : 1);
            setInputRecordTypeIds([...businessObject.data.input_record_type_ids].sort((a,b) => (a==selectedSourceRecordTypeId) ? -1 : 1));
            setSetDataFirstTime(true);
        }
    }, [businessObject.dataUpdatedAt, setDataFirstTime]);

    const onDeleteField = useCallback((field: BusinessObjectField) => {
        setFields(draft => {
            const idx = draft.findIndex(d => d.id === field.id);
            if (idx >= 0) {
                draft.splice(idx, 1);
            }
        });
        setPageDirty(true);
    }, []);

    const [selectedFieldIds, setSelectedFieldIds] = useImmer<string[]>([]);

    const toggleSelectedField = useCallback((fieldId: string) => {
        setSelectedFieldIds(draft => {
            const index = draft.indexOf(fieldId);
            if (index >= 0) {
                draft.splice(index, 1);
            } else {
                draft.push(fieldId);
            }
        });
    }, []);

    const removeDataSource = useCallback((srtId: string) => {
        confirmation({
            header: 'Disconnect Data Source?',
            message: 'Are you sure you want to disconnect this data source from this business object?',
            confirmationButtonVariant: 'danger',
            confirmationButtonText: 'Disconnect',
            onClose: () => {

            },
            onConfirm: async () => {
                setInputRecordTypeIds(draft => {
                    const idx = draft.findIndex(el => el === srtId);
                    if(idx >= 0) {
                        draft.splice(idx, 1);
                    }
                })
                setPageDirty(true);
            }
        })
    }, []);

    

    const [searchInput, setSearchInput] = useState('');
    const [searchTerm] = useDebounce(searchInput, 250);

    const [boSearchInput, setBoSearchInput] = useState('');
    const [boSearchTerm] = useDebounce(boSearchInput, 250);

    const usedFields = useMemo(() => {
        const used: string[] = [];
        fields.forEach(f => {
            f.map_options?.forEach(mo => {
                if (mo.source_record_type_id) {
                    used.push(mo.source_record_type_id + ':' + mo.attribute_key);
                } else if (mo.sub_options) {
                    mo.sub_options.forEach(so => {
                        used.push(so.source_record_type_id + ':' + so.attribute_key);
                    });
                }
            });
        });
        return used;
    }, [fields])

    const filteredColumns = useMemo(() => {

        if (!sourceRecordTypes.data || !businessObject.data) {
            return [];
        }

        const term = searchTerm.toLowerCase();

        

        return inputRecordTypeIds.map(srtId => {
            const srt = sourceRecordTypes.data.find(s => s.id === srtId);
            const theseFilteredColumns = srt?.columns?.filter(c => c.label.toLowerCase().indexOf(term) >= 0 || c.label.toLowerCase().indexOf(term) >= 0).sort((a, b) => {
                if (a.label > b.label) {
                    return 1;
                }
                return -1;
            });

            // Now break them into used/unused
            if (!theseFilteredColumns) {
                return {
                    'used': [],
                    'unused': [],
                }
            } 
            return {
                'used': theseFilteredColumns.filter(c => usedFields.includes(srtId + ':' + c.key)),
                'unused': theseFilteredColumns.filter(c => !usedFields.includes(srtId + ':' + c.key)),
            }

        });
    }, [inputRecordTypeIds, searchTerm, businessObject.data, sourceRecordTypes.data, usedFields]);

    const mergeText = useMemo(() => {
        const compositeFieldNames = fields.filter(f => f.part_of_composite_key).map(f => f.label);
        let joiner: string = ' and ';
        if (combineLogicGate == 'OR') {
            joiner = ' or ';
        }
        if (compositeFieldNames.length) {
            let fieldText = '';
            if (compositeFieldNames.length === 1) {
                fieldText = compositeFieldNames[0];
            } else if (compositeFieldNames.length === 2) {
                fieldText = compositeFieldNames.join(joiner);
            } else {
                let last = compositeFieldNames.pop();
                fieldText = compositeFieldNames.join(', ') + ', ' + joiner + last;
            }
            return fieldText;
        }
        return '';

        


    }, [fields, combineLogicGate]);
    




    const onCancelSelectedStdMapping = useCallback(() => {
        setSearchParams({});
    }, []);

    const onChangeFieldName = useCallback((fieldId: string, newName: string) => {

        
        setFields(draft => {
            const theField = draft.find(f => f.id === fieldId);
            if (theField) {
                theField.label = newName;

                if (!theField.name) {
                    theField.name = newName;
                }
            }
        });
        setPageDirty(true);
    }, []);

    const onChangeFieldType = useCallback((fieldId: string, newType: string) => {

        
        setFields(draft => {
            const theField = draft.find(f => f.id === fieldId);
            if (theField) {
                theField.type = newType;
            }
        });
        setPageDirty(true);
    }, []);

    const onChangeFieldDescription = useCallback((fieldId: string, newDescription: string) => {
        setFields(draft => {
            const theField = draft.find(f => f.id === fieldId);
            if (theField) {
                theField.description = newDescription;
            }
        });
        setPageDirty(true);
    }, []);

    const toggleFieldCompositeKey = useCallback((fieldId: string) => {
        
        setFields(draft => {
            const theField = draft.find(f => f.id === fieldId);
            if (theField) {
                console.log('Setting composite key to', !theField.part_of_composite_key)
                theField.part_of_composite_key = !theField.part_of_composite_key;
            } else {
                console.log('Field not found');
            }
        });
        setPageDirty(true);
    }, []);

    const onChangeMapOptions = useCallback((fieldId: string, newMapOptions: BusinessObjectMapOption[]) => {
        
        setFields(draft => {
            const theField = draft.find(f => f.id === fieldId);
            if (theField) {
                theField.map_options = newMapOptions;
            }
        });
        setPageDirty(true);
    }, []);




    const onConnectSource = useCallback(async (srtId: string) => {
        if (!businessObject.data) {
            return;
        }
        setLoading(true);

        await sourceRecordTypes.refetch();
        await sources.refetch();

        if (inputRecordTypeIds.indexOf(srtId) == -1) {
            setInputRecordTypeIds(draft => {
                draft.unshift(srtId);
            })
        }

        setSearchParams({"sourceRecordTypeId": srtId});

        setLoading(false);
    }, [businessObjectId, businessObject.data, inputRecordTypeIds]);

    const onDrop = useCallback((srtId: string, srtColumn: SourceRecordTypeColumn, businessObjectFieldId: string) => {
        setFields(draft => {
            if (businessObjectFieldId == 'NEW') {
                draft.push({
                    id: shortid(),
                    label: srtColumn.label,
                    name: srtColumn.label,
                    part_of_composite_key: false,
                    type: 'STRING',
                    map_options: [
                        {
                            id: shortid(),
                            source_record_type_id: srtId,
                            attribute_key: srtColumn.key,
                            combination_rule: 'PICK_ONE',
                        }
                    ]
                });
            } else {
                const theField = draft.find(f => f.id === businessObjectFieldId);
                if (!theField) {
                    throw new Error('field not found');
                }

                console.log('DROPPED', srtId, srtColumn);

                if (!theField.map_options) {
                    theField.map_options = [];
                }

                theField.map_options.push({
                    id: shortid(),
                    source_record_type_id: srtId,
                    attribute_key: srtColumn.key,
                    combination_rule: 'PICK_ONE', 
                });
            }
            
        });
        setPageDirty(true);
    }, []);

    const [visibleSources, setVisibleSources] = useImmer<string[]>([]);
    
    const toggleSourceVisibility = useCallback((srtId: string) => {
        setVisibleSources(draft => {
            if (draft.includes(srtId)) {
                const index = draft.indexOf(srtId);
                draft.splice(index, 1);
            } else {
                draft.push(srtId);
            }
        });
    }, []);

    const scrollingContainer = useRef<HTMLDivElement>(null);

    const scrollUp = useThrottledCallback(() => {
        console.log('Should be scrolling up');
        if (!scrollingContainer.current) {
            return;
        }

        const currentY = scrollingContainer.current.scrollTop;
        if (currentY > 0) {
            scrollingContainer.current.scrollTo({
                behavior: 'smooth',
                top: currentY - 500,
            });
        }
    }, 1000);

    const scrollDown = useThrottledCallback(() => {
        if (!scrollingContainer.current) {
            return;
        }

        const currentY = scrollingContainer.current.scrollTop;
        if (currentY < scrollingContainer.current.scrollHeight) {
            scrollingContainer.current.scrollTo({
                behavior: 'smooth',
                top: currentY + 500,
            });
        }
    }, 1000);

    const [showMergeTutorial, setShowMergeTutorial] = useState(false);

    const [currentlyDragging, setCurrentlyDragging] = useState('');

    const mergingFields = useMemo(() => {
        return fields.filter(f => f.part_of_composite_key);
    }, [fields]);
    const nonMergingFields = useMemo(() => {
        const lower = boSearchTerm.toLowerCase();
        return fields.filter(f => !f.part_of_composite_key && f.label.toLowerCase().indexOf(lower) >= 0);
    }, [fields, boSearchTerm]);

    const addAllFieldsFromSource = useCallback((sourceRecordTypeId: string) => {
        if (!sourceRecordTypes.data) {
            return;
        }

        const theSrt = sourceRecordTypes.data.find(s => s.id === sourceRecordTypeId);
        if (!theSrt) {
            return;
        }

        const idx = inputRecordTypeIds.indexOf(sourceRecordTypeId);

        const unusedFields = filteredColumns[idx].unused;

        setFields(draft => {
            unusedFields.forEach(f => {
                // Find a BO field with the same name and add it if so, otherwise create
                const mapOption = {
                    id: shortid(),
                    source_record_type_id: sourceRecordTypeId,
                    attribute_key: f.key,
                    combination_rule: 'PICK_ONE', 
                };
                const fieldWithSameName = draft.find(field => field.label.toLowerCase() == f.label.toLowerCase());
                if (fieldWithSameName) {
                    if (!fieldWithSameName.map_options) {
                        fieldWithSameName.map_options = [];
                    }

                    fieldWithSameName.map_options.push(mapOption);

                } else {
                    draft.push({
                        id: shortid(),
                        name: f.label,
                        label: f.label,
                        part_of_composite_key: false,
                        type: 'STRING',
                        map_options: [mapOption],
                    })
                }
            })
        });
        setPageDirty(true);


    }, [fields, sourceRecordTypes.dataUpdatedAt, inputRecordTypeIds]);

    if (businessObject.data) {
        return (
            <Pane style={{position: 'relative'}}>
                <BusinessObjectSubnav
                    businessObjectId={businessObjectId as string}
                >
                    <SaveButton
                        onClick={onSave}
                        disabled={!pageDirty}
                    />

                </BusinessObjectSubnav>
                {currentlyDragging && (
                    <>
                        <ScrollCatcher className="top" onDragOver={() => scrollUp()}/>
                        <ScrollCatcher className="bottom" onDragOver={() => scrollDown()}/>

                    </>
                )}
                
                <DataSourceSelectModal
                    show={showAddSourceModal}
                    allowSelectExisting
                    onClose={() => setShowAddSourceModal(false)}
                    onSourceAdded={onConnectSource}
                    selectExistingBlacklist={inputRecordTypeIds}
                />

                {businessObject.data && sourceRecordTypes.data && (
                    <div className="d-flex" style={{height: '100%'}}>
                        <DndProvider backend={HTML5Backend}>
                            <div style={{width: '35%'}}>
                                    <Pane>
                                        <PaneContent>
                                            <div className="p-3">
                                            <HeaderContainer>
                                                <h4>Data Sources</h4>
                                                <button onClick={() => setShowAddSourceModal(true)}>
                                                    <i className="mdi mdi-plus-thick"></i>
                                                </button>
                                            </HeaderContainer>
                                            <SubHeaderContainer>
                                                <input type="text" className="form-control round-input" placeholder="Search columns" value={searchInput} onChange={(e) => setSearchInput(e.target.value)}/>
                                            </SubHeaderContainer>

                                            
                                            
                                            {inputRecordTypeIds.map((s, idx) => {
                                                const srt = sourceRecordTypes.data.find(x => x.id === s);
                                                if (!srt) {
                                                    return <></>;
                                                }
                                                const source = (!!sources.data) ? sources.data.find(s => s.id === srt!.source_id) : undefined;
                                                const isVisible = visibleSources.includes(srt!.id as string);

                                                const columns = filteredColumns[idx];
                                                return <FieldContainer className="shadow-box" key={s}>
                                                    <FieldTitle onClick={() => toggleSourceVisibility(srt!.id as string)}>
                                                        <h4>{srt!.name}</h4>
                                                        
                                                        <button >
                                                        {isVisible && (
                                                            <span><i className="mdi mdi-unfold-less-horizontal"></i></span>
                                                        )}
                                                        {!isVisible && (
                                                            <span><i className="mdi mdi-unfold-more-horizontal"></i></span>
                                                        )}
                                                        </button>
                                                        
                                                    </FieldTitle>

                                                    <Collapse in={isVisible}>
                                                        <div>
                                                            {columns.used.map((c, idx) => {
                                                                const id = `srt|${srt!.id}|col|${c.key}`;
                                                                return <DraggableField
                                                                    setIsDragging={(isDragging) => isDragging ? setCurrentlyDragging(id) : setCurrentlyDragging('')}
                                                                    disableActions={currentlyDragging && currentlyDragging != id ? true : false}
                                                                    key={c.key}
                                                                    column={c}
                                                                    showCheckMark
                                                                    srtId={srt!.id as string}
                                                                    onDrop={(fieldId: string) => {
                                                                        onDrop(srt!.id as string, c, fieldId);
                                                                    }}

                                                                />
                                                            })}
                                                            <FieldListSubHeader className="d-flex center-vertically">
                                                                <div className="flex-1">Unused Columns</div>
                                                                {columns.unused.length > 0 && (
                                                                    <button className="ms-3" onClick={() => {
                                                                        addAllFieldsFromSource(srt!.id as string)
                                                                    }}>
                                                                        Add All <i className="mdi mdi-play"></i>
                                                                    </button>
                                                                )}
                                                                
                                                            </FieldListSubHeader>
                                                            {columns.unused.map((c, idx) => {
                                                            const id = `srt|${srt!.id}|col|${c.key}`;
                                                            return <DraggableField
                                                                    setIsDragging={(isDragging) => isDragging ? setCurrentlyDragging(id) : setCurrentlyDragging('')}
                                                                    disableActions={currentlyDragging && currentlyDragging != id ? true : false}
                                                                    key={c.key}
                                                                    column={c}
                                                                    srtId={srt!.id as string}
                                                                    onDrop={(fieldId: string) => {
                                                                        onDrop(srt!.id as string, c, fieldId);
                                                                    }}

                                                                />
                                                                
                                                                
                                                            })}
                                                        </div>
                                                        
                                                    </Collapse>
                                                    
                                                    
                                                </FieldContainer>
                                            })}
                                            </div>
                                        </PaneContent>
                                    </Pane>
                            </div>
                            <div style={{width: '65%'}}>
                                <Pane>
                                        <PaneContent ref={scrollingContainer}>
                                            <div className="p-3">
                                                <HeaderContainer>
                                                    <h4>{businessObject!.data.name} Fields</h4>
                                                    <button onClick={() => addNewField()}>
                                                        <i className="mdi mdi-plus-thick"></i>
                                                    </button>
                                                </HeaderContainer>
                                                <SubHeaderContainer>
                                                    <MergeInfo>
                                                        <div className={`icon ${mergeText ? 'highlight' : ''}`}>
                                                            <i className="mdi key"></i>

                                                        </div>
                                                        <div className="text">
                                                            {mergeText && (
                                                                <>
                                                                    <div className="d-flex center-vertically">
                                                                        <h5 className="flex-1">Merging.</h5>
                                                                        {mergingFields.length >= 2 && (
                                                                            <>
                                                                                
                                                                                <div>
                                                                                    <Form.Check
                                                                                        type="switch"
                                                                                        id="custom-switch"
                                                                                        label="Match all"
                                                                                        checked={combineLogicGate === 'AND'}
                                                                                        onChange={(e) => setCombineLogicGate(e.target.checked ? 'AND' : 'OR')}
                                                                                    />
                                                                                </div>
                                                                                {/* {combineLogicGate == 'OR' && (
                                                                                    <div>
                                                                                        <Form.Range
                                                                                            min={1}
                                                                                            max={mergingFields.length}
                                                                                            step={1}
                                                                                            value={combineRequiredMatches}
                                                                                            onChange={(e) => setCombineRequiredMatches(parseInt(e.target.value))}
                                                                                        />
                                                                                    </div>
                                                                                )} */}
                                                                                
                                                                            </>
                                                                        )}
                                                                        
                                                                    </div>
                                                                    
                                                                    <div>Records with the same values for <strong>{mergeText}</strong> will be merged.</div>
                                                                </>
                                                            )}
                                                            {!mergeText && (
                                                                <>
                                                                    <h5>Not Merging.</h5>
                                                                    <div>Click the merge button to choose fields to merge on.</div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </MergeInfo>
                                                    
                                                    
                                                    
                                                </SubHeaderContainer>
                                                
                                                {mergingFields.map(field => {
                                                    let error: string = '';
                                                    if (!field.map_options || field.map_options.length != inputRecordTypeIds.length) {
                                                        error = 'You need to map one field from each source in order to use this field for merging.';
                                                    }
                                                    return <>
                                                        <FieldComponent
                                                            field={field}
                                                            mergeText={mergeText}
                                                            onChangeFieldName={onChangeFieldName}
                                                            onChangeFieldType={onChangeFieldType}
                                                            toggleFieldCompositeKey={toggleFieldCompositeKey}
                                                            onDeleteField={onDeleteField}
                                                            onChangeMapOptions={onChangeMapOptions}  
                                                        />
                                                        {error && <Danger>
                                                            {error}
                                                        </Danger>}
                                                    </>
                                                })}
                
                                                {mergeText && (
                                                    <div className="mb-3">
                                                        < hr/>
                                                        <h4>Other Fields</h4>
                                                        <p>If you're not merging on a field, you can change how Pliable picks the winning value by reordering the mapped fields from your data sources.</p>
                                                        <input type="text" className="form-control round-input" placeholder="Search fields" value={boSearchInput} onChange={(e) => setBoSearchInput(e.target.value)}/>

                                                        </div>
                                                )}
                                                {currentlyDragging && (
                                                    <GhostFieldComponent
                                                        sourceRecordTypeColumn={{
                                                            key: 'foobar',
                                                            label: 'New Column',
                                                            is_synthetic_field: false,
                                                            is_system_field: false,
                                                        }}
                                                    />
                                                )}
                                                {nonMergingFields.map(field => <FieldComponent
                                                    field={field}
                                                    mergeText={mergeText}
                                                    onChangeFieldName={onChangeFieldName}
                                                    onChangeFieldType={onChangeFieldType}
                                                    toggleFieldCompositeKey={toggleFieldCompositeKey}
                                                    onDeleteField={onDeleteField}
                                                    onChangeMapOptions={onChangeMapOptions}  
                                                />)}
                                                <button className="btn btn-lg w-100 btn-ghost" onClick={() => addNewField()}>Add New Field</button>
                                                
                                                
                                            </div>
                                        </PaneContent>
                                    </Pane>
                            </div>
                        </DndProvider>
                    </div>
                )}
            </Pane>     
            
        );
    }
    return <Spinner/>;
    
}


export default BusinessObjectFieldsPage;