import AsyncButton from "@components/button/AsyncButton.component";
import DeleteButton from "@components/button/DeleteButton.component";
import SaveButton from "@components/button/SaveButton.component";
import { TableExplorer } from "@components/datatable/TableExplorer.component";
import DrawerHeader from "@components/drawers/DrawerHeader.component";
import Dropdown, { MultiDropdown, Option } from "@components/form/Dropdown.component";
import { ToggleIcon } from "@components/nav/DataLibrary.component";
import CleaningStepsList from "@components/sources/CleaningStepsList.component";
import SourceRecordTypeColumnSelector from "@components/sources/SourceRecordTypeColumnSelector.component";
import BuildOrchestrationORM from "@models/buildOrchestration";
import DataLoadORM from "@models/dataload";
import FileORM from "@models/file";
import { ColumnPreference, SourceRecordTypeORM } from "@models/source";
import { DedupeConfig, FilterConfig } from "@models/standardizationPipeline";
import DataSourceImporter from "@pages/Sources/DataSourceImporter.component";
import { getPromptAnswer, promptEmoji } from "@services/alert/alert.service";
import ApiService, { JobEnqueueResponse, SingleRecordResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";
import { getErrorMessage } from "@services/errors.service";
import { summarizeNumber } from "@services/formatting.service";
import { timeAgo } from "@services/time.service";
import toast from "@services/toast.service";
import { deleteSourceRecordType, invalidateDataLoadsForSRT, invalidateEverything, invalidateMissionControlDataFlowData, invalidateSourceRecordType, saveSourceRecordType, useBusinessObjects, useDataLoadsForSRT, useProjectConfig, useSourceDataConnection, useSourceDataConnections, useSourceRecordType } from "@stores/data.store";
import produce from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Accordion, Badge, Collapse, Form, Offcanvas, Spinner, Tab, Tabs } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Column } from "react-table";
import styled from 'styled-components';

const MarginnedBadge = styled(Badge)`
margin-left: 8px;
`

const PreviewContainer = styled.div`
margin-top: 1rem;
`

const TableContainer = styled.div`
width: 100%;
height: 100%;
border: solid 1px var(--ct-border-color);
`

const ResultInfo = styled.span`
margin-right: 1rem;
line-height: 2.6;
`

interface Props {
    sourceRecordTypeId: string;
}

interface TableRef {
    schema: string;
    table: string;
}

export interface ExecutionResultsInfo {
    total_input: number;
    final_total: number;
    total_after_filter: number;
    total_after_dedupe: number;
    dupes_table: string;
    filtered_out_table: string;
    executed_at?: string;
    result_table: TableRef;
}

const createTablePath = (tableRoot: string, table_ref?: TableRef, promoteData: boolean = true, cache_bust: string = '') => {
    let rootPath = `${tableRoot}v=${(!cache_bust) ? (new Date()).toString() : cache_bust }`;
        if (table_ref) {
            rootPath = rootPath + `&schema=${table_ref.schema}&table=${table_ref.table}&promote=${(promoteData) ? 'true': 'false'}`;
        }
        return rootPath;
}

const DEFAULT_DEDUPE_CONFIG = {
    shared_fields: [],
    fuzzy_match_threshold: 1.0,
    resolution_column: '',
    resolution_direction: '',
};

const DEFAULT_FILTERS = {
    filters: []
};

const SourceRecordTypeDrawer = (props: Props) => {
    const srt = useSourceRecordType(props.sourceRecordTypeId);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [saving, setSaving] = useState(false);
    const [activeTab, setActiveTab] = useState('columns');
    const [resultsInfo, setResultsInfo] = useState<ExecutionResultsInfo|undefined>(undefined);
    const [dataResultsInfo, setDataResultsInfo] = useState<ExecutionResultsInfo|undefined>(undefined);
    const [previewTablePath, setPreviewTablePath] = useState('');
    const [dataTablePath, setDataTablePath] = useState('');

    const [showCleaningFilters, setShowCleaningFilters] = useState(true);
    const [showCleaningDedupe, setShowCleaningDedupe] = useState(true);

    const dataLoads = useDataLoadsForSRT(props.sourceRecordTypeId);
    const location = useLocation();
    const navigate = useNavigate();

    const columnOptions = useMemo(() => {
        if (srt.data && srt.data.shape) {
            return srt.data?.shape?.columns.map(c => c.key);
        }
        return [];
    }, [srt.data]);

    const config = useProjectConfig();



    const [loadingPreview, setLoadingPreview] = useState(false);
    useEffect(() => {
        if (!!srt.data) {
            
            setName(srt.data.name);
            setDescription(srt.data.description);
        }
    }, [srt.dataUpdatedAt]);
    
    // const onHide = useCallback(() => {
    //     setActiveTab('stats');
    //     props.onHide();
    // }, [props.onHide]);
    const connections = useSourceDataConnections(props.sourceRecordTypeId);

    const [dedupeConfig, setDedupeConfig] = useState<DedupeConfig>(DEFAULT_DEDUPE_CONFIG);

    const onChangeDedupeConfig = useCallback((config: DedupeConfig) => {
        setDedupeConfig(config);
    }, []);

    const [columnPreferences, setColumnPreferences] = useState<ColumnPreference[]>([]);

    const onChangeColumnPreferences = useCallback((prefs: ColumnPreference[]) => {
        setColumnPreferences(prefs);
    }, []);


    useEffect(() => {
        if (srt.data) {

            if (srt.data.column_preferences) {
                setColumnPreferences(srt.data.column_preferences);
            }

            if (srt.data.shape) {
                setCompositeKeyOptions(srt.data.shape.columns.map(c => {
                    return {
                        value: c.key,
                        label: c.key,
                        // description: tc.type,
                    }
                }));
            }

            if (srt.data.composite_key) {
                setCompositeKeySelection(srt.data.composite_key);
            }

            if (srt.data.last_modified_column) {
                setLastModifiedColumnSelection(srt.data.last_modified_column);
            }

            setImageUrl(srt.data.image_url as string);
        }
    }, [srt.dataUpdatedAt]);

    const onDelete = useCallback(async () => {
        if (srt.data) {
            await deleteSourceRecordType(srt.data);
            // this is a little brute force, but we need to invalidate both the BO list and any specific BOS that this SRT pointed to that might already be cached in react-query
            // TODO DH-702
            //await invalidateEverything();
        }
        navigate(location.pathname);

    }, [srt.dataUpdatedAt]);

    const changeIcon = useCallback(() => {
        promptEmoji({
            onConfirm: (emoji: any) => {
                setImageUrl(emoji.imageUrl as string);
            },
            onClose: () => {
                return;
            }
        });
    }, [srt.dataUpdatedAt]);

    const tableRoot: string = useMemo(() => {
        if (srt.data) {
            return `/sources/${srt.data.source_id}/record-type/${srt.data.id}/data?`;
        }
        return '';
        
    }, [srt.data]);

    const reloadData = useCallback(() => {
        if (!srt.data) {
            return;
        }

        console.log('RELOADING DATA');

        setDataTablePath(`/sourcerecordtypes/${srt.data.id as string}/data?bust=${(new Date()).toString()}`)
        
    }, [srt.data]);

    useEffect(() => {
        reloadData();
    }, [srt.dataUpdatedAt]);

    const downloadOriginalFile = useCallback(async (fileId: string) => {
        await FileORM.downloadOriginal(fileId);
    }, []);

    const [compositeKeySelection, setCompositeKeySelection] = useState<string[]>([]);
    const [compositeKeyOptions, setCompositeKeyOptions] = useState<Option[]>([]);
    const [lastModifiedColumnSelection, setLastModifiedColumnSelection] = useState<string>('');

    const save = useCallback(async () => {
        if (!srt.data) {
            return;
        }

        try{
            const res = await saveSourceRecordType({
                ...srt.data,
                name: name,
                description: description,
                image_url: imageUrl,
                column_preferences: columnPreferences,
                composite_key: compositeKeySelection,
                last_modified_column: lastModifiedColumnSelection
            });

            // build the SRT
            const orchestration = await BuildOrchestrationORM.buildModel('SourceRecordType', srt.data!.id as string);
            await BackgroundService.getInstance().waitForJob(orchestration.job_id);
        }catch(ex: any) {
            toast('danger', 'Error!', getErrorMessage(ex));
            throw ex;
        }

        reloadData();
        invalidateMissionControlDataFlowData();

    }, [srt.dataUpdatedAt, name, description, imageUrl, columnPreferences, compositeKeySelection, lastModifiedColumnSelection]);


    const [deletingFile, setDeletingFile] = useState(false);


    const onDeleteFile = useCallback(async (dataLoadId: string) => {
        setDeletingFile(true);
        const { job_id } = await DataLoadORM.deleteById(dataLoadId) as JobEnqueueResponse;
        await BackgroundService.getInstance().waitForJob(job_id);
        invalidateDataLoadsForSRT(srt.data!.id as string);
        invalidateSourceRecordType(srt.data!.id as string);
        setDeletingFile(false);
        reloadData();

    }, [srt.data]);
    const toggleShowFilters = useCallback(() => {
        console.log('Toggling to', !showCleaningFilters);
        setShowCleaningFilters(!showCleaningFilters);
    }, [showCleaningFilters]);

    const tableColumns = useMemo(() => {
        return columnPreferences.filter(c => c.visible).map(c => {
            return {
                key: c.key,
                header: c.rename ? c.rename : c.key,
            }
            
        });
    }, [columnPreferences]);

    const addSyntheticColumn = useCallback(async () => {
        const columnName = await getPromptAnswer('Enter column name', 'Add Static Column');
        if (columnName) {
            const newPreferences = produce(columnPreferences, draft => {
                draft.push({
                    key: columnName,
                    visible: true,
                    rename: columnName,
                    default_value: '',
                    static_value: '',
                    is_static: true,
                });
            });
            setColumnPreferences(newPreferences);
        }
    }, [columnPreferences]);

    

    return <>
            {srt.isLoading && <Spinner/>}
            {srt.data && <>
                <DrawerHeader
                    label="Data Source"
                    title={name}
                    description={description}
                    totalRecords={srt.data.total_records}
                    onChangeTitle={(v: string) => setName(v)}
                    onChangeDescription={(v: string) => setDescription(v)}
                    imageUrl={imageUrl}
                    onIconClick={changeIcon}
                >
                    <div style={{position: 'relative'}}>
                        <SaveButton
                        
                            className="btn btn-success me-1"
                            onClick={() => save()}
                        />
                        <DeleteButton onConfirmDelete={() => onDelete()}/>
                    </div>
                    

                </DrawerHeader>

                <Tabs
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k as string)}
                    className="mb-3 nav-bordered"
                >
    
                    <Tab eventKey="columns" title="Columns">
                        <div className="scrollable-container">
                            {config.data && config.data.database == 'SNOWFLAKE' && (
                                <>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="small">Primary Key</Form.Label>
                                        <MultiDropdown
                                            selected={compositeKeySelection}
                                            options={compositeKeyOptions}
                                            onChange={setCompositeKeySelection}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label className="small">Last Modified Column</Form.Label>
                                        <Dropdown
                                            selected={lastModifiedColumnSelection}
                                            options={compositeKeyOptions}
                                            onChange={setLastModifiedColumnSelection}
                                        />
                                    </Form.Group>
                                </>
                            )}
                            
                            
                            <p>Hint: here you can reorder your columns, hide ones you don't need, rename them, or set default values if they are empty.</p>
                            
                        </div>
                        

                    </Tab>
                    <Tab eventKey="cleaning" title="Cleaning">
                        <div className="scrollable-container">
                            <p className="text-muted">These cleaning rules will be applied to all data from this source, even from new files that you upload.</p>
                            {(!srt.data.cleaning_steps || srt.data.cleaning_steps.length == 0) && (
                                <p>No cleaning yet! <Link to={`/cleaning/${srt.data.id}`}>Start cleaning.</Link></p>
                            )}
                            {srt.data.cleaning_steps && srt.data.cleaning_steps.length > 0 && (
                                <div>
                                    <Link to={`/cleaning/${srt.data.id}`}>Clean some more.</Link>
                                    <CleaningStepsList
                                        cleaningSteps={srt.data.cleaning_steps}
                                    />
                                </div>
                                    
                            )}
                        </div>
                        
                    </Tab>
                    <Tab eventKey="data" title="Data">
                    {dataTablePath && (
                                <TableContainer>
                                     <TableExplorer
                                        tablePath={dataTablePath}
                                        columns={tableColumns}
                                        onClickExpand={() => {
                                            navigate(`/data/source/${srt.data!.id as string}`)
                                        }}
                                    />
                                </TableContainer>
                               
                            )}
                    </Tab>
                    {/* <Tab eventKey="connections" title="Connections">
                        <h5>Connections to other data sources</h5>
                        <h5>Connections to business objects</h5>
                    </Tab> */}
                    {config.data && config.data.database == 'PLIABLE' && (
                        <Tab eventKey="files" title="Files">
                            <div className="scrollable-container">
                                {deletingFile && (
                                    <div className="alert alert-info">
                                        <i className="mdi mdi-loading mdi-spin"></i> Removing file data and rebuilding data source...
                                    </div>
                                )}
                                {!deletingFile && (
                                    <DataSourceImporter
                                        onSourceAdded={() => {
                                            reloadData();
                                        }}
                                        sourceRecordTypeId={srt.data!.id as string}
                                    />
                                )}
                                
                                {!deletingFile && dataLoads.data && (
                                    <table className="table table-centered table-nowrap">
                                        <tbody>
                                            {dataLoads.data.map(dl => <tr>
                                                <td>
                                                    <span className="text-muted font-123">
                                                        Filename
                                                    </span>
                                                    <a role="button" onClick={() => downloadOriginalFile(dl.file_id)}>
                                                        
                                                        <h5 className="font-14 mt-1">
                                                            <span className="me-1"><i className="mdi mdi-download"></i></span>
                                                            {dl.file_name}
                                                            
                                                        </h5>
                                                    </a>
                                                    
                                                </td>
                                                <td>
                                                    <span className="text-muted font-13">Uploaded</span>
                                                    <h5 className="font-14 mt-1">{timeAgo(dl.completed_at)}</h5>
                                                </td>
                                                <td>
                                                    <span className="text-muted font-13">
                                                        Records
                                                    </span>
                                                    <h5 className="font-14 mt-1">{summarizeNumber(dl.total_records)}</h5>
                                                </td>
                                                <td className="table-action text-end">
                                                    
                                                    <button className="action-icon me-1" onClick={() => onDeleteFile(dl.id as string)} title="Delete file">
                                                        <i className="mdi mdi-delete"></i>
                                                    </button>
                                                    {/* <button className="action-icon me-1" onClick={() => downloadOriginalFile(dl.file_id)} title="Download file">
                                                        <i className="mdi mdi-download"></i>
                                                    </button> */}
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                            
                        </Tab>
                    )}
                </Tabs>
                {/* <Link to={`/sources/${srt.data.source_id}/data/${srt.data.id}`}>View & Clean Data</Link> */}
            </>}
    </>
}

export default SourceRecordTypeDrawer;