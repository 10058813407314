import { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { timeAgo } from '../../services/time.service';
import FileORM, { File } from '../../models/file';
import { subscribe, unsubscribe } from '@nextcloud/event-bus'
import { Badge } from 'react-bootstrap';
import ConfirmationModal from '@components/alert/ConfirmationModal.component';
import { PipelineNode, PipelineNodeORM } from '@models/pipelineNode';
import { usePipelineNode } from '@stores/data.store';

interface RowProps {
    file: File;
    onDelete: (file: File) => void;
}

const removeExtension = (v: string) => {
    const spl = v.split('.');
    spl.pop();
    return spl.join('.');
}

class FileRow extends Component<RowProps> {

    async downloadOriginalFile(file_id: string) {
        await FileORM.downloadOriginal(file_id);
    }
    
    render() {
        const file = this.props.file;
        

        return (
            <tr>
                <td>
                    <span className="text-muted font-13">Filename</span>
                    <h5 className="font-14 mt-1 fw-normal">{file.name}</h5>
                </td>
                <td>
                    <span className="text-muted font-13">Uploaded</span>
                    <h5 className="font-14 mt-1 fw-normal">{timeAgo(file.uploaded_at)}</h5>
                </td>
                {/* <td>
                    <span className="text-muted font-13">Total Records</span>
                    <h5 className="font-14 mt-1 fw-normal">{integerFormatter(10)}</h5>
                </td> */}
                <td>
                    {file.processing_status === 'UPLOADING' && (
                        <Badge
                            bg="warning"
                        >Requires Processing!</Badge>
                    )}
                    {file.processing_status === 'PRE_PROCESSED' && (
                        <Badge
                            bg="primary"
                        >Processed - needs loading</Badge>
                    )}
                    {file.processing_status === 'LOADED' && (
                        <Badge
                            bg="success"
                        >Loaded</Badge>
                    )}
                </td>
                <td className="table-action text-end">
                    <button className="btn btn-secondary btn-sm me-1" onClick={() => this.props.onDelete(file)} title="Delete file">
                        <i className="mdi mdi-delete"></i> Delete
                    </button>
                    <button className="btn btn-outline-primary btn-sm me-1" onClick={() => this.downloadOriginalFile(file.id)} title="Download file">
                        <i className="mdi mdi-download"></i> Download
                    </button>
                    {/* <Link to={'/sources/' + file.source_id + '/create-business-object?fileId='+ (file.id as string)} className="action-icon" title="Create Business Object from File">
                        <i className="mdi mdi-auto-fix"></i> 
                    </Link>  */}
                    {/* <Link to={'/sources/' + file.source_id + '/files/' + (file.id as string)} className="btn btn-secondary btn-sm">
                        <i className="mdi mdi-pencil-box"></i> View
                    </Link> */}
                    {/* <Link to={"/sources/profile?srcType=SOURCE_FILE&src=" + removeExtension(this.props.file.filename)} className="action-icon">
                        <i className="mdi mdi-eye"></i>
                    </Link> */}
                </td>
                
                
            </tr>
        );
    }
}

interface State {
    loading: boolean;
    files: File[];
    error: string|null;
    showUploader: boolean;
    confirmDeleteFile?: File;
}
interface Props {
    source_record_type_id?: string;
    sourceId?: string;
    pipelineNode?: PipelineNode|null;
    onDelete?: (file: File) => void;
}
export default class FilesList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            files: [],
            error: null,
            showUploader: false,
            confirmDeleteFile: undefined
        };
    }
    render() {
        const pipelineNodeId = this.props.pipelineNode?.id
        let innerContent;
        if (this.state.loading) {
            innerContent = (
                <div>
                    <Spinner animation="border" role="status">
                    </Spinner>
                    <span>Loading...</span>
                </div>
                
            );
        } else if (this.state.files.length) {
            innerContent = (
                <>
                <ConfirmationModal
                        header="Delete File?"
                        confirmationButtonText="Delete"
                        message={`Are you sure you want to delete this file and all its records?`}
                        show={!!this.state.confirmDeleteFile}
                        onClose={() => this.setState({
                            confirmDeleteFile: undefined,
                        })}
                        confirmationButtonVariant="danger"
                        confirmIcon='mdi-delete'
                        onConfirm={ async () => {
                                if (this.props.onDelete && this.state.confirmDeleteFile) {
                                    await this.props.onDelete(this.state.confirmDeleteFile);
                                }
                                this.setState({confirmDeleteFile: undefined});
                                this.loadFiles();
                            }
                        }
                    />
                <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                        <tbody>
                            {this.state.files.map(f => {
                                return (<FileRow file={f} key={f.id} onDelete={(f) => this.setState({confirmDeleteFile: f})}/>);
                            })}
                        </tbody>
                        
                    </table>
                </div>
                </>
            )
        } else {
            innerContent = (
                <div className="alert alert-info">You haven't uploaded any files yet.</div>
            )
        }

        return (
            <>
                {innerContent}
            </>
        )

    }


    componentDidMount() {
        this.loadFiles();
        subscribe('ws:files:reload', this.reloadTriggered);
    }

    componentWillUnmount(): void {
        unsubscribe('ws:files:reload', this.reloadTriggered);
    }

    reloadTriggered = (data: any) => {
        this.loadFiles();
    }
    

    private async loadFiles(reloadSourceData: boolean = false) {
        this.setState({
            loading: true,
        });

        let filter;

        if (this.props.source_record_type_id) {
            filter = {
                'source_record_type_id': {
                    '$eq': this.props.source_record_type_id as string,
                }
            }
        }

        const resp = await FileORM.find(filter);
        this.setState({
            loading: false,
            files: resp.records.sort((a, b) => {
                if (a.uploaded_at > b.uploaded_at) {
                    return -1;
                }
                return 1;
            }),
        });
    }
}