import LoadingCard from "@components/card/LoadingCard.component";
import { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
export interface WaitingModalProps {
    show?: boolean;
    message?: string;
}

const WaitingModal = ({message = "Loading...", show}: WaitingModalProps) => {
    return (
        <Modal show={show}>
            <Modal.Body>
                <LoadingCard action={message} />
            </Modal.Body>
        </Modal>

    )
}

export default WaitingModal;