import styled from 'styled-components';
import { timeAgo } from "@services/time.service";
import { summarizeNumber } from "@services/formatting.service";
import { Badge } from "react-bootstrap";
import { invalidateEverything, invalidateMissionControlDataFlowData, invalidatePipelineNodes, useDraftVersionId, useIsInDraftMode, usePipelineNode } from '@stores/data.store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PipelineNodeORM } from '@models/pipelineNode';
import { getPromptAnswer, requireConfirmation } from '@services/alert/alert.service';
import { SourceRecordTypeORM } from '@models/source';
import { Link, useNavigate } from 'react-router-dom';
import { requireDraftMode } from '@components/branch/help';
import { getNodeTypeConfig } from '@services/modeling.service';
import { DraftModeRequired } from '@components/project/DraftModeRequired.component';
import { Modal } from "react-bootstrap";
import toast from '@services/toast.service';
import { getErrorMessage } from '@services/errors.service';
import LoadingCard from '@components/card/LoadingCard.component';
import useGlobalState from '@stores/global.state';

interface Props {
    pipelineNodeId: string;
    disableEdit?: boolean;
    searchText?: string;
}


const DBTConfigStyles = styled.div`
display: flex;
align-items: center;
img {
    width: 25px;
    margin-right: .5rem;
}
`

export const RecordInfoStyles = styled.div`

.img img {
    max-width: 40px;
    max-height: 40px;
    margin-right: 10px;
}


h2, .description {
    .edit-icon {
        opacity: 0;
    }

    &:hover {
        .edit-icon {
            opacity: 1; 
        }
    }
}
.view-in-context {
    font-size: 12px;
}
`

const PipelineNodeInfo = (props: Props) => {
    const navigate = useNavigate();
    const {data: draftVersionId} = useDraftVersionId();

    const inDraftMode = useIsInDraftMode();

    const node = usePipelineNode(props.pipelineNodeId);
    const searchText = props.searchText
    const [saving, setSaving] = useState(false);

    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [downloadLocation, setDownloadLocation] = useState('');
    const [downloadError, setDownloadError] = useState('');

    

    const onChangeTitle = useCallback(async () => {
        if (!node.data) {
            return;
        }
        const newTitle = await getPromptAnswer('Enter new name', 'Update Name', true, node.data.name);
        if (!newTitle) {
            return;
        }
        setSaving(true);
        try {
            await PipelineNodeORM.patch(props.pipelineNodeId, {
                name: newTitle
            });
            invalidatePipelineNodes();
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
       
    }, [props.pipelineNodeId, node.dataUpdatedAt]);

    const onChangeDescription = useCallback(async () => {
        if (!node.data) {
            return;
        }
        const newDescription = await getPromptAnswer('Enter new description', 'Update Description', true, node.data.description);
        if (!newDescription) {
            return;
        }

        setSaving(true);
        try {
            await PipelineNodeORM.patch(props.pipelineNodeId, {
                description: newDescription
            });
            invalidatePipelineNodes();
        } catch(err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
        
    }, [props.pipelineNodeId, node.dataUpdatedAt]);

    const onEditDescriptionClick = useCallback(() => {
        requireDraftMode(
            draftVersionId as string,
            () => onChangeDescription()
        )
    }, [draftVersionId, onChangeDescription]);
    const onEditTitleClick = useCallback(() => {
        requireDraftMode(
            draftVersionId as string,
            () => onChangeTitle()
        )
    }, [draftVersionId, onChangeTitle]);

    const [nodeType, setNodeType] = useState('STAGING');

    useEffect(() => {
        if (node.data) {
            setNodeType(node.data.node_type);
        }
    }, [node.dataUpdatedAt]);

    const convertToStagingTable = useCallback(async () => {
        const confirmed = await requireConfirmation('Changing this to a staging table will make it inaccessible in your reports. Are you sure you want to make this change?', 'Confirmation', 'Yes', 'Cancel');
        if (confirmed) {
            setSaving(true);
            await PipelineNodeORM.patch(props.pipelineNodeId, {
                node_type: 'STAGING',
            });
            setSaving(false);
            invalidatePipelineNodes();
            invalidateMissionControlDataFlowData();
        }
    }, [props.pipelineNodeId]);

    const gotoSource = useCallback(async () => {
        if (node.data && node.data?.source_record_type_id) {
            const srt = await SourceRecordTypeORM.findById(node.data?.source_record_type_id);
            navigate(`/source/${srt.source_id}`);
        }
        
    }, [node.dataUpdatedAt]);

    const convertToBusinessObject = useCallback(async () => {
        setSaving(true);
        await PipelineNodeORM.patch(props.pipelineNodeId, {
            node_type: 'BUSINESS_OBJECT',
        });
        setSaving(false);
        invalidatePipelineNodes();
        invalidateMissionControlDataFlowData();
    }, [props.pipelineNodeId]);

    const nodeTypeConfig = useMemo(() => {
        if (!node.data) {
            return undefined;
        }

        return getNodeTypeConfig(node.data);
    }, [node.dataUpdatedAt]);


    const downloadNodeData = useCallback(async () => {
        if (!node.data) {
            return;
        }
        setDownloadError('');
        setShowDownloadModal(true);
        setLoadingDownload(true);
        setDownloadLocation('');

        try {
            const download = await PipelineNodeORM.queueDownloadJob(props.pipelineNodeId, searchText || '');
            setDownloadLocation(download.url);
            
        } catch(err_msg) {
            setDownloadError(getErrorMessage(err_msg));
            console.log(err_msg)
        } finally {
            setLoadingDownload(false);
        }
        
    }, [node.data, props.pipelineNodeId, searchText])



    const badge = useMemo(() => {
        if (!node.data || !nodeTypeConfig) {
            return <></>
        }

        const bg = nodeTypeConfig.group.color;
        const icon = nodeTypeConfig.nodeType.icon;
        const label = nodeTypeConfig.nodeType.label;

        
        return <Badge pill bg={bg}>
            <i className={icon}></i> {label}
        </Badge>
    }, [node.dataUpdatedAt, nodeTypeConfig]);

    const { pageDirty, setPageDirty } = useGlobalState();


    const deleteNode = useCallback(async () => {
        const confirmed = await requireConfirmation(`Are you sure you want to delete this node? Type out the full node name ("${node.data?.name}") to continue.`, 'Delete Node', 'Delete', 'Cancel', node.data?.name);
        if (confirmed) {
            setPageDirty(false)
            await PipelineNodeORM.deleteById(props.pipelineNodeId);
            navigate('/');
            invalidateEverything();
            toast('success', 'Node deleted', 'Node deleted successfully.');
        }
    }, [props.pipelineNodeId, node.dataUpdatedAt]);

    const copyNode = useCallback(async () => {
        const newName = await getPromptAnswer('Enter new name for the copied node', 'Copy Node', false, `${node.data?.name}_copy`);
        if (!newName) {
            return;
        }
        const newNode = await PipelineNodeORM.copyPipelineNode(props.pipelineNodeId, newName);
        navigate(`/node/${newNode.id}/config`);
    }, [props.pipelineNodeId, node.dataUpdatedAt]);

    if (node.isLoading) {
        return <i className="mdi mdi-loading mdi-spin"></i>
    }
    else if (!node.data || node.status == 'error') {
        return <>
            Oops! This node doesn't exist.
        </>
    }

    return <RecordInfoStyles>
        <Modal show={showDownloadModal} onHide={() => setShowDownloadModal(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Download</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="text-center">
                                    {loadingDownload && <div>
                                            <LoadingCard action='Preparing your download' />
                                        </div>}
                                    {!loadingDownload && downloadError && (
                                        <div className="alert alert-danger">{downloadError}</div>
                                    )}
                                    {!loadingDownload && downloadLocation && !downloadError && (
                                        <div>
                                            <span style={{fontSize: 55}}><i className="mdi mdi mdi-download"></i></span><br />
                                            <a target="_blank" className="btn btn-pliable" href={downloadLocation} >Click here to download your file.</a>
                                        </div>
                                    )}
                                </Modal.Body>
                            </Modal>

        <div className="mb-1 d-flex">
            {badge}
        </div>
        <div className="d-flex center-vertically mb-1">
            
            <h2 className="mb-0 flex-1">
                {node.data.name}
                <DraftModeRequired justHideChildren><button className="icon-button edit-icon" onClick={onEditTitleClick}><i className="mdi mdi-pencil"></i></button></DraftModeRequired>
                
            </h2>
        </div>
        <div className="description font-poppins mb-1">
            {node.data.description ? node.data.description : 'No description'}
            <DraftModeRequired justHideChildren><button className="icon-button edit-icon" onClick={onEditDescriptionClick}><i className="mdi mdi-pencil"></i></button></DraftModeRequired>
            
        </div>
        {node.data.build_status === 'error' && <>
            <div style={{width: '100%'}}>
                <div className="shadow-box p-2 border-danger">
                    <h4>Build Error</h4>

                    <code>{node.data.build_error}</code>

                </div>
            </div>
        </>}
        
        
        <div className="">
            <Link to={`/dag?focusNodeId=PipelineNode:${props.pipelineNodeId}`}>View in Context &rsaquo;</Link>
        </div>
        <hr />
        
        <h4>Node Info</h4>
        <div className="font-poppins text-13">
           <div className="">
                <i className="mdi mdi-file-multiple"></i> Total Records: {node.data.total_records ? summarizeNumber(node.data.total_records) : '0'}
            </div> 
            <div>
                <i className="mdi mdi-clock"></i> Last Updated: {node.data.last_build_completed ? timeAgo(node.data.last_build_completed, 'mini-minute-now') : 'never'}

            </div>
            <div className="row mt-3">
                <div className="col-6 pe-1">
                <button className="btn btn-outline-primary btn-sm w-100" onClick={downloadNodeData}>
                    <i className="mdi mdi-download"></i> Download
                </button>
                </div>
                {inDraftMode && <>
                    <div className="col-3 ps-1 pe-1">
                        <button className="btn btn-outline-danger btn-sm  w-100" onClick={deleteNode}>
                            <i className="mdi mdi-delete"></i>
                        </button>
                    </div>
                    <div className="col-3 ps-1">
                        <button className="btn btn-outline-secondary btn-sm w-100" onClick={copyNode}>
                            <i className="mdi mdi-content-copy"></i>
                        </button>
                    </div>

                </>}
                
            </div>
                
            
            
        </div>
        
        
        <div>
            {saving && <><i className="mdi mdi-loading mdi-spin"></i> Saving</>}
        </div>
    </RecordInfoStyles>
    
}

export default PipelineNodeInfo;
