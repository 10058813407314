import styled from 'styled-components';

const SectionHeader = styled.div`
    background-color: black;
    color: white;
    text-transform: uppercase;
    padding: 8px 12px;
    font-family: "Poppins";
    font-weight: 600;
`

export default SectionHeader;