import { NodeFieldJoinPath, PipelineNode, PipelineNodeCellAction, PipelineNodeField } from "@models/pipelineNode";
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import PipelineNodeName, { PipelineNodeRelationshipName } from "../PipelineNodeName.component";
import styled from 'styled-components';
import { Editor } from "@monaco-editor/react";
import { DraftModeRequired } from "@components/project/DraftModeRequired.component";
import { useEntitlements } from "@frontegg/react";

const EditorStyles = styled.div`
.monaco-editor .suggest-widget { 
    width: 280px !important; 
    left: 0px !important;
}
`

interface Props {
    cellActions: PipelineNodeCellAction[];
    allFields?: PipelineNodeField[];
    onChangeCellActions: (newActions: PipelineNodeCellAction[]) => void
}


const example = `{
    "label": "Action Label",
    "action": "NAVIGATE_TO_NODE",
    "config": {
        "node_id": "",
        "search_query": ""
    }
}`

const PipelineNodeFieldCellActions = (props: Props) => {
    const [ editMode, setEditMode ] = useState(false);

    const { isEntitled: cellLinkingEnabled } = useEntitlements({
        featureKey: 'cell_actions'
    })

    useEffect(() => {

    }, []);

    const editorRef = useRef<any>();
    const [completionDisposable, setCompletionDisposable] = useState<any>();

    const editorDidMount = useCallback((editor: any, monacoParam: any) => {
        editorRef.current = editor;

        setCompletionDisposable(
            monacoParam.languages.registerCompletionItemProvider('*', {
                provideCompletionItems: function(model: any, position: any) {
                    //add BO objects
                    let suggestions: any[] = [];
                    if(props.allFields && props.allFields.length > 0) {

                        suggestions = props.allFields.map(f => {
                            return {
                                label: f.label,
                                kind: monacoParam.languages.CompletionItemKind.Variable,
                                insertText: `$[${f.name}]`

                            }
                        })
                    }
    
                    return {
                        suggestions: suggestions
                    };
                }
            })
        );
    }, []);

    const onValueChange = useCallback((value: string) => {
        try {
            const newActions = JSON.parse(value) as PipelineNodeCellAction[];
            props.onChangeCellActions(newActions);
        } catch(e) {
            console.error(e);
        }
    }, []);

    if(!editMode) {
        return(<div>
            {cellLinkingEnabled && <DraftModeRequired>
                <button className="btn btn-sm float-right" onClick={() => setEditMode(true)}>Edit</button>
            </DraftModeRequired>}
            <div className="small">Menu actions for cells in this column. </div>
            <ul>
                {props.cellActions.map((ca, idx) => {
                    return <li key={idx}>
                        {ca.label}
                    </li>
                })}
            </ul>
        </div>);

    }
    
    return <>
        <EditorStyles>
            <Editor
                
                onChange={(value) => onValueChange(value ? value as string : '')}
                height="150px"
                defaultLanguage="json"
                options={{
                    minimap: { enabled: false },
                }}
                defaultValue={props.cellActions ? JSON.stringify(props.cellActions, null, 2) : ''}
                onMount={(editor, monaco) => editorDidMount(editor, monaco)}
            />
        </EditorStyles>
    
    </>
}


export default PipelineNodeFieldCellActions;