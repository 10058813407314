import styled from 'styled-components';
import { Badge } from "react-bootstrap";
import { ReactNode, useCallback } from "react";
import { getPromptAnswer, prompt } from '@services/alert/alert.service';
import { useDraftVersionId } from '@stores/data.store';
import { requireDraftMode } from '@components/branch/help';

interface Stat {
    label: string;
    value: string;
    icon: string;
}
interface Props {
    iconComponent: ReactNode;
    showIcon?: boolean;
    title: string;
    description?: string;
    badges: ReactNode;
    stats: Stat[];
    onChangeTitle?: (newTitle: string) => void;
    onChangeDescription?: (newDescription: string) => void;
    saving?: boolean;
}

const Styles = styled.div`
padding: 1.5rem 12px;

.img img {
    max-width: 40px;
    max-height: 40px;
    margin-right: 10px;
}


h2, .description {
    .edit-icon {
        opacity: 0;
    }

    &:hover {
        .edit-icon {
            opacity: 1; 
        }
    }
}
`

const RecordInfo = (props: Props) => {
    const {data: draftVersionId} = useDraftVersionId();

    const onEditTitle = useCallback(async () => {
        if (!props.onChangeTitle) {
            return;
        }
        const newTitle = await getPromptAnswer('Enter new name', 'Update Name', false, props.title);

        if (newTitle) {
            props.onChangeTitle(newTitle);
        }
    }, [props.onChangeTitle, props.title]);

    const onEditTitleClick = useCallback(() => {
        requireDraftMode(
            draftVersionId as string,
            () => onEditTitle()
        )
    }, [draftVersionId]);

    const onEditDescription = useCallback(async () => {
        if (!props.onChangeDescription) {
            return;
        }
        const newDescription = await getPromptAnswer('Enter new description', 'Update Description', true, props.description);

        if (newDescription) {
            props.onChangeDescription(newDescription);
        }
    }, [props.onChangeDescription, props.description]);

    const onEditDescriptionClick = useCallback(() => {
        requireDraftMode(
            draftVersionId as string,
            () => onEditDescription()
        )
    }, [draftVersionId]);

    return <Styles>
        <div className="d-flex mb-1 center-vertically">
            {props.showIcon && (
                <div className="img">
                    {props.iconComponent}
                </div>
            )}
            
            <h2 className="mb-0 flex-1">
                {props.title}
                {props.onChangeTitle && <button className="icon-button edit-icon" onClick={onEditTitleClick}><i className="mdi mdi-pencil"></i></button>}
            </h2>
        </div>
        <p className="description font-poppins">
            {props.description ? props.description : 'No description'}
            {props.onChangeDescription && <button className="icon-button edit-icon" onClick={onEditDescriptionClick}><i className="mdi mdi-pencil"></i></button>}
        </p>
        <div className="badge-container mb-3 text-18">
            {props.badges}
        </div>
        {props.stats.map(s => {
            return <div key={s.label} className="font-poppins">
                <i className={s.icon}></i> {s.label}: {s.value}
            </div>
        })}
        
    </Styles>
}

export default RecordInfo;