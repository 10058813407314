import AsyncButton from "@components/button/AsyncButton.component";
import SaveButton from "@components/button/SaveButton.component";
import GithubConnectorConfig from "@components/connectors/GithubConnectorConfig.component";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import SourceORM, { ConfigField, RecordTypeOptionResponse, SourceRecordType } from "@models/source";
import ApiService, { JobEnqueueResponse, SingleRecordResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";
import { usePipelineNodes, useSourceRecordTypes, useSourceSetupScript, useSourceTypeConfig } from "@stores/data.store";
import { useSource } from "@stores/sources.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Form, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import { useImmer } from "use-immer";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent } from "./PageStructure.component";
import RecordInfo from "@components/card/RecordInfo.component";
import { SidebarNodeList } from "@components/nav/DagDataLibrary.component";
import { atomOneLight, CopyBlock } from "react-code-blocks";


interface RecordTypeOptionSettings {
    enabled: boolean;
    primaryKey: string[];
    recordTypeInfo: RecordTypeOptionResponse;
}

interface RecordTypeOptionProps {
    rto: RecordTypeOptionResponse;
    enabled?: boolean;
    primaryKey?: string[];
    onToggle?: (val: boolean) => void;
}

const Toolbar = styled.div`
font-size: 18px;
margin-bottom: 1rem;

display: flex;
flex-direction: row;

.left {
    .badge {
        margin-right: .5rem;
    }
}

.right {
    flex: 1;
    text-align: right;
    .btn {
        margin-left: .5rem;
    }
}

`

const RecordTypeOptionStyles = styled.div`
display: flex;



.switch {
    width: 100px;
}

.label {
    flex: 1;
    font-weight: 600;
    font-family: "Poppins";
}

.primary-key {
    width: 250px;
}

&.disabled .label {
    color: var(--ct-text-muted) !important;
}
`

const Container = styled.div`
border: solid 1px var(--ct-border-color);
padding: 2rem;
margin: 2rem;
background-color: white;
border-radius: 5px;
`

const Header = styled.div`

margin-bottom: 2rem;
padding-bottom: 2rem;
border-bottom: solid 1px var(--ct-border-color);


.header-row {
    display: flex;

    .img {
        margin-right: 2rem;
        img {
            width: 50px;
            height: 50px;
        }
    }
    
    .text {
        flex: 1;
    }
    
}


`

const ScrollingPre = styled.textarea`
max-width: 100%;
width: 100%;
height: 200px;
overflow: scroll;
font-family: monospace;
`

interface ConfigFieldProps {
    onChange: (newVal: string) => any;
    configField: ConfigField;
    value: string;
}

const ConfigFieldEditor = (props: ConfigFieldProps) => {
    if (props.configField.field_type == 'LONG_TEXT') {
        return <Form.Group className="mb-2">
            <Form.Label>{props.configField.label}</Form.Label>
            <Form.Control as="textarea" onChange={(e) => {
                props.onChange(e.target.value)
            }} value={props.value}/>
        </Form.Group>
    }

    return <Form.Group className="mb-2">
        <Form.Label>{props.configField.label}</Form.Label>
        <Form.Control onChange={(e) => {
            props.onChange(e.target.value)
        }} value={props.value}/>
    </Form.Group> 
}


const RecordTypeOption = (props: RecordTypeOptionProps) => {
    return <RecordTypeOptionStyles className={props.enabled ? 'enabled' : 'disabled'}>
        <div className="switch">
            <Form.Check
                type="switch"
                label=""
                checked={!!props.enabled}
                onChange={(e) => {
                    if (props.onToggle) {
                        props.onToggle!(!props.enabled);
                    }
                }}
            />
        </div>
        <div className="label">
            {props.rto.label}
        </div>
    </RecordTypeOptionStyles>
}

const SourceConfigPage = () => {
    const {sourceId} = useParams();

    const source = useSource(sourceId as string);

    const [syncOn, setSyncOn] = useState(false);
    const [loadingCatalog, setLoadingCatalog] = useState(false);

    const sourceRecordTypes = useSourceRecordTypes(sourceId as string);

    const sourceTypeConfig = useSourceTypeConfig(source.data ? source.data.type : '');

    const sourceSetupScript = useSourceSetupScript(source.data ? source.data.id as string: '');

    const refreshSourceFivetranStatus = useCallback(async () => {
        const result = await ApiService.getInstance().request('GET', `/sources/${sourceId}/refresh`)
        source.refetch();
    }, [sourceId]);

    const [rtoSettings, setRtoSettings] = useImmer<{
        [key: string]: RecordTypeOptionSettings,
    }>({});

    const [config, setConfig] = useImmer<{
        [key: string]: any
    }>({});

    const [secretConfig, setSecretConfig] = useImmer<{
        [key: string]: any
    }>({});

    const toggleRto = useCallback((rtoName: string, toggleValue: boolean) => {
        setRtoSettings(draft => {
            draft[rtoName].enabled = toggleValue;
        });
    }, []);

    useEffect(() => {
        if (source.data) {
            setConfig(source.data.config);

            if (source.data.fivetran) {
                setSyncOn(!source.data.fivetran.connector.paused);
            }
        }
    }, [source.dataUpdatedAt]);

    useEffect(() => {
        if (!sourceTypeConfig.data || !sourceRecordTypes.data || !sourceTypeConfig.data || !sourceTypeConfig.data.exposed_record_types) {
            return;
        }

        setRtoSettings(draft => {
            sourceTypeConfig.data!.exposed_record_types?.forEach((rt: RecordTypeOptionResponse) => {
                // Is there an existing record type?
                const existing = sourceRecordTypes.data.find(srt => srt.resource_name === rt.name);

                draft[rt.name] = {
                    enabled: !!existing,
                    primaryKey: rt.default_primary_key,
                    recordTypeInfo: rt,
                }
            });
        });
    }, [sourceTypeConfig.dataUpdatedAt, sourceRecordTypes.dataUpdatedAt]);

    const reloadCatalog = useCallback( async() => {
        setLoadingCatalog(true);
        await SourceORM.getConnectorCatalog(sourceId as string, true);
        setLoadingCatalog(false);
    }, [sourceId]);

    const changeConfig = useCallback((key: string, val: any) => {
        setConfig(draft => {
            draft[key] = val;
        });
    }, []);

    const changeSecretConfig = useCallback((key: string, val: any) => {
        setSecretConfig(draft => {
            draft[key] = val;
        });
    }, []);

    const save = useCallback(async () => {
        const srts = Object.keys(rtoSettings).map(k => {
            if (rtoSettings[k].enabled) {
                return {
                    composite_key: rtoSettings[k].primaryKey,
                    resource_name: k,
                    source_id: '',
                    name: rtoSettings[k].recordTypeInfo.label,
                };
            }
            return null;
            
        }).filter(d => !!d);
        try {
            let hasSecureConfig = false;
            Object.keys(secretConfig).forEach(k => {
                if (!!secretConfig[k]) {
                    hasSecureConfig = true;
                }
            })
            const result = await ApiService.getInstance().request('POST', `/sources/${sourceId}/init`, {
                source_record_types: srts,
                config: config,
                secure_config: hasSecureConfig ? secretConfig : null,
            }) as JobEnqueueResponse;
    
            const jobResult = await BackgroundService.getInstance().waitForJob(result.job_id);
            console.log(jobResult);

        } catch (err) {
            console.error(err);
        } finally {
            source.refetch();

        }

    }, [source.dataUpdatedAt, config, secretConfig, rtoSettings]);

    const [pausing, setPausing] = useState(false);
    const togglePause = useCallback(async () => {
        if (!source.data) {
            return;
        }
        setPausing(true);
        const currentPauseState = !!source.data.fivetran?.connector.paused;
        await SourceORM.toggleSync(source.data.id as string, !currentPauseState)
        setPausing(false);

        source.refetch();
    }, [source.dataUpdatedAt, sourceId])

    const checkForRefresh = useCallback(() => {
        console.log('Checking for refresh', source.data?.fivetran?.connector.status.sync_state);
        if (source.data && (['scheduled', 'syncing'].includes(source.data.fivetran?.connector.status.sync_state as string))) {
            refreshSourceFivetranStatus();
        }
    }, [refreshSourceFivetranStatus, source.dataUpdatedAt]);

    useEffect(() => {
        const interval = setInterval(() => {
            checkForRefresh();
        }, 5000)
        
        return () => {
            clearInterval(interval);
        }
    }, []);


    const ConfigComponent = useMemo(() => {
        if (!source.data) {
            return <></>;
        }

        const props = {
            config: config,
            secretConfig: secretConfig,
            onChangeConfig: changeConfig,
            onChangeSecretConfig: changeSecretConfig,
        }

        switch (source.data.type) {
            case 'fivetran_github':
                return <GithubConnectorConfig {...props}/>

        }
        return <>Unknown</>
    }, [source.dataUpdatedAt, config, secretConfig, changeConfig, changeSecretConfig]);

    const [loadingConnectorCard, setLoadingConnectorCard] = useState(false);

    const startConnectorCardFlow = useCallback(async () => {
        setLoadingConnectorCard(true);
        const resp = (await SourceORM.getConnectorCardUrl(sourceId as string, '/'));
        setLoadingConnectorCard(false);
        window.location.href = resp.connect_card_url;
    }, [sourceId]);

    const loadNewData = useCallback(async () => {
        await ApiService.getInstance().request('GET', `/sources/${sourceId}/check-for-new-data`);
    }, [sourceId]);

    const nodes = usePipelineNodes();

    const nodesInThisSource = useMemo(() => {
        if (!nodes.data) {
            return [];
        }

        return nodes.data.filter(n => n.source_id == sourceId);
    }, [nodes.dataUpdatedAt, sourceId])

    if (source.isLoading || !source.data || !sourceTypeConfig.data || !sourceRecordTypes.data) {
        return <Spinner/>
    }

    return <PageStructure
        pageTitle="Configure Data Source"
    >
        <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3">
                        <RecordInfo
                            title={source.data.name}
                            description={""}
                            stats={[]}
                            iconComponent={<img src={sourceTypeConfig.data.icon_path}/>}
                            badges={<>
                                {source.data.fivetran?.connector.status.setup_state == 'connected' && (
                                    <Badge
                                        pill
                                        bg="success"
                                    >Connected</Badge>
                                )}

                                {source.data.fivetran?.connector.status.sync_state == 'syncing' && (
                                    <Badge
                                        pill
                                        bg="info"
                                    >
                                        <i className="mdi mdi-loading mdi-spin"></i> Syncing
                                    </Badge>
                                )}
                            </>}
                            showIcon
                        />
                        <hr />
                        {nodesInThisSource.length == 0 && <>
                            <></>
                        </>}
                        <SidebarNodeList nodes={nodesInThisSource}/>
                    </div>
                </PaneContent>
            </Pane>
        </PageSidebar>
        <PageContent>
            <Pane>
                <nav className="subnav">
                    <div className="actions">
                        <SaveButton onClick={save}/>
                        <button 
                            className="btn btn-primary"
                            onClick={() => togglePause()}
                            disabled={pausing}
                        >
                            {pausing && <i className="mdi mdi-loading mdi-spin"></i>}
                            {!pausing && !!source.data.fivetran?.connector.paused && <i className="mdi mdi-play"></i>}
                            {!pausing && !source.data.fivetran?.connector.paused && <i className="mdi mdi-pause"></i>}
                        </button>
                    </div>
                </nav>
                <PaneContent>
                    
                    <div className="p-3">
                        {sourceTypeConfig.data.provider == 'fivetran' && <>
                            <h2>Configuration</h2>
                            <InfoAlert>
                                <div>
                                    Use Fivetran to connect to your data source.
                                </div>
                            </InfoAlert>
                            
                            <AsyncButton
                                loading={loadingConnectorCard}
                                text="Update Connection"
                                className="btn-pliable" 
                                onClick={() => startConnectorCardFlow()}
                            />
                        </>}
                        {sourceTypeConfig.data.provider == 'pliable' && <>
                            {!sourceTypeConfig.data.additional_config && !sourceTypeConfig.data.additional_secret_config && <>
                                <div className="card">
                                    <div className="card-body">
                                        No configuration needed!
                                    </div>
                                </div>
                            </>}
                            {(sourceTypeConfig.data.additional_config || []).length > 0 && <>
                                <div className="card">
                                    <div className="card-body">
                                        <h2>Configuration</h2>
                                        {sourceTypeConfig.data.additional_config?.map(c => {
                                            return <ConfigFieldEditor
                                                onChange={(newVal: string) => {
                                                    changeConfig(c.name, newVal);
                                                }}
                                                value={config.hasOwnProperty(c.name) ? config[c.name] : ''}
                                                configField={c}
                                            />  
                                        })}
                                    </div>
                                </div>
                                
                            </>}
                            {(sourceTypeConfig.data.additional_secret_config || []).length > 0 && <>
                                <div className="card">
                                    <div className="card-body">
                                        <h2>Secure Configuration</h2>
                                        <p>This data is stored securely and cannot be displayed. You can change the values by entering in new data here.</p>
                                        {sourceTypeConfig.data.additional_secret_config?.map(c => {
                                            return <ConfigFieldEditor
                                                onChange={(newVal: string) => {
                                                    changeSecretConfig(c.name, newVal);
                                                }}
                                                value={secretConfig.hasOwnProperty(c.name) ? secretConfig[c.name] : ''}
                                                configField={c}
                                            />
                                        })}
                                    </div>
                                </div>
                                
                            </>}

                            {!!sourceSetupScript.data && <>  
                                <div className="card">
                                    <div className="card-body">
                                        <h2>Setup Script</h2>

                                        <div style={{maxHeight: 400, overflowX: 'scroll'}} className="mb-3">
                                        <CopyBlock
                                            text={sourceSetupScript.data as string}
                                            language="sql"
                                            showLineNumbers={false}
                                            theme={atomOneLight}
                                            codeBlock={true}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </>}
                    </div>
                </PaneContent>
            </Pane>
        </PageContent>
    </PageStructure>

}

export default SourceConfigPage;