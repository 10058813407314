import PageTitle from '../components/pageTitle/PageTitle.component';
import ApiService, { JobEnqueueResponse } from '@services/api/api.service';
import BackgroundService from '@services/bg.service';
import { useBusinessObjectRelationship, useBusinessObjects, useSourceDataRelationships } from '@stores/data.store';
import { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const VisualTools = styled.div`
height: 40px;
box-shadow: 0px 2px 3px 0px #0000001A;
display: flex;

position: absolute;
top: 70px;
right: 20px;
z-index: 1001;
border: solid 1px var(--ct-border-color);
border-radius: 5px;
background-color: white;

div.spacer {
    display: inline-block;
    margin-left: 2rem;
    margin-right: 2rem;
    height: 38px;
    background-color: white;
    line-height: 38px;
    text-align: center;
    color: var(--ct-border-color);
}

button {
    height: 38px;
    width: 40px;
    line-height: 38px;
    text-align: center;
    background-color: white;
    color: #bbb;
    border: none;
    font-size: 20px;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

    &:disabled {
        color: var(--ct-border-color);
        cursor: default;
    }
    

    &.active {
        color: #00A1E0;

        &:hover {
            color: #00A1E0;
        }
    }

    &:hover {
        color: black;

        &:disabled {
            color: var(--ct-border-color);
        }

    }
    &:first-child {
        border-right: solid 1px var(--ct-border-color);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}
`


// 
const RelationshipConnections = () => {
    const {relationshipId} = useParams();
    const businessObjects = useBusinessObjects();
    const relationship = useBusinessObjectRelationship(relationshipId as string);

    const relationshipTitle = useMemo(() => {
        if (!relationship.data || !businessObjects.data) {
            return '';
        }

        const parent = businessObjects.data.find(bo => bo.id === relationship.data?.parent_business_object_id);
        const child = businessObjects.data.find(bo => bo.id === relationship.data?.child_business_object_id);

        if (parent && child) {
            return child.name + ' <> ' + parent.name;
        }
        return '';
    }, [relationship.dataUpdatedAt, businessObjects.dataUpdatedAt]);


    return <div className="container" style={{position: 'relative'}}>
        <PageTitle title="Source Connections"
            breadcrumbs={[{
                title: 'Relationship',
            }, {
                title: relationshipTitle,
                path: `/?drawer=rel&drawer_id=${relationshipId}`
            }]}
        >
            <Link to={`/?drawer=rel&drawer_id=${relationshipId}`} className="btn btn-primary">Back to Relationship</Link>
        </PageTitle>
        <div style={{width: '100%', height: 'calc(100vh - 50px)'}}>
        </div>
        
        
    </div>
}

export default RelationshipConnections;

