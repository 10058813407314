import ApiService from "./api/api.service";

const MIN_SLEEP = 2000;
const MAX_SLEEP = 5000;

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export default class BackgroundService {
  private static instance: BackgroundService;

  public static getInstance(): BackgroundService {
    if (!BackgroundService.instance) {
        BackgroundService.instance = new BackgroundService();
    }

    return BackgroundService.instance;
  }

  constructor() {
  }


  public async waitForJob(jobId: string) {
    let jobStatus = 'queued';
    let resp : any;
    let iteration = 1;

    while (['queued', 'started', 'deferred', 'scheduled'].includes(jobStatus)){
      if (iteration > 50) {
        throw new Error('Max wait for job exceeded')
      }
      let sleepFor = iteration * 1000;
      if (sleepFor < MIN_SLEEP) {
        // race condition where if job fails quick, we may retreive it before its exception handler has been run
        sleepFor = MIN_SLEEP;
      }
      if (sleepFor > MAX_SLEEP) {
        sleepFor = MAX_SLEEP;
      }
      await sleep(sleepFor);
      resp = await ApiService.getInstance().request('GET', `/get-job-status/${jobId}`);
      jobStatus = resp.job_status;
      iteration += 1;
    }

    if (resp.job_status === 'failed') {
      throw new Error(resp.error_message || 'Job failed');
    }

    return resp.job_result;
  }
}
