import BusinessObjectSelector from "@components/businessObjects/BusinessObjectSelector.component";
import { BusinessObject } from "@models/businessObject";
import { deleteDataMart, saveDataMart, useBusinessObjectRelationships, useBusinessObjects, useDataMart, useDraftVersionId, useDrilldown, usePipelineNodes, useProjectConfig, useReportColumns } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Badge, Container, Form, Modal, Nav, NavDropdown, Navbar, Spinner } from "react-bootstrap";
import styled from 'styled-components';
import Select, { OptionProps, SingleValue, SingleValueProps, ValueContainerProps, components as ReactSelectComponents, InputProps, Options } from 'react-select';
import { useImmer } from "use-immer";
import { ReportColumn, ReportMetric, ReportSort } from "@models/dataMart";
import ApiService, { JobEnqueueResponse, ListRecordsResponse } from "@services/api/api.service";
import { CellFormatOptions, Column, SimpleDataTable } from "@components/datatable/DataTable.component";

import PageTitle from "@components/pageTitle/PageTitle.component";
import AsyncButton from "@components/button/AsyncButton.component";
import { getErrorMessage } from "@services/errors.service";
import Warning from "@components/statusIndicators/Warning.component";
import { useNavigateToDrawer } from "@components/drawers/DrawerManager.component";
import SaveButton from "@components/button/SaveButton.component";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getPromptAnswer, requireConfirmation } from "@services/alert/alert.service";
import DeleteButton from "@components/button/DeleteButton.component";
import Danger from "@components/statusIndicators/Danger.component";
import { summarizeNumber } from "@services/formatting.service";
import toast from "@services/toast.service";
import BuildOrchestrationORM from "@models/buildOrchestration";
import BackgroundService from "@services/bg.service";
import { TableExplorer } from "@components/datatable/TableExplorer.component";
import useGlobalState from "@stores/global.state";
import Sidebar from "@components/sidebar/Sidebar.component";
import produce from "immer";
import { useRouteBlocker } from "@services/routing.service";
import Editor from "@monaco-editor/react";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent, PaneContentInnerWithSidebar, PaneContentSidebar, PaneContentWithSubnav, PaneFooter } from "./PageStructure.component";
import { ColumnFormatter, TableLoadResponse } from "@models/shared";
import { BackendRequired } from "@components/project/BackendRequired.component";
import { Backends } from "@services/config/config.service";
import LoadingCard from "@components/card/LoadingCard.component";
import Dropdown from "@components/form/Dropdown.component";
import { useDebounce } from "use-debounce";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import { PipelineNode } from "@models/pipelineNode";
import RecordInfo from "@components/card/RecordInfo.component";
import { timeAgo } from "@services/time.service";
import { shortid } from "@services/id.service";
import { useEntitlements } from "@frontegg/react";
import { DraftModeRequired } from "@components/project/DraftModeRequired.component";
import FilterConfigForm from "./SourceRecordType/FilterConfigForm.component";
import { FilterConfig } from "@models/standardizationPipeline";
import DagDataLibrary from "@components/nav/DagDataLibrary.component";

interface ReportColumnComponentProps {
    reportColumn: ReportColumn;
}

function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
}

function rgbToBrightness(rgb: {r: number, g: number, b: number}){
    return Math.round(((rgb.r * 299) +
                      (rgb.g * 587) +
                      (rgb.b * 114)) / 1000);
}

function getTextColorForHex(hex: string) {
    const rgb = hexToRgb(hex);
    if (!!rgb) {
        return (rgbToBrightness(rgb) > 125) ? 'black' : 'white'
    }
    return 'rgb(108, 117, 125)';
}

function getRangeOptions(groupSize: number, startNum: number, input_hexes: string[]) {
    const range: any[] = []
    input_hexes.forEach((hex: string, idx: number) => {
        const endNum = startNum + groupSize;
        range.push({
            formatOptions: {
                cellColor: hex,
                textColor: getTextColorForHex(hex)
            },
            range: [startNum, endNum]
        });
        startNum = endNum + 1;
    });
    return range;
}



const PageStyles = styled.div`
a {
    color: var(--ct-body-color);
}
`
const SidebarContainer = styled.div`
    width: 530px;
    background: white;
    border-right: solid 1px var(--ct-border-color);
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.05) 5px 0px 10px 0px;
    z-index: 1;
    height: 100%;
    overflow-y: auto;

    a {
        color: grey;
    }
`

const TableContainer = styled.div`
    width: calc(100% - 530px);
    overflow: hidden;
    height: 100%;

    .table-container {
        height: calc(100% - 40px);
        overflow: auto;
        width: 100%;
        flex: 1;
    }
    
    .footer-container {
        height: 40px;
        line-height: 40px;
        border-top: solid 1px var(--ct-border-color);
        padding: 0px 1rem;
    }
`


const InlineSelector = styled.div`
display: inline-block;
width: 250px;
margin-left: 1rem;
margin-right: 1rem;
`

const aggregateOptions = [
{
    label: 'Pick One',
    value: 'ANY_VALUE',
},
{
    label: 'Count Records',
    value: 'COUNT'
}, {
    label: 'Sum',
    value: 'SUM'
}, {
    label: 'Average',
    value: 'AVG',
}, {
    label: 'Maximum',
    value: 'MAX',
}, {
    label: 'Minimum',
    value: 'MIN',
}, 
// {
//     label: 'Custom SQL',
//     value: 'CUSTOM',
// }
];

const cellValidationOptions = [
    {
        label: "Basic Red/Green",
        value: {
            id: 'BASIC_RED_GREEN',
            type: 'VALUE_RANGE',
            args: {
                null_color: '#ffffff',
                zero_color: '#ffffff',
                negative_range: [
                    '#f6d2d0',
                    '#f2ada9',
                    '#ea8883',
                    '#de625e'
                ],
                positive_range: [
                    '#b6ddc9',  
                    '#8dc9a9',
                    '#64b589',
                    '#36a168'
                ]
            },
        }
    },
    {
        label: "Basic Red/Green/Yellow",
        value: {
            id: 'BASIC_RED_GREEN_YELLOW',
            type: 'VALUE_RANGE',
            args: {
                null_color: '#FFD54D',
                zero_color: '#FFD54D',
                negative_range: [
                    '#f6d2d0',
                    '#f2ada9',
                    '#ea8883',
                    '#de625e'
                ],
                positive_range: [
                    '#b6ddc9',  
                    '#8dc9a9',
                    '#64b589',
                    '#36a168'
                ]
            },
        }
    },
    {
        label: "Mark Nulls Red",
        value: {
            id: 'NULLS_RED',
            type: 'VALUE_RANGE',
            args: {
                null_color: '#de625e',
                zero_color: '#ffffff',
                negative_range: [
                    '#ffffff',
                    '#ffffff',
                    '#ffffff',
                    '#ffffff'
                ],
                positive_range: [
                    '#ffffff',
                    '#ffffff',
                    '#ffffff',
                    '#ffffff'
                ]
            },
        }
    },
    {
        label: "Positive Magenta",
        value: {
            id: 'POSITIVE_MAGENTA',
            type: 'VALUE_RANGE',
            args: {
                null_color: '#ffffff',
                zero_color: '#ffffff',
                negative_range: [
                    '#ffffff',
                    '#ffffff',
                    '#ffffff',
                    '#ffffff'
                ],
                positive_range: [
                    '#eebfdd',
                    '#efa0d2',
                    '#ef7fc7',
                    '#ee58ba'
                ]
            },
        }
    }, 
    {
        label: "Positive Blue",
        value: {
            id: 'POSITIVE_BLUE',
            type: 'VALUE_RANGE',
            args: {
                null_color: '#ffffff',
                zero_color: '#ffffff',
                negative_range: [
                    '#ffffff',
                    '#ffffff',
                    '#ffffff',
                    '#ffffff'
                ],
                positive_range: [
                    '#9fa9ea',
                    '#8895ec',
                    '#7181ee',
                    '#586cee'
                ]
            },
        }
    }
];

const ValueRangeLabel = (props: any) => {
    const { null_color, zero_color, negative_range, positive_range } = props.data.value.args;
    const nullTextColor = getTextColorForHex(null_color);
    const zeroTextColor = getTextColorForHex(zero_color);
    const minTextColor = getTextColorForHex(negative_range[0]);
    const maxTextColor = getTextColorForHex(positive_range[3]);

    return (
      <ReactSelectComponents.Option {...props}>
        <button className="btn btn-light" style={{backgroundColor: null_color, color: nullTextColor }}>
            <i className="mdi mdi-set-none"></i>
        </button>
        <ButtonGroup style={{marginLeft: '3px'}}>
            <button className="btn" style={{backgroundColor: negative_range[0], color: minTextColor, padding: '0px 8px' }}>
                Min
            </button>
            <button className="btn" style={{backgroundColor: negative_range[1] }}></button>
            <button className="btn" style={{backgroundColor: negative_range[2] }}></button>
            <button className="btn" style={{backgroundColor: negative_range[3] }}></button>
            <button className="btn" style={{backgroundColor: zero_color, color: zeroTextColor }}>
                0
            </button>
            <button className="btn" style={{backgroundColor: positive_range[0] }}></button>
            <button className="btn" style={{backgroundColor: positive_range[1] }}></button>
            <button className="btn" style={{backgroundColor: positive_range[2] }}></button>
            <button className="btn" style={{backgroundColor: positive_range[3], color: maxTextColor, padding: '0px 8px'  }}>
                Max
            </button>
        </ButtonGroup>
      </ReactSelectComponents.Option>
    );
};


const ValueRangeValue = (props: any) => {
    return (
      <ReactSelectComponents.SingleValue {...props}>
        <ValueRangeLabel {...props} />
      </ReactSelectComponents.SingleValue>
    );
};


const ValueRangeOption = (props: any) => {
    return (
      <ReactSelectComponents.Option {...props}>
        <ValueRangeLabel {...props} />
      </ReactSelectComponents.Option>
    );
  };

const windowOptions = [
    {
        label: 'None',
        value: '',
    },
    {
        label: 'Sum Last N Rows',
        value: 'CUMULATIVE_SUM',
    },
    {
        label: 'Calculate % Change',
        value: 'PERCENTAGE_CHANGE',
    },
    
    {
        label: '% of Total',
        value: 'PERCENT_OF_TOTAL',
    }
];

const formatOptions = [
    {
        label: '-',
        value: '',
    }, {
        label: 'Percent',
        value: 'PERCENT'
    }, {
        label: 'Financial',
        value: 'FINANCIAL'
    }
]


const dateFunctionOptions = [
    ['HOUR', 'Hour', 'One row per hour per day'],
    ['DAY', 'Day', 'One row per date'],
    ['WEEK', 'Week', 'One row per week'],
    ['DAY_OF_WEEK', 'Day of Week', '7 rows, one for each day of the week'],
    ['MONTH', 'Month', 'One row per calendar month'],
    ['DAY_OF_MONTH', 'Day of Month', '31 rows, one for each day of the month'],
    ['QUARTER', 'Quarter', 'One row per quarter'],
    ['YEAR', 'Year', 'One row per year'],
];


interface DownloadResponse {
    download_url: string;
}

interface ColumnSelectorOptionProps {
    column: ReportColumn;
}

const ColumnSelectorOption = (props: ColumnSelectorOptionProps) => {
    return <ColumnSelectorOptionStyles>
            <div  style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{flex: 1, display: 'block'}}>
                    <h6 className="mb-0">{props.column.column_name}</h6>
                    
                </div>
                <div>
                    {props.column.datatype == 'STRING' && <Badge bg="dark">Text</Badge>}
                    {props.column.datatype == 'ID' && <Badge bg="pliable">ID</Badge>}
                    {props.column.datatype == 'DATE' && <Badge bg="info">Date</Badge>}
                    {props.column.datatype == 'DATETIME' && <Badge bg="primary">Date + Time</Badge>}
                    {props.column.datatype == 'INT' && <Badge bg="success">Whole Number</Badge>}
                    {props.column.datatype == 'DECIMAL' && <Badge bg="light">Decimal</Badge>}
                    {props.column.datatype == 'FORMULA' && <Badge bg="dark">Formula</Badge>}
                </div>
            </div>

            
            <div style={{lineHeight: '12px', fontSize: '12px'}}>
                {props.column.datatype == 'FORMULA' && (
                    <span>Custom Formula</span>
                )}
                {props.column.path.length > 0 && (
                    <span>Lineage: {props.column.path.filter(p => p.object_type === 'NODE').map((p, idx) => {
                        return <span>
                            {idx > 0 && <span className="ms-1 me-1">&rarr;</span>}
                            <span>{p.label}</span>
                        </span>
                    })}</span>
                )}
            </div>
            
        </ColumnSelectorOptionStyles>
}


const ColumnSelectedValue = (props: SingleValueProps<ReportColumn>) => {
    return <div style={{gridArea: '1/1/2/3'}}>
        <ColumnSelectorOption column={props.data}/>
    </div> 
    
}



const ColumnSelectorOptionStyles = styled.div`
padding: 0px;
`

const ColumnSelectorOptionWrapperStyles = styled.div`
&:hover {
    cursor: pointer;
    background-color: var(--pliable-yellow-light);
}

&.selected {
    background-color: var(--pliable-blue-bg);
}

padding: 8px;
`

const ColumnSelectorOptionWrapper = (props: OptionProps<ReportColumn>) => {
    return <div ref={props.innerRef} {...props.innerProps}>
        <ColumnSelectorOptionWrapperStyles className={props.isSelected  ? 'selected' : ''}>
            <ColumnSelectorOption column={props.data}/>
        </ColumnSelectorOptionWrapperStyles>
    </div>
}

const ColumnSelectorInput = (props: InputProps<ReportColumn>) => {
    return <div style={{height: '53.6px', gridArea: '1/1/2/3', lineHeight: '49.6px'}}>

    
        <ReactSelectComponents.Input {...props}>{props.children}</ReactSelectComponents.Input>
    </div>
}


// Hacky way to know what the back end will name the column. Mimics gold.get_column_output_label
function getColumnOutputLabel(col: ReportColumn): string {
    if (col.function) {
        return `${col.function}(${col.column_selector})`;
    }
    return col.column_selector;
}



interface ColumnSelectorProps {
    columns: ReportColumn[];
    selectedId: string;
    onChange: (newId: string) => void;
}

const ColumnSelector = (props: ColumnSelectorProps) => {
    return <Select
        components={{ Option: ColumnSelectorOptionWrapper, SingleValue: ColumnSelectedValue, Input: ColumnSelectorInput }}
        options={props.columns}
        value={props.columns.find(c => c.id === props.selectedId)}
        onChange={(e) => {
            if (e) {
                // @ts-ignore
                props.onChange(e.id);

            } else {
                props.onChange('');
            }
        }}
        getOptionValue={(option) => option.id}
        isOptionSelected={(option: ReportColumn, selectValue: Options<ReportColumn>) => {
            if (selectValue.length === 0) {
                return false;
            }
            return selectValue[0].id === option.id;
        }}
    />
}

interface DrilldownTableProps {
    dataMartId: string;
    metricIdx: number;
    breakdownValues: string[];
}

const DrilldownTable = (props: DrilldownTableProps) => {
    const data = useDrilldown(props.dataMartId, props.metricIdx, props.breakdownValues);

    if (data.isLoading || !data.data) {
        return <div>
            <i className="mdi mdi-loading mdi-spin"></i> Drilling down...
        </div>
    }

    return <SimpleDataTable
        data={data.data}
    />
}


const ReportingPage = () => {
    const { dataMartId } = useParams();
    const pipelineNodes = usePipelineNodes();

    const navigate = useNavigate();


    const [selectedRootNode, setSelectedRootNode] = useState<PipelineNode|undefined>(undefined);

    const setDataLibraryEnabled = useGlobalState((state: any) => state.setLibraryEnabled);

    const [reportType, setReportType] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [luceneTableQuery, setLuceneTableQuery] = useState('');

    const [activeMetricIdx, setActiveMetricIdx] = useState<number>(-1);

    setDataLibraryEnabled(false);

    const { isEntitled: isSnapshotsEntitled, justification: snapshotJustification } = useEntitlements({
        featureKey: "snapshots",
    });

    const { isEntitled: isVisualizationsEnabled } = useEntitlements({
        featureKey: "visualizations",
    });

    const dateFunctionSelectOptions = useMemo(() => {
        return dateFunctionOptions.map((val) => {
            return {
                label: val[1],
                value: val[0],
            }
        });
    }, []);

    const dataMart = useDataMart(dataMartId as string);



    const [currentTitle, setCurrentTitle] = useState('New Report');
    const [currentDescription, setCurrentDescription] = useState('');
    const [currentOutputViewName, setCurrentOutputViewName] = useState('');
    const [currentFilters, setCurrentFilters] = useState<FilterConfig>({
        filters: [],
        logic_gate: 'AND',
    });

    useEffect(() => {
        if (!pipelineNodes.data) {
            return;
        }
        if (dataMart.data) {
            setCurrentTitle(dataMart.data.name);
            setCurrentDescription(dataMart.data.description);
            setCurrentOutputViewName(dataMart.data.table_name ? dataMart.data.table_name : '');

            if (dataMart.data.breakdown && dataMart.data.breakdown.length > 0) {
                const bo = pipelineNodes.data.find(b => b.id == dataMart.data!.breakdown![0].column.path[0].object_id)
                setSelectedRootNode(bo);
            }
            setBreakdown(dataMart.data.breakdown ? dataMart.data.breakdown : []);
            setMetrics(dataMart.data.metrics ? dataMart.data.metrics : []);
            setReportType(dataMart.data.report_type as string);
            setDefaultSort(dataMart.data.default_sort || []);
            setIncludeInSnapshots(dataMart.data.include_in_snapshots);
            calcTableColumns();
        } else {
            setCurrentTitle('New Report');
            setBreakdown([]);
            setSelectedRootNode(undefined);
            setMetrics([]);
            setReportType('');
        }
    }, [dataMart.dataUpdatedAt, pipelineNodes.dataUpdatedAt]);

    const [tableCacheBust, setTableCacheBust] = useState((new Date()).toISOString());


    const [metrics, setMetrics] = useImmer<ReportMetric[]>([]);

    const [loading, setLoading] = useState(false);

    const [includeInSnapshots, setIncludeInSnapshots] = useState(false);

    const [breakdown, setBreakdown] = useImmer<ReportMetric[]>([]);
    const [defaultSort, setDefaultSort] = useImmer<ReportSort[]|undefined>(undefined);
    const reportColumns = useReportColumns(selectedRootNode ? selectedRootNode.id as string : '');
    const [tableColumns, setTableColumns] = useState<Column[]>([]);

    const [reportRecordCount, setReportRecordCount] = useState(0);

    const columnOptions = useMemo(() => {
        if (!reportColumns.data) {
            return [];
        }

        return reportColumns.data;
    }, [reportColumns.dataUpdatedAt]);

    const columnOptionsWithCustom = useMemo(() => {
        return produce(columnOptions, draft => {
            draft.unshift({
                id: 'CUSTOM',
                label: 'CUSTOM',
                datatype: 'FORMULA',
                column_name: 'CUSTOM',
                column_selector: 'CUSTOM',
                column_id: 'CUSTOM',
                path: []
            });
        })
    }, [columnOptions]);


    const columnFormatters = useMemo(() => {
        const formatters: { [column: string]: (value: any) => CellFormatOptions; } = {};
        
        if(!dataMart.data || !metrics){
            return formatters;
        }


        metrics.forEach((metric) => {
            // only have one custom formatting option which is ValueRange for cell color
            if(metric.format?.cell_color){
                console.log('MEtric has cell color', metric);
                // we have a cell color formatter for this column - lets get column shape and create the formatter function
                const columnShape = dataMart.data?.shape?.columns.find((colStats) => colStats.key == metric.name);
                
                if (!columnShape) {
                    return;
                }
                const min = parseInt(columnShape!.min);
                const max = parseInt(columnShape!.max);

                const range: any[] = [];

                if (min < 0 ) {
                    if(max < 0) {
                        range.push(...getRangeOptions(Math.floor(Math.abs(max - min)/4), min, metric.format?.cell_color.args.negative_range));
                    }else{
                        range.push(...getRangeOptions(Math.floor(Math.abs(0 - min)/4), min, metric.format?.cell_color.args.negative_range));
                        range.push(...getRangeOptions(Math.floor(Math.abs(0 - max)/4), 1, metric.format?.cell_color.args.positive_range));
                    }
                }else{
                    range.push(...getRangeOptions(Math.floor(Math.abs(0 - max)/4), 1, metric.format?.cell_color.args.positive_range));
                }

                const nullFormatOptions = {
                    cellColor: metric.format?.cell_color.args.null_color,
                    textColor: getTextColorForHex(metric.format?.cell_color.args.null_color)
                };

                const zeroFormatOptions = {
                    cellColor: metric.format?.cell_color.args.zero_color,
                    textColor: getTextColorForHex(metric.format?.cell_color.args.zero_color)
                };
                
                // this is the function the table will call to get custom format options
                formatters[metric.name] = (value: any) => {
                    if (value == 0) {
                        return zeroFormatOptions;
                    } 
                    if (!value) {
                        return nullFormatOptions;
                    }
                    // find the first range this item fits in.
                    let found = range.find((a) => value >= a.range[0] && value <= a.range[1]);
                    if (!!found) {
                        return found.formatOptions;
                    }
                    return {};
                }
            }
        });

        console.log('Formatters:', formatters);

        return formatters

    }, [dataMart.dataUpdatedAt, metrics]);

    const dateColumnOptions = useMemo(() => {
        if (!reportColumns.data) {
            return [];
        }

        return reportColumns.data.filter(c => {
            return c.path.length === 1 && ['DATE', 'DATETIME'].includes(c.datatype as string);
        });
    }, [reportColumns.dataUpdatedAt]);


    const defaultSortOptions = useMemo(() => {
        const options: any[] = [];
        const handleMetric = (label:string, metric: ReportMetric) => {
            options.push({
                label: `${label} DESC`,
                value: {
                    metric_id: metric.id, 
                    direction: 'DESC'
                }
            });
            options.push({
                label: `${label} ASC`,
                value: {
                    metric_id: metric.id,
                    direction: 'ASC'
                }
            })
        }
        breakdown.forEach((b) => handleMetric(b.column.column_name, b));
        metrics.forEach((m) => handleMetric(m.name, m));
        return options;
    }, [metrics])

    const availableDefaultSortOptions = useMemo(() => {
        if(!!defaultSort) {
            return defaultSortOptions.filter((op) => {
                let found = defaultSort.find((sort) => sort.metric_id == op.value.metric_id);
                return !found;
            });
        }
        return [];
    }, [defaultSortOptions, defaultSort]);

    const defaultSortSelectValue = useMemo(() => {
        if(!!defaultSort){
            return defaultSortOptions.filter((op) => !!defaultSort.find((sort) => sort.metric_id == op.value.metric_id && sort.direction == op.value.direction))
        }

    }, [defaultSort, defaultSortOptions])

    const tableExplorerDefaultSort = useMemo(() => {
        if(!!defaultSort){
            const ops: any[] = [];
            defaultSort.forEach((rs) => {
                let found = null;
                found = breakdown.find(m => m.id == rs.metric_id);

                if (!!found){
                    ops.push(
                        {id: getColumnOutputLabel(found.column), desc: rs.direction == 'DESC'}
                    )
                    return;
                }

                found = metrics.find(m => m.id == rs.metric_id);
                
                if(!!found) {
                    ops.push(
                        {id: found.name, desc: rs.direction == 'DESC'}
                    )
                }
            });
            return ops;
        }
        return null
    }, [defaultSort]);

    

    const [downloading, setDownloading] = useState(false);

    const download = useCallback(async () => {
        setDownloading(true);
        const result = await ApiService.getInstance().request('GET', `/datamarts/${dataMartId}/data`, {
            'download': 'y'
        }) as DownloadResponse;

        window.location.href = result.download_url;
        setDownloading(false);
    }, [dataMartId])

    const allowRun = useMemo(() => {
        if (!!selectedRootNode && breakdown.length > 0) {
            return true;
        }
        return false;
    }, [selectedRootNode, breakdown]);

    const [showMetrics, setShowMetrics] = useState(false);

    const [showCustomGroupBy, setShowCustomGroupBy] = useState(false);

    const [saving, setSaving] = useState(false);

    const handleSearchEnterKey = useCallback((event:any) => {
        if (event.key === 'Enter') {
            setLuceneTableQuery(searchInput);
        }
    }, [searchInput])

    const preventColumnDupes = useCallback(() => {
        const counts : { [Col: string]: number} = {};

        const getCountForLabel = (label: string) => {
            if (!counts[label]) {
                counts[label] = 1;
            }else{
                counts[label] += 1;
            }
            return counts[label]
        }

        breakdown.forEach(c => {
            let label = getColumnOutputLabel(c.column);
            const b_count = getCountForLabel(label);
        });

        const cleanedMetrics: ReportMetric[] = [];
        metrics.forEach(c => {
            let label = c.name;
            const m_count = getCountForLabel(label);
            const copyMetric = structuredClone(c);
            if (m_count > 1) {
                copyMetric.name = `${label} (${m_count})`;
                counts[copyMetric.name] = m_count;
            }
            cleanedMetrics.push(copyMetric);
        });

        return cleanedMetrics;

    }, [breakdown, metrics]);

    const saveReport = useCallback(async () => {
        setSaving(true);

        let theTitle = currentTitle;

        if (theTitle === 'New Report') {
            theTitle = await getPromptAnswer('Please enter a title for this report');
        }

        const metricsCleanedNames = preventColumnDupes();
        setMetrics(metricsCleanedNames);

        const dm = {
            id: dataMartId ? dataMartId: null,
            name: theTitle,
            breakdown: breakdown,
            metrics: metricsCleanedNames,
            default_sort: defaultSort,
            sql: '',
            description: currentDescription,
            report_type: reportType,
            table_name: currentOutputViewName,
            include_in_snapshots: includeInSnapshots,
            filters: currentFilters
        }

        try {
            const result = await saveDataMart(dm);
            setLoading(true);
            calcTableColumns();
            const orchestration = await BuildOrchestrationORM.buildWithSelector('+' + result.name);
            await BackgroundService.getInstance().waitForJob(orchestration.job_id);

            // Reload the orchestration to see what happened
            const finalOrchestation = await BuildOrchestrationORM.findById(orchestration.id as string);

            if (finalOrchestation.status == 'ERROR') {
                
                Object.keys(finalOrchestation.build_executions).forEach(k => {
                    const be = finalOrchestation.build_executions[k];
                    if (be.status == 'ERROR') {
                        toast('danger', 'Build Error', be.build_exception ? be.build_exception.message : 'Unknown error');
                    }
                })
            }

            if (dataMart) {
                dataMart.refetch();
            }
            dataMart.refetch();
            setTableCacheBust((new Date()).toISOString());


            if (!dataMartId) {
                navigate(`/reporting/${result.id as string}`)
            }
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setLoading(false);
            setSaving(false);
            setPageDirty(false);
        }
        
        
    }, [breakdown, dataMart, metrics, currentTitle, dataMartId, reportType, currentDescription, currentOutputViewName]);

    
    const { pageDirty, setPageDirty } = useRouteBlocker(saveReport);
    const chooseRootNode = useCallback((bo: PipelineNode|undefined) => {
        setBreakdown([]);
        setMetrics([]);
        setSelectedRootNode(bo);
        setPageDirty(true);
    }, [setPageDirty]);

    const updateTitle = useCallback((newTitle: string) => {
        setCurrentTitle(newTitle);
        setPageDirty(true);
    }, [setPageDirty])

    const updateDescription = useCallback((newDescription: string) => {
        setCurrentDescription(newDescription);
        setPageDirty(true);
    }, [setPageDirty])

    const addGroupBy = useCallback((col: ReportColumn) => {
        setBreakdown(draft => {
            draft.push({
                id: shortid(),
                name: col.column_name,
                column: col
            }
            );
        });
        setPageDirty(true);
    }, [setPageDirty]);


    const setGroupByColumn = useCallback((colId: string, idx: number) => {
        if (!reportColumns.data) {
            return;
        }

        const col = reportColumns.data.find(c => c.id === colId);
        if (col) {
            setBreakdown(draft => {
                draft[idx].column = {...col} 
            });
        } else {
            setBreakdown(draft => {
                draft[idx] = {
                    id: shortid(),
                    name: '',
                    column: {
                        id: '',
                        column_name: '',
                        column_selector: '',
                        path: [],
                        label: '',
                    }
                }
                ;
                
            });
        }
        // setPageDirty(true);
        
    }, [reportColumns.dataUpdatedAt, setPageDirty]);

    const setGroupByFunction = useCallback((func: string, idx: number) => {
        if (!reportColumns.data) {
            return;
        }

        setBreakdown(draft => {
            draft[idx].column.function = func;
        });
        setPageDirty(true);
        
    }, [reportColumns.dataUpdatedAt, setPageDirty]);

    const addEmptyGroupBy = useCallback(() => {
        setBreakdown(draft => {
            draft.push({
                id: shortid(),
                name: '',
                column: {
                    id: '',
                    column_name: '',
                    column_selector: '',
                    path: [],
                    label: '',
                }
            });
        });
        setPageDirty(true);
    }, [setPageDirty]);

    const removeGroupBy = useCallback((idx: number) => {
        setBreakdown(draft => {
            draft.splice(idx, 1);
        });
        setPageDirty(true);
    }, [setPageDirty]);


    const addMetric = useCallback(() => {
        setMetrics(draft => {
            draft.push({
                id: shortid(),
                name: '',
                aggregator: 'ANY_VALUE',
                column: {
                    id: '',
                    label: '',
                    column_selector: '',
                    column_name: '',
                    path: [],
                }
            });
        });
        setPageDirty(true);
    }, [setPageDirty]);

    const removeMetric = useCallback((idx: number) => {
        setMetrics(draft => {
            draft.splice(idx, 1);

            const new_ids = breakdown.map((rm) => rm.id).concat(draft.map((rm) => rm.id));
            setDefaultSort(defaultSort?.filter((rs) => new_ids.includes(rs.metric_id)))

        });
        setPageDirty(true);
    }, [defaultSort, setPageDirty]);

    const updateMetricCustomSQL = useCallback((idx: number, customSql: string) => {
        setMetrics(draft => {
            draft[idx].custom_sql = customSql;
        });
        setPageDirty(true);
    }, [setPageDirty]);

    const setFirstGroupByFunction = useCallback((f: string) => {
        setBreakdown(draft => {
            draft[0].column.function = f;
        });
        setPageDirty(true);
    }, [setPageDirty]);

    const deleteReport = useCallback(async () => {
        if (!dataMartId) {
            return;
        }

        await deleteDataMart(dataMartId);
        toast('success', 'Success!', 'Report deleted');
        navigate('/');


    }, [dataMartId]);

    const onClickDelete = useCallback(async () => {
        const confirmed = await requireConfirmation('Are you sure you want to delete this report?', 'Confirm Deletion', 'Delete', 'Cancel');
        if (confirmed) {
            deleteReport();
        }
    }, [deleteReport]);

    const onReportDataLoaded = useCallback((reportData: TableLoadResponse) => {
        setReportRecordCount(reportData.total_records);
    }, []);

    const setMetricColumn = useCallback((newColumnId: string, metricIdx: number) => {

        const col = columnOptionsWithCustom.find(co => co.id === newColumnId);
        if (col) {
            setMetrics(draft => {
                if(!draft[metricIdx].name) {
                    draft[metricIdx].name = col.column_name;
                }

                draft[metricIdx].column = col;
                draft[metricIdx].custom_sql = '';
            });
            setPageDirty(true);
        }
        
        
    }, [columnOptionsWithCustom, setPageDirty]);

    const openColumnEditor = useCallback((idx: number) => {
        setActiveMetricIdx(idx);
    }, []);

    useEffect(() => {
        if (activeMetricIdx >= 0) {
            setActiveMetric({
                ...metrics[activeMetricIdx]
            });

            console.log('Active metric is now:', metrics[activeMetricIdx]);
        } else {
            setActiveMetric(undefined);
        }
    }, [activeMetricIdx, metrics]);

    const [activeMetric, setActiveMetric] = useImmer<ReportMetric|undefined>(undefined);

    const setActiveMetricName = useCallback((newName: string) => {
        setActiveMetric(draft => {
            draft!.name = newName;
        });
    }, []);

    const setActiveMetricWindowArguments = useCallback((argIdx: number, value: any) => {
        setActiveMetric(draft => {
            if (!draft!.window_arguments) {
                draft!.window_arguments = []
            }

            draft!.window_arguments![argIdx] = value;
        });
    }, []);

    const setActiveMetricAggregator = useCallback((newAggregator: string) => {
        setActiveMetric(draft => {
            draft!.aggregator = newAggregator;

        });
    }, []);

    const setActiveMetricWindowFunction = useCallback((func: string) => {
        setActiveMetric(draft => {
            draft!.window_function = func;
            draft!.window_arguments = [-2, 0];

        });
    }, []);

    const setActiveMetricValueFormatter = useCallback((formatter: string) => {
        console.log('Setting formatter to:', formatter);
        setActiveMetric(draft => {
            if (!draft!.format) {
                draft!.format = {};
            }

            draft!.format.cell_value = {
                type: 'CELL_VALUE',
                id: formatter,
                args: {}
                
            }
        });
    }, []);
    
    const setActiveMetricCellColorFormatter = useCallback((formatter: ColumnFormatter | null) => {
        setActiveMetric(draft => {
            draft!.format!.cell_color = formatter;
        });
    }, []);

    const setActiveMetricCustomSql = useCallback((newSql: string) => {
        setActiveMetric(draft => {
            draft!.custom_sql = newSql;
        });
    }, []);

    const setActiveMetricColumn = useCallback((newColumnId: string) => {
        const col = columnOptionsWithCustom.find(co => co.id === newColumnId);
        if (col) {
            setActiveMetric(draft => {
                draft!.column = col;

                draft!.custom_sql = '';
            });
        }
    }, [columnOptionsWithCustom]);

    const saveActiveMetric = useCallback(() => {
        if (!activeMetric) {
            return;
        }

        let metricToSave: ReportMetric;
        if (activeMetric.column.id === 'CUSTOM') {
            const sql = editorRef.current.getValue();
            metricToSave = produce(activeMetric, draft => {
                draft.custom_sql = sql;
            });
        } else {
            metricToSave = activeMetric;
        }

        console.log('Metric to save:', metricToSave);
        
        setMetrics(draft => {
            draft[activeMetricIdx] = metricToSave;
        })
        setActiveMetric(undefined);
        setActiveMetricIdx(-1);
        setPageDirty(true);
    }, [activeMetric, activeMetricIdx, setPageDirty]);

    const editorRef = useRef<any>();
    const [completionDisposable, setCompletionDisposable] = useState<any>();
    
    const editorDidMount = useCallback((editor: any, monacoParam: any) => {
        editorRef.current = editor;

        // editor.getModel().onDidChangeContent((event: any) => {
        //     setIsSqlDirty(report.sql != editor.getValue().trim());
        // })

        // editor.addAction({
        //     id: "runCurrentSql",
        //     label: "Run SQL",
        //     keybindings: [
        //         monacoParam.KeyMod.CtrlCmd | monacoParam.KeyCode.Enter,
        //     ],
        //     precondition: null,
        //     contextMenuGroupId: "navigation",
	    //     contextMenuOrder: 1.5,
        //     run: () => {
        //         runSQL();
        //     },
        // });

        // editor.addAction({
        //     id: "revertCurrentSql",
        //     label: "Revert SQL",
        //     keybindings: [
        //         monacoParam.KeyMod.WinCtrl | monacoParam.KeyCode.KeyX,
        //     ],
        //     precondition: null,
        //     contextMenuGroupId: "navigation",
	    //     contextMenuOrder: 1.6,
        //     run: () => {
        //         revertSQL();
        //     },
        // });

        // editor.addAction({
        //     id: "saveCurrentSql",
        //     label: "Save Changes",
        //     keybindings: [
        //         monacoParam.KeyMod.WinCtrl | monacoParam.KeyCode.KeyS,
        //     ],
        //     precondition: null,
        //     contextMenuGroupId: "navigation",
	    //     contextMenuOrder: 1.7,
        //     run: () => {
        //         save();
        //     },
        // });
        setCompletionDisposable(
            monacoParam.languages.registerCompletionItemProvider('*', {
                provideCompletionItems: function(model: any, position: any) {

                    // add BO objects
                    const suggestions: any[] = [];
                    columnOptions.forEach(co => {
                        const pathComponents = co.path.filter(p => p.object_type == 'NODE').map(p => {
                            return "'" + p.label + "'";
                        }).join(', ');
                        suggestions.push({
                            label: co.label,
                            kind: monacoParam.languages.CompletionItemKind.Variable,
                            insertText: `REF('${co.column_name}', ${pathComponents})`
                        });
                    });

                    console.log('Suggestions', suggestions);
                    

                    return {
                        suggestions: suggestions
                    };
                }
            })
        );
    }, [columnOptions]);

    useEffect(() => {
        return () => {
            if (
                completionDisposable?.dispose &&
                typeof completionDisposable.dispose === "function"
            ) {
                completionDisposable.dispose();
            }
        };
    }, [completionDisposable]);

    const onGroupByDragEnd = useCallback((res: DropResult) => {
        if (res.destination) {
            setBreakdown(draft => {
                const movingItem = draft[res.source.index];
                draft.splice(res.source.index, 1);
                draft.splice(res.destination!.index, 0, movingItem);
            });
            setPageDirty(true);
        }
        
    }, []);


    const onMetricDragEnd = useCallback((res: DropResult) => {
        if (res.destination) {
            setMetrics(draft => {
                const movingItem = draft[res.source.index];
                draft.splice(res.source.index, 1);
                draft.splice(res.destination!.index, 0, movingItem);
            });
            setPageDirty(true);
        }
    }, []);

    const cloneMetric = useCallback((idx: number) => {
        const theMetric = metrics[idx];
        const copy = produce(theMetric, draft => {
            draft.name = draft.name + ' (Copy)'
            draft.id = shortid();
        })
        setMetrics(draft => {
            draft.push(copy);
        });
    }, [metrics])

    const [drilldownProps, setDrilldownProps] = useState<DrilldownTableProps|undefined>(undefined);
    const [showDrilldown, setShowDrilldown] = useState(false);

    const drilldown = useCallback((metricKey: string, rowData: any) => {
        if (!dataMart.data) {
            return;
        }

        if (!dataMart.data.metrics || !dataMart.data.breakdown) {
            toast('danger', 'Build Required', 'You need to run your report before you can drill down');
            return;
        }

        const theMetricIdx = dataMart.data.metrics.findIndex(m => m.name == metricKey);


        if (theMetricIdx < 0) {
            toast('danger', 'Build Required', 'You need to run your report before you can drill down');
            return;
        }

        const breakdownValues = dataMart.data.breakdown.map(o => {
            return rowData[getColumnOutputLabel(o.column)];
        });

        setDrilldownProps({
            dataMartId: dataMart.data.id as string,
            metricIdx: theMetricIdx,
            breakdownValues: breakdownValues,
        });

        setShowDrilldown(true);
        
    }, [dataMart.dataUpdatedAt]);

    const projectConfig = useProjectConfig();

    const calcTableColumns = useCallback(() => {
        const columns: Column[] = [];

        breakdown.forEach(c => {
            const label = getColumnOutputLabel(c.column);
            columns.push({
                header: label,
                key: label,
            });
        });

        metrics.forEach(c => {
            columns.push({
                header: c.name,
                key: c.name,
                formatter: c.format?.cell_value?.id
            });
        });

        setTableColumns(columns);
    }, [breakdown, metrics, drilldown, projectConfig.dataUpdatedAt]);


    const updateOutputViewName = useCallback((newName: string) => {
        setCurrentOutputViewName(newName);
        setPageDirty(true);
    }, [setCurrentOutputViewName])

    const onToggleIncludeIndSnapshots = useCallback(() => {
        setIncludeInSnapshots(!includeInSnapshots);
        setPageDirty(true);
    }, [includeInSnapshots]);

    const onChangeFilters = useCallback((updatedFilters: FilterConfig) => {
        setCurrentFilters(updatedFilters);
        setPageDirty(true);
    }, [currentFilters])


    return <PageStructure
        pageTitle={currentTitle}
        breadcrumbs={[
            {
                title: 'Reporting',
            }
        ]}
    >
        <Modal size="xl" show={showDrilldown} onHide={() => setShowDrilldown(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Drill Down (Beta)</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!!drilldownProps && (
                    <DrilldownTable
                        {...drilldownProps}
                    />
                )}
                
            </Modal.Body>
        </Modal>
        <Modal show={activeMetricIdx >= 0} size="xl" onHide={() => setActiveMetricIdx(-1)}>
            <Modal.Header closeButton>
                <Modal.Title>Configure Column</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {activeMetric && (
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control value={activeMetric.name} onChange={(e) => setActiveMetricName(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <ColumnSelector
                                columns={columnOptionsWithCustom}
                                selectedId={activeMetric.column.id}
                                onChange={(e) => setActiveMetricColumn(e)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Format</Form.Label>
                            <Dropdown
                                options={formatOptions}
                                onChange={setActiveMetricValueFormatter}
                                selected={activeMetric.format?.cell_value?.id}
                            />
                        </Form.Group>
                        {activeMetric.column.id === 'CUSTOM' && (
                            <Form.Group className="mb-3">
                                <Form.Label>Enter Formula</Form.Label>
                                    <Editor
                                        className="mt-3 mb-3"
                                        height="300px"
                                        defaultLanguage="sql"
                                        options={{
                                            readOnly: false,
                                            minimap: { enabled: false },
                                        }}
                                        defaultValue={activeMetric.custom_sql}
                                        onMount={(editor, monaco) => editorDidMount(editor, monaco)}
                                    />
                                
                                {/* <Form.Control className="input-code" as="textarea" value={activeMetric.custom_sql} onChange={(e) => setActiveMetricCustomSql(e.target.value)}/> */}
                            </Form.Group>
                        )}
                        {activeMetric.column.id !== 'CUSTOM' && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Aggregation</Form.Label>
                                    <Select
                                        className="mb-2"
                                        value={aggregateOptions.find(a => a.value === activeMetric.aggregator)}
                                        placeholder="Choose aggregation"
                                        options={aggregateOptions}
                                        onChange={(e) => {
                                            setActiveMetricAggregator(e ? e.value : '');
                                        }}
                                    ></Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Window Function (Optional)</Form.Label>
                                    <Select
                                        className="mb-2"
                                        value={windowOptions.find(a => a.value === activeMetric.window_function)}
                                        placeholder="Choose window function"
                                        options={windowOptions}
                                        onChange={(e) => {
                                        setActiveMetricWindowFunction(e ? e.value : '');
                                        }}
                                    ></Select>
                                </Form.Group>
                                {!!activeMetric.window_function && ['CUMULATIVE_SUM', 'PERCENTAGE_CHANGE'].includes(activeMetric.window_function) && (
                                    
                                    <Form.Group className="mb-3">
                                        <Form.Label># of records to include</Form.Label>
                                        <div className="row">
                                            <div className="col-6 pe-1">
                                                <Form.Label className="small">Range Start</Form.Label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    value={activeMetric.window_arguments ? activeMetric.window_arguments[0] : 2}
                                                    onChange={(e) => {
                                                        setActiveMetricWindowArguments(0, e.target.value);
                                                    }}
                                                />
                                                {activeMetric.window_arguments && (
                                                    <Form.Text>
                                                        {activeMetric.window_arguments[0] > 0 && (
                                                            <span>{activeMetric.window_arguments[0]} row(s) forward</span>
                                                        )}
                                                        {activeMetric.window_arguments[0] == 0 && (
                                                            <span>Current row</span>
                                                        )}
                                                        {activeMetric.window_arguments[0] < 0 && (
                                                            <span>{Math.abs(activeMetric.window_arguments[0])} row(s) back</span>
                                                        )}
                                                    </Form.Text>
                                                )}
                                                
                                            </div>
                                            <div className="col-6 ps-1">
                                            <Form.Label className="small">Range End</Form.Label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    value={activeMetric.window_arguments ? activeMetric.window_arguments[1] : 2}
                                                    onChange={(e) => {
                                                        setActiveMetricWindowArguments(1, e.target.value);
                                                    }}
                                                />
                                                {activeMetric.window_arguments && (
                                                    <Form.Text>
                                                        {activeMetric.window_arguments[1] > 0 && (
                                                            <span>{activeMetric.window_arguments[1]} row(s) forward</span>
                                                        )}
                                                        {activeMetric.window_arguments[1] == 0 && (
                                                            <span>Current row</span>
                                                        )}
                                                        {activeMetric.window_arguments[1] < 0 && (
                                                            <span>{Math.abs(activeMetric.window_arguments[1])} row(s) back</span>
                                                        )}
                                                    </Form.Text>
                                                )}
                                            </div>
                                        </div>
                                        

                                    </Form.Group>
                                    
                                )}
                            </>
                            
                        )}
                        <Form.Group className="mb-3">
                                    <Form.Label>Cell Validation</Form.Label>
                                    <Select
                                        className="mb-2"
                                        value={cellValidationOptions.find(a => !!a.value && a.value.id === activeMetric.format?.cell_color?.id)}
                                        placeholder="Choose format"
                                        options={cellValidationOptions}
                                        isSearchable={false}
                                        isClearable={true}
                                        components={{
                                            SingleValue: ValueRangeValue, 
                                            Option: ValueRangeOption 
                                        }}
                                        onChange={(e) => {
                                            setActiveMetricCellColorFormatter(e ? e.value : null)
                                        }}
                                    ></Select>
                                </Form.Group>
                        {/* <div className="mb-1">
                            <h5 className="mb-0">Prepend & Append</h5>
                            <Form.Text>If you'd like to prepend or append to the output of this column, you can do so here.</Form.Text>
                        </div>
                        
                        <div className="row">
                            <div className="col-6 pe-2">
                                <Form.Group>
                                    <Form.Label className="small">Prepend With</Form.Label>
                                    <Form.Control></Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-6 ps-2">
                                <Form.Group>
                                    <Form.Label className="small">Append With</Form.Label>
                                    <Form.Control></Form.Control>
                                </Form.Group>
                            </div>
                        </div> */}
                    </>
                )}
                
            </Modal.Body>
            <Modal.Footer>
                <div className="flex-1">
                <div className="row">
                    <div className="col-6 pe-1">
                        <button onClick={() => saveActiveMetric()} className="btn btn-pliable-gradient w-100">
                            Set Config
                        </button>
                    </div>
                    <div className="col-6 ps-1">
                        <button className="btn btn-light w-100" onClick={() => setActiveMetricIdx(-1)}>
                            Cancel
                        </button>
                    </div>
                </div>
                </div>
                
            </Modal.Footer>
        </Modal>
        <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3">
                        {dataMart.data && (
                            <RecordInfo
                                title={currentTitle}
                                onChangeTitle={updateTitle}
                                description={currentDescription}
                                onChangeDescription={updateDescription}
                                iconComponent={<></>}
                                badges={<>
                                    <div>
                                        <Link className="view-in-context" to={`/?focusNodeId=DataMart:${dataMartId}`} style={{fontSize: '12px'}}>View in Context</Link>
                                    </div>
                                </>}
                                stats={[
                                    {
                                        label: 'Total Records',
                                        icon: 'mdi mdi-file-multiple',
                                        value: dataMart.data.total_records ? summarizeNumber(dataMart.data.total_records) : '0',
                                    }, {
                                        label: 'Last Updated',
                                        icon: 'mdi mdi-clock',
                                        value: dataMart.data.last_build_completed ? timeAgo(dataMart.data.last_build_completed, 'mini-minute-now') : 'never',
                                    }
                                ]}
                            />
                        )}
                        
                    </div>
                    <div className="p-3 border-top">
                        <DagDataLibrary activeNodeId={`DataMart:${dataMartId}`}/>
                    </div>
                </PaneContent>
            </Pane>
            
        </PageSidebar>
        <PageContent>
            <Pane>
                <nav className="subnav">
                    {/* <a role="button" className="nav-link">
                        <i className="mdi mdi-database"></i> Report Settings
                    </a>
                    <a role="button" className="nav-link">
                        <i className="mdi mdi-broom"></i> Configure Columns
                    </a> */}
                    <input placeholder="Search" style={{width: '300px', display: 'inline-block', margin: '5px'}} type="text" className="form-control round-input" onKeyDown={handleSearchEnterKey} onChange={(e) => setSearchInput(e.target.value)} value={searchInput}/>
                    
                    <div className="actions">
                    
                        <DraftModeRequired justHideChildren>
                            <AsyncButton
                                    className="w"
                                    loading={saving}
                                    text="Save & Run"
                                    variant="primary"
                                    disabled={!pageDirty}
                                    onClick={() => {
                                        saveReport()
                                    }}
                                />
                        </DraftModeRequired>

                        
                    </div>
                </nav>

                <PaneContentWithSubnav>
                <div className="d-flex" style={{height: '100%'}}>
                    <PaneContentInnerWithSidebar>
                        <Pane>
                            <PaneContent>
                                {loading && <LoadingCard action="Rebuilding" />}
                                {!loading && tableExplorerDefaultSort != null &&  (

                                    <TableExplorer
                                        tableShape={dataMart.data?.shape}
                                        tablePath={`/datamarts/${dataMartId as string}/data?bust=${tableCacheBust}`}
                                        columnFormatters={columnFormatters}
                                        onDataLoaded={onReportDataLoaded}
                                        columns={tableColumns}
                                        initialSort={tableExplorerDefaultSort}
                                        searchQuery={luceneTableQuery}
                                        // columnOrder={columnOrder}
                                        // onClickExpand={() => {
                                        //     navigate(`/data/business-object/${bo.data!.id as string}`)
                                        // }}
                                        // onColumnClick={onColumnClick}
                                        onFilterSortChange={(f) => console.log(f)}
                                    />

                                )}
                                
                            </PaneContent>
                            <PaneFooter>
                                <div className="d-flex center-vertically ps-3 pe-3" style={{lineHeight: '40px'}}>
                                    <div className="flex-1">
                                        <span>{summarizeNumber(reportRecordCount)} total rows </span>
                                        <BackendRequired allowed_backends={[Backends.Pliable]}>
                                        - <a role="button" onClick={() => download()}>
                                            {downloading && (
                                                <i className="mdi mdi-loading mdi-spin"></i>
                                            )}
                                            Download
                                        </a> 
                                        </BackendRequired>
                                    </div>
                                    <div className="text-end">
                                        
                                    </div>
                                </div>
                            </PaneFooter>
                        </Pane>
                    </PaneContentInnerWithSidebar>
                    <PaneContentSidebar>
                        <Pane>
                            <PaneContent>

                            
                                <DraftModeRequired showDraftModeLink={true}>
                                    <div className="p-3">
                                        <h3 className="mb-0">
                                            <i className="mdi mdi-cog"></i> Configure Report
                                        </h3>
                                    </div>
                                    <h2 className="sidebar-section-header">Report Type</h2>
                                    <div className="p-3">
                                        <Form.Group className="mb-2">
                                            <Form.Label>Root business object</Form.Label>
                                            <PipelineNodeSelector
                                                selectedId={selectedRootNode ? (selectedRootNode.id as string) : ''}
                                                onSelect={chooseRootNode}
                                                optionFilter={opt => {
                                                    return ['FACT', 'DIMENSION', 'BUSINESS_OBJECT'].includes(opt.node_type)
                                                }}
                                            />
                                            <Form.Text>This helps us determine what business objects you can add to your report, using their relationships to the selected business object.</Form.Text>
                                        </Form.Group>
                                        <Form.Group className="border-top pt-2">
                                            <Form.Label>Group By</Form.Label>
                                            <DragDropContext onDragEnd={onGroupByDragEnd}>
                                                <Droppable droppableId="main">
                                                    {provided => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {breakdown.map((c, idx) => {
                                                                const selectedXform = dateFunctionSelectOptions.find(opt => opt.value === c.column.function as string);
                                                                return <Draggable 
                                                                    draggableId={c.id}
                                                                    key={c.id}
                                                                    index={idx}
                                                                >
                                                                    {dragProvided => (
                                                                        <div 
                                                                            {...dragProvided.draggableProps}
                                                                            {...dragProvided.dragHandleProps}
                                                                            className="shadow-box p-2 mb-2"
                                                                            ref={dragProvided.innerRef}
                                                                            key={c.id}
                                                                        >
                                                                    
                                                                            <Form.Group>
                                                                                
                                                                                <div className="d-flex">
                                                                                    <div className="flex-1">
                                                                                    <Form.Label className="small">Business Object Field</Form.Label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <button className="icon-button" role="button" onClick={() => removeGroupBy(idx)}>
                                                                                            <i className="mdi mdi-trash-can"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <ColumnSelector
                                                                                    columns={columnOptions}
                                                                                    selectedId={c.column.id}
                                                                                    onChange={(newVal: string) => {
                                                                                        if (!!newVal) {
                                                                                            setGroupByColumn(newVal, idx);
                                                                                        } else {
                                                                                            setGroupByColumn('', idx);
                                                                                        }   
                                                                                    }}
                                                                                />
                                                                            </Form.Group>
                                                                            {['DATE', 'DATETIME'].includes(c.column.datatype as string) && (
                                                                                <Form.Group className="mt-2">
                                                                                    <Form.Label className="small">Transformer</Form.Label>
                                                                                
                                                                                    <Select
                                                                                        value={selectedXform}
                                                                                        options={dateFunctionSelectOptions}
                                                                                        onChange={(val) => {
                                                                                            if (!!val) {
                                                                                                setGroupByFunction(val.value, idx);
                                
                                                                                            } else {
                                                                                                setGroupByFunction('', idx);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                
                                                                                </Form.Group>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                                    
                                                                
                                                                
                                                            })}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                            <button className="icon-button" onClick={() => addEmptyGroupBy()}>
                                            <i className="mdi mdi-plus-circle"></i> Add Group By Column
                                        </button>
                                        </Form.Group>
                                        
                                        
                                       
                                    </div>
                                    <h2 className="sidebar-section-header">Additional Columns</h2>
                                    <div className="p-3">
                                        {/* <small>These columns will be added to each row. You can add calculated metrics using aggregations like "Sum" or "Total", or just grab additional columns from business objects using "Pick One".</small> */}

                                        
                                        <DragDropContext onDragEnd={onMetricDragEnd}>
                                            <Droppable droppableId="main">
                                                {provided => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {metrics.map((m, idx) => {
                                                            const aggregationOption = aggregateOptions.find(a => a.value === m.aggregator);
                                                            
                                                            const columnOption = columnOptionsWithCustom.find(c => c.id === m.column?.id);

                                                            let selectedColumnId = columnOption ? columnOption!.id : '';

                                
                                                            return <Draggable
                                                                draggableId={m.id!}
                                                                index={idx}
                                                                key={m.id!}
                                                            >
                                                                {dragProvided => (
                                                                    <div 
                                                                        {...dragProvided.draggableProps}
                                                                        {...dragProvided.dragHandleProps}
                                                                        className="shadow-box p-2 mb-2"
                                                                        ref={dragProvided.innerRef}
                                                                        key={m.id}
                                                                    >
                                                                        <div className="d-flex center-vertically  mb-2">
                                                                            <div className="flex-1">
                                                                                <Form.Control 
                                                                                    className="inline-edit"
                                                                                    value={m.name} 
                                                                                    placeholder="Column Name"
                                                                                    onChange={(e) => {
                                                                                        setMetrics(draft => {
                                                                                            draft[idx].name = e.target.value as string;
                                                                                        });
                                                                                        setPageDirty(true);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <button className="icon-button me-2" onClick={() => cloneMetric(idx)}>
                                                                                    <i className="mdi mdi-content-copy"></i>
                                                                                </button>
                                                                                <button className="icon-button me-2" onClick={() => openColumnEditor(idx)}>
                                                                                    <i className="mdi mdi-pencil"></i>
                                                                                </button>
                                                                                
                                                                                <button className="icon-button" onClick={() => removeMetric(idx)}>
                                                                                    <i className="mdi mdi-trash-can"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <>
                                                                            {/* <Select
                                                                                className="mb-2"
                                                                                value={aggregationOption}
                                                                                placeholder="Choose aggregation"
                                                                                options={aggregateOptions}
                                                                                onChange={(e) => {
                                                                                    setMetrics(draft => {
                                                                                        draft[idx].aggregator = e ? e.value as string : '';
                                                                                    });
                                                                                }}
                                                                            ></Select> */}
                                                                            {aggregationOption && selectedColumnId != 'CUSTOM' && <Badge className="mb-1" bg="info" pill>{aggregationOption.label}</Badge>}
                                                                            <ColumnSelector
                                                                                columns={columnOptionsWithCustom}
                                                                                selectedId={selectedColumnId}
                                                                                onChange={(e) => setMetricColumn(e, idx)}
                                                                            />
                                                                        </>
                                                                        
                                                                        
                                                                    
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        })}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                            
                                        <button className="icon-button" onClick={() => addMetric()}>
                                            <i className="mdi mdi-plus-circle"></i> Add Column
                                        </button>

                                        
                                    </div>
                                    <h2 className="sidebar-section-header">Filters</h2>
                                    <div className="p-3">
                                        <small>Optionally apply filters to the root business object.</small>
                                        
                                        <FilterConfigForm
                                            config={currentFilters}
                                            columnOptions={(!selectedRootNode) ? [] : selectedRootNode.fields}
                                            onChange={onChangeFilters}
                                        />
                                        
                                    </div>
                                    <h2 className="sidebar-section-header">Other Settings</h2>
                                    <div className="p-3">
                                        <Form.Group className="mb-2">
                                            <Form.Label className="small">Output View Name</Form.Label>
                                            <Form.Control onChange={(e) => updateOutputViewName(e.target.value)} value={currentOutputViewName}/>
                                            <Form.Text>Set the name of the friendly-labeled view that you can use in external tools to reference this report.</Form.Text>
                    
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label className="small">
                                                Default Sort
                                            </Form.Label>
                                            <Select
                                                value={defaultSortSelectValue}
                                                placeholder="Add sort option"
                                                options={availableDefaultSortOptions}
                                                isMulti={true}
                                                onChange={(e) => {
                                                    console.log(Array.from(e.values()));
                                                    //   @ts-ignore
                                                    setDefaultSort(Array.from(e.values()).map((op) => op.value));
                                                    setPageDirty(true);
                                                }}
                                            ></Select>
                                            <Form.Text>Set the initial sorting columns and direction when someone first lands on this report. They can always change it by clicking on the column headers.</Form.Text>
                                        
                                        </Form.Group>
                                        
                                        

                                        { isSnapshotsEntitled && <>
                                            <div>
                                                
                                                <Form.Group>
                                                    <Form.Check
                                                        label="Record snapshots of this report"
                                                        checked={!!includeInSnapshots}
                                                        reverse
                                                        onChange={onToggleIncludeIndSnapshots}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Snapshots are recorded after each <Link to="/account/build-schedule">scheduled build <i className="mdi mdi-information-outline"></i></Link>
                                                    </Form.Text>
                                                </Form.Group>
                                            </div>
                                        </>}
                                    </div>
                                    <h2 className="sidebar-section-header">
                                        Danger Zone
                                    </h2>
                                    <div className="p-3">
                                        <button className="btn btn-danger me-1 w-100" onClick={onClickDelete}>
                                            <i className="mdi mdi-delete"></i> Delete Report
                                        </button>
                                    </div>
                                    
                                
                                    
                                    </DraftModeRequired>
                            </PaneContent>
                        </Pane>
                        
                    </PaneContentSidebar>
                </div>
                </PaneContentWithSubnav>
            </Pane>
            
                
        </PageContent>
    </PageStructure>
}

export default ReportingPage;