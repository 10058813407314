import { confirmation } from '@services/alert/alert.service';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
position: relative;
display: inline-block;
line-height: 1.5;

`

const DeleteButtonStyles = styled.button`
font-size: 24px;
padding: 0px .45rem;
&.confirming {
    visibility: hidden;
}
width: 50px;
max-width: 50px;
position: relative;
z-index: 2;
height: 100%;
`

const SlideOut = styled.div`
max-width: 50px;
width: 50px;
transition: width 0.15s ease-in-out, max-width 0.15s ease-in-out;
font-size: 24px;
padding: 0px .45rem;
position: absolute;
top: 0px;
right: 0px;
z-index: 1;
height: 100%;
border-radius: 0.15rem;

background-color: var(--ct-danger);
border-color: var(--ct-danger);
color: white;
&:hover {
    color: white;
    cursor: default;
    filter: none;
}

display: flex;

div.label {
    display: none;
    position: absolute;
    color: white;
    font-size: 14px;
    line-height: 38px;
    left: .45rem;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
}
&.confirming {
    max-width: 200px;
    width: 200px;
    text-align: left;

    div.label {
        display: block;
        opacity: 1;
    }
}

button {
    width: 32px;
    height: 100%;
    border: none;
    top: 0px;
    position: absolute;
    transition: opacity 0.4s ease-in-out;
    opacity: 0;
    background-color: white;
    font-size: 18px;

    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    
    color: var(--ct-danger);
    &:hover {
        background-color: var(--ct-danger);
        color: white;
    }
}

button.confirm-button {
    
    right: 32px;
    border: none;
}

button.cancel-button {
    
    right: 0px;
    border: none;
    border-left: solid 1px var(--ct-danger);
    border-top-right-radius: 0.15rem;
    border-bottom-right-radius: 0.15rem;
}

&.confirming button {
    opacity: 1;
}
`


interface Props {
    onConfirmDelete: () => void;
    size?: string;
    disabled?: boolean;
}

const DeleteButton = (props: Props) => {
    const [isConfirming, setIsConfirming] = useState(false);
    const onClick = useCallback(() => {
       setIsConfirming(true);
    }, []);

    const className = useMemo(() => {
        let cn='btn';
        if (isConfirming) {
            cn = cn + ' confirming';
        }

        if (!!props.size && props.size == 'sm') {
            cn = cn + ' btn-sm';
        }
        return cn;
    }, [isConfirming]);

    const onConfirmDelete = useCallback(() => {
        setIsConfirming(false);
        props.onConfirmDelete();
    }, [props.onConfirmDelete]);

    
    return <Wrapper>
        <DeleteButtonStyles onClick={() => onClick()} className={className + ' btn-danger'}>
            <i className="mdi mdi-delete-forever"></i>
        </DeleteButtonStyles>
        <SlideOut className={className}>
            {/* <i className="mdi mdi-delete-forever"></i> */}
            <div className="label">Confirm delete?</div>
            <button className="confirm-button" onClick={() => onConfirmDelete()}>
                Y
            </button>
            <button className="cancel-button" onClick={() => setIsConfirming(false)}>
                N
            </button>
        </SlideOut>

    </Wrapper>
}

export default DeleteButton;