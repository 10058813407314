import { ReactNode, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

interface Props {
    businessObjectId: string;
    children?: ReactNode;
}

const BusinessObjectSubnav = (props: Props) => {
    const location = useLocation();
    const activePage = useMemo(() => {
        let path = location.pathname;

        if (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
        }
        
        if (path.endsWith('/fields')) {
            return 'fields';
        } else if (path.endsWith('/relationships')) {
            return 'relationships';
        } else if (path.endsWith('/insights')) {
            return 'insights';
        }

        return 'data';
    }, [location]);
    return <nav className="subnav">
        <Link to={`/business-object/${props.businessObjectId}`} className={`nav-link ${activePage == 'data' ? 'active' : ''}`}>
            <i className="mdi mdi-table"></i> Data
        </Link>
        <Link to={`/business-object/${props.businessObjectId}/fields`} className={`nav-link ${activePage == 'fields' ? 'active' : ''}`}>
            <i className="mdi mdi-graph mdi-rotate-90"></i> Fields & Sources
        </Link>
        <Link to={`/business-object/${props.businessObjectId}/relationships`} className={`nav-link ${activePage == 'relationships' ? 'active' : ''}`}>
            <i className="mdi mdi-transit-connection-variant"></i> Relationships
        </Link>

        <div className="actions">
            {props.children}
        </div>
    </nav>
}

export default BusinessObjectSubnav;