import { useProjectConfig } from "@stores/data.store";
import { PropsWithChildren } from 'react'

type BackendRequiredProps = {
    allowed_backends: string[]
}


export function BackendRequired({allowed_backends, children}: PropsWithChildren<BackendRequiredProps>) {
    const config = useProjectConfig();

    if (allowed_backends.includes(config.data?.database as string)){
        return <>{children}</>
    }

    return <></>
}

 