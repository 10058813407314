import { useCallback, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import EmojiPicker from 'emoji-picker-react';
export interface EmojiPickerModalProps {
    onConfirm: (value: string) => void;
    onClose: () => void;
    show?: boolean;
    header?: string;
}

const EmojiPickerModal = (props: EmojiPickerModalProps) => {
    const close = useCallback(() => {
        props.onClose();

    }, [props.onClose]);

    const onEmojiClick = useCallback((emoji: any) => {
        props.onConfirm(emoji);
    }, [props.onConfirm])

    
    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.header || "Choose an icon"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EmojiPicker width="100%" onEmojiClick={onEmojiClick} />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => close()}>Cancel</button>
            </Modal.Footer>
        </Modal>

    )
}

export default EmojiPickerModal;