import { useCallback } from 'react';
import styled from 'styled-components';

interface Props {
    onClick?: () => void;
}

const Styles = styled.div`

background: linear-gradient(0deg, var(--ct-warning), var(--ct-warning)),
linear-gradient(180deg, var(--ct-warning) 0%, #EDD500 100%);

border: 1px solid var(--ct-warning);

box-shadow: 0px 2px 3px 0px #0000001A;
width: 26px;
height: 26px;
border-radius: 13px;
color: black;
text-align: center;
transition: scale .15s ease-in-out, opacity .15s ease-in-out;

&:hover {
    cursor: pointer;
    scale: 1.5;
}
`

const WarningStatusIndicator = (props: Props) => {

    const onClick = useCallback(() => {
        if (props.onClick) {
            props.onClick();
        }
    }, [props.onClick]);
    return <Styles onClick={() => onClick()}>
        <i className="mdi mdi-alert"></i>
    </Styles>
}

export default WarningStatusIndicator