import { AuthorizedContent } from "@frontegg/react";
import { usePipelineNode } from "@stores/data.store";
import { Role } from "../../constants/roles";
import { ReactNode, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

interface Props {
    children?: ReactNode;
}

const TenantConfigSubnav = (props: Props) => {
    const location = useLocation();
    const activePage = useMemo(() => {
        let path = location.pathname;

        if (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
        }
        
        if (path.endsWith('/build-schedule')) {
            return 'schedule';
        } else if (path.endsWith('/git-setup')) {
            return 'git';
        } else if (path.endsWith('/sources')) {
            return 'sources';
        } else if (path.endsWith('/billing')) {
            return 'billing';
        }

        return 'schedule';
    }, [location]);


    return <nav className="subnav">
        <Link to={`/account/build-schedule`} className={`nav-link ${activePage == 'schedule' ? 'active' : ''}`}>
            <i className="mdi mdi-calendar-clock"></i> Build Schedule
        </Link>
        <AuthorizedContent requiredRoles={[Role.Admin]} key="tenant-admin-wrap" >
            <Link to={`/account/sources`} className={`nav-link ${activePage == 'sources' ? 'active' : ''}`}>
                <i className="mdi mdi-file-multiple"></i> Sources
            </Link>
        </AuthorizedContent>
        {/* <Link to={`/account/git-setup`} className={`nav-link ${activePage == 'git' ? 'active' : ''}`}>
            <i className="mdi mdi-git"></i> Git
        </Link> */}
        {/* <Link to={`/account/billing`} className={`nav-link ${activePage == 'billing' ? 'active' : ''}`}>
            <i className="mdi mdi-credit-card-multiple"></i> Billing
        </Link> */}
        
        <div className="actions">
            {props.children}
        </div>
    </nav>
}

export default TenantConfigSubnav;