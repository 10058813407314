import React, { Component, ReactNode, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { Form, Modal } from 'react-bootstrap';
import SaveButton from '@components/button/SaveButton.component';

export interface Breadcrumb {
    title: string;
    path?: string;
}

interface Props {
    title: string;
    icon?: string;
    subtitle?: string;
    breadcrumbs?: Breadcrumb[];
    buttons?: ReactNode;
    children?: ReactNode;

    // If provided, there will be a little edit button next to the title
    // that you can click on and open a modal
    onChangeTitle?: (newTitle: string) => void;
}

const PageTitleStyles = styled.div`
.page-title-box {
    display: flex;
    height: 50px;
    padding: 0px 1rem 0px 0px;
    
    background: white;
    border-bottom: solid 1px var(--ct-border-color);

    box-shadow: 5px 0px 10px 0px #0000000D;
    z-index: 2;

}

.page-title-left {
    flex: 1;
    padding-left: 15px;
}

.page-title-box h1.page-title {
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins";
    text-transform: uppercase;
    color: black;
    padding: 0px;
    margin: 0px;
    line-height: 50px;
}

.page-subtitle {
    color: var(--ct-text-muted);
    font-size: 13px;
}

.page-title-right {
    flex: 1;
    text-align: right;
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;


    .btn {
        padding: 0px 2rem;
        height: 32px;
        line-height: 32px;
    }

}

.page-title-icon {
    flex-basis: 50px;
}

.breadcrumb-title {
    color: #797979;
}

.breadcrumb-separator {
    margin-left: .5rem;
    margin-right: .5rem;    
    color: #ccc;
}

.mission-control-home-button{
    border-right: 1px solid var(--ct-border-color);
    
    line-height: 50px;
    background-color: white;

    &:hover {
        background-color: #eee;
    }

    a {
        display: inline-block;
        color: #2E2E2E;
        font-size: 15px;
        padding: 0px 1rem 0px 1rem;
    }
}

`



const PageTitle = (props: Props) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [newTitle, setNewTitle] = useState('');

    useEffect(() => {
        setNewTitle(props.title);
    }, [props.title]);

    const changeTitle = useCallback(async () => {
        if (props.onChangeTitle) {
            await props.onChangeTitle(newTitle);
        }
        setShowEditModal(false);
    }, [newTitle, props.onChangeTitle]);

    return (
        <PageTitleStyles>
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>New Title</Form.Label>
                        <Form.Control value={newTitle} onChange={(e) => setNewTitle(e.target.value)}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton
                        disabled={!newTitle}
                        onClick={() => changeTitle()}
                    />
                </Modal.Footer>
            </Modal>
            
            <div className="page-title-box">
                <div className="mission-control-home-button">
                        <Link to="/"><i className="mdi mdi-home"></i></Link>
                    </div>
                {props.icon && (
                    <div className="page-title-icon">
                        <img src={props.icon} className="rounded-circle avatar-xs img-thumbnail"/>
                    </div>
                )}
                <div className="page-title-left">
                    <h1 className="page-title">
                        {props.breadcrumbs && props.breadcrumbs.map((bc, idx) => {
                            return <span key={idx}>
                            
                                <span className="breadcrumb-title">
                                    {bc.path && (
                                        <Link to={bc.path}>{bc.title}</Link>
                                    )}
                                    {!bc.path && (
                                        <span>{bc.title}</span>
                                    )}
                                </span>
                                <span className="breadcrumb-separator">/</span>

                            </span>
                        })}
                        
                        {!!props.onChangeTitle && (
                            <a role="button" onClick={() => setShowEditModal(true)}>
                                {props.title}
                                <span className="ms-1">
                                    <i className="mdi mdi-pencil"></i>
                                </span>
                            </a>
                            
                        )}
                        {!props.onChangeTitle && props.title}
                        
                        
                    </h1>
                
                </div>
                <div className="page-title-right">
                    {props.children}
                </div>
            </div>
        </PageTitleStyles>
    );
}

export default PageTitle;

