import { useQuery } from "react-query";
import ORM, { BaseModel } from "./orm";

export interface DashboardFilterOptionColumnReference {
    pipeline_node_id: string;
    field_id: string;
}

export interface DashboardFilterOption {
    column_references: DashboardFilterOptionColumnReference[];
    filter_type: 'QUERY_LIST' | 'STATIC_LIST' | 'FREE_TEXT';
    comparator: string;
    query_list_node_id?: string;
    query_list_field_id?: string;
    static_list?: string[];
    allow_multiple?: boolean;
    default_value?: string;
}


export type DashboardObjectType = undefined | 'NODE' | 'VISUALIZATION' | 'MARKDOWN' | 'FILTER';

export interface DashboardComponent {
    id: string;
    title: string;
    x: number;
    y: number;
    width: number;
    height: number;
    object_id: string;
    object_type?: DashboardObjectType;
    markdown_content?: string;
    filter_config?: DashboardFilterOption;
    column_whitelist?: string[];
    
}

export interface Dashboard extends BaseModel {
    name: string;
    description: string;
    components: DashboardComponent[];

}

export class _DashboardORM extends ORM<Dashboard>{

};

export const DashboardORM = new _DashboardORM('dashboards');

export const useDashboards = () => {
    return useQuery(['dashboards'], async () => {
        const result = await DashboardORM.find({});
        return result.records;
    });
}

export const useDashboard = (dashboardId: string) => {
    return useQuery(['dashboards', dashboardId], async () => {
        if (!dashboardId) {
            return undefined;
        }
        const result = await DashboardORM.findById(dashboardId);
        return result;
    })
}