import FeatureFlagWrapper from "@components/featureflags/FeatureFlagWrapper.component";
import { getNodeTypeConfig } from "@services/modeling.service";
import { usePipelineNode, useSource, useSourceTypes } from "@stores/data.store";
import { ReactNode, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

interface Props {
    pipelineNodeId: string;
    children?: ReactNode;
}

const PipelineNodeSubnav = (props: Props) => {
    const location = useLocation();
    const node = usePipelineNode(props.pipelineNodeId);

    const activePage = useMemo(() => {
        let path = location.pathname;

        if (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
        }
        
        if (path.endsWith('/config')) {
            return 'config';
        } else if (path.endsWith('/files')) {
            return 'files';
        } else if (path.indexOf('/relationships') >= 0) {
            return 'relationships';
        } else if (path.endsWith('/insights')) {
            return 'insights';
        } else if (path.endsWith('/filters')) {
            return 'filters';
        } else if (path.indexOf('/visualizations') >= 0) {
            return 'visualizations';
        }

        return 'data';
    }, [location]);

    const nodeConfig = useMemo(() => {
        if (!node.data) {
            return undefined;
        }
        return getNodeTypeConfig(node.data);
    }, [node.dataUpdatedAt])

    if (!node.data || !nodeConfig) {
        return <></>;   
    }

    return <nav className="subnav">
        <Link to={`/node/${props.pipelineNodeId}`} className={`nav-link ${activePage == 'data' ? 'active' : ''}`}>
            <i className="mdi mdi-table"></i> Output
        </Link>
        <Link to={`/node/${props.pipelineNodeId}/config`} className={`nav-link ${activePage == 'config' ? 'active' : ''}`}>
            <i className="mdi mdi-cog"></i> Configure
        </Link>
        {node.data && node.data.flat_file && (
        <Link to={`/node/${props.pipelineNodeId}/files`} className={`nav-link ${activePage === 'files' ? 'active' : ''}`}>
          <i className="mdi mdi-file-multiple"></i> Files
        </Link>
        )}
        {['STAGING', 'DATA_MODELING'].includes(nodeConfig.group.value) &&  (
            <>
                
                <Link to={`/node/${props.pipelineNodeId}/relationships`} className={`nav-link ${activePage == 'relationships' ? 'active' : ''}`}>
                    <i className="mdi mdi-transit-connection-variant"></i> Relationships
                </Link>
            </>
            
        )}
        <FeatureFlagWrapper requiredFlags={['visualizations']}>
            <Link to={`/node/${props.pipelineNodeId}/visualizations`} className={`nav-link ${activePage == 'visualizations' ? 'active' : ''}`}>
                <i className="mdi mdi-chart-bar"></i> Visualizations
            </Link>
        </FeatureFlagWrapper>
        
        

        <div className="actions">
            {props.children}
        </div>
    </nav>
}

export default PipelineNodeSubnav;