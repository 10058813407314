import { useCallback, useEffect, useMemo, useState } from "react";
import 'reactflow/dist/style.css';
import { Link, useNavigate } from "react-router-dom";
import { Form, Row } from "react-bootstrap";
import SaveButton from "@components/button/SaveButton.component";
import { useRouteBlocker } from "@services/routing.service";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent, PaneContentWithSubnav } from "@pages/PageStructure.component";
import TenantConfigSubnav from "@components/tenantConfig/TenantConfigSubnav.component";
import AuthService from "@services/auth/auth.service";
import TimezoneSelect, { ITimezoneOption, type ITimezone } from 'react-timezone-select'
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css'
import { useBuildOrchestrationHistory, useProjectConfig, useSourceTypes, useSources } from "@stores/data.store";
import TenantConfigService from "@services/tenantConfig.service";
import TrackingService, { Events } from "@services/tracking.service";
import BuildOrchestrationORM from "@models/buildOrchestration";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import PliableLoader from "@components/loaders/PliableLoader.component";
import { timeAgo } from "@services/time.service";
import SourceORM, { Source, SourceType } from "@models/source";
import styled from 'styled-components';
import AsyncButton from "@components/button/AsyncButton.component";

const Styles = styled.div`

margin-bottom: 2rem;
padding-bottom: 2rem;
border-bottom: solid 1px var(--ct-border-color);
padding: 25px;


.header-row {
    display: flex;

    .img {
        margin-right: 2rem;
        img {
            width: 50px;
            height: 50px;
        }
    }
    
    .text {
        flex: 1;
    }
    
}


`

const TenantAuthorizeSourcesPage = () => {
    const navigate = useNavigate();

    const {dataUpdatedAt, data: tenantConfig} = useProjectConfig();

    const [connectingId, setConnectingId] = useState('')
    const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    const tenant_name = AuthService.getInstance().getTenantName();


    const {isLoading, data: sourceTypes } = useSourceTypes();

    const sourceTypesByeId: any = useMemo(() => {
        const stById: any = {};
        if(sourceTypes) {
            sourceTypes.forEach((st: SourceType) => {
               stById[st.id] = st;
            })
        }
        return stById;
    }, [sourceTypes])
    

    const { data: sources}  = useSources();

    const unfinishedSource = useMemo(() => {
        return sources?.filter((s) => {
            if(s.type.startsWith('fivetran_')) {
                try{
                    return s.fivetran?.connector.status.setup_state != 'connected'
                }catch(ex){
                    return true;
                }
            }
            return false
        })

    }, [sources]);

    const startConnectorCardFlow = useCallback(async (sourceId: string) => {
        setConnectingId(sourceId);
        const resp = (await SourceORM.getConnectorCardUrl(sourceId as string, `/source/${sourceId}/finish-fivetran?continue=true`));
        window.location.href = resp.connect_card_url;
    }, []);

    console.log(unfinishedSource);

    return <PageStructure
        pageTitle="Build Schedule"
        breadcrumbs={[
            {
                title: 'Account',
            }
            
        ]}
    >
        
        <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3">
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Account Name
                            </Form.Label>
                            <Form.Control value={tenant_name as string} disabled={true} />
                            <Form.Text></Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>
                                Account ID
                            </Form.Label>
                            <Form.Control value={tenantConfig?.tenant_slug as string} disabled={true} />
                            <Form.Text>
                                <strong>cannot be changed</strong> after creation
                            </Form.Text>
                        </Form.Group>
                        <hr />
                    </div>
                </PaneContent>
            </Pane>
        </PageSidebar>
        <PageContent>

            <Pane>
                <Styles>
                    {unfinishedSource && unfinishedSource?.length > 0 && <>
                        <div className="text-center" style={{padding: '25px'}}>
                            <h1>Lets connect your sources:</h1>

                        </div>
                        <div className="row">
                        {unfinishedSource?.map((source: Source) => {
                            return <div className="col-4">
                                    <div className="header-row">
                                        <div className="img">
                                            <img src={sourceTypesByeId[source.type].icon_path}/>
                                        </div>
                                        <div className="text">
                                            <div style={{minHeight: '100px'}}>

                                            <h3>{source.name}</h3>
                                            <small>{sourceTypesByeId[source.type].description}</small><br />
                                            </div>
                                            <AsyncButton
                                                loading={connectingId == source.id}
                                                disabled={connectingId != ''}
                                                text={`Connect ${sourceTypesByeId[source.type].title}`}
                                                className="btn-pliable btn-sm" 
                                                onClick={() => startConnectorCardFlow(source.id as string)}
                                            />

                                        
                                        </div>
                                    </div>
                                </div>
                            
                            
                        })}
                        </div>
                    </>}
                    {unfinishedSource && unfinishedSource?.length == 0 && <>
                        <div className="text-center" style={{padding: '25px'}}>
                            <h1>You're all set</h1>
                            <br /><br />
                            <Link to="/">Start Modeling</Link>
                        </div>
                    </>}
                </Styles>
            </Pane>
        
            
           
        </PageContent>
               
    </PageStructure>
    
}


export default TenantAuthorizeSourcesPage;