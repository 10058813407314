import { confirmation } from "@services/alert/alert.service";
import { enterDraftMode } from "@stores/data.store";


export function requireDraftMode(currentDraft: string, callback: () => void) {
    if(!currentDraft) {
        confirmation({
            header: 'Draft Required',
            message: 'You must first enter draft mode to make edits',
            confirmationButtonText: 'Start Draft',
            onConfirm: async () => {
                await enterDraftMode('draft_required_popup');
                callback();
            },
            onClose: () => {}
        });
    }else{
        callback();
    }
  }