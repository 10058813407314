import { Option } from "@components/form/Dropdown.component";
import DagDataLibrary from "@components/nav/DagDataLibrary.component";
import PipelineOrchestration from "@components/orchestration/PipelineOrchestration.component";
import PipelineNodeColumnSelector from "@components/pipelineNodes/PipelineNodeColumnSelector.component";
import PipelineNodeDataTable from "@components/pipelineNodes/PIpelineNodeDataTable";
import PipelineNodeInfo from "@components/pipelineNodes/PipelineNodeInfo.component";
import PipelineNodeNotFound from "@components/pipelineNodes/PipelineNodeNotFound.component";
import PipelineNodeSubnav from "@components/pipelineNodes/PipelineNodeSubnav.component";
import SourceSelector from "@components/sources/SourceSelector.component";
import BuildOrchestrationORM, { BuildExecution, BuildOrchestration } from "@models/buildOrchestration";
import { PipelineNodeField, PipelineNodeORM } from "@models/pipelineNode";
import { FilterConfig } from "@models/standardizationPipeline";
import { ColumnSort } from "@models/pipelineNode";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent, PaneContentWithSubnav } from "@pages/PageStructure.component";
import FilterConfigForm from "@pages/SourceRecordType/FilterConfigForm.component";
import DefaultSortForm from "@pages/SourceRecordType/DefaultSortForm.component";
import { requireConfirmation } from "@services/alert/alert.service";
import { ApiError } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import { getNodeTypeConfig } from "@services/modeling.service";
import toast from "@services/toast.service";
import { invalidateEverything, useIsInDraftMode, useOutputTableForNode, usePipelineNode } from "@stores/data.store";
import useGlobalState from "@stores/global.state";
import { useCallback, useEffect, useMemo, useState, KeyboardEvent } from "react";
import { ButtonGroup, Form, Dropdown as BSDropdown, Button, Offcanvas, OffcanvasBody } from "react-bootstrap";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import PipelineNodeColumnOrder from "@components/pipelineNodes/PipelineNodeColumnOrder.component";
import { DraftModeRequired } from "@components/project/DraftModeRequired.component";


const PipelineNodeDataPage = () => {
    const { pipelineNodeId } = useParams();
    const pipelineNode = usePipelineNode(pipelineNodeId as string);

    const [searchParams, setSearchParams] = useSearchParams();

    const query = searchParams.get('q') || '';

    const [searchInput, setSearchInput] = useState(query);


    const [filters, setFilters] = useState<FilterConfig>({
        filters: [],
        logic_gate: 'AND',
    });

    const [includeInSnapshots, setIncludeInSnapshots] = useState(false);
    const [incrementalBuild, setIncrementalBuild] = useState(false);
    const [incrementalTimestampFieldId, setIncrementalTimestampFieldId] = useState('');
    const [alternateIdentifierId, setAlternateIdentifierId] = useState('');
    const [associatedSourceId, setAssociatedSourceId] = useState('');

    const { pageDirty, setPageDirty } = useGlobalState();

    const [compositeKeySelection, setCompositeKeySelection] = useState<string[]>([]);
    const [compositeKeyOptions, setCompositeKeyOptions] = useState<Option[]>([]);
    const [lastModifiedColumnSelection, setLastModifiedColumnSelection] = useState<string>('');
    const [fields, setFields] = useState<PipelineNodeField[]>([]);
    const [showColumn, setShowColumn] = useState<boolean>(false);

    useEffect(() => {
        if (pipelineNode.data && pipelineNode.data.filters) {
            setFilters(pipelineNode.data.filters);
        }

        if (pipelineNode.data && pipelineNode.data.default_sort) {
            setDefaultSorts(pipelineNode.data.default_sort);
        }

        if (pipelineNode.data && pipelineNode.data.fields) {
            setFields(pipelineNode.data.fields);
        }


        if (pipelineNode.data) {
            setIncludeInSnapshots(!!pipelineNode.data.include_in_snapshots);
            setAlternateIdentifierId(pipelineNode.data.alternate_identifier_field_id || '');
            
            if (pipelineNode.data.shape) {
                setCompositeKeyOptions(pipelineNode.data.shape.columns.map(c => {
                    return {
                        value: c.key,
                        label: c.key,
                        // description: tc.type,
                    }
                }));
            }

            if (['SOURCE', 'SOURCE_FILE'].includes(pipelineNode.data.node_type)) {
                setAssociatedSourceId(pipelineNode.data.source_id || '');
            }
            setIncrementalBuild(!!pipelineNode.data.incremental_build);

            if (pipelineNode.data.incremental_timestamp_field_id) {
                setIncrementalTimestampFieldId(pipelineNode.data.incremental_timestamp_field_id);
            } else {
                setIncrementalTimestampFieldId('');
            }
    
            if (pipelineNode.data.composite_key) {
                setCompositeKeySelection(pipelineNode.data.composite_key);
            }
    
            if (pipelineNode.data.last_modified_column) {
                setLastModifiedColumnSelection(pipelineNode.data.last_modified_column);
            }
        }
        
    }, [pipelineNode.dataUpdatedAt]);

    const navigate = useNavigate();

    const [building, setBuilding] = useState(false);
    
    const [applyingFilters, setApplyingFilters] = useState(false);

    const onChangeFilters = useCallback((newFilters: FilterConfig) => {
        setFilters(newFilters);
    }, []);

    const [defaultSorts, setDefaultSorts] = useState<ColumnSort[]>([]);
    
    useEffect(() => {
        setSearchInput(query);
    }, [query]);

    const onColumnToggle = useCallback((field: PipelineNodeField) => {
        if (!pipelineNode.data) return;
        const updatedShowColumn = field.show_column ?? false;
        setShowColumn(updatedShowColumn);
    }, [pipelineNode.data]);
    
    const onNodeFieldChange = useCallback((newFields: PipelineNodeField[]) => {
        if (!pipelineNode.data) return;
        setFields(newFields);
    }, [pipelineNode.data]);
    
    const saveColumnView = useCallback(async (newFields: PipelineNodeField[]) => {
        try {
            setFields(newFields);
            const selectedField = newFields.find(field => field.id === pipelineNodeId);
            if (selectedField) {
                setShowColumn(selectedField.show_column ?? false);
            }
            await PipelineNodeORM.patch(pipelineNodeId as string, {
                fields: newFields,
            });
            await pipelineNode.refetch();
            setShowColumnOrder(false)
            setPageDirty(false);
        } catch (error) {
            toast('error', 'Error', 'Failed to save column view.');
        }
    }, [pipelineNodeId, pipelineNode, fields]);

    const applyDefaultSorts = useCallback(async () => {
        try {
            await PipelineNodeORM.patch(pipelineNodeId as string, {
                'default_sort': defaultSorts,
            });
            await pipelineNode.refetch();
            setPageDirty(false);
        } catch (error) {
            toast('error', 'Error', 'Failed to apply sorts.');
        }
    }, [pipelineNodeId, defaultSorts, pipelineNode]);
    

    const onToggleIncludeInSnapshots = useCallback(async () => {
        const newValue = !includeInSnapshots;
        setIncludeInSnapshots(newValue);

        await PipelineNodeORM.patch(pipelineNodeId as string, {
            'include_in_snapshots': newValue,
        })
    }, [pipelineNodeId, includeInSnapshots]);

    const onChangeAlternateIdentifier = useCallback(async (fieldId: string|undefined) => {
        setAlternateIdentifierId(fieldId || '');
        await PipelineNodeORM.patch(pipelineNodeId as string, {
            'alternate_identifier_field_id': fieldId || ''
        })
    }, [pipelineNodeId]);

    const onChangeAssociatedSource = useCallback(async (sourceId: string|undefined) => {
        setAssociatedSourceId(sourceId || '');
        await PipelineNodeORM.patch(pipelineNodeId as string, {
            'source_id': sourceId || ''
        })
    }, [pipelineNodeId]);

    const onChangeIncrementalBuild = useCallback(async (newVal: boolean) => {
        setIncrementalBuild(newVal);
        await PipelineNodeORM.patch(pipelineNodeId as string, {
            'incremental_build': newVal,
        });
    }, [pipelineNodeId]);

    const onChangeIncrementalTimestampFieldId = useCallback(async (newVal: string) => {
        setIncrementalTimestampFieldId(newVal);
        await PipelineNodeORM.patch(pipelineNodeId as string, {
            'incremental_timestamp_field_id': newVal,
        });
    }, [pipelineNodeId]);

    const applyFilters = useCallback(async () => {
        setApplyingFilters(true);
        await PipelineNodeORM.patch(pipelineNodeId as string, {
            'filters': filters, 
        });
        setPageDirty(false);

        const shouldRebuild = await requireConfirmation('Pliable needs to rebuild this node to see the results of your filters. Do you want to rebuild now?', 'Rebuild Required');

        if (shouldRebuild) {
            await runBuild('THIS');
        }

        pipelineNode.refetch();
        setApplyingFilters(false);
    }, [pipelineNodeId, filters]);

    const [activeOrchestration, setActiveOrchestration] = useState<BuildOrchestration|undefined>();

    const checkActiveOrchestration = useCallback(async () => {
        if (!activeOrchestration) {
            return;
        }

        const newStatuses: {
            [key: string]: BuildExecution
        } = {};
    
        Object.keys(activeOrchestration.build_executions).map(k => {
            const be = activeOrchestration.build_executions[k];
            newStatuses[`${be.object_type}:${be.object_id}`] = be;
        });

        
        if (!['ERROR', 'COMPLETE', 'IN_REVIEW'].includes(activeOrchestration.status)) {
            setTimeout(async () => {
                // Keep polling, which should then re-run this effect, and so on.
                const updatedOrch = await BuildOrchestrationORM.findById(activeOrchestration.id as string);
                setActiveOrchestration(updatedOrch);
            }, 1000);
        } else if (activeOrchestration.status == 'COMPLETE') {
            setActiveOrchestration(undefined);
            toast('success', 'Success', 'Models have been successfully updated.');
            invalidateEverything();
            setBuilding(false);
        } else if (activeOrchestration.status == 'ERROR') {
            toast('danger', 'Error', getErrorMessage(activeOrchestration.error));
            setBuilding(false);
        }

    }, [activeOrchestration]);

    const runBuild = useCallback(async (selector: 'THIS' | 'UPSTREAM' | 'DOWNSTREAM' | 'ALL') => {
        
        setActiveOrchestration(undefined)
        
        if (!pipelineNode.data) {
            return;
        }
        try {
            setBuilding(true);
            let selectorValue: string = '';
            switch (selector) {
                case 'THIS':
                    selectorValue = pipelineNode.data.name;
                    break;
                case 'UPSTREAM':
                    selectorValue = '+' + pipelineNode.data.name;
                    break;
                case 'DOWNSTREAM':
                    selectorValue = pipelineNode.data.name + '+';
                    break;
                case 'ALL':
                    selectorValue = '+' + pipelineNode.data.name + '+'
                    break;
            }
            const orchestration = await BuildOrchestrationORM.buildWithSelector(selectorValue);

            if (orchestration.status == 'COMPLETE') {
                toast('info', 'Everything up to date', 'No need to rebuild.');
                setBuilding(false);
              
            } else {
                setActiveOrchestration(orchestration);
            }

        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
    }, [pipelineNodeId, pipelineNode.dataUpdatedAt, fields]);

    useEffect(() => {
        if (activeOrchestration) {
            checkActiveOrchestration();
        }
    }, [activeOrchestration]);

    const isInDraft = useIsInDraftMode();

    const nodeTypeConfig = useMemo(() => {
        if (!pipelineNode.data) {
            return;
        }

        return getNodeTypeConfig(pipelineNode.data);
    }, [pipelineNode.dataUpdatedAt]);

    const outputTable = useOutputTableForNode(pipelineNodeId);

    const inDraftMode = useIsInDraftMode();

    const [showConfig, setShowConfig] = useState(false);
    const [showColumnOrder, setShowColumnOrder] = useState(false);

    const checkForSearchEnterKey = useCallback((evt: KeyboardEvent<HTMLInputElement>) => {
        if (evt.keyCode == 13) {
            setSearchParams({q: searchInput});
        }
    }, [searchInput]);

    if (pipelineNode.status == 'error' && (pipelineNode.error as ApiError).code == 404) {
        return <PipelineNodeNotFound/>
    }
    return <PageStructure
        pageTitle={pipelineNode.data ? pipelineNode.data.name : ''}
        breadcrumbs={[
            {
                title: 'Pipeline Node',
            }
            
        ]}
    >
        <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3">
                        <PipelineNodeInfo
                            pipelineNodeId={pipelineNodeId as string}
                            searchText={searchInput}
                         />
                    </div>
                    <div className="p-3 border-top">
                        <DagDataLibrary activeNodeId={`PipelineNode:${pipelineNodeId}`}/>
                    </div>
                    
                    
                </PaneContent>
            </Pane>
            
        </PageSidebar>
        <PageContent>
            <Offcanvas show={showConfig} onHide={() => setShowConfig(false)} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Output Settings</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Pane>
                        <PaneContent>
                                {nodeTypeConfig && <>
                                    <div className="p-3">
                                        <div>Data for this node is available in<br /><code>{outputTable.data ? outputTable.data : ''}</code></div>
                                        
                                        
                                    </div>
                                    {pipelineNode.data && (
                                        <>
                                            <h2 className="sidebar-section-header">Output Configuration</h2>

                                            <div className="p-3">
                                                <Form.Group className="mb-3">
                                                    <Form.Check
                                                        disabled={!inDraftMode}
                                                        label="Record daily snapshots of this node"
                                                        checked={!!includeInSnapshots}
                                                        reverse
                                                        onChange={onToggleIncludeInSnapshots}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Snapshots are updated after each <Link to="/account/build-schedule">scheduled build <i className="mdi mdi-information-outline"></i></Link>
                                                    </Form.Text>
                                                </Form.Group>
                                                <h4>Default Filters</h4>
                                                <div className="mb-3">
                                                    <FilterConfigForm
                                                        config={filters}
                                                        columnOptions={pipelineNode.data.fields}
                                                        onChange={onChangeFilters}
                                                    />
                                                    <button disabled={!inDraftMode} onClick={applyFilters} className="btn btn-outline-secondary mt-2">Apply Filters</button>
                                                </div>
                                                <h4>Default Sort</h4>
                                                <div className="mb-3">
                                                    <DefaultSortForm
                                                        fields={pipelineNode.data.fields}
                                                        currentSort={defaultSorts}
                                                        onChange={setDefaultSorts}
                                                    />
                                                    <button onClick={applyDefaultSorts} className="btn btn-outline-secondary">Apply Sort</button>    
                                                </div>
                                            </div>
                                            <h2 className="sidebar-section-header">Report Configuration</h2>
                                            <div className="p-3">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Record Identifier</Form.Label>
                                                    <PipelineNodeColumnSelector
                                                        pipelineNodeId={pipelineNodeId as string}
                                                        selectedId={alternateIdentifierId}
                                                        onSelect={onChangeAlternateIdentifier}
                                                        disabled={!inDraftMode}
                                                    />
                                                    <Form.Text>Choose a column Pliable will use to identify this record (for example in reports). If this isn't set, we will default to the internal <code>Record ID</code> column</Form.Text>
                                                </Form.Group>
                                            </div>
                                            <h2 className="sidebar-section-header">Other Configuration</h2>
                                            {pipelineNode.data.node_type == 'SOURCE' && <>
                                                <div className="p-3">
                                                    <Form.Group>
                                                        <Form.Label>Associated Data Source</Form.Label>
                                                        <SourceSelector
                                                            onSelect={onChangeAssociatedSource}
                                                            selectedId={associatedSourceId}
                                                            disabled={!inDraftMode}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </>}
                                            {['SOURCE', 'SOURCE_FILE', 'STACK', 'MERGE', 'DIMENSION'].includes(pipelineNode.data.node_type) && <div className="p-3">
                                                <Form.Group className="mb-2">
                                                    <Form.Check
                                                        type="switch"
                                                        checked={incrementalBuild}
                                                        onChange={(e) => onChangeIncrementalBuild(e.target.checked)}
                                                        label="Incremental Builds"
                                                        disabled={!inDraftMode}
                                                    />
                                                    <Form.Text>
                                                        When enabled, Pliable will only update records that have changed since the last build.
                                                    </Form.Text>

                                                </Form.Group>
                                                {incrementalBuild && ['STACK', 'MERGE', 'DIMENSION'].includes(pipelineNode.data.node_type) && <Form.Group className="mb-2">
                                                    <Form.Label>Incremental Timestamp Field</Form.Label>
                                                    <PipelineNodeColumnSelector
                                                        pipelineNodeId={pipelineNodeId as string}
                                                        selectedId={incrementalTimestampFieldId}
                                                        onSelect={onChangeIncrementalTimestampFieldId}
                                                        disabled={!inDraftMode}
                                                    />
                                                    <Form.Text>Select the column to use for the "last updated" value for incremental builds. Any record for which this column is later than the current maximum value will be processed with each build.</Form.Text>
                                                </Form.Group>}
                                            </div>}
                                            
                                        </>
                                        
                                    )}
                                </>}
                                
                                
                        </PaneContent>
                    </Pane>
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas show={showColumnOrder} onHide={() => setShowColumnOrder(false)} placement="end">
                <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Order and Visibility</Offcanvas.Title>
                </Offcanvas.Header>
                <OffcanvasBody>
                    <Pane>
                        <PaneContent>
                            <PipelineNodeColumnOrder
                                fields={fields}
                                shape={pipelineNode.data?.shape as any}
                                onClickColumn={onColumnToggle}
                                onChangeNodeField={onNodeFieldChange}
                                onSave={saveColumnView}
                            />    
                        </PaneContent>
                    </Pane>                
                </OffcanvasBody>
            </Offcanvas>

                    <Pane>
                        <PipelineNodeSubnav
                            pipelineNodeId={pipelineNodeId as string}
                        >
                            
                            <ButtonGroup>
                                <Button disabled={building} title="Build this node + upstream nodes" variant="pliable" onClick={() => runBuild('UPSTREAM')}>Build</Button>
                                <BSDropdown>
                                
                                
                                <BSDropdown.Toggle variant="pliable" disabled={building} style={{padding: '0em 1em'}}>
                                </BSDropdown.Toggle>

                                <BSDropdown.Menu className="font-13 text-end">
                                    <BSDropdown.Item onClick={() => runBuild('THIS')}>Just this node</BSDropdown.Item>
                                    <BSDropdown.Item onClick={() => runBuild('UPSTREAM')}>This node + upstream nodes</BSDropdown.Item>
                                    <BSDropdown.Item onClick={() => runBuild('DOWNSTREAM')}>This node + downstream nodes</BSDropdown.Item>
                                    <BSDropdown.Item onClick={() => runBuild('ALL')}>This node + upstream & downstream nodes</BSDropdown.Item>

                                </BSDropdown.Menu>
                                </BSDropdown>
                            </ButtonGroup>
                            <button title="Output Settings" className="btn btn-outline-secondary me-1" style={{padding: '0 1em'}} onClick={() => setShowConfig(true)}>
                                <i className="mdi mdi-tune-variant"></i>
                            </button>
                            <DraftModeRequired justHideChildren={true}>
                                <button title="Order and Visibility" className="btn btn-outline-secondary me-1" style={{padding: '0 1em'}} onClick={() => setShowColumnOrder(true)}>
                                    <i className="mdi mdi-eye"></i>
                                </button>
                            </DraftModeRequired>
                            <input type="text" placeholder="Search data" onKeyUp={checkForSearchEnterKey} value={searchInput} style={{width: '250px', display: 'inline-block'}} onChange={(e) => setSearchInput(e.target.value as string)} className="form-control round-input me-2 font-13" />
                            
                        </PipelineNodeSubnav>
                        <PaneContentWithSubnav>
                            <Pane>
                                <PaneContent noScroll>
                                    {(building || (activeOrchestration?.status == 'ERROR')) && ( 
                                        <div className="p-3">
                                            {activeOrchestration?.status == 'ERROR' && (
                                                <button className="btn btn-primary" id="btn-close-orc" onClick={() => setActiveOrchestration(undefined)}>Go Back</button>
                                            )}
                                            {activeOrchestration && <PipelineOrchestration orchestration={activeOrchestration}/>}
                                        </div>
                                    )}

                                    {!building && activeOrchestration?.status !== 'ERROR' && (
                                        <PipelineNodeDataTable
                                            defaultSorts={defaultSorts}
                                            pipelineNodeId={pipelineNodeId as string}
                                            showLineageButton
                                            searchQuery={query}
                                        />
                                    )}
                                </PaneContent>
                            </Pane>
                        </PaneContentWithSubnav>
                
                    </Pane>
                
            
        </PageContent>
    </PageStructure>

}

export default PipelineNodeDataPage;