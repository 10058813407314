import AsyncButton from "@components/button/AsyncButton.component";
import { TemplateGroup, TemplateGroupStep } from "@models/templateGroup";
import ApiService from "@services/api/api.service";
import { useSources, useTemplateGroup } from "@stores/data.store";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Params {
    template_group: TemplateGroup;
}

const DoneStep = (props: Params) => {
    const navigate = useNavigate();
    return <>
        <h1>Template data is Ready</h1>
        <p></p>
        <AsyncButton
            icon="mdi mdi-table-edit"
            variant="pliable"
            text={`View`}
            loading={false}
            onClick={() => navigate('/')}
        />  
    </>
}

export default DoneStep;