import { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SourceORM, { Source } from '../../models/source';
import { timeAgo } from '../../services/time.service';
import ConfirmationModal from '../alert/ConfirmationModal.component';
import LoadingCard from '@components/card/LoadingCard.component';
import { useSourceTypes } from '@stores/data.store';

interface RowProps {
    source: Source;
    clickedDelete: () => void;
}

const removeExtension = (v: string) => {
    const spl = v.split('.');
    spl.pop();
    return spl.join('.');
}



function SourceRow(props: RowProps){
    const { isLoading: loadingSourceTypes, data: soureTypeOptions } = useSourceTypes();
    const sourceType = soureTypeOptions?.find((st) => st.id == props.source.type);

    let name:any = props.source.name;
    if (props.source.type == 'FORM'){
        name = <Link to={`/form-entry/${props.source.id}`}>{props.source.name}</Link>
    }
    
    return (
        <tr>
            <td>
                <img src={sourceType?.icon_path} height="50"/>
            </td>
            <td>
                <span className="text-muted font-13">Name</span>
                <h5 className="font-14 mt-1 fw-normal">{name}</h5>
            </td>
            <td>
                <span className="text-muted font-13">Last Updated</span>
                <h5 className="font-14 mt-1 fw-normal">{timeAgo(props.source.last_updated as Date)}</h5>
            </td>
            {/* <td>
                <span className="text-muted font-13">Total Records</span>
                <h5 className="font-14 mt-1 fw-normal">(total records)</h5>
            </td> */}
            <td className="table-action">
                {/* <a
                    className="action-icon"
                    href="#"
                    onClick={() => props.clickedDelete()}
                >
                    <i className="mdi mdi-delete"></i>
                </a> */}

                <Link
                    className="action-icon"
                    to={'/source/' + props.source.id}
                >
                    <i className="mdi mdi-eye"></i>

                </Link>
            </td>
            

        </tr>
    );
    
}






interface Props {}
interface State {
    loading: boolean;
    sources: Source[];
    showFormModal: boolean;
    selectedSourceForDeletion?: Source;

    formSource?: Source;
}

export default class SourcesList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            sources: [],
            showFormModal: false,
        }
    }

    private async load() {
        this.setState({
            loading: true,
            sources: [],
        });
        
        const resp = await SourceORM.find();
        
        this.setState({
            loading: false,
            sources: resp.records,
        })
    
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });

        this.load();
    }

    showNewSourceModal() {
        this.setState({
            showFormModal: true,
        });
    }

    private async deleteSource() {
        this.setState({
            loading: true,
        });
        const result = await SourceORM.delete(this.state.selectedSourceForDeletion as Source);
        this.setState({
            selectedSourceForDeletion: undefined,
        });
        this.load();
    }


    
    render() {
        if (this.state.loading) {
            return <LoadingCard action="Loading Sources" />
        } else if (this.state.sources.length === 0) {
            return (
                <p>
                    No data sources yet.
                    <Link to="/sources/new" className="btn btn-primary">Create One</Link>
                </p>
            )
        } else {
            return (
                <div>
                    <ConfirmationModal
                        header="Delete data source?"
                        confirmationButtonText="Delete"
                        message={`To delete this data source, please type "${this.state.selectedSourceForDeletion?.name}" in the field below:`}
                        show={!!this.state.selectedSourceForDeletion}
                        typedValueExpectation={this.state.selectedSourceForDeletion?.name}
                        onClose={() => this.setState({
                            selectedSourceForDeletion: undefined,
                        })}
                        confirmationButtonVariant="danger"
                        onConfirm={() => this.deleteSource()}
                    />
                    <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                            <tbody>
                                {this.state.sources.map(s => {
                                    return (<SourceRow 
                                        key={s.id}
                                        source={s}
                                        clickedDelete={() => this.setState({
                                            selectedSourceForDeletion: s,
                                        })}
                                    />);
                                })}
                            </tbody>
                            
                        </table>
                    </div>
                </div>
                
                
            )
        }
    }
}