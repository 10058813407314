import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import { queryClient, useTemplates } from "@stores/data.store";
import AsyncButton from '@components/button/AsyncButton.component';
import ApiService, { SingleRecordResponse } from '@services/api/api.service';
import PageStructure, { PageContentNoSidebar } from '@pages/PageStructure.component';
import PliableLoader from '@components/loaders/PliableLoader.component';
import BuildOrchestrationORM from "@models/buildOrchestration";


const StartTemplatePage = () => {
    const { templateId } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);
    const templates = useTemplates();
    const autouse = searchParams.get('autouse') == 'true';

    useEffect(() => {
        if(autouse) {
            apply_template();
        }
    }, []);

    const template = useMemo(() => {
        if(templates.data){
            return templates.data.filter((tmpl) => tmpl.id == templateId)[0]
        }
    }, [templates]);


    const apply_template = useCallback(async () => {
        setSaving(true);
        try {
            const createTmplateGroupResp = await ApiService.getInstance().request('POST', `/templates/use/${templateId}`, {}) as SingleRecordResponse<any>;
            queryClient.invalidateQueries('business_objects');
            queryClient.invalidateQueries('source_record_types');
            queryClient.invalidateQueries('datamarts');
            navigate(`/template-group/${createTmplateGroupResp.record.id}`);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }

    }, [templateId]);

    let content;

    if (templates.isLoading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <PliableLoader size='lg' />
            </div>
        );
    }else if(saving){
        content = <>
                <div className="d-flex justify-content-center mt-5">
                    <PliableLoader size='lg' />
                </div>
                <div className="d-flex justify-content-center">
                    <h5>Creating template objects</h5>
                </div>
        </>
    }else {
        content = <div className="d-flex justify-content-center mt-5">
            <div>
                <h5>{template!.name}</h5> 
                <p>{template!.description}</p>
                <AsyncButton loading={saving} variant="pliable" onClick={() => apply_template()} text="Use this Template"/>
            </div>
        </div>
    }
    
    
    return <PageStructure pageTitle={template!.name}>
            <PageContentNoSidebar>
                {content}
            </PageContentNoSidebar>
    </PageStructure>
}

export default StartTemplatePage;