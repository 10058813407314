import AsyncButton from "@components/button/AsyncButton.component";
import { regenerateSeed, saveSeed, useSeed } from "@models/seed";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent } from "@pages/PageStructure.component";
import { JobEnqueueResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Handsontable from 'handsontable/base';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import { HotTable } from '@handsontable/react';
import { CellChange, ChangeSource } from "handsontable/common";
import { usePipelineNode } from "@stores/data.store";
import toast from "@services/toast.service";
import { useImmer } from "use-immer";
import produce from "immer";
import { getErrorMessage } from "@services/errors.service";
import SaveButton from "@components/button/SaveButton.component";


registerAllModules();

const SeedPage = () => {
    const { seedId } = useParams();
    const seed = useSeed(seedId as string);

    const [generating, setGenerating] = useState(false);
    const regenerate = useCallback(async () => {
        setGenerating(true);
        const jobInfo = await regenerateSeed(seedId as string) as JobEnqueueResponse;
        const result = await BackgroundService.getInstance().waitForJob(jobInfo.job_id);
        await seed.refetch();
        setGenerating(false);
        toast('success', 'Success', 'Table updated with latest distinct values.');
    }, [seed.dataUpdatedAt]);

    const pipelineNode = usePipelineNode(seed.data && seed.data.generated_from_node_id ? seed.data.generated_from_node_id : '');

    const [updatedTableData, setUpdatedTableData] = useImmer<string[][]>([]);

    useEffect(() => {
        if (seed.data && seed.data.seed_data) {
            setUpdatedTableData(seed.data.seed_data);
        }
    }, [seed.dataUpdatedAt]);
    const tableData = useMemo(() => {
        if (!seed.data) {
            return [];
        }

        return seed.data.seed_data ? seed.data.seed_data : [];
    }, [seed.dataUpdatedAt]);

    const tableDataChanged = useCallback((changes: CellChange[] | null, source: ChangeSource) => {
        if (changes) {
            setUpdatedTableData(draft => {
                changes.forEach(c => {
                    const rowIdx = c[0] as number;
                    const colIdx = c[1] as number;
                    const newValue = c[3] as string;
                    draft[rowIdx][colIdx] = newValue;
                });
            });
        }
    }, []);

    const sourceNodeName = useMemo(() => {
        if (pipelineNode.data) {
            return pipelineNode.data.name;
        }
        return '';
    }, [pipelineNode.dataUpdatedAt]);

    const sourceNodeFieldName = useMemo(() => {
        if (!pipelineNode.data || !seed.data) {
            return '';
        }

        if (seed.data.generated_from_node_column) {
            const theField = pipelineNode.data.fields.find(f => f.id === seed.data.generated_from_node_column);
            return theField?.name;
        }
        return '';
    }, [seed.dataUpdatedAt, pipelineNode.dataUpdatedAt]);

    const [saving, setSaving] = useState(false);
    const save = useCallback(async () => {
        if (!seed.data) {
            return;
        }
        setSaving(true);
        try {
            await saveSeed(produce(seed.data, draft => {
                draft.seed_data = updatedTableData;
            }));
            toast('success', 'Success', 'Seed saved');
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [seedId, updatedTableData, seed.dataUpdatedAt]);

    return <PageStructure
        pageTitle="Seed"
    >
        <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3">Sidebar</div>
                </PaneContent>
            </Pane>
            
        </PageSidebar>
        <PageContent>
            <Pane>
                <PaneContent>
                    {seed.data && <div className="p-3">
                        <h1 className="page-title">Configure Seed</h1>
                        
                        {seed.data.generated_from_node_id && <>
                        
                        
                            <div>
                                <div className="shadow-box p-3">
                                    <div className="d-flex mb-2" >
                                        <div className="flex-1">
                                            <h3>{seed.data.name}</h3>

                                            <div>This table contains all unique values from the <strong>{sourceNodeFieldName}</strong> column in your <strong><Link to={`/node/${seed.data.generated_from_node_id}`}>{sourceNodeName}</Link></strong> node. You can regenerate the table at any time by clicking on "Regenerate".</div>
                                        </div>
                                        <div>
                                            <AsyncButton
                                                onClick={regenerate}
                                                loading={generating}
                                                text="Regenerate"
                                            />
                                        </div>
                                    </div>
                                    
                                    
                                    <HotTable
                                        data={tableData}
                                        stretchH="all"
                                        colHeaders={seed.data.seed_headers}
                                        height={220}
                                        afterChange={tableDataChanged}
                                        licenseKey="non-commercial-and-evaluation"
                                    />
                                    <SaveButton
                                        onClick={save}
                                    />
                                </div>
                            
                                
                            </div>
                        </>}
                    </div>
                        
                        
                    }
                </PaneContent>
            </Pane>
        </PageContent>
    </PageStructure>
}

export default SeedPage;

