import styled from 'styled-components';

export const HeaderContainer = styled.div`
display: flex;
h4 {
    font-family: "Poppins";
    color: black;
    font-size: 24px;
    font-weight: 600;
    flex: 1;
}

button {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    text-align: center;
    padding: 0px;
    margin: 0px;
    border: none;
    background-color: #E9E9E9;
    color: black;

    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

    &:hover {
        background-color: #00A1E0;
        color: white;
    }
}
`

export const SubHeaderContainer
 = styled.div`
 height: 50px;
`

export const FieldContainer = styled.div`
padding: 16px 20px;
margin-bottom: 1rem;



&.selectable {
    &:hover {
        cursor: pointer;
        outline: solid 1px var(--pliable-blue);
    }
}

&.active {
    outline: solid 2px var(--pliable-blue);

    &:hover {
        outline: solid 2px var(--pliable-blue);
    }
}

`

export const FieldTitle = styled.div`
display: flex;
align-items: center;
margin-bottom: 0px;


&:hover {
    cursor: pointer;
    
}

img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: solid 1px var(--ct-border-color);
    margin-right: 1rem;
}

h3 {
    input {
        width: 75%;
    }
    flex: 1;
    color: black;
    margin-bottom: 0px;
}

h4 {
    flex: 1;
    color: black;
    margin-bottom: 0px;
}

button {
    background: none;
    padding: none;
    border: none;
    color: var(--ct-body-color);
    font-size: 18px;


    &:hover {
        color: black;
        cursor: pointer;
    }

    &.highlight {
        color: var(--pliable-yellow);
    }

    &:disabled {
        color: #ccc;
        cursor: default;
    }
}
`;