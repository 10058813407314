// Helper functions for determining status on various objects

import { MissionControlNodeProps } from "@components/missionControl/dataflow/MissionControlNode.component";
import { BusinessObject } from "@models/businessObject";
import { useBusinessObjects } from "@stores/data.store";
import { useMemo } from "react";

export interface StatusIndicator {
    type: 'ERROR' | 'WARNING' | 'INFO';
    message: string;

    // Drawer tab to open when they want to see more
    drawerTab: string;
}

/**
 * Hook for mission control nodes to return the 
 * status indicators for their specific object
 */
export function useStatusIndicatorsForNode(props: MissionControlNodeProps) {
    const businessObjects = useBusinessObjects();

    const indicators = useMemo(() => {
        if (!businessObjects.data) {
            return [];
        }
        if (props.nodeType === 'BUSINESS_OBJECT') {
            const bo =  businessObjects.data.find(bo => bo.id === props.objectId);

            if (!bo) {
                return []
            }
            return getStatusIndicatorsForBusinessObject(
                bo
            )
        }
        return [];
    }, [businessObjects.dataUpdatedAt, props.nodeType, props.objectId]);

    return indicators;
}

export function getMappedSourceIdsForBusinessObject(bo: BusinessObject): string[] {
    const mappedSourceIds: string[] = [];
    bo.fields.forEach(f => {
        if (f.map_options) {
            f.map_options.forEach(mo => {
                if (mo.source_record_type_id && !mappedSourceIds.includes(mo.source_record_type_id)) {
                    mappedSourceIds.push(mo.source_record_type_id);
                } else if (mo.sub_options) {
                    mo.sub_options.forEach(so => {
                        if (!mappedSourceIds.includes(so.source_record_type_id!)) {
                            mappedSourceIds.push(so.source_record_type_id!);
                        }
                    })
                }
            })
        }
    });
    return mappedSourceIds;
}

export function getStatusIndicatorsForBusinessObject(bo: BusinessObject): StatusIndicator[] {
    if (!bo) {
        return [];
    }

    const indicators: StatusIndicator[] = [];
    const mappedSourceIds: string[] = getMappedSourceIdsForBusinessObject(bo);
    
    if (bo.input_record_type_ids.length === 0) {
        indicators.push({
            type: 'WARNING',
            message: 'NO_SOURCES',
            drawerTab: 'sources',
        });
    }

    if (mappedSourceIds.length < bo.input_record_type_ids.length) {
        indicators.push({
            type: 'WARNING',
            message: 'MISSING_MAPPINGS',
            drawerTab: 'sources',
        });
    }

    if (bo.fields.length === 0) {
        indicators.push({
            type: 'WARNING',
            message: "NO_FIELDS",
            drawerTab: 'attributes',
        });
    }

    return indicators;


}