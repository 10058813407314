import styled from 'styled-components';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteTemplateGroup, invalidateMissionControlDataFlowData, queryClient, useTemplateGroup } from "@stores/data.store";
import LoadingCard from '@components/card/LoadingCard.component';
import DeleteButton from '@components/button/DeleteButton.component';
import FivetranSourceStep from './steps/FivetranSourceStep';
import WaitStep from './steps/WaitStep';
import DoneStep from './steps/ReadyStep';
import PageStructure, { PageContentNoSidebar } from '@pages/PageStructure.component';
import TemplateGroupORM, { TemplateGroupStep } from '@models/templateGroup';
import { Spinner } from 'react-bootstrap';
import BuildOrchestrationORM from '@models/buildOrchestration';
import ConfigService from '@services/config/config.service';



const TemplateGroupContainer = styled.div`
.pane-side {
    height: 100vh;
    overflow: scroll;
}

.step-pane {
    margin-top: 25%;
    h1 {
        font-family: Poppins;
        font-size: 26px;
        font-weight: 500;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: center;
    }
     
    P {
        text-align: center;
    }
}
.sidebar {
    min-height: 100vh;
    border-left: 1px solid #D9D9D9;
    padding: 30px;
    background-color: #fff;
    height: 100%;

    .step {
        margin-bottom: 20px;

        h2 {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 5px;
        }

        h3 {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 300;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
        }

        .step-number {
            width: 25px;
            height: 25px;
            border: 2px solid #00A1E0;
            border-radius: 13px;
            color: #00A1E0;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
    
            &.active {
                color: #fff;
                background-color: #00A1E0;
            }
        }
    }

    
}

`

interface SidebarStepPropd {
    stepIndex: number;
    step: TemplateGroupStep;
    isActive: boolean;
}

const SidebarStep = ({step, stepIndex, isActive}: SidebarStepPropd) => {
    return <div className={`step ${step.status.toLowerCase()}`}>
        <div className="library-item new-item d-flex">
            <div className="d-flex flex-row">
                { step.status == 'checking' && <Spinner />  }
                { step.status != 'checking' && <div className={`step-number ${isActive ? 'active' : ''}`}>{stepIndex+1}</div>  }
            </div> 
            <div className="d-flex flex-column px-2">
                <h2>{step.name}</h2>
                <h3>{step.description}</h3>
            </div>
        </div>
    </div>
}

const ViewTemplateGroupPage = () => {
    const { templateGroupId } = useParams();
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);
    const [checking, setChecking] = useState(false);
    const [lastStatus, setLastStatus] = useState('');

    const {isLoading: templateGroupLoading, data: templateGroup, refetch: refetchTemplateGroup} = useTemplateGroup(templateGroupId as string);

    useEffect(() => {
        if(!templateGroup || checking == true){
            return;
        }

        if(['waiting_for_ready', 'ready'].includes(templateGroup?.status) && !templateGroup.current_check_job_id){
            setChecking(true);
            TemplateGroupORM.checkForReady(templateGroup!.id as string);
        }
    }, [templateGroup, templateGroup?.status, checking]);

    const onDelete = useCallback(async () => {
        if (templateGroup) {
            await deleteTemplateGroup(templateGroup);
            queryClient.invalidateQueries('business_objects');
            queryClient.invalidateQueries('source_record_types');
            queryClient.invalidateQueries('datamarts');
            invalidateMissionControlDataFlowData();
            navigate('/');
        }

    }, [templateGroup]);

    const activeStep = useMemo(() => {
        if (!!templateGroup && templateGroup.steps && templateGroup.active_step_idx != null) {
            return templateGroup.steps[templateGroup.active_step_idx];
        }

    }, [templateGroup])

    const onStepComplete = useCallback(() => {
        refetchTemplateGroup()
    }, [templateGroup]);

    const renderStepPane = useCallback(() => {
        if(!!templateGroup && templateGroup.status == 'done'){
            ConfigService.getInstance().loadConfig();
            return <DoneStep template_group={templateGroup} />
        }else if(!!activeStep && activeStep.model_id && !!templateGroup) {
            const step_action = <FivetranSourceStep source_id={activeStep.model_id} template_group={templateGroup} step={activeStep} onComplete={onStepComplete} />;
            return <>
                {step_action}
            </>
        }else if(!!activeStep && !!templateGroup){
            return <WaitStep template_group={templateGroup} step={activeStep} onComplete={refetchTemplateGroup} />
        }
        return <></>
        

    }, [activeStep, templateGroup]);



    if (templateGroupLoading) {
        return (
            <LoadingCard />
        );
    }
    return <PageStructure
            pageTitle={templateGroup!.name}
            pageTitleChildren={
            <>
                <DeleteButton onConfirmDelete={() => onDelete()}/>
            </>
            
        }>

    <PageContentNoSidebar>
    <TemplateGroupContainer>
        <div className='row'>
            <div className="col-8 pane-side">
                <div className="section row d-flex justify-content-md-center">
                    <div className="col-6 step-pane d-flex flex-column justify-content-center">
                        {renderStepPane()}
                    </div>
                </div>

            </div>
            <div className="col-4 sidebar">
                <div className="steps">
                    {templateGroup!.steps!.map((step, idx) => {
                        return <div className={`step ${step.status.toLowerCase()}`}>
                            <SidebarStep step={step} stepIndex={idx} isActive={idx==templateGroup?.active_step_idx} />
                        </div>
                    })}
                </div>
                <div className="help">

                </div>

            </div>
        </div>
        
        
    </TemplateGroupContainer>
       
    </PageContentNoSidebar>
</PageStructure>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
}

export default ViewTemplateGroupPage;