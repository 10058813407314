import MissionControlConnectionLine from "@components/missionControl/MissionControlConnectionLine";
import { MissionControlCardinalEdge } from "@components/missionControl/edges/MissionControlEdge.component";
import SourceRecordTypeName from "@components/sources/SourceRecordTypeName.component";
import { autoLayout } from "@services/diagram.service";
import { BusinessObjectLineageEdge, BusinessObjectLineageNode } from "@stores/data.store";
import { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactFlow, { Node, Edge, NodeProps, Handle, Position, MiniMap, Controls, EdgeProps, useStore, getBezierPath, XYPosition, internalsSymbol, MarkerType, ConnectionMode} from 'reactflow';
import styled from 'styled-components';
import BusinessObjectName from "./BusinessObjectName.component";
import Warning from "@components/statusIndicators/Warning.component";

const NodeContainer = styled.div`
width: 300px;
height: 300px;

&.source {
    border: solid 2px var(--pliable-blue);
}

&.bronze {
    border: solid 2px var(--pliable-yellow);
}

&.silver {
    border: solid 2px var(--ct-success);
}

&.active {
    background: var(--pliable-yellow-light);
    border: solid 5px var(--pliable-yellow);
}
`

interface NodeWrapper {
    lineage: BusinessObjectLineageNode;
    onViewAll: () => void;
    businessObjectId: string;
}


interface Props {
    nodes: BusinessObjectLineageNode[];
    edges: BusinessObjectLineageEdge[];
    businessObjectId: string;
    autoLayoutMethod: 'NETWORK' | 'LINEAR';
}

const HiddenHandle = styled(Handle)`
width: 12px;
height: 12px;
visibility: hidden !important;
`

const SourceNode = (props: NodeProps<NodeWrapper>) => {
    const keys = useMemo(() => {
        return Object.keys(props.data.lineage.record_data).filter(k => !k.startsWith('_plb_')).slice(0, 8);
    }, [props.data.lineage.record_data]);
    return <NodeContainer className="shadow-box p-3 source">
        <h4>Source: <SourceRecordTypeName sourceRecordTypeId={props.data.lineage.record_source_record_type_id}/></h4>

        {keys.map(k => {
            return <div key={k}>
                <strong>{k}:</strong> {props.data.lineage.record_data[k]}
            </div>
        })}
        <div className="mt-2">
            <button className="me-1 btn btn-sm btn-primary" onClick={props.data.onViewAll}><i className="mdi mdi-eye"></i> Data</button>

        </div>        
        <HiddenHandle  type="source" position={Position.Right}/>
    </NodeContainer>
}

const BronzeNode = (props: NodeProps<NodeWrapper>) => {
    const keys = useMemo(() => {
        return Object.keys(props.data.lineage.record_data).filter(k => !k.startsWith('_plb_')).slice(0, 8);
    }, [props.data.lineage.record_data]);
    return <NodeContainer className="bronze shadow-box p-3">
        <h4>Cleaned: <SourceRecordTypeName sourceRecordTypeId={props.data.lineage.record_source_record_type_id}/></h4>
        {keys.map(k => {
            return <div key={k}>
                <strong>{k}:</strong> {props.data.lineage.record_data[k]}
            </div>
        })}
        <div className="mt-2">
            <button className="me-1 btn btn-sm btn-primary" onClick={props.data.onViewAll}><i className="mdi mdi-eye"></i> Data</button>
            <Link className="btn btn-sm btn-dark" to={`/data-source/${props.data.lineage.record_source_record_type_id}`}>
                <i className="mdi mdi-pencil-circle"></i> Cleaning
            </Link>
        </div>

        <HiddenHandle  type="source" position={Position.Right}/>
        <HiddenHandle  type="target" position={Position.Left}/>


    </NodeContainer>
}

const SilverNode = (props: NodeProps<NodeWrapper>) => {
    const keys = useMemo(() => {
        return Object.keys(props.data.lineage.record_data).filter(k => !k.startsWith('_plb_')).slice(0, 8);
    }, [props.data.lineage.record_data]);
    return <NodeContainer className="silver shadow-box p-3">
        <h4>Business Object</h4>
        {keys.map(k => {
            return <div key={k}>
                <strong>{k}:</strong> {props.data.lineage.record_data[k]}
            </div>
        })}
        <div className="mt-2">
            <button className="me-1 btn btn-sm btn-primary" onClick={props.data.onViewAll}><i className="mdi mdi-eye"></i> Data</button>
            <Link className="btn btn-sm btn-dark" to={`/business-object/${props.data.businessObjectId}/fields`}>
                <i className="mdi mdi-pencil-circle"></i> Mapping
            </Link>
        </div>
        <HiddenHandle  type="target" position={Position.Left}/>
    </NodeContainer>
}

const BusinessObjectNode = (props: NodeProps<NodeWrapper>) => {
    const keys = useMemo(() => {
        return Object.keys(props.data.lineage.record_data).filter(k => !k.startsWith('_plb_')).slice(0, 8);
    }, [props.data.lineage.record_data]);
    return <NodeContainer className={`business-object shadow-box p-3 ${props.data.businessObjectId == props.data.lineage.record_business_object_id ? 'active' : ''}`}>
        <h4><BusinessObjectName businessObjectId={props.data.lineage.record_business_object_id!}/></h4>
        {keys.map(k => {
            return <div key={k}>
                <strong>{k}:</strong> {props.data.lineage.record_data[k]}
            </div>
        })}
        <div className="mt-2">
            <button className="me-1 btn btn-sm btn-primary" onClick={props.data.onViewAll}><i className="mdi mdi-eye"></i> Data</button>
            <Link className="btn btn-sm btn-dark" to={`/business-object/${props.data.businessObjectId}/data/record/${props.data.lineage.record_uuid}`}>
                <i className="mdi mdi-source-merge"></i> Lineage
            </Link>
        </div>
        <HiddenHandle
        type="source"
        position={Position.Top}
        id="a"
        >
        </HiddenHandle>
    
        <HiddenHandle  type="source" position={Position.Right} id="b">
        </HiddenHandle>
        <HiddenHandle  type="source" position={Position.Bottom} id="c">
        </HiddenHandle>
        <HiddenHandle  type="source" position={Position.Left} id="d">
        </HiddenHandle>
    </NodeContainer>
}

const AmbiguousNode = (props: NodeProps<NodeWrapper>) => {
    return <NodeContainer className="shadow-box p-3">
        <h4>Business Object</h4>
        AMBIGUOUS RELATIONSHIP: {props.data.lineage.record_business_object_id}
        <HiddenHandle
        type="source"
        position={Position.Top}
        id="a"
        >
        </HiddenHandle>
    
        <HiddenHandle  type="source" position={Position.Right} id="b">
        </HiddenHandle>
        <HiddenHandle  type="source" position={Position.Bottom} id="c">
        </HiddenHandle>
        <HiddenHandle  type="source" position={Position.Left} id="d">
        </HiddenHandle>
    </NodeContainer>
}

const OrphanNode = (props: NodeProps<NodeWrapper>) => {
   
    return <NodeContainer className="shadow-box p-3">
        <h4><BusinessObjectName businessObjectId={props.data.lineage.record_business_object_id!}/></h4>
        <Warning>
            No related company!
        </Warning>
        <HiddenHandle
        type="source"
        position={Position.Top}
        id="a"
        >
        </HiddenHandle>
    
        <HiddenHandle  type="source" position={Position.Right} id="b">
        </HiddenHandle>
        <HiddenHandle  type="source" position={Position.Bottom} id="c">
        </HiddenHandle>
        <HiddenHandle  type="source" position={Position.Left} id="d">
        </HiddenHandle>
    </NodeContainer>
}


const nodeTypes = {
    // Record lineage
    'SOURCE': SourceNode,
    'BRONZE': BronzeNode,
    'SILVER': SilverNode,

    // Relationship graph
    'BUSINESS_OBJECT': BusinessObjectNode,
    'AMBIGUOUS': AmbiguousNode,
    'ORPHAN': OrphanNode,
};

const connectionLineStyle = {
    strokeWidth: 2,
    stroke: '#FF9F00',
    strokeDashArray: 5,
};

const EdgePathContainer = styled.g`
    &.faded {
        opacity: 20%;
    }



    .react-flow__edge-path {
        stroke-width: 5;
        stroke: #FF9F00;
    }

    .react-flow__edge-path.active {
        stroke-dasharray: 50;
        animation: 5s linear 0s infinite dash;
      }
      
      @keyframes dash {
          to {
            stroke-dashoffset: -1250;
          }
      }

   // Select the second path aka the invisible interactive 
   
    path.react-flow__edge-interaction {
        z-index: 5000;
        pointer-events: all; 

        // disabling to stop flicker
        // &:hover + path{
        //     stroke-width: 10;
        // }

        .buttons-container {
            display: none;
        }

        &:hover {
            .buttons-container: display: block;
        }


    }
`

function getHandleCoordsByPosition(node: Node, handlePosition: Position, handleType: 'source' | 'target'): XYPosition {
    // all handles are from type source, that's why we use handleBounds.source here
  
    //   @ts-ignore
    let handle: any;
    if (handleType == 'source') {
        //   @ts-ignore
        handle = node[internalsSymbol].handleBounds.source.find(
            //   @ts-ignore
            (h) => h.position === handlePosition
        );
    } else {
        //   @ts-ignore
        handle = node[internalsSymbol].handleBounds.target.find(
            //   @ts-ignore
            (h) => h.position === handlePosition
        );
    }
    
  
    let offsetX = handle!.width / 2;
    let offsetY = handle!.height / 2;

  
    const x = node.positionAbsolute!.x + handle!.x + offsetX;
    const y = node.positionAbsolute!.y + handle!.y + offsetY;
  
    return {x, y};
}

function getEdgePath(sourceNode: Node, targetNode: Node) {
    const sourceHandlePosition = getHandleCoordsByPosition(sourceNode, Position.Right, 'source');
    const targetHandlePosition = getHandleCoordsByPosition(targetNode, Position.Left, 'target');

    return getBezierPath({
        sourceX: sourceHandlePosition.x as number,
        sourceY: sourceHandlePosition.y as number,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        targetX: targetHandlePosition.x as number,
        targetY: targetHandlePosition.y as number,
    });
}

const LineageEdge = (props: EdgeProps) => {
    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(props.source), [props.source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(props.target), [props.target]));
    const [edgePath, labelX, labelY] = useMemo(() => {
        return getEdgePath(sourceNode!, targetNode!);
    }, [sourceNode, targetNode]);

    return <EdgePathContainer>
         <marker
            id="triangle"
            viewBox="0 0 10 10"
            refX="8"
            refY="5"
            markerUnits="strokeWidth"
            markerWidth="5"
            markerHeight="5"
            orient="auto">
            <path d="M 0 0 L 10 5 L 0 10 z" fill="#FF9F00" />
        </marker>
        <path
          id={props.id}
          className="react-flow__edge-path"
          d={edgePath}
          style={{'stroke': '#FF9F00', 'strokeWidth': '5'}}
          markerEnd='url(#triangle)'
        />
    </EdgePathContainer>
}

const edgeTypes = {
    lineage: LineageEdge,
    cardinal: MissionControlCardinalEdge,
}



const BusinessObjectLineage = (props: Props) => {
    const viewNodeDetails = useCallback((node: BusinessObjectLineageNode) => {
        setActiveNode(node);
    }, []);

    const [reactFlowNodes, reactFlowEdges] = useMemo(() => {
        const nodes = props.nodes.map(n => {
            return {
                id: n.id,
                type: n.record_type,
                data: {
                    lineage: n,
                    onViewAll: () => {
                        viewNodeDetails(n);
                    },
                    businessObjectId: props.businessObjectId
                },
                position: {
                    x: 0,
                    y: 0,
                }
            }
        });

        const edges = props.edges.map(e => {
            return {
                id: e.from_id + '|' + e.to_id,
                source: e.from_id,
                target: e.to_id,
                type: props.autoLayoutMethod == 'LINEAR' ? 'lineage' : 'cardinal',
            }
            
        });

        console.log('NODES', nodes);

        // Auto-arrange

        const positions = autoLayout(nodes, edges, props.autoLayoutMethod, 600, 300, 300)
        
        const finalNodes = nodes.map(n => {
            n.position = positions[n.id];
            return n;
        });

        console.log('EDGES:', edges);
        return [finalNodes, edges];
    }, [props.nodes, props.edges, viewNodeDetails, props.businessObjectId]);

    const [activeNode, setActiveNode] = useState<BusinessObjectLineageNode | undefined>(undefined);



    

    return <div style={{width: '100%', height: '100%'}}>
        <Modal size="lg" show={!!activeNode} onHide={() => setActiveNode(undefined)}>
            <Modal.Header closeButton>
                <Modal.Title>View All Data</Modal.Title>

            </Modal.Header>
            <Modal.Body>
                <p><code>Record ID {activeNode?.record_data['_plb_uuid']}</code></p>
                <table className="table table-bordered">
                    <tbody>
                        {activeNode && Object.keys(activeNode.record_data).filter(k => !k.startsWith('_plb_')).map(k => {
                            return <tr>
                                <th>{k}</th>
                                <td>{activeNode.record_data[k]}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
               
                    
                
            </Modal.Body>
        </Modal>
        <ReactFlow
            nodes={reactFlowNodes}
            edges={reactFlowEdges}
            fitView
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            proOptions={{hideAttribution: true}}
            connectionLineStyle={connectionLineStyle}
            connectionMode={props.autoLayoutMethod == 'LINEAR' ? ConnectionMode.Strict : ConnectionMode.Loose}
        >

            <MiniMap position="bottom-right" pannable/>
            <Controls
                showZoom
                showFitView
                showInteractive={false}
            />

        </ReactFlow>
    </div>

}

export default BusinessObjectLineage;