import React from 'react'
import { Outlet } from "react-router-dom";
import styled from 'styled-components';

import { Spinner } from 'react-bootstrap';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


const Main = styled.main`
    height: 100%;
    width: 100%;
`



const UnauthenticatedRootPage = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LcasCApAAAAAJTRvrhKnT1kzAzvNPOMZOqMg-Y5">
            <Main>
                <Outlet/>
            </Main>
        </GoogleReCaptchaProvider>
    );
}

export default UnauthenticatedRootPage;