import PageTitle from "@components/pageTitle/PageTitle.component";
import SourceRecordTypeDataTable from "@components/sources/SourceRecordTypeDataTable.component";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from 'styled-components';
import { useDebounce } from "use-debounce";
import { Allotment } from "allotment";
import { useSourceRecordType } from "@stores/data.store";

const Container = styled.div`
height: calc(100vh - 50px);
width: 100%;
max-width: 100%;
overflow: hidden;
`

const SubContainer = styled.div`
height: calc(100vh - 90px);
width: 100%;
max-width: 100%;
overflow: hidden;
background-color: white;
`

const Header = styled.div`
height: 40px;
line-height: 40px;
padding-left: 5px;
display: flex;
border-bottom: solid 1px var(--ct-border-color);

input {
    line-height: 25px;
    border: none;
    border-left: solid 1px var(--ct-border-color);
    width: 200px;
}
`

const SourceSideBySidePage = () => {
    const { srt1Id, srt2Id } = useParams();
    const [query1, setQuery1] = useState('');
    const [query2, setQuery2] = useState('');

    const srt1 = useSourceRecordType(srt1Id!);
    const srt2 = useSourceRecordType(srt2Id!);

    const [debouncedQuery1] = useDebounce(query1, 1000);
    const [debouncedQuery2] = useDebounce(query2, 1000);
    return <>
        <PageTitle
            title="Side by Side"
        />

        <Container>
            <Allotment>
                <Allotment.Pane>
                    <div>
                        <Header>
                            <strong className="flex-1">{srt1.data && srt1.data.name}</strong>
                            <Link to={`/cleaning/${srt1Id}`} className="icon-button me-2"><i className="mdi mdi-broom"></i> Edit Cleaning Rules</Link>
                            <input type="text" placeholder="Search" value={query1} onChange={(e) => setQuery1(e.target.value)}/>

                        </Header>
                        <SubContainer>
                            <SourceRecordTypeDataTable
                                searchQuery={debouncedQuery1}
                                sourceRecordTypeId={srt1Id!}
                            />
                        </SubContainer>
                    </div>
                    
                </Allotment.Pane>
                <Allotment.Pane>
                    <div>
                        <Header>
                            <strong className="flex-1">{srt2.data && srt2.data.name}</strong>
                            <Link to={`/cleaning/${srt2Id}`} className="icon-button me-2"><i className="mdi mdi-broom"></i> Edit Cleaning Rules</Link>
                            <input type="text" placeholder="Search" value={query2} onChange={(e) => setQuery2(e.target.value)}/>

                        </Header>                  
                        <SubContainer>
                            <SourceRecordTypeDataTable
                                searchQuery={debouncedQuery2}
                                sourceRecordTypeId={srt2Id!}
                            />
                        </SubContainer>
                    </div>
                    
                </Allotment.Pane>
            </Allotment>
            
        </Container>

    </>
}

export default SourceSideBySidePage;