
import { SimpleDataTable } from "@components/datatable/DataTable.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import DagDataLibrary from "@components/nav/DagDataLibrary.component";
import PipelineNodeDataTable from "@components/pipelineNodes/PIpelineNodeDataTable";

import PipelineNodeInfo from "@components/pipelineNodes/PipelineNodeInfo.component";
import PipelineNodeName from "@components/pipelineNodes/PipelineNodeName.component";
import PipelineNodeNotFound from "@components/pipelineNodes/PipelineNodeNotFound.component";
import PipelineNodeSingleRecord from "@components/pipelineNodes/PipelineNodeSingleRecord.component";
import PipelineNodeSubnav from "@components/pipelineNodes/PipelineNodeSubnav.component";
import { PipelineNode, PipelineNodeMeasure, usePipelineNodeRecordData } from "@models/pipelineNode";

import PageStructure, { PageContent, PageSidebar, Pane, PaneContent, PaneContentWithSubnav, PaneFooter } from "@pages/PageStructure.component";
import { ApiError } from "@services/api/api.service";
import { usePipelineNode, usePipelineNodeRelationships } from "@stores/data.store";
import { useMemo, useState } from "react";
import { Collapse } from "react-bootstrap";
import { useParams } from "react-router-dom";

interface MeasureDataProps {
    node: PipelineNode;
    measure_indices: number[];
    data: Record<string, any>[];
    show: boolean;
    onToggle: () => any;
}

const MeasureData = (props: MeasureDataProps) => {
    const measureLabels = props.node.measures!.filter((m: PipelineNodeMeasure, idx: number) => {
        return props.measure_indices.includes(idx);
    }).map(m => m.name);

    return <div className="card mb-2">
        <div className="card-header">
        <div className="d-flex center-vertically pointer" onClick={props.onToggle}>
                <div className="flex-1">
                    <strong>
                        <PipelineNodeName
                            pipelineNodeId={props.node.measures![props.measure_indices[0]].pipeline_node_id}
                            plural
                        />
                        &nbsp; ({measureLabels.join(', ')}) 
            
                    </strong>
                </div>   
                <div>
                    
                    {props.show && <span className="text-36">
                        <i className="mdi mdi-menu-up"></i>
                    </span>}
                    {!props.show && <span className="text-36">
                        <i className="mdi mdi-menu-down"></i>
                    </span>}
                </div>
            </div>
            
        </div>
        <Collapse in={props.show}>

            <div className="card-body">
                <div style={{height: '500px'}}>
                    <PipelineNodeDataTable
                        pipelineNodeId={props.node.measures![props.measure_indices[0]].pipeline_node_id}
                        dataOverride={props.data}
                    />
                </div>
            </div>
        </Collapse>
    </div>


}

const PipelineNodeRecordPage = () => {
    const { pipelineNodeId, recordUuid } = useParams();
    const pipelineNode = usePipelineNode(pipelineNodeId as string);
    const recordData = usePipelineNodeRecordData(pipelineNodeId as string, recordUuid as string);
    
    const relationships = usePipelineNodeRelationships();

    const parents = useMemo(() => {
        if (!relationships.data || !pipelineNodeId) {
            return [];
        }
        return relationships.data.filter(r => r.child_node_id == pipelineNodeId);
    }, [relationships.dataUpdatedAt, pipelineNodeId]);

    const children = useMemo(() => {
        if (!relationships.data || !pipelineNodeId) {
            return [];
        }
        return relationships.data.filter(r => r.parent_node_id == pipelineNodeId);
    }, [relationships.dataUpdatedAt, pipelineNodeId]);

    const [activeRelationshipId, setActiveRelationshipId] = useState('');

    if (pipelineNode.status == 'error' && (pipelineNode.error as ApiError).code == 404) {
        return <PipelineNodeNotFound/>
    }
    return <PageStructure
        pageTitle={pipelineNode.data ? pipelineNode.data.name : ''}
        breadcrumbs={[
            {
                title: 'Pipeline Node',
            }
            
        ]}
    >
        <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3">
                        <PipelineNodeInfo pipelineNodeId={pipelineNodeId as string}/>
                    </div>
                    <div className="p-3 border-top">
                        <DagDataLibrary activeNodeId={`PipelineNode:${pipelineNodeId}`}/>
                    </div>
                    
                    
                </PaneContent>
            </Pane>
            
        </PageSidebar>
        <PageContent>
            <Pane>
                <PipelineNodeSubnav
                    pipelineNodeId={pipelineNodeId as string}
                >
                    
                </PipelineNodeSubnav>
                <PaneContentWithSubnav>
                    <Pane>
                        <PaneContent>
                            <div className="p-3">
                                <h2><PipelineNodeName singular pipelineNodeId={pipelineNodeId}/> Record</h2>
                            
                                {(recordData.isLoading || pipelineNode.isLoading) &&  <PliableLoader/>}
                                {!recordData.isLoading && !pipelineNode.isLoading && recordData.data && pipelineNode.data && <div>
                                    <div className="row">
                                        <div className="col-4 pe-1">
                                            <div className="card">
                                                <div className="card-header">
                                                    <strong>Record Data</strong>
                                                </div>
                                                <div className="card-body">
                                                    <PipelineNodeSingleRecord
                                                        record={recordData.data.record}
                                                        pipelineNodeId={pipelineNodeId as string}
                                                        compact
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-8 ps-1">
                                            <h3>Related Records</h3>
                                            {recordData.data && recordData.data.measures && recordData.data.measures.map((m, idx) => {
                                                const key = `measure${idx}`;
                                                return <MeasureData
                                                    node={pipelineNode.data!}
                                                    measure_indices={m.measure_indices}
                                                    data={m.data}
                                                    show={activeRelationshipId == key}
                                                    onToggle={() => {
                                                        if (activeRelationshipId == key) {
                                                            setActiveRelationshipId('');
                                                        } else {
                                                            setActiveRelationshipId(key);
                                                        }
                                                    }}

                                                />
                                            })}
                                            {parents.map(p => {
                                                if (!recordData.data!.relationships!.hasOwnProperty(p.id as string)) {
                                                    return <></>;
                                                }
                                                const relData = recordData.data!.relationships![p.id as string];
                                                 
                                                return <div className="card mb-2">
                                                    <div className="card-header">
                                                        <div className="d-flex center-vertically pointer" onClick={() => {
                                                            if (activeRelationshipId == p.id as string) {
                                                                setActiveRelationshipId('');
                                                            } else {
                                                                setActiveRelationshipId(p.id as string);
                                                            }
                                                        }}>
                                                            <div className="flex-1">
                                                                <strong>
                                                                    <PipelineNodeName
                                                                        pipelineNodeId={p.parent_node_id}
                                                                        singular
                                                                    />
                                                                    &nbsp;{p.name ? ('(' + p.name + ')') : ''} 
                                                      
                                                                </strong>
                                                            </div>   
                                                            <div>
                                                                
                                                                {activeRelationshipId == p.id as string && <span className="text-36">
                                                                    <i className="mdi mdi-menu-up"></i>
                                                                </span>}
                                                                {activeRelationshipId != p.id as string && <span className="text-36">
                                                                    <i className="mdi mdi-menu-down"></i>
                                                                </span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Collapse in={activeRelationshipId == p.id as string}>
                                                        <div className="card-body">
                                                            {relData === null && <>
                                                                No parent
                                                            </>}
                                                            {relData !== null && <>
                                                                <PipelineNodeSingleRecord
                                                                    record={relData[0]}
                                                                    pipelineNodeId={p.parent_node_id}
                                                                    includeLink
                                                                />
                                                            </>}
                                                        </div>
                                                    </Collapse>
                                                    
                                                </div>
                                            })}
                                            {children.map(p => {
                                                if (!recordData.data!.relationships!.hasOwnProperty(p.id as string)) {
                                                    return <></>;
                                                }
                                                const relData = recordData.data!.relationships![p.id as string];
                                                return <div className="card mb-2">
                                                    <div className="card-header">
                                                    <div className="d-flex center-vertically pointer" onClick={() => {
                                                            if (activeRelationshipId == p.id as string) {
                                                                setActiveRelationshipId('');
                                                            } else {
                                                                setActiveRelationshipId(p.id as string);
                                                            }
                                                        }}>
                                                            <div className="flex-1">
                                                                <strong>
                                                                    <PipelineNodeName
                                                                        pipelineNodeId={p.child_node_id}
                                                                        plural
                                                                    />
                                                                    &nbsp;{p.name ? ('(' + p.name + ')') : ''} 
                                                      
                                                                </strong>
                                                            </div>   
                                                            <div>
                                                                
                                                                {activeRelationshipId == p.id as string && <span className="text-36">
                                                                    <i className="mdi mdi-menu-up"></i>
                                                                </span>}
                                                                {activeRelationshipId != p.id as string && <span className="text-36">
                                                                    <i className="mdi mdi-menu-down"></i>
                                                                </span>}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <Collapse in={activeRelationshipId == p.id as string}>

                                                        <div className="card-body">
                                                            {relData === null && <>
                                                                No children
                                                            </>}
                                                            {relData !== null && <div style={{height: '500px'}}>
                                                                <PipelineNodeDataTable
                                                                    pipelineNodeId={p.child_node_id}
                                                                    dataOverride={relData}
                                                                />
                                                            </div>}
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </PaneContent>
                    </Pane>
                </PaneContentWithSubnav>
        
            </Pane>
                
            
        </PageContent>
    </PageStructure>

}

export default PipelineNodeRecordPage;