import AsyncButton from "@components/button/AsyncButton.component";
import BuildOrchestrationORM from "@models/buildOrchestration";
import { BusinessObject } from "@models/businessObject";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent, PaneFooter } from "@pages/PageStructure.component";
import { alert, promptEmoji } from "@services/alert/alert.service";
import { getErrorMessage } from "@services/errors.service";
import { summarizeNumber } from "@services/formatting.service";
import { timeAgo } from "@services/time.service";
import toast from "@services/toast.service";
import TrackingService, { Events } from "@services/tracking.service";
import { patchBusinessObject, useBusinessObject } from "@stores/data.store";
import useGlobalState from "@stores/global.state";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useImmer } from "use-immer";

const BusinessObjectWrapperPage = () => {
    const { businessObjectId } = useParams();

    const businessObject = useBusinessObject(businessObjectId as string);

    const [formData, setFormData] = useImmer<BusinessObject>({
        id: null,
        name: '',
        description: '',
        fields: [],
        input_record_type_ids: [],
    });

    useEffect(() => {
        if (businessObject.data) {
            setFormData(produce(businessObject.data, draft => {
                return
            }));
        }
    }, [businessObject.dataUpdatedAt]);

    const [saved, setSaved] = useState(false);



    const onSave = useCallback(async () => {
        const result = await patchBusinessObject(businessObjectId as string, {
            name: formData.name,
            description: formData.description,
            image_url: formData.image_url,
        });
        setSaved(true);
        setTimeout(() => {
            setSaved(false);
        }, 3000);
    }, [businessObjectId, formData]);

    const debouncedSave = useDebouncedCallback(onSave, 1000);


    const onNameChange = useCallback((newName: string) => {
        setFormData(draft => {
            draft.name = newName;
        });
        setTimeout(() => {
            debouncedSave();
        }, 500);
    }, [debouncedSave]);

    const onDescriptionChange = useCallback((newDescription: string) => {
        setFormData(draft => {
            draft.description = newDescription;
        });
        setTimeout(() => {
            debouncedSave();
        }, 500);
    }, [debouncedSave]);

    
    const changeIcon = useCallback(() => {
        promptEmoji({
            onConfirm: (emoji: any) => {
                setFormData(draft => {
                    draft.image_url = emoji.imageUrl as string;
                });
                setTimeout(() => {
                    debouncedSave();
                }, 500);
            },
            onClose: () => {
                return;
            }
        });
    }, []);

    const { pageDirty } = useGlobalState();

    const [building, setBuilding] = useState(false);

    const navigate = useNavigate();
    const onBuild = useCallback(async () => {
        if (pageDirty) {
            alert('Warning', 'You have unsaved changes that might affect your build. You should save first before building!');
            return;
        }

        try {
            setBuilding(true);
            const orchestration = await BuildOrchestrationORM.buildModel('BusinessObject', businessObjectId as string);

            if (orchestration.status == 'COMPLETE') {
                toast('info', 'Everything up to date', 'No need to rebuild.');
              
            } else {
                navigate(`/?watchOrchestrationId=${orchestration.id}`);

            }

            TrackingService.track_event(Events.BUSINESS_OBJECT_PROCESS_CLK, {
                business_object_id: businessObjectId as string
            });

        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setBuilding(false);
        }
    }, [pageDirty, businessObjectId]);

    return <PageStructure
        pageTitle={businessObject.data ? businessObject.data.name : ''}
        breadcrumbs={[
            {
                title: 'Business Object',
            }
            
        ]}
    >
        {/* <PageTitle title={businessObject.data.name}
                breadcrumbs={[
                    {
                        title: 'Business Object',
                    }
                    
                ]}
            /> */}
        <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3 mb-1">
                        <div className="d-flex center-vertically mb-3">
                            <div className="me-2">
                                <img style={{width: '50px'}} src={formData.image_url} onClick={() => changeIcon()}/>

                            </div>
                            <div className="flex-1">
                                <Form.Control value={formData.name} onChange={(e) => onNameChange(e.target.value)} className="inline-edit text-header"/>

                            </div>
                        </div>
                        <div>
                            <Form.Control placeholder="Description" as="textarea" className="inline-edit text-13" value={formData.description} onChange={(e) => onDescriptionChange(e.target.value)}/>
                            <Form.Text>
                                {saved && <span className="text-success">Changes saved!</span>}
                                {!saved && <span>&nbsp;</span>}
                            </Form.Text>

                        </div>
                        
                    </div>
                    <hr />
                    {businessObject.data && (
                        <div className="p-3 mb-3">
                            <Form.Group className="mb-2">
                                <Form.Label className="small">
                                    Total Records
                                </Form.Label>
                                <h3>{summarizeNumber(businessObject.data.total_records!)}</h3>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="small">
                                    Last Build
                                </Form.Label>
                                <h3>{businessObject.data.last_build_completed ? timeAgo(new Date (businessObject.data.last_build_completed as string)) : 'never'}</h3>
                            </Form.Group>
                            
                        </div>
                    )}
                    
                </PaneContent>
                <PaneFooter big>
                    <div className="p-3 d-flex center-vertically" style={{height: '100%'}}>
                        <AsyncButton
                            className="btn btn-primary w-100"
                            icon="mdi mdi-play"
                            loading={building}
                            text="Rebuild"
                            disabled={building}
                            onClick={onBuild}
                        />
                    </div>
                </PaneFooter>
            </Pane>
            
        </PageSidebar>
        <PageContent>
            
            <Outlet/>
        </PageContent>
    </PageStructure>
    
}

export default BusinessObjectWrapperPage;