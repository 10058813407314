import React, { useRef, useState, useLayoutEffect, ElementType } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import Overlay from 'react-bootstrap/Overlay';

interface OverflowTooltipProps {
  text: string;
  className?: string;
  as?: ElementType;
}

const OverflowTooltip: React.FC<OverflowTooltipProps> = ({
  text,
  className = '',
  as: Tag = 'div',
  ...props
}) => {
  const textRef = useRef<HTMLElement>(null);
  const [isOverflowed, setIsOverflowed] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useLayoutEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        const element = textRef.current;
        const isOverflowing = element.scrollWidth > element.clientWidth;
        if (isOverflowed !== isOverflowing) {
          setIsOverflowed(isOverflowing);
        }
      }
    };

    checkOverflow();
    
    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
    });
    
    if (textRef.current) {
      resizeObserver.observe(textRef.current);
    }

    const mutationObserver = new MutationObserver(() => {
      checkOverflow();
    });
    
    if (textRef.current) {
      mutationObserver.observe(textRef.current, { childList: true, subtree: true });
    }

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [text, textRef, isOverflowed]);

  return (
    <div style={{ position: 'relative' }}>
      <Tag
        ref={textRef}
        className={className}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {text}
      </Tag>
      <Overlay target={textRef.current} show={showTooltip && isOverflowed} placement="top">
        {(props) => (
          <Tooltip id="tooltip" {...props}>
            {text}
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};

export default OverflowTooltip;
