import { useEffect, useRef, useCallback, useState, forwardRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TenantPicker from '@components/tenant/TenantPicker.component';
import AuthService, { useAuthState } from "../../services/auth/auth.service";
import logoLarge from '../../assets/images/pliable/logo_with_text_black.png';
import { PliableIcon } from '@components/icons/PliableIcons.component';
import { AuthorizedContent, AdminPortal, useEntitlements } from "@frontegg/react";
import { Role } from "../../constants/roles";
import TrackingService, {Events} from '@services/tracking.service';
import styled from 'styled-components';
import { useHotkeys } from 'react-hotkeys-hook';
import { pickTemplate } from '@services/alert/alert.service';
import { toggleThenaWidget } from "@stores/data.store";
import ApiService from '@services/api/api.service';
import { enterDraftMode, exitDraftMode, useDraftVersionId } from '@stores/data.store';

const Styles = styled.div`
    box-shadow: 0px 0px 7px 0px #555;
    color: white;
    z-index: 6000;
    
    &.sidebar-compact {
        width: 60px;
        span.link-label {
            display: none;
        }
    }

    span.link-label {
        margin-left: .5rem;
    }

    text-align: left;
    width: 12rem;
    background-color: #272727;
    height: 100vh;

    .nav-item {
        font-size: 1em;
        text-align: center;
        margin-bottom: 1rem;
        
        button {
            border-radius: 100%;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
            text-align: center;
            color: white;
            font-size: 24px;
            margin: 0px auto;
        }
        .nav-link {
            color: var(--ct-body-color);
            &.active {
                background: white;
                color: var(--pliable-blue);

                &:hover {
                    color: var(--pliable-blue);
                }
            }
            &:hover {
                color: black;
                text-decoration: none;
            }
        }
    }

    .version {
        font-size: .8em;
        color: #666;
    }
`;

// @ts-ignore
const AuthToggle = forwardRef(({ children, onClick, disabled }, ref) => (
    <button
    //   @ts-ignore
      ref={ref}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      style={{
        padding: '0px',
        background: 'none',
        border: 'none',
      }}
    >
      {children}
    </button>
  ));


function useComponentVisible(initialIsVisible: boolean) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef<HTMLLIElement>(null);
    

    const handleClickOutside = (event: any) => {
        // console.log('Click outside:', event);
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref, isComponentVisible, setIsComponentVisible };
}

export interface NavParams {
    disableSidebar?: boolean;
}

const Nav = (params: NavParams = {}) => {
    const navigate = useNavigate();
    const {data: draftVersionId} = useDraftVersionId();
    const logout = () => {
        navigate('/logout');
    }

    const settings = () => {
        navigate('/account');
    }

    useHotkeys('ctrl+t', () => {
        pickTemplate({
            onTemplateSelected: (tmpl_id) => { navigate(`/template/${tmpl_id}?autouse=true`) }
        })
    }, []);

    useHotkeys('ctrl+a', () => {
        navigate(`/advanced/new`)
    }, []);

    useHotkeys('ctrl+d', () => {
        onToggleDraftMode('hotkey');
    }, [draftVersionId]);
    

    const location = useLocation();

    const authState = useAuthState();

    // const { sidebarExpanded, toggleSidebar} = useGlobalState((state: any) => state);

    const sidebarExpanded = false;

    let extraClass = '';
    if (params.disableSidebar) {
        extraClass = 'sidebar-disabled';
    } else if (!sidebarExpanded) {
        extraClass = 'sidebar-compact';
    }

    let toggleClass = '';
    if (params.disableSidebar) {
        toggleClass = 'invisible';
    }

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const username = AuthService.getInstance().authState?.user?.email;
    const tenant_name = AuthService.getInstance().getTenantName();

    const { isEntitled: isAssistantEnabled } = useEntitlements({
        featureKey: "tenant_assistant",
    });

    const onToggleDraftMode = useCallback(async (trigger: string) => {
        if(draftVersionId) {
            await exitDraftMode(trigger);
        }else{
            await enterDraftMode(trigger);
        }
    }, [draftVersionId]);

    const showAdminPortal = useCallback(() => {
        AdminPortal.show();
        TrackingService.track_event(Events.USER_ADMIN_CLK);
    }, []);

    const showAssistant = useCallback(() => {
        navigate('/data-assistant');
    }, []);

    const trackLogout = useCallback(() => {
        TrackingService.track_event(Events.USER_LOGOUT_CLICK);
    }, []);

    const runSchedulerCheck = useCallback(async () => {
        const service = ApiService.getInstance();
        const response = await fetch(service.hostname + '/system/scheduler/check', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getInstance().getAccessToken(),
            },
            mode: 'cors',
            credentials: 'include',
        });
    }, []);

    return (
    <Styles className={`d-flex flex-column flex-shrink-0 bg-body-tertiary  ${extraClass}`}>
        <ul className="nav nav-pills nav-flush flex-column mb-auto">
            <li className="nav-item" >
                
                <Link className={`nav-link py-3`} to="/" title="Dashboard">
                    {!!sidebarExpanded && (
                        <img src={logoLarge} style={{height: '27px'}}/>
                    )}
                    {!sidebarExpanded && (
                        <PliableIcon height='50px' />
                    )}
                </Link>
            </li>
        </ul>

        <ul className="nav nav-pills nav-flush flex-column">
            {isAssistantEnabled && 
                <li className="nav-item">
                    <button onClick={showAssistant} className="btn btn-pliable" title="Assisant">
                        <i className="mdi mdi-robot-outline"></i>
                    </button>
                </li>
            }
            <li className="nav-item">
                <button onClick={showAdminPortal} className="btn btn-pliable" title="User Admin">
                    <i className="mdi mdi-account-group"></i>
                </button>
            </li>
            <li className="nav-item start-thena-chat" role="button" title="Support">
                <button onClick={toggleThenaWidget} className="btn btn-primary">
                    <i className='mdi mdi-comment-question'></i>
                </button>
                
            </li>
            <Dropdown drop='end' className="nav-item">
                <Dropdown.Toggle as={AuthToggle}>
                    <img src={authState?.user?.profilePictureUrl as string | undefined} alt="mdo" width="40" height="40" className="rounded-circle" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {/* <Link to="/audit-logs" className='dropdown-item'><i className="mdi mdi-archive"></i> Audit Log</Link> */}
                    <Dropdown.Item></Dropdown.Item>
                    {!!authState?.user?.superUser && <Dropdown.Item onClick={runSchedulerCheck}>
                        Run Scheduler    
                    </Dropdown.Item>}
                    <span className="dropdown-item version">version {process.env.REACT_APP_VERSION}</span>
                    <span className="dropdown-item version">{tenant_name}</span>
                    <AuthorizedContent requiredRoles={[Role.Admin]} key="tenant-admin-wrap" >
                        <Dropdown.Item href="#" onClick={settings}><i className="mdi mdi-cog"></i> Settings</Dropdown.Item>
                    </AuthorizedContent>
                    
                    <Dropdown.Item href="#" onClick={logout}><i className="mdi mdi-logout"></i> Logout</Dropdown.Item>
                    <TenantPicker />
                </Dropdown.Menu>
            </Dropdown>
        </ul>
    </Styles>
    );

}


export default Nav;