import { PipelineNodeMeasureJoinTree } from "@models/pipelineNode";
import { ReactNode } from "react";
import PipelineNodeName, { PipelineNodeRelationshipName } from "../PipelineNodeName.component";

interface Props {
    joinTree: PipelineNodeMeasureJoinTree;
}

const getComponentsForJoinTree = (joinTree: PipelineNodeMeasureJoinTree): ReactNode[] => {
    const components: ReactNode[] = [];
    if (joinTree.relationship_id) {
        components.push(<PipelineNodeRelationshipName relationshipId={joinTree.relationship_id} link linkToNewPage/>)
    } 
    components.push(<PipelineNodeName pipelineNodeId={joinTree.node_id} link linkToNewPage/>);
    return components;
}

const PipelineNodeJoinTreeLabel = (props: Props) => {
    const components: ReactNode[] = getComponentsForJoinTree(props.joinTree);

    return <ul>
        <li>
            <PipelineNodeName pipelineNodeId={props.joinTree.node_id} link linkToNewPage/>
            {props.joinTree.relationship_id && <>
                &nbsp;(<PipelineNodeRelationshipName relationshipId={props.joinTree.relationship_id} link linkToNewPage/>)
            </>}

            {props.joinTree.downstream.map((dt, idx) => {
                return <PipelineNodeJoinTreeLabel key={idx} joinTree={dt}/>
            })}
        </li>
    </ul>
}

export default PipelineNodeJoinTreeLabel;