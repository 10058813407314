import PageStructure, { PageContent, PageSidebar, Pane, PaneContent,PaneContentWithSubnav } from "@pages/PageStructure.component";
import { usePipelineNode } from "@stores/data.store";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DagDataLibrary from "@components/nav/DagDataLibrary.component";
import PipelineNodeInfo from "@components/pipelineNodes/PipelineNodeInfo.component";
import PipelineNodeSubnav from "@components/pipelineNodes/PipelineNodeSubnav.component";
import FilesList from "@components/files/FilesList.component";
import { File } from '@models/file';
import DataSourceImporter from "@pages/Sources/DataSourceImporter.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import { PipelineNodeORM } from "@models/pipelineNode";

const PipelineNodeFilesPage = () => {
    const { pipelineNodeId } = useParams();
    const { isLoading, data: pipelineNode, refetch: refetchPipelineNode } = usePipelineNode(pipelineNodeId as string);
    const navigate = useNavigate();

    const onSourceAdded = useCallback((pipelineNodeId: string) => {
        
        navigate(`/node/${pipelineNodeId}`);
    }, [pipelineNodeId]);

    const onFileDelete = useCallback(async (file: File) => {
        await PipelineNodeORM.deleteFile(pipelineNodeId as string, file.id as string);
        
        refetchPipelineNode();
    }, [pipelineNodeId]);

    return <PageStructure
        pageTitle={pipelineNode ? pipelineNode.name : ''}
        breadcrumbs={[
            {
                title: 'Pipeline Node',
            } 
        ]}
    >
        <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3">
                        <PipelineNodeInfo pipelineNodeId={pipelineNodeId as string}/>
                    </div>
                    <div className="p-3 border-top">
                        <DagDataLibrary activeNodeId={`PipelineNode:${pipelineNodeId}`}/>
                    </div>
                </PaneContent>
            </Pane>
        </PageSidebar>
        <PageContent>
                    <Pane>
                        <PipelineNodeSubnav
                            pipelineNodeId={pipelineNodeId as string}
                        >
                        </PipelineNodeSubnav>
                        <PaneContentWithSubnav>
                            <Pane>
                                <PaneContent>
                                    {isLoading && <PliableLoader />}
                                    {!isLoading && <>
                                        <div className="p-3">
                                        <DataSourceImporter 
                                            onSourceAdded={onSourceAdded}
                                            sourceRecordTypeId={pipelineNode?.source_record_type_id}
                                            />
                                        </div>
                                        <div className="p-3">
                                            <FilesList 
                                                source_record_type_id={pipelineNode?.source_record_type_id}
                                                pipelineNode={pipelineNode}
                                                onDelete={onFileDelete}
                                            />
                                        </div>
                                    </>}
                                </PaneContent>
                            </Pane>
                        </PaneContentWithSubnav>
                    </Pane>
        </PageContent>
    </PageStructure>
}

export default PipelineNodeFilesPage;