import { ListRecordsResponse, SingleRecordResponse } from "@services/api/api.service";
import ORM, { BaseModel } from "./orm";


export interface TemplateGroupStep {
    name: string
    description: string;
    action_title: string;
    action_description: string;
    finalizing_message: string;
    status: string;
    model_id?: string;
}

export interface TemplateOutputModel {
    model_id: string
    class_name: string;
}

export interface TemplateGroup extends BaseModel {
    name: string;
    template_id: string;
    template_version: string;
    applied_at: Date;
    status: string;
    output_models: TemplateOutputModel[];
    steps?: TemplateGroupStep[];
    active_step_idx?: number;
    current_check_job_id?: string;
}


export class _TemplateGroupORM extends ORM<TemplateGroup>{
    public async checkForReady(templateGroupId: string) {
        const result = await this.apiService.request('POST', `/${this.endpoint}/${templateGroupId}/check-for-ready`) as SingleRecordResponse<TemplateGroup>;
        return result.record;
    }

};

const TemplateGroupORM = new _TemplateGroupORM('templategroups');
export default TemplateGroupORM;


