import BusinessObjectDataTable from "@components/businessObjects/BusinessObjectDataTable.component";
import BusinessObjectSubnav from "@components/businessObjects/BusinessObjectSubnav.component";
import { Pane, PaneContent, PaneContentWithSubnav } from "@pages/PageStructure.component";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";

const BusinessObjectDataPage = () => {
    const { businessObjectId } = useParams();
    const [searchInput, setSearchInput] = useState('');

    const [ debouncedSearchInput ] = useDebounce(searchInput, 500);
    return <Pane>
        <BusinessObjectSubnav
            businessObjectId={businessObjectId as string}
        >
            <input type="text" placeholder="Search" style={{width: '300px', display: 'inline-block', marginTop: '6px'}} onChange={(e) => setSearchInput(e.target.value as string)} className="form-control round-input" />

        </BusinessObjectSubnav>
        <PaneContentWithSubnav>
            <BusinessObjectDataTable 
                businessObjectId={businessObjectId as string}
                showLineageButton
                searchQuery={debouncedSearchInput}
            />
        </PaneContentWithSubnav>
        
    </Pane>
    // return <div className="container">
        
    //     <div className="content-inner has-subnav">
            
    //     </div>
        
    // </div>
}

export default BusinessObjectDataPage;