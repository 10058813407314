import SaveButton from "@components/button/SaveButton.component";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import { Source, SourceRecordType } from "@models/source";
import { useSourceTypeConfig } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import styled from 'styled-components';
import { useImmer } from "use-immer";
import Select from 'react-select';


interface RecordTypeOptionResponse {
    type: string;
    label: string;
    name: string;
    columns: {
        value: string;
        label: string;
    }[];
    default_primary_key: string[];
}

interface RecordTypeOptionSettings {
    enabled: boolean;
    primaryKey: string[];
    recordTypeInfo: RecordTypeOptionResponse;
}

interface RecordTypeOptionProps {
    rto: RecordTypeOptionResponse;
    enabled?: boolean;
    primaryKey?: string[];
    onToggle?: (val: boolean) => void;
}

const RecordTypeOptionStyles = styled.div`
display: flex;

.switch {
    width: 100px;
}

.label {
    flex: 1;
    font-weight: 600;
    font-family: "Poppins";
}

.primary-key {
    width: 250px;
}

&.disabled .label {
    color: var(--ct-text-muted) !important;
}
`

const RecordTypeOption = (props: RecordTypeOptionProps) => {
    return <RecordTypeOptionStyles className={props.enabled ? 'enabled' : 'disabled'}>
        <div className="switch">
            <Form.Check
                type="switch"
                label=""
                checked={!!props.enabled}
                onChange={(e) => {
                    if (props.onToggle) {
                        props.onToggle!(!props.enabled);
                    }
                }}
            />
        </div>
        <div className="label">
            {props.rto.label}
        </div>
    </RecordTypeOptionStyles>
}

interface Props {
    config: {
        [key: string]: any;
    }
    secretConfig: {
        [key: string]: any;
    };

    onChangeConfig: (key: string, value: any) => void;
    onChangeSecretConfig: (key: string, value: any) => void;
}

const GithubConnectorConfig = (props: Props) => {

    const [orgOptions, setOrgOptions] = useState<{
        label: string;
        value: string;
    }[]>([]);

    const [repoOptions, setRepoOptions] = useState<{
        label: string;
        value: string;
    }[]>([]);

    const loadOrgs = useCallback(async () => {
        const result = await fetch('https://api.github.com/user/orgs', {
            method: 'GET',
            headers: {'Authorization': 'token ' + props.secretConfig.pat},
        });

        const json = await result.json();
        console.log('JSON:', json);
        setOrgOptions(json.map((o: any) => {
            return {
                label: o.login,
                value: o.login,
            };
        }));
    }, [props.secretConfig.pat]);

    const loadRepoForOrg = useCallback(async (orgName: string) => {
        const result = await fetch(`https://api.github.com/orgs/${orgName}/repos`, {
            method: 'GET',
            headers: {'Authorization': 'token ' + props.secretConfig.pat},
        });

        const json = await result.json();
        return json.map((j: any) => {
            return {
                label: j.full_name,
                value: j.full_name,
            }
        });
    }, [props.secretConfig.pat]);

 



    const [selectedOrgs, setSelectedOrgs] = useState<{
        label: string;
        value: string;
    }[]>([]);



    const selectedRepos = useMemo(() => {
        if (props.config.repositories) {
            return repoOptions.filter(r => props.config.repositories.includes(r.value));
        }
    }, [props.config, repoOptions]);

    const updateSelectedRepos = useCallback((selected: string[]) => {
        props.onChangeConfig('repositories', selected);
    }, [props.onChangeConfig]);

    const loadRepos = useCallback(async () => {
        const promises = selectedOrgs.map(o => loadRepoForOrg(o.value));

        const results = await Promise.all(promises);
        setRepoOptions(results.flat(1));
    }, [props.secretConfig.pat, selectedOrgs]);
    
    return <div>
        <Form.Group>
            <Form.Label>Enter Personal Access Token</Form.Label>
            <Form.Control type="password" value={props.secretConfig.pat} onChange={(e) => props.onChangeSecretConfig('pat', e.target.value)}/>
        </Form.Group>
        <Form.Group>
            <Form.Label>Select Organization(s)</Form.Label>
            <button onClick={() => loadOrgs()}>Load Orgs</button>
            <Select 
                isMulti
                className="input-sm" 
                options={orgOptions} 
                onChange={(e) => setSelectedOrgs(e.map(c => c))}
                value={selectedOrgs}
            />

        </Form.Group>
        <Form.Group>
            <Form.Label>Select Repositories</Form.Label>
            <button onClick={() => loadRepos()}>Load Repos</button>
            <Select 
                isMulti
                className="input-sm" 
                options={repoOptions} 
                onChange={(e) => updateSelectedRepos(e.map(c => c.value))}
                value={selectedRepos}
            />
        </Form.Group>
        


    </div>
}
export default GithubConnectorConfig;