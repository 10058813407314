import { useCallback, useEffect, useMemo, useState } from 'react';
import { unstable_useBlocker } from 'react-router-dom';
import { confirmation, requireConfirmation } from './alert/alert.service';
import useGlobalState from '@stores/global.state';

export const useRouteBlocker = (saveFunction: () => void) => {
    const { pageDirty, setPageDirty } = useGlobalState();
    // const [pageDirty, setPageDirty] = useState(false);

    /**
     * Block the route change with the confirmation modal if the page is marked dirty
     * and they are navigating to an actual different page instead of just changing
     * the query params (e.g. opening a drawer or changing sort).
     */
    const shouldBlock = useCallback(({ currentLocation, nextLocation }: any) => {
        return pageDirty && nextLocation.pathname !== currentLocation.pathname;
    }, [pageDirty]);

    const blocker = unstable_useBlocker(shouldBlock);

    const blockNavigationEvent = useCallback(async () => {
        confirmation({
            header: 'Navigate without saving?',
            message: 'Are you sure you want to navigate without saving your changes?',
            confirmationButtonVariant: 'success',
            confirmationButtonText: 'Save First',
            onClose: () => {
                blocker.reset?.();
            },
            onConfirm: async () => {
                await saveFunction();
                blocker.proceed?.();
                setPageDirty(false);
            },
            closeButtonText: 'Go Back',
            secondaryButtonVariant: 'secondary',
            secondaryButtonText: 'Discard Changes',
            onSecondaryAction: async () =>{
                blocker.proceed?.();
                setPageDirty(false);
            }
        });
    }, [blocker, saveFunction]);


    useEffect(() => {
        if (blocker.state === 'blocked') {
            blockNavigationEvent();
        }
    }, [blocker.state]);

    useEffect(() => {
        console.log('PAge dirty is: ', pageDirty);
    }, [pageDirty]);

    return {
        pageDirty,
        setPageDirty,
    }

}