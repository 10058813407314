import React from 'react';
import { ConnectionLineComponentProps, getBezierPath, getStraightPath } from 'reactflow';

function MissionControlConnectionLine(props :ConnectionLineComponentProps) {

  const [edgePath] = getBezierPath({
    sourceX: props.fromX,
    sourceY: props.fromY,
    sourcePosition: props.fromPosition,
    targetPosition: props.toPosition,
    targetX: props.toX,
    targetY: props.toY,
  });

  const style = {
    ...props.connectionLineStyle,
    'strokeWidth': '5',
  };

  return (
    <g>
      <path style={style} strokeWidth={8} fill="none" d={edgePath} />
      <circle cx={props.toX} cy={props.toY} fill="#FF9F00" r={3} stroke="white" strokeWidth={1.5} />
    </g>
  );
}

export default MissionControlConnectionLine;